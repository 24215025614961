
import Login from './Login.vue';
import NotFound from './NotFound.vue';
import Dashboard from './Dashboard.vue';
import AgencyList from './AgencyList.vue';
import AddNewAgent from './AddNewAgent.vue';
import Intake from './Settings/IntakeList.vue';
import AddNewMultipleAgentImport from './AddNewMultipleAgentImport.vue';
// import AddRegion from './Settings/AddRegion.vue';
import RegionList from './Settings/RegionList.vue';
import RegionalSupervisor from './Settings/RegionalSupervisor.vue';
import CourseLevel from './Settings/CourseLevel.vue';
import IntakeStatus from './Settings/IntakeStatus.vue';
import InvoiceStatus from './Settings/InvoiceStatus.vue';
import StudentStatus from './Settings/StudentStatus.vue';
import AgentStatus from './Settings/AgentStatus.vue';
import SettingsStatuses from './Settings/Statuses.vue';
import Commissions from './Settings/Commissions.vue';
import EmailSettings from './Settings/EmailSettings.vue';
import UniversityProfile from './Settings/UniversityProfile.vue';
import UniversitySignup from './UniversitySignup.vue';

//Gateway
import AllApplication from './Gateway/AllApplication.vue';
import IntakeWiseAgentList from './Gateway/IntakeWiseAgentList.vue';
import AgentWiseStudentList from './Gateway/AgentWiseStudentList.vue';

//import BulkVerficationUpload from './Gateway/BulkVerficationUpload.vue';
import UniStudentBulkImport from './Gateway/UniStudentBulkImport.vue';
import BulkStudentImportSummary from './Gateway/BulkStudentImportSummary.vue';

import BulkVerficationList from './Gateway/BulkVerficationList.vue';
import AddAgentMultipleStudent from './Gateway/AddAgentMultipleStudent.vue';

// import AgencyWiseBulkStudent from './Gateway/AgencyWiseBulkStudent.vue';
import DisputedStudentListByIntake from './Gateway/Dispute/DisputedStudentListByIntake.vue';
import DisputedIntakeList from './Gateway/Dispute/DisputedIntakeList.vue';
import ForgotPassword from './ForgotPassword.vue';
import ResetPassword from './ResetPassword.vue';

import AgentAccountManagers from './Settings/AgentAccountManagers.vue';
import PasswordResetAfterLogin from './PasswordResetAfterLogin.vue';
import Users from './Settings/Users.vue';
import Permits from './Settings/Permits.vue';
import AddEditRole from './Settings/AddEditRole.vue';
import RolePermissions from './Settings/RolePermissions.vue';
import MarketingSuiteAddAndUpdate from './Settings/MarketingSuiteAddAndUpdate.vue';
import MarketingSuiteList from './Settings/MarketingSuiteList.vue';
import MarketingSuiteEmailSample from './Settings/MarketingSuiteEmailSample.vue';
import TerritoryList from './Settings/TerritoryList.vue';
import EmailTemplateList from './Settings/EmailTemplateList.vue';
import EmailTemplateAddAndUpdate from './Settings/EmailTemplateAddAndUpdate.vue';
import EmailConfiguration from './Settings/EmailConfiguration.vue';

import Communications from './Gateway/Communications.vue';
import TaskList from './Gateway/TaskList.vue';
import AgentProfile from './AgentProfile.vue';
import Logout from './Logout.vue';
import Invoices from './Invoice/Invoices-old';
import UserSignUp from './UserSignUp.vue';

import ChangePassword from './User/ChangePassword.vue';
import ReportList from './Report/ReportList.vue';
import Faculty from './Settings/Faculty.vue';
import Course from './Settings/Course.vue';
import CourseTuitionFees from './Settings/CourseTuitionFees.vue';

export default [
  { 
    path: '/',
    redirect: '/login' 
  },
  {
    path: '/login',
    component: Login,
    name: 'Login'
  },
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/agency-list',
    component: AgencyList,
    name: 'AgencyList'
  },{
    path: '/add-new-agent',
    component: AddNewAgent,
    name: 'AddNewAgent'
  },{
    path: '/add-new-multiple-agent',
    component: AddNewMultipleAgentImport,
    name: 'AddNewMultipleAgent'
  },{
    path: '/settings/region-list',
    component: RegionList,
    name: 'RegionList'
  },{
    path: '/settings/regional-supervisor-list',
    component: RegionalSupervisor,
    name: 'RegionalSupervisor'
  },{
    path: '/settings/intake',
    component: Intake,
    name: 'Intake'
  },{
    path: '/settings/course-level',
    component: CourseLevel,
    name: 'CourseLevel'
  },{
    path: '/settings/intake-status',
    component: IntakeStatus,
    name: 'IntakeStatus'
  },{
    path: '/settings/invoice-status',
    component: InvoiceStatus,
    name: 'InvoiceStatus'
  },{
    path: '/settings/student-status',
    component: StudentStatus,
    name: 'StudentStatus'
  },{
    path: '/settings/agent-status',
    component: AgentStatus,
    name: 'AgentStatus'
  },{
    path: '/gateway/all-application',
    component: AllApplication,
    name: 'AllApplication'
  },{
    path: '/gateway/intake-wise-agent-list/:id',
    component: IntakeWiseAgentList,
    name: 'IntakeWiseAgentList'
  },{
    path: '/gateway/agent-wise-student-list/:id/:intake_id/:active_tab?',
    component: AgentWiseStudentList,
    name: 'AgentWiseStudentList'
  }/* ,{
    path: '/gateway/bulk-verfication-upload/:id',
    component: BulkVerficationUpload,
    name: 'BulkVerficationUpload'
  } */,{
    path: '/gateway/bulk-verfication-upload/:id',
    component: UniStudentBulkImport,
    name: 'BulkVerficationUpload'
  },{
    path: '/gateway/bulk-student-import-summary/:import_log_id',
    component: BulkStudentImportSummary,
    name: 'BulkStudentImportSummary'
  },{
    path: '/gateway/bulk-verfication-list/:intake_hash_ids',
    component: BulkVerficationList,
    name: 'BulkVerficationList'
  },{
    path: '/settings/statuses',
    component: SettingsStatuses,
    name: 'SettingsStatuses'
  },{
    path: '/settings/commissions',
    component: Commissions,
    name: 'Commissions'
  },{
    path: '/settings/email-settings',
    component: EmailSettings,
    name: 'EmailSettings'
  },
  {
    path: '/settings/university-profile-3998',
    component: UniversityProfile,
    name: 'UniversityProfile'
  },
  {
    path: '/signup',
    component: UniversitySignup,
    name: 'UniversitySignup'
  },
  // {
  //   path: '/agency-wise-bulk-students/:id',
  //   component: AgencyWiseBulkStudent,
  //   name: 'AgencyWiseBulkStudent'
  // },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'ForgotPassword'
  }, {
    path: '/reset-password/:password_reset_token',
    component: ResetPassword,
    name: 'ResetPassword'
  }, {
    path: '/settings/agent-account-managers',
    component: AgentAccountManagers,
    name: 'AgentAccountManagers'
  }, {
    path: '/reset-password',
    component: PasswordResetAfterLogin,
    name: 'PasswordResetAfterLogin'
  }, {
    path: '/settings/users',
    component: Users,
    name: 'Users'
  }, {
    path: '/settings/permits',
    component: Permits,
    name: 'Permits'
  }, {
    path: '/settings/add-edit-role/:id?',
    component: AddEditRole,
    name: 'AddEditRole'
  }, {
    path: '/settings/role-permissions',
    component: RolePermissions,
    name: 'RolePermissions'
  }, {
    path: '/gateway/import-agent-multiple-student/:intake_id',
    component: AddAgentMultipleStudent,
    name: 'AddAgentMultipleStudent'
  }, {
    path: '/gateway/communications/:query?',
    component: Communications,
    name: 'Communications'
  },
  {
    path: '/gateway/dispute-list/:query?',
    component: DisputedIntakeList,
    name: 'DisputedIntakeList'
  },
  {
    path: '/gateway/dispute-list-by-intake/:query?',
    component: DisputedStudentListByIntake,
    name: 'DisputedStudentListByIntake'
  },
  {
    path: '/gateway/task-list',
    component: TaskList,
    name: 'TaskList'
  }, {
    path: '/settings/marketing-suite/:id?',
    component: MarketingSuiteAddAndUpdate,
    name: 'MarketingSuiteAddAndUpdate'
  }, {
    path: '/settings/marketing-suite-list',
    component: MarketingSuiteList,
    name: 'MarketingSuiteList'
  }, {
    path: '/settings/marketing-suite-email-sample/:id',
    component: MarketingSuiteEmailSample,
    name: 'MarketingSuiteEmailSample'
  }, {
    path: '/agent-profile/:id',
    component: AgentProfile,
    name: 'AgentProfile'
  }, {
    path: '/settings/territory-list',
    component: TerritoryList,
    name: 'TerritoryList'
  }, {
    path: '/settings/email-template-list',
    component: EmailTemplateList,
    name: 'EmailTemplateList'
  }, {
    path: '/settings/email-template/:id?',
    component: EmailTemplateAddAndUpdate,
    name: 'EmailTemplateAddAndUpdate'
  }, {
    path: '/settings/email-configuration',
    component: EmailConfiguration,
    name: 'EmailConfiguration'
  }, {
    path: '/logout',
    component: Logout,
    name: 'Logout'
  }, {
    path: '/invoices',
    component: Invoices,
    name: 'Invoices'
  }, {
    path: '/user-signup/:token',
    component: UserSignUp,
    name: 'UserSignUp'
  },
  {
    path: '/user/change-password',
    component: ChangePassword,
    name: 'ChangePassword'
  },
  {
    path: '/report-list',
    component: ReportList,
    name: 'ReportList'
  },
  {
    path: '/settings/faculty_list',
    component: Faculty,
    name: 'Faculty'
  }, 
  {
    path: '/settings/course_list',
    component: Course,
    name: 'Course'
  }, 
  {
    path: '/settings/course_tuition_fees_list',
    component: CourseTuitionFees,
    name: 'CourseTuitionFees'
  }, 
]