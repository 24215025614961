<template>
<section class="role-permit">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                        <h5 class="content-header-title float-left mt-50">Settings</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item">Settings</li>
                                <li class="breadcrumb-item active"><router-link to="/settings/role-permissions">Role Permissions</router-link></li>
                                <!-- <li class="breadcrumb-item"><router-link to="/settings/access-roles">Access Roles</router-link></li> -->
                                <li class="breadcrumb-item active">{{ form.role.id ? 'Edit Role' : 'Add New Role' }}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="form-section">

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title text-primary" v-if="!form.role.id">Add Role</h5>
                    <h5 class="card-title text-primary" v-if="form.role.id">Edit Role</h5>
                    <div class="form-row add_role_form">

                        <div class="col-6">
                            <div class="form-group">
                                <label for="role_name">
                                    Role Name
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" v-model="form.role.role_name" class="form-control" id="role_name" placeholder="Enter role name">
                            </div>
                        </div>
                        <!-- <div class="col">
                            <div class="form-group">
                                <label for="role_code">
                                    Role Code
                                    <span class="text-danger"></span>
                                </label>
                                <input type="text" v-model="form.role.role_code" class="form-control" id="role_code" placeholder="Enter role code">
                            </div>
                        </div> -->
                        <!-- <div class="col">
                            <div class="form-group">
                                <label for="user_type_id">
                                    User Type<span class="text-danger">*</span>
                                </label>
                                <treeselect v-model="form.role.user_type_id" :options="userTypes" placeholder="Select user type" id="user_type_id" autocomplete="off" v-on:input="get_permits()"/>
                            </div>
                        </div> -->
                    </div>
                    
                </div>
            </div>

            <div class="permits-cards">
                <div v-for="(permit, index) in list.permits" class="card mt-1" :key="index">
                    <div class="card-header border-bottom p-1">
                        <b-form-checkbox class="custom-control-primary"
                            :value="permit.id"
                            v-model="form.role.permit_ids"
                        >
                            <strong class="text-primary" v-html="permit.permit_title"></strong>
                        </b-form-checkbox>
                        <b-form-checkbox
                            class="custom-control-success"
                            v-on:change="select_all_with_childs(permit)"
                            v-model="permit.select_all"
                        >
                            Select All
                        </b-form-checkbox>
                    </div>
                    <div v-if="permit.childs.length" class="card-body p-1">
                        <h5 v-if="permit.childs.filter(item => item.type_id == 87).length">Page</h5>
                        <div v-if="permit.childs.filter(item => item.type_id == 87).length" class="row border-bottom pb-1 mb-1">
                            <div class="col-md-3" v-for="child_permit in permit.childs.filter(item => item.type_id == 87)" :key="child_permit.id">
                                <b-form-checkbox class="custom-control-primary"
                                    :value="child_permit.id"
                                    v-model="form.role.permit_ids"
                                >
                                    <span v-html="child_permit.permit_title"></span>
                                </b-form-checkbox>
                            </div>
                        </div>
                        <h5 :class="permit.childs.filter(item => item.type_id == 87).length ? 'mt-1' : ''" v-if="permit.childs.filter(item => item.type_id == 86).length">Operation</h5>
                        <div class="row" v-if="permit.childs.filter(item => item.type_id == 86).length">
                            <div class="col-md-3" v-for="child_permit in permit.childs.filter(item => item.type_id == 86)" :key="child_permit.id">
                                <b-form-checkbox class="custom-control-primary"
                                    :value="child_permit.id"
                                    v-model="form.role.permit_ids"
                                >
                                    <span v-html="child_permit.permit_title"></span>
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-1">
                <div class="card-body">
                    <div class="row">
                        <div class="col text-right">

                            <button type="submit" class="btn btn-primary" v-if="!form.role.id" @click.prevent="save_role()">Submit</button>

                            <button type="submit" class="btn btn-primary" v-if="form.role.id" @click.prevent="save_role()">Update</button>

                        </div>
                    </div>
                </div>
            </div>

        </section>

    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
// import Treeselect from '@riophae/vue-treeselect';
// import SidebarForm from '../../SidebarForm.vue';
// import { XIcon } from 'vue-feather-icons'
// import swal from 'bootstrap-sweetalert';
import { BFormCheckbox } from 'bootstrap-vue'
//import Swal from 'sweetalert2'

export default{
    name: 'AddRole',
    components:{
        MainLayout,
        // Treeselect,
        BFormCheckbox,
        //BFormCheckboxGroup
        // SidebarForm,
        // EditIcon,
        // Trash2Icon,
        // XIcon,
    },
    data:()=>{
        return {
            list:{
                permits:[]
            },
            form: {
                role: {
                    id: '',
                    role_name: '',
                    role_code: '',
                    user_type_id: null,
                    permit_ids: [],
                    checked_all: true,
                }
            },
            listData: [],
            roleList:[],
            permitList:[],
            parentPermitList:[],
            withoutParentPermitList:[],
            userTypes:[],
        };
    },
    computed: {},
    created: async function(){
        this.setup_urls();
        if (this.$route.params.id) {
            this.roleId = this.hash_id(this.$route.params.id, false)[0]
        //    this.getEditingData(this.roleId)
        }
    },
    methods:{
        getUserTypeList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'role_user_types' }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);

                this.userTypes = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })
                this.userTypes.shift()
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        get_permits: async function () {

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/get_permits_by_user_type');

            try {
                ref.wait_me('.permits-cards');
                var res = await jq.get(url);
                ref.list.permits=res.data.permits;

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.permits-cards', 'hide');
            }



        },
        // insertFormData: async function () {

        //     var ref=this;
        //     var jq=ref.jq();
        //     try {
        //         ref.wait_me('.wait_me_insert_form');
        //         var res = await jq.post(ref.url('api/v1/university/ajax/add_role'), ref.form.role);
        //         this.$router.push('/settings/role-permit-list')
        //         ref.alert(res.msg);
        //         this.update_reset()

        //     } catch (err) {
        //         ref.alert(ref.err_msg(err), 'error');
        //     }
        //     finally {
        //         ref.wait_me('.wait_me_insert_form', 'hide');
        //     }
        // },
        save_role: async function () {

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/add_edit_role');

            try{
                ref.wait_me('.form-section');
                var res = await jq.post(url, ref.form.role);
                this.$router.push('/settings/role-permissions')
                ref.alert(res.msg);
                //this.update_reset()

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.form-section', 'hide');
            }

        },
        select_all_with_childs: function(permit){

            var ref=this;
            var permit_ids=[];

            permit_ids.push(permit.id);

            if(permit.childs.length){
                permit.childs.forEach(row=>{
                    permit_ids.push(row.id);
                });
            }

            if(permit.select_all){
                ref.form.role.permit_ids=ref.form.role.permit_ids.concat(permit_ids);
            }else{
                ref.form.role.permit_ids=ref.form.role.permit_ids.filter(value => !permit_ids.includes(value));
            }


        },
        check_for_edit: async function(){

            var ref=this;

            if(this.$route.params.id){
                var role_id=this.hash_id(this.$route.params.id, false)[0];
                
                var jq=ref.jq();
                var url=ref.url('api/v1/university/ajax/get_role_item_by_id');

                try {

                    var res = await jq.get(url, { role_id: role_id });

                    ref.form.role.id = res.data.role.id;
                    ref.form.role.role_name = res.data.role.role_name;
                    ref.form.role.role_code = res.data.role.role_code;
                    ref.form.role.user_type_id = res.data.role.user_type_id;
                    ref.form.role.permit_ids = res.data.permit_ids

                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }

            }            

        }
    },
    mounted: function(){
        feather.replace();
        this.getUserTypeList();
        this.check_for_edit();
        this.get_permits()
        //this.getParentPermitList();
    }
}
</script>
