<template>
<section class="root">
  <main-layout>

		<div class="content-header row">
			<div class="content-header-left col-md-9 col-12 mb-2">
				<div class="row breadcrumbs-top">
						<div class="col-12">
							<!-- <h2 class="content-header-title float-left mb-0">Commission Configuration</h2> -->
							<h5 class="content-header-title float-left mt-50">Commission Configuration</h5>
							<div class="breadcrumb-wrapper">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
									<li class="breadcrumb-item">Settings</li>
									<li class="breadcrumb-item active">Commission Configuration</li>
								</ol>
							</div>
						</div>
				</div>
			</div>
		</div>

		<div class="content-body">
			<div class="row">
				<div class="col-md-4">
					<div class="card uni-comm-configs">
						<div class="card-header d-flex p-1">
							<h3>Configuration List</h3>
							<button class="btn btn-primary btn-sm" v-on:click="open_add_edit_comm()">Add New</button>				
						</div>
						<div class="card-body table-responsive p-0 mb-0">
							<table class="table table-bordered">
								<thead>
									<tr>
										<th class="px-1">Configuration Title</th>
										<th class="px-1 text-center">Status</th>
										<th width="120" class="px-1 text-center">Actions</th>
									</tr>
								</thead>
								<tbody v-if="list.uni_comm_configs && list.uni_comm_configs.length">
									<tr v-for="(row, index) in list.uni_comm_configs" :key="index">
										<td class="px-1">
											<small class="d-block" v-html="row.parsed_comm_configs.title"></small>
										</td>
										<td class="px-1 text-center">
											<span v-if="row.active" class="badge badge-light-success badge-pill hand" v-on:click="update_uni_comm_active_status(row)" v-tooltip="'Click to change Status'">Active</span>
											<span v-else class="badge badge-light-danger badge-pill hand" v-on:click="update_uni_comm_active_status(row)" v-tooltip="'Click to change Status'">Inactive</span>
										</td>
										<td class="px-1 text-center">
											<button class="btn btn-outline-secondary btn-sm p-50 mr-25" v-on:click="show_comm_details(row)">
												<i class="fa fa-search"></i>
											</button>
											<button class="btn btn-outline-info btn-sm p-50 mr-25" v-on:click="open_add_edit_comm(row.id)" >
												<i class="fa fa-pencil"></i>
											</button>
											<button class="btn btn-outline-danger btn-sm p-50" v-on:click="delete_uni_comm_config(row, index)">
												<i class="fa fa-trash-o"></i>
											</button>
										</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<th colspan="3" class="text-center">
											<span v-if="list.uni_comm_configs===null">
												Processing...
											</span>
											<span v-else>
												<i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
												Found no commission type.
											</span>											
										</th>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="col-md-8">
					<!-- <uni-comm-details :active_comm="info.active_comm" :uni_comm_config_list="list.uni_comm_configs" :args="list"></uni-comm-details> -->
					<uni-comm-details :active_comm="info.active_comm" :args="list"></uni-comm-details>
					<!-- <div class="card">
						<div class="card-header p-1 border-bottom">
							<h3>Commission Details</h3>
						</div>
						<div class="card-body">
							<h4 class="text-center my-5">
								<i class="fa fa-info text-info" aria-hidden="true"></i>
								Please select a commission type first.
							</h4>
						</div>
					</div> -->
				</div>
			</div><!--end of content body main row-->
		</div>

		<add-edit-commission ref="add_edit_commission" :args="list" v-on:comm_config_saved="get_uni_comm_configs()"></add-edit-commission>

  </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import AddEditCommission from './Commissions/AddEditCommission.vue';
import UniCommDetails from './Commissions/UniCommDetails.vue';
import Swal from 'sweetalert2';

export default{
  name: 'CommissionSetup',
    metaInfo: {
        titleTemplate: '%s | Commissions'
    },
	components:{
		MainLayout,
		AddEditCommission,
		UniCommDetails
	},
  data:()=>{
    return {
			list:{
				comm_types: [],
				intakes: [],
				course_levels:[],
				regions: [],
				countries:[],
				agents:[],
				territories:[],
				uni_comm_configs:null
			},
			info:{
				active_comm: null
			},
      flag:{},
			form:{}
    };
	},
  methods:{
		init_data: async function(){

			var ref=this;
			var jq=ref.jq();
			var url=ref.url('api/v1/university/ajax/commission_type_init_data');

			try{
				var res=await jq.get(url);
				ref.list.comm_types=res.data.comm_types;
				ref.list.intakes=res.data.intakes;
				ref.list.course_levels=res.data.course_levels;
				ref.list.regions=res.data.regions;
				ref.list.countries=res.data.countries;
				ref.list.agents=res.data.agents;
				ref.list.territories=res.data.territories;
			}catch(err){
				console.log(ref.err_msg(err));
			}

		},
		open_add_edit_comm: function(edit_comm_id=null){
			this.$refs.add_edit_commission.toggle_comm_form(edit_comm_id);
		},
		get_uni_comm_configs: async function(){

			var ref=this;
			var jq=ref.jq();
			var url=ref.url('api/v1/university/ajax/get_uclan_comm_configs');
			ref.info.active_comm=null;
			
			try{
				ref.wait_me('.uni-comm-configs');
				var res=await jq.get(url);
				ref.list.uni_comm_configs=res.data.uni_comm_configs;
				// console.log('res.data.uni_comm_configs', res.data.uni_comm_configs)
				ref.show_comm_details(ref.list.uni_comm_configs[0])
			}catch(err){
				console.log(ref.err_msg(err));
				ref.list.uni_comm_configs=[];
			}finally{
				ref.wait_me('.uni-comm-configs', 'hide');
			}

		},
		show_comm_details: function(row){
			if(this.info.active_comm && this.info.active_comm.id==row.id){
				this.info.active_comm=null;
			}else this.info.active_comm=row;
			
		},
		update_uni_comm_active_status: async function(row){

			var swal_confirm = Swal.mixin({
					customClass: {
							confirmButton: 'btn btn-success mr-1',
							cancelButton: 'btn btn-light'
					},
					buttonsStyling: false
			});

			var { value: active_status } = await swal_confirm.fire({
					title: 'Update commission active Status',
					input: 'radio',
					inputValue: row.active?'yes':'no',
					inputOptions: {
						'yes':'Active',
						'no':'Inactive'
					},
					showCancelButton: true,
					inputValidator:(value)=>{
						if (!value) {
							return 'Please select an active status'
						}
					}
			});

			if(active_status){

					var ref=this;
					var jq=ref.jq();
					var url=ref.url('api/v1/university/ajax/update_uni_comm_active_status');

					var form_data={
							id: row.id,
							active_status: active_status
					};

					try{
							var res=await jq.post(url, form_data);
							//ref.get_uni_comm_configs();
							row.active=active_status=='yes'?1:0;
							ref.alert(res.msg);
					}catch(err){
							ref.alert(ref.err_msg(err), 'error');
					}

			}
		},
		delete_uni_comm_config: async function(row, index){

			if(row.active){
				this.alert('Can not delete active commission configuration. To delete this configuration please change it\'s status to inactive.', 'error');
				return;
			}

			var comm_label=this.cn(row, 'parsed_comm_configs.title', 'this')

			var swal_title=`Do you want to delete ${comm_label} configuration?`;

			var swal_confirm = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-danger mr-1',
					cancelButton: 'btn btn-light'
				},
				buttonsStyling: false
			});

			var {isConfirmed} = await swal_confirm.fire({
				title: 'Delete Confirmation!',
				text: swal_title,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Delete'
			});

			if(isConfirmed){

				var ref=this;
				var jq=ref.jq();
				var url=ref.url('api/v1/university/ajax/delete_uni_comm_config');

				var form_data={
					id: row.id
				};

				try{
					ref.wait_me('.uni-comm-configs');
					var res=await jq.post(url, form_data);
					ref.remove_row(ref.list.uni_comm_configs, index);
					ref.alert(res.msg);
				}catch(err){
					ref.alert(ref.err_msg(err), 'error');
				}finally{
					ref.wait_me('.uni-comm-configs', 'hide');
				}

			}

		}/* ,
		copy_uni_comm_config: async function(row){

		} */
  },
  computed: {},
	created(){			
		this.setup_urls();			
	},
	mounted: function(){
		//this.commissionTypeId = this.cn(this.store_uni, 'commission_type_id', 0);
		feather.replace();				
		this.init_data();
		this.get_uni_comm_configs();
	},
	beforeUpdate(){
		//this.commissionTypeId = this.store_uni.commission_type_id;
	}
}
</script>

<style scoped>
.comm-type-info-card{
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	padding: 8px;
	min-height: 60px;
	margin-bottom: 10px;
	border-radius: 5px;
	text-align: center;
	cursor: pointer;
}

.comm-active{
	border: 3px solid rgb(4, 126, 175);
	color: rgb(4, 126, 175);
	font-weight: 500;
}
</style>