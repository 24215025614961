<template>
<section class="agency-list">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-6 col-6 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Gateway</h2> -->
                        <h5 class="content-header-title float-left mt-50">Gateway</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active"><router-link to="/gateway/all-application">All Lists</router-link></li>
                                <li class="breadcrumb-item active"><router-link :to="`/gateway/intake-wise-agent-list/${$route.params.id}`">{{ info.intake.name }}</router-link></li>
                                <li class="breadcrumb-item active">Agent Lists by Intake</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-6 text-right">
                <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light mr-1"><i data-feather='filter'></i></button>
                <button class="btn btn-primary waves-effect waves-float waves-light" v-on:click="gotoImportAgentMultipleStudentPage()">
                    <i data-feather="plus-square"></i>
                    Import Agents' Lists
                </button>
            </div>
        </div>

        <div v-show="is_intake_before_start_date()" class="card wait_me_list_data mb-1">
            <div class="card-body p-0">
                <div class="alert alert-warning text-center m-1 p-1" role="alert">
                    Agents will be able to submit students from <span v-html="dDate(info.intake.start_date)"></span>.
                </div>
            </div>
        </div>

        <div class="card bg-light-gray mb-1" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1 mb-2 pl-2 pr-2"  v-show="flag.show_filter">
                    <div class="col-md-2">
                        <input type="text" v-model="search.agent_code" class="form-control" id="agent_code" placeholder="Banner code">
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.agent_name" class="form-control" id="agent_name" placeholder="Agency name">
                    </div>
                    <div class="col-md-2">
                        <!-- <treeselect v-model="search.region_id" :options="regionTreeSelectList" placeholder="Select Region" id="region_id" autocomplete="off"/> -->
                        <treeselect v-model="search.territory_id" :options="territoryList" placeholder="Select Territory" id="territory_id" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.country_id" :options="countryTreeSelectList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Student ID">
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.list_id" class="form-control" id="list_id" placeholder="list id">
                    </div>
                </div>

                <div class="form-row px-2 mb-2"  v-show="flag.show_filter">
                    <div class="col-md-2">
                        <treeselect v-model="search.account_manager_id" :options="treeselect_uni_users" placeholder="Select Account Manager" id="country_id" autocomplete="off"/>
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-primary mr-50" @click="searchData()"><i data-feather='search'></i> Search</button>
                        <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-show="is_intake_after_end_date()" class="card wait_me_list_data mb-1">
            <div class="card-body p-0">
                <div class="alert alert-warning text-center m-1 p-1" role="alert">
                    The deadline for this intake was <span v-html="dDate(info.intake.end_date)"></span>. If you submit list now, University may assess this list and can decide based on their policy whether to accept or reject this list.
                </div>
            </div>
        </div> -->

        <div v-if="!is_intake_before_start_date()" class="card wait_me_list_data">
            <div class="card-header d-block pb-0">
                <div class="row">
                    <div class="col-md-7">
                        <p>
                            <strong>
                                <span v-html="info.intake.name" class="h3"></span> <i class="fa fa-long-arrow-right ml-1 mr-1"></i>
                                <b class="mr-1" v-html="'Projected Commission - £ ' + integer(total_projected_comm)"></b>
                                <!-- (<span v-html="info.total_agents"></span>/<span v-html="info.total_students"></span>) -->
                            </strong>

                            <!-- <span v-if="cn(info, 'total_agents', null)" v-html="info.total_agents" class="badge badge-pill badge-light-primary"></span> -->
                        </p>
                        <small @click="searchData()" v-if="cn(info, 'total_agents', null)" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + total_list_data"></small>
                        <small @click="getStatusWiseListData('io_status')" v-tooltip="'Working on International Office'" class="cursor-pointer badge badge-pill badge-light-dark mr-1" v-html="'IO - ' + total_io_status"></small>
                        <small @click="getStatusWiseListData('fo_status')" v-tooltip="'Working on Finance Office'" class="cursor-pointer badge badge-pill badge-light-warning mr-1" v-html="'FO - ' + total_fo_status"></small>
                        <small @click="getStatusWiseListData('cl_status')" v-tooltip="'Preparing Confirmed List'" class="cursor-pointer badge badge-pill badge-light-success mr-1" v-html="'CL - ' + total_cl_status"></small>
                        <small @click="getStatusWiseListData('in_status')" v-tooltip="'Waiting for Invoice'" class="cursor-pointer badge badge-pill badge-light-success mr-1" v-html="'WI - ' + total_in_status"></small>
                        <small @click="getStatusWiseListData('ir_status')" v-tooltip="'Invoice Received'" class="cursor-pointer badge badge-pill badge-light-danger mr-1" v-html="'IR - ' + total_ir_status"></small>
                        <small @click="getStatusWiseListData('ip_status')" v-tooltip="'Invoice Paid'" class="cursor-pointer badge badge-pill badge-light-blue mr-1" v-html="'IP - ' + total_ip_status"></small>
                    </div>
                    <div class="col-md-5 text-right">
                        <!-- <button type="button" @click="toggle_filter()" class="btn btn-primary waves-effect waves-float waves-light mr-1"><i data-feather='filter'></i></button>
                        <button class="btn btn-primary waves-effect waves-float waves-light mr-1" v-on:click="gotoImportAgentMultipleStudentPage()">
                            <i data-feather="plus-square"></i>
                            Import Agents' Lists
                        </button> -->
                        <!-- <b-dropdown class="mr-2" text="Download" variant="outline-primary">
                            <b-dropdown-item  download :href="info.download_url">
                                Excel                                            
                            </b-dropdown-item>
                        </b-dropdown> -->
                            <a :href="info.download_url" download v-tooltip="'Download Agent Lists by Intake'" class="mr-1">
                                <i class="fa fa-cloud-download text-uclan font-size-20" aria-hidden="true"></i>
                            </a>
                            
                            <!-- <i class="fa fa-paper-plane text-success cursor-pointer font-size-20" v-if="total_cl_status != 0" v-on:click="submit_confirm_list_to_agents()" v-tooltip="'Send All Confirmed Lists to Agents'"></i> -->
                            <i class="fa fa-share-square-o text-success cursor-pointer font-size-20" v-if="total_cl_status != 0" v-on:click="submit_confirm_list_to_agents()" v-tooltip="'Send All Confirmed Lists to Agents'"></i>
                            <!-- <button class="btn btn-success waves-effect waves-light ml-1'" v-on:click="submit_confirm_list_to_agents()">
                                <span v-tooltip="'Send All Confirmed Lists to Agents'"><i data-feather="send"></i></span>
                            </button> -->
                            <div class="d-block pt-2">
                                <b-form-group>
                                    <b-form-radio-group
                                        id="radio-slots"
                                        v-model="search.issue_type"
                                        :options="issueStatusList"
                                        @change="searchData()"
                                        name="radio-options-slots"
                                    >
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>
                    </div>
                </div>
            </div>

            

                <!-- <div class="form-row mt-1 mb-2 pl-2 pr-2"  v-show="flag.show_filter">
                    <div class="col-md-2">
                        <input type="text" v-model="search.agent_code" class="form-control" id="agent_code" placeholder="Banner code">
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.agent_name" class="form-control" id="agent_name" placeholder="Agency name">
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.region_id" :options="regionTreeSelectList" placeholder="Select Region" id="region_id" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.country_id" :options="countryTreeSelectList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="uni ref no">
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.list_id" class="form-control" id="list_id" placeholder="list id">
                    </div>
                </div>

                <div class="form-row px-2 mb-2"  v-show="flag.show_filter">
                    <div class="col-md-2">
                        <treeselect v-model="search.account_manager_id" :options="treeselect_uni_users" placeholder="Select Account Manager" id="country_id" autocomplete="off"/>
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-outline-danger mr-50" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                        <button class="btn btn-primary" @click="searchData()"><i data-feather='search'></i>Search</button>
                    </div>
                </div> -->

                <!-- <div class="form-row px-2 mb-2" v-if="!submit_cl_to_agent_checkbox">
                    <div class="col text-right">
                        <button class="btn btn-success waves-effect waves-light ml-1'" v-on:click="submit_confirm_list_to_agents()">
                            <span><i data-feather="send"></i> Send All Confirmed Lists to Agents</span>
                        </button>
                    </div>
                </div> -->
    
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="px-1">#</th>
                                <th class="px-1">
                                    <span v-tooltip="'Account Manager'">AM</span>
                                </th>
                                <th><span v-tooltip="'List Reference ID'">List Ref</span></th>
                                <th>Business Name &#38; Banner CODE</th>
                                <!-- <th>Total Students</th> -->
                                <th class="px-1" v-tooltip="'Submitted Students'">S.Students</th>
                                <th class="text-center" v-tooltip="'Verified Students'">V.Students</th>
                                <!-- <th>Dispute</th> -->
                                <th class="text-right"><span v-tooltip="'Projected Commission'">Projected Comm</span></th>
                                <!-- <th>Submission &amp; Accepted Date</th> -->
                                <th class="text-center">
                                    Status
                                </th>
                                <th class="text-center">
                                    Actions
                                    <!-- <i class="fa fa-square-o cursor-pointer ml-50" style="font-size: 20px" @click="submit_confirm_list_to_agents_button_show()" v-if="submit_cl_to_agent_checkbox && permission_check('cl_completed_button')" v-tooltip="'Send all confirmed lists at one click'"></i>
                                    <i class="fa fa-check-square cursor-pointer text-success ml-50" style="font-size: 20px" @click="submit_confirm_list_to_agents_button_show()" v-if="!submit_cl_to_agent_checkbox && permission_check('cl_completed_button')" v-tooltip="'Send all confirmed lists at one click'"></i> -->
                                </th>
                                <!-- <th>Accepted</th>
                                <th>
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="check-all-invitation" v-model="form.send_invitation.checked_all" v-on:change="check_all_invitaion(true)">
                                        <label class="custom-control-label" for="check-all-invitation"></label>
                                    </div>
                                </th>
                                <th width="15%">Action</th> -->
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td class="px-1" v-html="index + pagination.slOffset"></td>
                                <td class="account-manager px-1">
                                    <div class="d-flex" v-if="item.agent_company">
                                        <div class="avatar-wrapper align-center"  v-if="cn(item, 'agent_company.assigned_account_manager.name', null)">
                                            <div class="avatar bg-light-primary mr-1">
                                                <small class="avatar-content" v-html="avatarText(item.agent_company.assigned_account_manager.name)" v-tooltip="item.agent_company.assigned_account_manager.name"></small>
                                            </div>
                                        </div>
                                        <div v-else class="avatar-wrapper align-center" v-on:click="open_assign_am_form_modal(item)">
                                            <div class="avatar bg-light-warning mr-1">
                                                <small class="avatar-content">
                                                    <user-plus-icon size="1.5x" class="custom-class"></user-plus-icon>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="list-ref">
                                    <div class="d-flex">
                                        <span class="d-block text-uclan hand"  v-html="item.list_code" v-on:click="gotoAgentWiseStudentListPage(item)"></span>
                                        <!-- <span class="">12, 8, 1</span> -->
                                        <i v-if="item.total_dispute" @click="gotoDisputeListPage(item)" class="fa fa-exclamation-triangle mt-25 ml-50 text-warning" v-tooltip="'Dispute Case Exists'"></i>
                                    </div>
                                    <small v-tooltip="'Submission Date'"  v-html="dDate(item.list_submitted_at)"></small>
                                </td>
                                <td class="business-name-and-banner-id">
                                    <div class="d-flex justify-content-left align-items-center cursor-pointer">
                                        <!-- <div class="avatar-wrapper">
                                            <div class="avatar mr-1">
                                                <b-avatar variant="primary" :text="avatarText(item.student_name)"></b-avatar>
                                            </div>
                                        </div> -->
                                        <div class="avatar-wrapper">
                                            <div class="avatar  mr-1" v-tooltip="item.agent_company.country.name">
                                                <img :src="item.agent_flag_url" alt="Avatar" height="32" width="32">
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <span class="font-weight-bold text-uclan font-size-12 hand" @click="gotoAgentWiseStudentListPage(item)" v-html="item.agent_name"></span>
                                            <small class="text-truncate align-middle cursor-pointer">
                                                <small @click="gotoAgentProfilePage(item)" class="badge badge-pill badge-light-primary" v-html="item.agent_code"></small>
                                            </small>
                                        </div>
                                    </div>
                                    
                                </td>

                                <td class="total-students">
                                    <span style="font-size: 14px" v-html="item.student_total" 
                                    class="badge badge-light-secondary"
                                    v-tooltip="item.student_total > 1 ? item.student_total + ' Students Submitted' :  item.student_total + ' Student Submitted'"
                                    ></span>

                                    <!-- <div class="d-inline" v-if="item.total_io_approved && !item.total_fo_approved">
                                        <strong class="ml-50 text-uclan-b" v-html="'IO - ' + item.total_io_approved"></strong>
                                    </div>
                                    <div class="d-inline" v-if="item.total_fo_approved && !item.total_cl_approved">
                                        <strong class="ml-50 text-info" v-html="'FO - ' + item.total_fo_approved"></strong>
                                    </div>
                                    <div class="d-inline" v-if="item.total_cl_approved">
                                        <strong class="ml-50 text-success" v-html="'CL - ' + item.total_cl_approved"></strong>
                                    </div> -->
                                    <!-- <div class="d-inline mr-1" v-if="item.total_dispute">
                                        <strong class="mr-25 text-danger" v-html="item.total_dispute"></strong>
                                        <small class="text-danger">Dispute</small>
                                    </div> -->
                                </td>

                                <td class="text-center auto-verified">
                                    <!-- <span v-html="item.verified_students"></span>/<span v-html="item.unverified_students"></span> -->
                                    <span v-if="item.intake.bulk_verification_status_id == 10" class="badge badge-light-success" v-tooltip="item.verified_students > 1 ? item.verified_students + ' Students Verified' :  item.verified_students + ' Student Verified'" v-html="item.verified_students"></span>
                                    <span v-else v-html="'-'"></span>
                                </td>

                                <!-- <td>
                                    <small class="badge badge-light-secondary" v-html="item.student_total"></small><br>
                                    <small class="text-success" v-html="item.student_total_confirmed + ' Confirmed'"></small>, 
                                    <small class="text-info" v-html="item.student_total_partially_paid + ' Partially Paid'"></small>, 
                                    <small class="text-danger" v-html="item.student_total_dispute + ' Dispute'"></small>
                                </td> -->
                                <!-- <td class="text-center dispute">-</td> -->
                                <td class="projected-comm text-right">
                                    <div v-if="item.projected_comm">
                                        &#163; <span v-html="float2(item.projected_comm)"></span>
                                    </div>
                                    <div v-else class="text-right">-</div>
                                </td>
                                <td class="text-center">
                                    <div v-tooltip="'Working on International Office'" v-on:click="gotoAgentWiseStudentListPageIoTab(item)" class="b-avatar badge badge-light-dark rounded hand ml-1" style="width: 42px; height: 42px;" v-if="item.io_status == null && item.fo_status == null && item.cl_status == null">
                                        <b class="avatar-content" v-html="'IO'"></b>
                                    </div>
                                    <div v-tooltip="'Working on Finance Office'" v-on:click="gotoAgentWiseStudentListPageFoTab(item)" class="b-avatar badge badge-light-warning rounded hand ml-1" style="width: 42px; height: 42px;" v-if="item.io_status != null && item.fo_status == null && item.cl_status == null">
                                        <b class="avatar-content" v-html="'FO'"></b>
                                    </div>
                                    <div v-tooltip="'Preparing Confirmed List'" v-on:click="gotoAgentWiseStudentListPageClTab(item)" class="b-avatar badge badge-light-success rounded hand ml-1" style="width: 42px; height: 42px;" v-if="item.io_status != null && item.fo_status != null && item.cl_status == null">
                                        <b class="avatar-content" v-html="'CL'"></b>
                                    </div>
                                    <div v-tooltip="'Waiting for Invoice'" class="b-avatar badge badge-light-success rounded hand ml-1" style="width: 42px; height: 42px;" v-if="item.io_status != null && item.fo_status != null && item.cl_status != null">
                                        <b class="avatar-content" v-html="'WI'"></b>
                                    </div>
                                    <div v-tooltip="'Waiting for Invoice Received'" class="b-avatar badge badge-light-danger rounded hand ml-1" style="width: 42px; height: 42px;" v-if="item.fo_status != null && item.cl_status != null && item.in_status != null">
                                        <b class="avatar-content" v-html="'IR'"></b>
                                    </div>
                                    <div v-tooltip="'Invoice Paid'" class="b-avatar badge badge-light-blue rounded hand ml-1" style="width: 42px; height: 42px;" v-if="item.fo_status != null && item.cl_status != null && item.in_status != null && item.ir_status != null">
                                        <b class="avatar-content" v-html="'IP'"></b>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <div v-if="permission_check('communications') && item.total_communication_count" @click="open_list_request_form(item)" class="position-relative d-inline-block hand mr-1">
                                        <i class="fa fa-comment-o" v-tooltip="'Communicate with the agent about the list'"></i>
                                        <span class="badge badge-pill badge-light-success badge-up text-success" v-html="item.total_communication_count"></span>
                                    </div>

                                    <div v-if="permission_check('task_add') && item.total_task_count" @click="open_assign_task_modal(item)" class="position-relative d-inline-block hand mr-1">
                                        <i class="fa fa-check-square-o custom-class" v-tooltip="'Assign a task'"></i>
                                        <span class="badge badge-pill badge-light-success badge-up text-success" v-html="item.total_task_count"></span>
                                    </div>

                                    <b-dropdown size="sm" variant="link" class="text-dark" toggle-class="text-decoration-none" no-caret>
                                        <template #button-content>
                                            <span><i class="fa fa-ellipsis-v text-dark" aria-hidden="true"></i></span>
                                        </template>
                                        <b-dropdown-item href="javascript:void(0)" v-if="permission_check('communications')" @click="open_list_request_form(item)">
                                            <!-- <i class="fa fa-comment-o mr-1" v-tooltip="'Communicate with the agent about the list'"></i> Communication -->
                                            <div class="position-relative d-inline-block mr-1">
                                                <i class="fa fa-comment-o" v-tooltip="'Communicate with the agent about the list'"></i>
                                                <span class="badge badge-pill badge-light-success badge-up text-success" v-html="item.total_communication_count"></span>
                                            </div> Communication
                                        </b-dropdown-item>
                                        <b-dropdown-item href="javascript:void(0)" v-if="permission_check('task_add')" @click="open_assign_task_modal(item)">
                                            <div class="position-relative d-inline-block mr-1">
                                                <i class="fa fa-check-square-o custom-class" v-tooltip="'Assign a task'"></i>
                                                <span class="badge badge-pill badge-light-success badge-up text-success" v-html="item.total_task_count"></span>
                                            </div> Assign a task
                                        </b-dropdown-item>
                                        <b-dropdown-item href="javascript:void(0)" @click="open_agent_student_list_activity_log(item)">
                                            <i class="fa fa-clock-o cursor-pointer mr-1" v-on:click="open_agent_student_list_activity_log(item)" v-tooltip="'Activity Log for the List'"></i> Activity Log
                                        </b-dropdown-item>
                                    </b-dropdown>

                                    <!-- <i class="fa fa-comment-o mr-1" v-tooltip="'Communicate with the agent about the list'" v-if="permission_check('communications')" v-on:click="open_list_request_form(item)"></i>
                                    <div v-if="permission_check('task_add')" class="position-relative d-inline-block">
                                        <i class="fa fa-check-square-o custom-class" @click="open_assign_task_modal(item)" v-tooltip="'Assign a task'"></i>
                                        <span class="badge badge-pill badge-light-success badge-up text-success" v-html="item.total_task_count"></span>
                                    </div>
                                    <i class="fa fa-clock-o cursor-pointer ml-1" v-on:click="open_agent_student_list_activity_log(item)" v-tooltip="'Activity Log for the List'"></i> -->
                                    
                                </td>
                                <!-- <td>
                                    <span class="text-truncate align-middle" v-html="'£ ' + item.student_fees"></span><br>
                                    <small class="emp_post text-muted text-truncate align-middle" v-html="'£ ' + item.student_commission"></small><br>
                                </td>
                                <td>
                                    <span class="text-truncate align-middle" v-if="item.accepted_date">
                                        <img :src="item.accepted_user_image" alt="Avatar" height="25" width="25" style="border-radius:50%; margin-right: 5px">
                                        <span v-if="isValidDate(item.accepted_date)">Invalid Date</span>
                                        <span v-else v-html="dDate(item.accepted_date)"></span>
                                    </span>
                                </td>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" :id="'invitation-checkbox-' + item.id" :value="item.id" v-model="form.send_invitation.agent_ids" v-on:change="check_all_invitaion()">
                                        <label class="custom-control-label" :for="'invitation-checkbox-' + item.id"></label>
                                    </div>
                                </td> -->
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="10" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no agent data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                </div>
            </div>
        </div>

        <!-- Compose Email Modal -->

        <student-list-request ref="student_list_request"/>
        <activity-log :log_data="agentStudentActivitylog" ref="agent_student_activity_log_modal"></activity-log>
        <assign-task ref="assign_task_modal"/>

        <b-modal ref="assign_account_manager" hide-footer>
            <template #modal-title>
                <span class="text-primary">Assign Account Manager</span>
            </template>

            <div class="assign_account_manager">
                <div class="row">
                    <div class="col">
                        To assign account manager on <span v-html="form.assign_am.agent_name"></span> Please select an option from dropdown.
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col">
                        <treeselect v-model="form.assign_am.uni_user_id" :options="treeselect_uni_users" placeholder="Select account manager" autocomplete="off"/>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col text-right">
                        <button type="button" class="btn btn-light btn-sm waves-effect waves-float waves-light mr-50" v-on:click="$refs.assign_account_manager.hide()">Cancel</button>
                        <button type="button" class="btn btn-success btn-sm waves-effect waves-float waves-light" v-on:click="submit_assign_am_form()">Save</button>
                    </div>
                </div>
            </div>

            <!-- <template #modal-footer>

            </template> -->
        </b-modal>

    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import { BModal, BPagination, BDropdown, BDropdownItem, BFormGroup, BFormRadioGroup } from 'bootstrap-vue';
// import { BModal, BPagination } from 'bootstrap-vue';
import moment from 'moment'
// import SidebarForm from '../../SidebarForm';     
import { UserPlusIcon } from 'vue-feather-icons'
import Hashids from 'hashids';
import Treeselect from '@riophae/vue-treeselect';
//import { MessageSquareIcon, SendIcon, CheckSquareIcon } from 'vue-feather-icons'
import ActivityLog from '../Partials/ActivityLog.vue';
import Swal from 'sweetalert2'

import StudentListRequest from '../Partials/StudentListRequest.vue';
import AssignTask from '../Partials/AssignTask.vue';

export default{
    name: 'IntakeWiseAgentList',
    metaInfo: {
        titleTemplate: '%s | Agent Lists by Intake'
    },
    components:{
        MainLayout,
        BPagination, BModal, BDropdown, BDropdownItem, BFormGroup, BFormRadioGroup,
        // BPagination, BModal,
        // SidebarForm,
        UserPlusIcon, 
        // CheckSquareIcon,
        Treeselect,
        //BAvatar,
        //MessageSquareIcon, SendIcon, CheckSquareIcon,
        StudentListRequest,
        ActivityLog,
        AssignTask,
    },
    data:()=>{
        return {
            submit_cl_to_agent_checkbox: true,
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: 2,
                slOffset: 1
            },
            loading: true,
            total_projected_comm: 0,
            total_list_data: 0,
            total_io_status: 0,
            total_fo_status: 0,
            total_cl_status: 0,
            total_in_status: 0,
            total_ir_status: 0,
            total_ip_status: 0,
            activityItem: {},
            activityLogs: [],
            agentStudentActivitylog: [],
            counterList: [],
            countryList: [],
            accountManagerList: [],
            territoryList: [],
            search: {
                agent_code: '',
                agent_name: '',
                region_id: null,
                territory_id: null,
                country_id: null,
                account_manager_id: null,
                uni_ref_no: '',
                list_id: '',
                io_status: '',
                fo_status: '',
                cl_status: '',
                issue_type: 'all',
            },
            listData: null,
            flag:{
                show_agent_form: false,
                show_filter: false,
                show_modal_activity_log: false,
                show_list_comment_form: false
            },
            form:{
                send_invitation:{
                    checked_all: false,
                    agent_ids:[]
                },
                list_comment:{
                    visible_type:'user_type_university',
                    message:'',
                    list_id:'',
                    tab_index:0
                },
                assign_am:{
                    agent_id: null,
                    agent_name: null,
                    uni_user_id: null
                }
            },
            info:{
                intake:{
                    name: 'Loading...'
                },
                total_agents:0,
                total_students:0,
                download_url:'#',
            },
            regionTreeSelectList: [],
            regionList: [],
            usersList:[],
            intake_id: ''
        };
    },
    watch: {
    },
    methods:{
        gotoAgentProfilePage: function (item) {
            if (this.permission_check('agent_profile')) {
                // this.$router.push({ name: 'AgentProfile', params: { id: this.hash_id(item.agent_company_id) } })
                let routeData = this.$router.resolve({name: 'AgentProfile', params: { id: this.hash_id(item.agent_company_id) }});
                window.open(routeData.href, '_blank');
            }
        },
        gotoDisputeListPage: function (item) {
            console.log('item', item)
            if (this.permission_check('intake_wise_agents')) {
                let routeData = this.$router.resolve({ name: 'DisputedStudentListByIntake', query: { 
                    list_id: this.hash_id(item.list_id),
                    intake_id: this.hash_id(item.intake_id),
                }})

                window.open(routeData.href, '_blank');
                // this.$router.push({ name: 'DisputedStudentList', query: { intake_id: this.hash_id(item.intake_id) } })
            }
        },
        filterIoStatus: function () {
            const data = this.listData.filter(item => item.io_status == null && item.fo_status == null && item.cl_status == null)
            if (data.length) {
                this.listData = data
            }
        },
        filterFoStatus: function () {
            const data = this.listData.filter(item => item.io_status != null && item.fo_status == null && item.cl_status == null)
            if (data.length) {
                this.listData = data
            }
        },
        filterClStatus: function () {
            const data = this.listData.filter(item => item.io_status != null && item.fo_status != null && item.cl_status == null)
            if (data.length) {
                this.listData = data
            }
        },
        ClearSearchData(){

            this.search.agent_code= '';
            this.search.agent_name= '';
            this.search.region_id= null;
            this.search.territory_id= null;
            this.search.country_id= null;
            this.search.account_manager_id= null;
            this.search.uni_ref_no= '';
            this.search.list_id= '';
            this.search.io_status= '';
            this.search.fo_status= '';
            this.search.cl_status= '';
            this.search.issue_type = 'all';
            this.getListData();

        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        toggle_modal_show_list_comment_form: function(){
            this.flag.show_list_comment_form = !this.flag.show_list_comment_form;
        },
        toggle_modal_activity_log: function(){
            this.flag.show_modal_activity_log = !this.flag.show_modal_activity_log;
        },
        showActiviyLog: async function () {
            
            this.toggle_modal_activity_log()
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_student_list_activity_log'));
                this.activityLogs = res.data.data

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        open_agent_student_list_activity_log: async function (item) {
            var ref=this;
            var jq=ref.jq();
            this.agentStudentActivitylog = []
            this.$refs.agent_student_activity_log_modal.show_modal()

            try {
                const params = { list_id: item.list_id }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_student_list_activity_log'), params);
                if (res.data.data.length) {
                    this.agentStudentActivitylog = res.data.data
                } else {
                    this.agentStudentActivitylog = null
                }

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        isValidDate: function (value) {
            if (value) {
               moment(value).isValid();
            } else {
                return null
            }
        },
        gotoAgentWiseStudentListPage: function (item) {
            if(this.permission_check('agent_wise_students')) {
                if (item.cl_status) {
                    this.$router.push({ name: 'AgentWiseStudentList', params: { 
                        id: this.hash_id(item.list_id),
                        intake_id: this.$route.params.id ,
                        active_tab: 'three',
                    }})
                } else if (item.fo_status) {
                    this.$router.push({ name: 'AgentWiseStudentList', params: { 
                        id: this.hash_id(item.list_id),
                        intake_id: this.$route.params.id ,
                        active_tab: 'three',
                    }})
                } else if (item.io_status) {
                    this.$router.push({ name: 'AgentWiseStudentList', params: { 
                        id: this.hash_id(item.list_id),
                        intake_id: this.$route.params.id ,
                        active_tab: 'two',
                    }})
                } else {
                    this.$router.push({ name: 'AgentWiseStudentList', params: { 
                        id: this.hash_id(item.list_id),
                        intake_id: this.$route.params.id ,
                        // active_tab: 'one',
                    }})
                }
            }
        },
        gotoAgentWiseStudentListPageIoTab: function (item) {
            // if(this.permission_check('international_office')) {
            //     this.$router.push({ name: 'AgentWiseStudentList', params: { 
            //         id: this.hash_id(item.list_id),
            //         intake_id: this.$route.params.id ,
            //         active_tab: 'one',
            //     }})
            // }
            this.$router.push({ name: 'AgentWiseStudentList', params: { 
                id: this.hash_id(item.list_id),
                intake_id: this.$route.params.id ,
                active_tab: 'one',
            }})
        },
        gotoAgentWiseStudentListPageFoTab: function (item) {
            // if(this.permission_check('finance_office')) {
            //     this.$router.push({ name: 'AgentWiseStudentList', params: { 
            //         id: this.hash_id(item.list_id),
            //         intake_id: this.$route.params.id ,
            //         active_tab: 'two',
            //     }})
            // }
            
            this.$router.push({ name: 'AgentWiseStudentList', params: { 
                id: this.hash_id(item.list_id),
                intake_id: this.$route.params.id ,
                active_tab: 'two',
            }})
        },
        gotoAgentWiseStudentListPageClTab: function (item) {
            // if(this.permission_check('confirmed_list')) {
            //     this.$router.push({ name: 'AgentWiseStudentList', params: { 
            //         id: this.hash_id(item.list_id),
            //         intake_id: this.$route.params.id ,
            //         active_tab: 'three',
            //     }})
            // }
            this.$router.push({ name: 'AgentWiseStudentList', params: { 
                id: this.hash_id(item.list_id),
                intake_id: this.$route.params.id ,
                active_tab: 'three',
            }})
        },
        gotoImportAgentMultipleStudentPage: function () {
            if(this.permission_check('import_agent_student')) {
                this.$router.push({ name: 'AddAgentMultipleStudent', params: { intake_id: this.$route.params.id } })
            }
        },
        async searchData () {
            this.getListData();
        },
        check_all_invitaion: function(check_all=false){
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Do you want to accept this list from International Education Counseling Center?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$swal.fire(
                        'Approved!',
                        'You have been accepted.',
                        'success'
                        )

                        var ref=this;
                        var invitation_from=ref.form.send_invitation;

                        if(check_all){

                            if(invitation_from.checked_all){
                            ref.listData.forEach(row=>{
                                invitation_from.agent_ids.push(row.id);
                            });
                            }else  invitation_from.agent_ids=[];

                        }else{
                            
                            if(invitation_from.agent_ids.length==ref.listData.length){
                                invitation_from.checked_all=true;
                            }else invitation_from.checked_all=false;
                        }
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === this.$swal.DismissReason.cancel
                    ) {
                        this.$swal.fire(
                        'Cancelled',
                        'Your imaginary file is safe :)',
                        'error'
                        )
                    }
                })
        },
        uni_course_level_insert_or_delete: async function(item){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.post(ref.url('api/v1/university/ajax/uni_course_level_insert_or_delete'));
                ref.alert(res.msg)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
                item.mapped_with_uni = !item.mapped_with_uni
            }
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();

            var intake_id = null;

            if (!ref.$route.params.id) {
               this.$router.push({ name: 'AllApplication' })
            } else {
                intake_id = this.hash_id(this.$route.params.id, false)[0]
                if (!intake_id) {
                    this.$router.push({ name: 'AllApplication' })
                }else{

                    try{
                        var server_res=await jq.get(ref.url('api/v1/uclan/ajax/get_uni_intake_info'), {
                            intake_id: intake_id
                        });
                        this.loading = false

                        ref.info.intake=server_res.data.intake;
                        ref.info.total_agents=server_res.data.total_agents;
                        ref.info.total_students=server_res.data.total_students;
                    }catch(err){
                        ref.alert(ref.err_msg(err), 'error');
                    }

                    try{
                        const params = Object.assign(this.search, { intake_id: intake_id })
                        var counter_res = await jq.get(ref.url('api/v1/university/ajax/intake_wise_agent_student_list_count'), params);

                        ref.total_list_data = counter_res.data.total_list_data
                        ref.total_io_status = counter_res.data.total_io_status
                        ref.total_fo_status = counter_res.data.total_fo_status
                        ref.total_cl_status = counter_res.data.total_cl_status
                        ref.total_in_status = counter_res.data.total_in_status
                        ref.total_ir_status = counter_res.data.total_ir_status
                        ref.total_ip_status = counter_res.data.total_ip_status

                    }catch(err){
                        ref.alert(ref.err_msg(err), 'error');
                    }

                }
            }

            try {
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, intake_id: intake_id })
                ref.wait_me('.wait_me_list_data');
                var res = await jq.get(ref.url('api/v1/uclan/ajax/gateway_intake_wise_agent_list'), params);
                this.listData = res.data.list.data
                this.total_projected_comm = res.data.total_projected_comm
                this.paginationData(res.data.list)
                // console.log('listData', this.listData)
                this.gen_download_url();
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_list_data', 'hide');
            }
        },
        getStatusWiseListData: async function(status){

            var ref=this;
            var jq=ref.jq();
            var intake_id = this.hash_id(this.$route.params.id, false)[0]

            try {
                const params = Object.assign({}, this.search, 
                { 
                    page: this.pagination.currentPage, 
                    per_page: this.pagination.perPage, 
                    intake_id: intake_id,
                    status: status,
                })
                ref.wait_me('.wait_me_list_data');
                var res = await jq.get(ref.url('api/v1/uclan/ajax/gateway_intake_wise_agent_list'), params);
                this.listData = res.data
                this.paginationData(res)
                this.gen_download_url(status);
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_list_data', 'hide');
            }
        },
        uni_course_level_email: async function(){

            this.$refs.invitation_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/uni_course_level_emails');

            try{

                ref.wait_me('.wait_me_list_data');
                var res=await jq.post(url, ref.form.uni_course_level);

                ref.form.uni_course_level.checked_all=false;
                ref.form.uni_course_level.agent_ids=[];
                ref.alert(res.msg);

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.wait_me_list_data', 'hide');
            }

        },
        gen_download_url: function (status = '') {
            var ref=this;
            var jq=this.jq();
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            var institute_id = ref.store_auth_user.institute_id;
            ref.info.download_url = ref.url('download/intake_wise_agent_list/'+hash_ids.encode(ref.info.intake.id, institute_id));
            this.search.auth_id = ref.store_auth_user.id
            this.search.status = status
            var search_query = jq.param(this.search)
            ref.info.download_url += '?' + search_query

        },
        is_intake_within_deadline: function(){

            var intake=this.info.intake;
            var today=moment();

            if(intake.start_date && intake.end_date){

                var start_date=moment(intake.start_date);
                var end_date=moment(intake.end_date);

                if(today.isBefore(end_date) && today.isAfter(start_date)){
                    return true;
                }else return false;

            }else return true;

        },
        is_intake_before_start_date: function(){

            var intake=this.info.intake;
            var today=moment();

            if(intake.start_date){

                var start_date=moment(intake.start_date);

                if(today.isBefore(start_date)){
                    return true;
                }else return false;

            }else return false;

        },
        is_intake_after_end_date: function(){

            var intake=this.info.intake;
            var today=moment();

            if(intake.end_date){

                var end_date=moment(intake.end_date);

                if(today.isAfter(end_date)){
                    return true;
                }else return false;

            }else return false;

        },
        open_list_request_form: function(agent_student_list){
            if(this.permission_check('communications')) {
                this.$refs.student_list_request.open_list_request_form(agent_student_list);
            }
        },
        open_assign_task_modal: function(agent_student_list){
            if(this.permission_check('task_add')) {
                this.$refs.assign_task_modal.getListData(agent_student_list);
            }
        },
        getAccountManagerList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_account_managers'));
                this.accountManagerList = res.data.managers.map(item => {
                    return {
                        id: item ? item.id : '',
                        label: item ? item.given_name + ' ' + item.family_name : ''
                    }
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getRegionList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_account_managers'));
                this.regionTreeSelectList = res.data.regions
                this.regionList = res.data.regions
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getUsersList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_uni_users_list'));
            this.usersList = res.data.usersList
        },
        open_assign_am_form_modal: function(row){

            if (!this.permission_check('assign_account_manager')) {
                return;
            }

            var form=this.form.assign_am;
            form.agent_id=row.agent_company.id;
            form.agent_name=row.name;
            this.$refs.assign_account_manager.show();

        },
        reset_assign_am_form: function(){

            var form=this.form.assign_am;
            form.agent_id=null;
            form.agent_name=null;
            form.uni_user_id=null;

        },
        submit_assign_am_form: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/assign_agent_account_manager');

            var form_data=ref.form.assign_am;

            try{
                ref.wait_me('.assign_account_manager');
                var res=await jq.post(url, form_data);
                this.getListData();
                ref.alert(res.msg);
                ref.$refs.assign_account_manager.hide();
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                 ref.wait_me('.assign_account_manager', 'hide');
            }
        },
        submit_confirm_list_to_agents_button_show: function () {
            this.submit_cl_to_agent_checkbox = !this.submit_cl_to_agent_checkbox
        },
        submit_confirm_list_to_agents: function(){
            if(!this.permission_check('cl_completed_button')) {
                return;
            }

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Submit Confirmed Lists?',
                html: "<p style='text-align:justify'>Do you want to submit all confirmed lists to agents?</p><p style='text-align:justify'>Remember, once you submit the lists to the agents, you will not be able to do further changes on these lists.</p>",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submit_confirm_list_to_agents_confirm()
                }
            })
        },
        submit_confirm_list_to_agents_confirm: async function(){

            var ref=this;
            var jq=this.jq();

            try{
                ref.wait_me('.wait_me_list_data');
                const params = { intake_id: this.intake_id }
                var res = await jq.post(ref.url('api/v1/uclan/ajax/all_agent_student_submit_confirm_list_to_agents'), params);

                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    // showConfirmButton: false,
                    // timer: 1500
                })
                
                this.getListData();
                this.submit_confirm_list_to_agents_button_show();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.wait_me_list_data', 'hide');
            }

        },
        getTerritoryList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_active_territory_list_data'));
            this.territoryList = res.data.list.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        },
    },
    computed: {
        countryTreeSelectList: function () {
            if (this.search.region_id) {
                var region= this.regionList.find(row=>{
                    return row.id == this.search.region_id;
                });

                return region.countries;
            } else {
                return this.countryList
            }
        },
        // countryTreeSelectList: function () {
        //     console.log('this.form.suite_email.region_ids', this.form.suite_email.region_ids)
        //     if (this.search.region_id) {
        //         let selectedRegions = this.regionList.filter(item => {
        //             return this.form.suite_email.region_ids.includes(item.id)
        //         })

        //         let regionCountryIds = []

        //         selectedRegions.forEach((data) => {
        //             data.region_countries.forEach(item => {
        //                 regionCountryIds.push(item.country_id)
        //             })
        //         })

        //         return this.countryList.filter(item => {
        //             return regionCountryIds.includes(item.id)
        //         })
        //     } else {
        //         return this.countryList
        //     }
        // },
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        },
        getIOTotal: function () {
            const data = this.listData.filter(item => item.io_status == null && item.fo_status == null && item.cl_status == null)
            return data.length
        },
        getFOTotal: function () {
            const data = this.listData.filter(item => item.io_status != null && item.fo_status == null && item.cl_status == null)
            return data.length
        },
        getCLTotal: function () {
            const data = this.listData.filter(item => item.io_status != null && item.fo_status != null && item.cl_status == null)
            return data.length
        },
        treeselect_uni_users: function(){

            return this.usersList.map((row)=>{
                return {
                    id: row.id,
                    label: row.name
                }
            });

        }
    },
    created: async function(){
        this.setup_urls();
        this.intake_id = this.hash_id(this.$route.params.id, false)[0]
        this.gen_download_url();
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        })
        this.getAccountManagerList()
    },
    mounted: function(){
        feather.replace();
        this.getListData();
        this.getRegionList()
        this.getUsersList();
        this.getTerritoryList();

        this.$root.$on('bv::modal::hide', () => {
            this.reset_assign_am_form();
        });

    }
}
</script>

<style scoped>
.form-label{
    font-size: 14px;
    font-weight: 500;
}
</style>
