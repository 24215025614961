<template>
<section class="user-role">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Settings</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active">Permission List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Permission List</h3>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_right_sidebar_form()">
                            <i data-feather="plus-square"></i>
                            Add New Permission
                        </button>
                        <!-- <router-link class="btn btn-primary waves-effect waves-float waves-light" to="/settings/add-permits">
                            <i data-feather="plus-square"></i>
                            Add New Region
                        </router-link> -->
                        <button type="button" @click="toggle_filter()" class="btn btn-primary waves-effect waves-float waves-light ml-2"><i data-feather='filter'></i></button>
                    </div>
                </div>
            </div>

                <div class="form-row mt-1 mb-2  pl-2 pr-2"  v-show="flag.show_filter">
                    <div class="col-md-3">
                        <input type="text" v-model="search.permit_title" class="form-control" id="permit_title" placeholder="Title">
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.permit_code" class="form-control" id="permit_code" placeholder="Code">
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.type_id" :options="permissionTypes" placeholder="Select type" id="type_id" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.parent_id" :options="parentList.filter(item => item.id != form.permits.id)" placeholder="Select parent" id="parent_id" autocomplete="off"/>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-outline-danger mr-50" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                        <button class="btn btn-primary" @click="searchData()"><i data-feather='search'></i>Search</button>
                    </div>
                </div>

            <div class="card-body p-0">
                <div class="table-responsive user-role-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Type</th>
                                <th>Title</th>
                                <th>Code</th>
                                <th>Parent</th>
                                <th>Active</th>
                                <th class="text-center" width="205">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + 1"></td>
                                <td>
                                    <span v-if="item.type" v-html="item.type.name"></span>
                                </td>
                                <td>
                                    <span v-html="item.permit_title"></span>
                                </td>
                                <td>
                                    <span v-html="item.permit_code"></span>
                                </td>
                                <td>
                                    <span class="badge badge-pill badge-light-primary" v-if="item.parent" v-html="item.parent.permit_title"></span>
                                </td>
                                <td>
                                    <!-- <span v-if="item.active == 1" class="badge badge-pill badge-light-success">Active</span>
                                    <span v-else class="badge badge-pill badge-light-danger">Deactive</span> -->
                                    <b-form-checkbox
                                        v-model="item.active"
                                        v-on:change="updateActiveStatus(item)"
                                        :id="'active'+item.id"
                                        class="custom-control-success"
                                        name="check-button"
                                        switch
                                    />
                                </td>
                                <td class="text-center">

                                    <button @click="editData(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;">
                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                    </button>                                                

                                    <button @click="deleteData(item, index)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                        <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="7" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <sidebar-form
            :show_sidebar="flag.show_right_sidebar_form"
            v-on:toggle-sidebar="toggle_right_sidebar_form()"
            title="Add New Permit"
        >
            
            <div class="card-body">
                <h5 class="card-title text-primary" v-if="!form.permits.id">Add Permission</h5>
                <h5 class="card-title text-primary" v-if="form.permits.id">Edit Permission</h5>
                <div class="add_permits_form">
                    <div class="form-group">
                        <label for="permit_title">Title <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.permits.permit_title" class="form-control" id="permit_title" placeholder="Enter title">
                    </div>
                    <div class="form-group">
                        <label for="permit_code">Permission Code <span class="text-danger"></span></label>
                        <input type="text" v-model="form.permits.permit_code" class="form-control" id="permit_code" placeholder="Enter permit code">
                    </div>
                    <div class="form-group">
                        <label for="type_id">Type <span class="text-danger">*</span></label>
                        <treeselect v-model="form.permits.type_id" :options="permissionTypes" placeholder="Select type" id="type_id" autocomplete="off"/>
                    </div>
                    <div class="form-group">
                        <label for="parent_id">Parent</label>
                        <treeselect v-model="form.permits.parent_id" :options="parentList.filter(item => item.id != form.permits.id)" placeholder="Select parent" id="parent_id" autocomplete="off"/>
                    </div>
                    <!-- <div class="form-group">
                        <label for="active">Active</label>
                        <b-form-checkbox
                            v-model="form.permits.active"
                            id="active"
                            class="custom-control-success"
                            name="check-button"
                            switch
                        />
                    </div> -->
                    
                    <button type="submit" class="btn btn-success pull-right" v-if="!form.permits.id" @click.prevent="insertFormData()">Save</button>

                    <button type="submit" class="btn btn-success pull-right" v-if="form.permits.id" @click.prevent="updateFormData()">Update</button>

                    <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
                    
                </div>
            </div>
        </sidebar-form>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../../SidebarForm.vue';
import { EditIcon, Trash2Icon, XIcon } from 'vue-feather-icons'
import { BFormCheckbox } from 'bootstrap-vue'
// import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'

export default{
    name: 'Permit',
    metaInfo: {
        titleTemplate: '%s | Permission'
    },
    components:{
        MainLayout,
        Treeselect,
        SidebarForm,
        EditIcon,
        Trash2Icon,
        XIcon,
        BFormCheckbox
    },
    data:()=>{
        return {
            saveData: true,
            flag:{
                show_right_sidebar_form:false,
                show_filter:false
            },
            form: {
                permits: {
                    id: '',
                    type_id: null,
                    parent_id: null,
                    permit_title: '',
                    permit_code: '',
                    active: '',
                }
            },
            search: {
                type_id: null,
                parent_id: null,
                permit_title: '',
                permit_code: '',
                active: '',
            },
            listData: null,
            userTypes:[],
            permissionTypes:[],
            parentList:[]
        };
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
    },
    methods:{
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        searchData () {
            this.getListData();
        },
        ClearSearchData(){
            this.search = {
                type_id: null,
                parent_id: null,
                permit_title: '',
                permit_code: '',
                active: '',
            }
            this.getListData();
        },
        toggle_right_sidebar_form: function(){
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
            this.form.permits = {}
        },
        update_reset: function () {
            this.form.permits = {};
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
        },
        formClose: function() {
            this.toggle_right_sidebar_form()
        },
        getListData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.user-role-table');
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/university/ajax/get_permits_list'), params);
                if (res.data.list.length) {
                    this.listData = res.data.list.map(item => {
                        const objData = { active : item.active == 1 ? true : false }
                        return Object.assign({}, item, objData)
                    })
                }
                // console.log('res.data.list', this.listData)
                const parentList = res.data.list.filter(item => item.parent_id == null)
                this.parentList = parentList.map(item => {
                    return {
                        id: item.id,
                        label: item.permit_title
                    }
                })
                // console.log('this.listData', this.listData)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.user-role-table', 'hide');
            }
        },
        getPermissionTypeList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'permission_type' }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);

                this.permissionTypes = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        insertFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/add_permits'), ref.form.permits);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_permits'), ref.form.permits);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        updateActiveStatus: async function (item) {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_permits'), item);
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        editData: function (item) {
            this.toggle_right_sidebar_form()
            this.saveData = false

            this.form.permits = JSON.parse(JSON.stringify(item))
        },
        deleteData: async function (item, index) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function (item, index) {
            var ref=this;
                var jq=ref.jq();
                try {
                    var res = await jq.get(ref.url('api/v1/university/ajax/delete_permits'), item);
                    this.listData.splice(index, 1)
                    ref.alert(res.msg)
                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
        },
    },
    mounted: function(){
        feather.replace();
        this.getPermissionTypeList();
        this.getListData();
    }
}
</script>
