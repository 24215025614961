<template>
  <section class="root">
    <sidebar-form
      :show_sidebar="flag.show_stu_req_sidebar"
      v-on:toggle-sidebar="toggle_stu_req_sidebar()"
    >
      <div class="stu-req-section" v-if="!flag.show_stu_req_details">
        <div class="mb-1">
          <h5>
            <span class="align-middle">Requirement List</span>
            <span class="badge badge-primary hand ml-50" v-on:click="form.stu_req.show_form=true" v-show="!form.stu_req.show_form">Add New</span>
          </h5>
        </div>

        <div class="row" v-show="!form.stu_req.show_form">
          <div class="col">
            <div class="alert alert-primary p-1">
              <span>Requirement for student
                <b v-html="agentStudentItem.first_name"></b> <b v-html="agentStudentItem.last_name"></b>
              </span>
              <span>- Student ID: 
                <b v-html="agentStudentItem.uni_ref_no"></b>
              </span>
              belongs to agent <b v-html="agentStudentItem.agent_company.name"></b>.
            </div>
            <div class="table-responsive" v-if="list.stu_reqs.length">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th class="bg-light-primary">Description</th>
                    <th class="text-center bg-light-primary">Type</th>
                    <th class="text-center bg-light-primary">Status</th>
                    <th class="text-center bg-light-primary">Date Time</th>
                    <th class="bg-light-primary"></th>
                  </tr>
                </thead>
                <tbody v-for="(row, index) in list.stu_reqs" :key="index">
                  <tr>
                    <td v-html="index+1"></td>
                    <td>
                      <small v-html="limit_str(row.description, 36)"></small>
                    </td>
                    <td>                      
                      <span v-show="row.need_to_upload" v-tooltip="'Document'" class="badge badge-light-info badge-pill">Doc</span>
                      <span v-show="!row.need_to_upload" v-tooltip="'Information'" class="badge badge-light-success badge-pill">Info</span>

                      <span v-if="row.information" class="badge badge-light-primary badge-pill ml-25 hand" v-on:click="toggle_info(row)">
                        <i class="fa fa-info" aria-hidden="true"></i>
                      </span>

                      <a v-if="row.media_url" :href="row.media_url" target="_blank" >
                        <span class="badge badge-light-primary badge-pill ml-25 hand" v-tooltip="'Download Document'">
                          <i class="fa fa-paperclip" aria-hidden="true"></i>
                        </span>
                      </a>
                      <span class="badge badge-light-secondary badge-pill ml-25" v-if="row.req_provided_at">
                        <i class="fa fa-clock-o"  v-tooltip="dDateTime(row.req_provided_at)"></i>
                      </span>

                    </td>
                    <td class="text-center">
                      <span v-if="!row.completed_at"  class="badge badge-light-warning badge-pill hand" v-on:click="toggle_req_status(row)">Pending</span>
                      <span v-else class="badge badge-light-success badge-pill hand" v-tooltip="'Completed At ' + dNow(row.completed_at)" v-on:click="toggle_req_status(row)">Completed</span>
                    </td>
                    <td class="text-center">
                      <small class="text-muted" v-html="dDate(row.created_at)"></small><br>
                      <small class="text-muted" v-html="dDate(row.created_at, 'LT')"></small>
                    </td>
                    <td class="text-center">
                      <i class="fa fa-search text-muted hand" v-on:click="show_stu_req_details(row)"></i>
                    </td>
                  </tr>

                  <tr v-if="row.show_info">
                    <td class="align-middle" colspan="6">
                      <strong>Provided Information:</strong>
                      <p class="p-0 m-0" v-html="row.information"></p>
                    </td>
                  </tr>

                  <tr v-if="row.change_status">
                    <td colspan="6" class="p-1 bg-light-warning">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'student_list_status_'+row.id" :id="'open_'+row.id" value="0" v-model="form.stu_req_status.completed">
                        <label class="form-check-label" :for="'open_'+row.id">
                          Pending
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" :name="'student_list_status_'+row.id" :id="'closed_'+row.id" value="1" v-model="form.stu_req_status.completed">
                        <label class="form-check-label" :for="'closed_'+row.id">
                          Completed
                        </label>
                      </div>

                      <span class="badge badge-primary hand float-right ml-50" v-on:click="update_stu_req_status()">
                        Update
                      </span>

                      <span class="badge badge-secondary hand float-right" v-on:click="toggle_req_status(row)">
                        Cancel
                      </span>

                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div v-else class="alert alert-warning text-center p-50" role="alert">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              You have no Requirement here.
            </div>
          </div>
        </div>

        <div class="list-request-form-section" v-show="form.stu_req.show_form">
          <div class="row">
            <div class="col form-group">
              <textarea
                class="form-control"
                rows="2"
                placeholder="Description"
                v-model="form.stu_req.description"
              ></textarea>
            </div>
          </div>

          <div class="row">
              <div class="col form-group">
                  <label for="req_types" class="form-label">
                      Select Type
                      <span class="text-danger">*</span>
                  </label>
                  <treeselect v-model="form.stu_req.need_to_upload" :options="list.treeselect_req_types" placeholder="Select Requirement Type" autocomplete="off" id="req_types"/>
              </div>
          </div>

          <div class="attachment-list d-flex justify-content-between align-items-center mb-50" v-for="(attachment, index) in form.attachments" :key="index">
            <div>
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x text-info"></i>
                <i class="fa fa-paperclip fa-stack-1x fa-inverse"></i>
              </span>
              <span v-html="attachment.name"></span>
            </div>
            <div>
              <i class="fa fa-times-circle text-danger fa-2x hand mr-25" aria-hidden="true" v-on:click="remove_attachment(index)"></i>
            </div>
          </div>

          <div class="row">
            <div class="col form-group">
              <button
                class="
                  btn btn-primary btn-sm
                  waves-effect waves-float waves-light
                "
                v-on:click="save_stu_req()"
              >
                Save
              </button>
              <button
                class="
                  btn btn-light btn-sm
                  waves-effect waves-float waves-light ml-50
                "
                v-on:click="close_new_req_form()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        
      </div>

      <div class="stu-req-details" v-else>

        <div class="mb-1">
          <h5>
            <span class="align-middle">Requirement Details</span>
            <span class="badge badge-light-primary hand ml-50" v-on:click="close_stu_req_details()">Go Back</span>
          </h5>
        </div>

        <div class="card">
          <div class="card-header p-50 d-block">
              <span v-show="info.active_stu_req.need_to_upload" class="badge badge-pill badge-light-info">Document</span>
              <span v-show="!info.active_stu_req.need_to_upload" class="badge badge-pill badge-light-success">Information</span>
              <span> Student requirements for 
                <b v-html="agentStudentItem.first_name"></b> <b v-html="agentStudentItem.last_name"></b>
              </span>
              <span>- Student ID: 
                <b v-html="agentStudentItem.uni_ref_no"></b>
              </span>
          </div>
          <div class="card-body px-1 py-50 text_by_me">
              <span class="font-weight-bolder" v-html="info.active_stu_req.description"></span>
          </div>
          <div class="p-25 border-0">

              <span v-show="!info.active_stu_req.completed_at" class="badge badge-light-warning badge-pill">Pending</span>
              <span v-show="info.active_stu_req.completed_at" class="badge badge-light-success badge-pill">Completed</span>

              <small>
                <i v-if="cn(info.active_stu_req, 'added_by.user_type', null) && info.active_stu_req.added_by.user_type=='agent'" class="fa fa-user mx-25"></i>
                <i v-else class="fa fa-university mx-25"></i>
                <span v-html="cn(info.active_stu_req, 'added_by.name', 'N/A')"></span>
                <i class="fa fa-clock-o mx-25" aria-hidden="true"></i>
                <span v-html="dDateTime(info.active_stu_req.created_at) + ' (' + dNow(info.active_stu_req.created_at) + ')'"></span>
              </small>

          </div>
        </div>
        
        <div class="text_by_other mt-1" v-if="info.active_stu_req.media_url">
            <span>Provided Document</span><br>
            <a :href="info.active_stu_req.media_url" target="_blank">
              <i class="fa fa-paperclip mr-25" aria-hidden="true"></i>
              <span v-html="cn(info.active_stu_req, 'media.original_name')"></span>
            </a>
        </div>

        <div class="text_by_other mt-1" v-else-if="info.active_stu_req.information">
            <span>Provided Information:</span><br>
            <strong v-html="info.active_stu_req.information"></strong>
        </div>
        
        <div class="p-25 border-0" v-if="info.active_stu_req.req_provided_at">
            <small>
              <i class="fa fa-clock-o mx-25" aria-hidden="true"></i>
              <span v-html="dDateTime(info.active_stu_req.req_provided_at) + ' (' + dNow(info.active_stu_req.req_provided_at) + ')'"></span>
            </small>
        </div>
        

      </div>

    </sidebar-form>
  </section>
</template>

<script>
import SidebarForm from "../../SidebarForm";
import Treeselect from '@riophae/vue-treeselect';

export default {
  name: "StudentReqirements",
  props: ['student_id'],
  components: {
    SidebarForm,
    Treeselect
    //BTabs, BTab,
    //HomeIcon, UserIcon,
  },
  data: () => {
    return {
      agentStudentItem: {},
      list: {
        stu_reqs:[],
        list_req_comments:[],
        list_req_types:[],
        stu_req_statuses:{
          'Completed': 1,
          'Pending': 0
        },
        treeselect_req_types:[
          {id: 0, label: 'Information'},
          {id: 1, label: 'Document'}
        ]
      },
      form: {
        stu_req:{
            show_form: false,
            student_id:'',
            description:'',
            as_list_id:'',
            need_to_upload:null
        }/* ,
        list_req_comment:{
          show_form: false,
          list_request_id:'',
          comment:''
        } */,
        stu_req_status:{
          id:'',
          completed:''
        },
        //file_form_data:null,
        attachments:[]
      },
      flag: {
        show_stu_req_sidebar: false,
        show_stu_req_details: false
      },
      info:{
        active_stu_req:{}
      }
    };
  },
  created: function () {
    var ref = this;
    ref.setup_urls();
  },
  mounted: function(){
    this.init_data();
  },
  methods: {
    init_data: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v1/university/ajax/get_as_list_req_init_data');

        try{
            var res=await jq.get(url);
            ref.list.list_req_types=res.data.list_req_types;
        }catch(err){
            ref.alert(ref.err_msg(err), 'error');
        }

    },
    toggle_stu_req_sidebar: function () {
      var ref = this;
      var form = ref.form.stu_req;
      ref.flag.show_stu_req_sidebar = !ref.flag.show_stu_req_sidebar;

      if (!ref.flag.show_stu_req_sidebar) {
        form.description = "";
        form.as_list_id = "";
        form.student_id = "";
        form.need_to_upload=null;
      } else {
        ref.close_new_req_form();
        ref.close_stu_req_details();
        ref.get_stu_reqs();
        //ref.form.file_form_data=new FormData();
      }
    },
    open_list_request_form: function(agent_student_list){

        var ref=this;
        var form=ref.form.list_request;
        form.as_list_id=agent_student_list.id;
        this.toggle_stu_req_sidebar();

    },
    open_stu_reqs: function(agent_student){

        var ref=this;
        var form=ref.form.stu_req;
        form.as_list_id=agent_student.list_id;
        form.student_id=agent_student.id;
        this.toggle_stu_req_sidebar();

    },
    save_stu_req: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v1/uclan/ajax/save_stu_req');

        var form_data=new FormData();

        ref.form.attachments.forEach(attach=>{
          form_data.append('attachments[]', attach);
        });

        form_data.append(
            'student_id',
            ref.form.stu_req.student_id
        );

        form_data.append(
            'description',
            ref.form.stu_req.description
        );

        form_data.append(
            'as_list_id',
            ref.form.stu_req.as_list_id
        );

        form_data.append(
            'need_to_upload',
            ref.form.stu_req.need_to_upload
        );

        ref.wait_me('.sidebar-form');

        jq.ajax({
            url: url,
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{

                ref.alert(res.msg);
                ref.close_new_req_form();
                ref.get_stu_reqs();

            }
        }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
        ).always(()=>ref.wait_me('.sidebar-form', 'hide'));

    },
    get_stu_reqs: async function(){

        var ref=this;
        var jq=ref.jq();
        var form=ref.form.stu_req;

        var qstr={
            as_list_id: form.as_list_id,
            student_id: form.student_id
        }

        var url=ref.url('api/v1/uclan/ajax/get_stu_reqs');

        try{
            ref.list.stu_reqs=[];
            ref.wait_me('.stu-req-section');
            var res=await jq.get(url, qstr);
            ref.list.stu_reqs=res.data.stu_reqs;
            ref.agentStudentItem=res.data.agent_student_item;
        }catch(err){
            ref.alert(ref.err_msg(err), 'error');
        }finally{
            ref.wait_me('.stu-req-section', 'hide');
        }

    },
    close_new_req_form: function(){

      var form=this.form.stu_req;
      form.show_form=false;
      form.description='';
      form.need_to_upload=null;
      //this.form.file_form_data=null;
      //this.$refs.req_attachment.value='';
      this.form.attachments=[];

    },
    show_stu_req_details:function(stu_req){

      var ref=this;
      ref.info.active_stu_req=stu_req;
      ref.flag.show_stu_req_details=true;
      //ref.toggle_comment_form(false);
      //ref.get_list_req_comments()

    },
    close_stu_req_details: function(){

      var ref=this;
      ref.info.active_stu_req={};
      ref.flag.show_stu_req_details=false;
      //ref.list.list_req_comments=[];

    },
    toggle_req_status: function(row){

      var ref=this;
      var form=ref.form;
      var change_status=row.change_status;

      //row.description+=' working...';
      
      ref.list.stu_reqs.forEach(item=>item.change_status=false);

      row.change_status=!change_status;

      if(row.change_status){
        form.stu_req_status.id=row.id;
        form.stu_req_status.completed=row.completed_at?1:0;
      }else{
        form.stu_req_status.id='';
        form.stu_req_status.completed='';
      }

    },
    update_stu_req_status: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v1/uclan/ajax/update_stu_req_status');

        try{
            ref.wait_me('.sidebar-form');
            var res=await jq.post(url, ref.form.stu_req_status);
            ref.alert(res.msg);
            ref.get_stu_reqs();
        }catch(err){
            ref.alert(ref.err_msg(err), 'error');
        }finally{
            ref.wait_me('.sidebar-form', 'hide');
        }

    },
    add_attachment: function(e){

      var ref=this;

      var file_input=e.target.files[0];

      setTimeout(function(){
        e.target.value='';
        ref.form.attachments.push(file_input);
      }, 1000);
      
        
      /* this.form.file_form_data.append(
          'attachment', file_input
      ); */

    },
    remove_attachment: function(index){
      this.remove_row(this.form.attachments, index);
    },
    toggle_info: function(row){

      var ref=this;
      var show_info=row.show_info;
      ref.list.stu_reqs.forEach(item=>item.show_info=false);
      row.show_info=!show_info;

    }
  }
};
</script>

<style scoped>
.comment-block{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}

.attachment-list{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  /* margin-top: 8px; */
  border-radius: 25px;
}
</style>
