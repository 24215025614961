<template>
<section class="statuses">
<main-layout>

    <div class="content-header">
        <div class="row mb-1">
            <div class="col">
                <h2>All Status Settings</h2>
            </div>
        </div>
    </div>

    <div class="content-body">
        <!-- Input Sizing start -->
        <section id="input-sizing">
            <div class="row">

                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                List of status groups
                            </h5>
                            <div class="card-tools">
                                <button class="btn btn-sm btn-outline-primary rounded-pill" v-on:click="toggle_status_group_modal_form()">
                                    Add New Group
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <!-- <th>SL</th> -->
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Code</th>
                                        <th>Active</th>
                                        <th>Filter Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in list.status_groups" :key="index">
                                        <!-- <td v-html="index+1"></td> -->
                                        <td v-html="row.id"></td>
                                        <td v-html="row.name"></td>
                                        <td>
                                            <span class="badge badge-pill badge-light-primary" v-html="row.code"></span>
                                        </td>
                                        <td>
                                            <span class="badge badge-pill badge-light-success" v-show="row.active">Yes</span>
                                            <span class="badge badge-pill badge-light-danger" v-show="!row.active">No</span>
                                        </td>
                                        <td class="text-center">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" name="filter_related_status" :value="row.id" v-model="form.filter_status"/>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <edit-icon class="text-warning hand" v-on:click="edit_status_group(row)"></edit-icon>
                                        </td>
                                    </tr>
                                    <tr v-show="!list.status_groups.length">
                                        <td colspan="5" class="text-center">
                                            <alert-triangle-icon size="1.5x" class="text-warning"></alert-triangle-icon>
                                            Found no status group.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer"></div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                List of status
                            </h5>
                            <div class="card-tools">
                                <button class="btn btn-sm btn-outline-primary rounded-pill" v-on:click="toggle_status_form()">
                                    Add New Status
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <!-- <th>SL</th> -->
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Code</th>
                                        <th>Active</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in computed_filtered_statuses" :key="index">
                                        <!-- <td v-html="index+1"></td> -->
                                        <td v-html="row.id"></td>
                                        <td>
                                            <span class="d-block" v-html="row.name"></span>
                                            <span class="badge badge-pill badge-light-dark" v-html="row.group.name"></span>
                                        </td>
                                        <td>
                                            <span class="badge badge-pill badge-light-primary" v-html="row.code"></span>
                                        </td>
                                        <td>
                                            <span class="badge badge-pill badge-light-success" v-show="row.active">Yes</span>
                                            <span class="badge badge-pill badge-light-danger" v-show="!row.active">No</span>
                                        </td>
                                        <td class="text-center">
                                            <edit-icon class="text-warning hand" v-on:click="edit_status(row)"></edit-icon>
                                        </td>
                                    </tr>
                                    <tr v-show="!computed_filtered_statuses.length">
                                        <td colspan="5" class="text-center">
                                            <alert-triangle-icon size="1.5x" class="text-warning"></alert-triangle-icon>
                                            Found no status.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        <div class="card-footer"></div>
                    </div>
                </div>

            </div>
        </section>
        <!-- Input Sizing end -->
    </div>

    <sidebar-form 
        :show_sidebar="flag.show_status_group_form"
        v-on:toggle-sidebar="toggle_status_group_modal_form()"
        title="Add/Edit Status Group"
        >
        <div class="mb-1">
            <h5 class="">
                <span class="align-middle">Add/Edit Status Group</span>
            </h5>
        </div>
        <div class="flex-grow-1 wait_me_edit_form">

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="edit_region_title">Group Name <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.status_group.name" class="form-control form-control-sm" id="edit_region_title" placeholder="Enter group name">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="edit_region_title">Code (Optional)</label>
                        <input type="text" v-model="form.status_group.code" class="form-control form-control-sm" id="edit_region_title" placeholder="Enter group name">
                    </div>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-12">
                    <label for="">Active</label>
                    <div class="d-flex">

                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="status_group_active_yes" name="status_group_active" :value="true" v-model="form.status_group.active">
                            <label class="form-check-label" for="status_group_active_yes">Yes</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="status_group_active_no" name="status_group_active" :value="false" v-model="form.status_group.active">
                            <label class="form-check-label" for="status_group_active_no">No</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">                
                <div class="col-12">
                    <button type="button" class="btn btn-success btn-sm mr-1" v-on:click="save_status_group()">
                        Save
                    </button>
                    <button type="button" v-on:click="reset_forms()" class="btn btn-outline-secondary btn-sm">
                        <x-icon size="1.5x" class="custom-class"></x-icon>
                        Close
                    </button>
                </div>
            </div>

        </div>
    </sidebar-form>

    <sidebar-form 
        :show_sidebar="flag.show_status_form"
        v-on:toggle-sidebar="toggle_status_form()"
        title="Add/Edit Status"
        >
        <div class="mb-1">
            <h5 class="">
                <span class="align-middle">Add/Edit Status</span>
            </h5>
        </div>
        <div class="flex-grow-1 wait_me_edit_form">

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Status Name <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.status.name" class="form-control form-control-sm" placeholder="Enter status name">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Serial</label>
                        <input type="text" v-model="form.status.serial" class="form-control form-control-sm" placeholder="Enter status serial">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Value (Optional)</label>
                        <input type="text" v-model="form.status.value" class="form-control form-control-sm" placeholder="Enter status value">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Code (Optional)</label>
                        <input type="text" v-model="form.status.code" class="form-control form-control-sm" placeholder="Status unique code">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col form-group">
                    <label>Select Status Group</label>
                    <treeselect v-model="form.status.status_group_id" :options="computed_status_groups" placeholder="Select Group" autocomplete="off"/>
                </div>
            </div>

            <div class="row">
                <div class="col form-group">
                    <label>Data (Optional must be valid json data)</label>
                    <textarea class="form-control" rows="2" v-model="form.status.data"></textarea>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-12">
                    <label for="">Active</label>
                    <div class="d-flex">

                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="status_active_yes" name="status_active" :value="true" v-model="form.status.active">
                            <label class="form-check-label" for="status_active_yes">Yes</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="status_active_no" name="status_active" :value="false" v-model="form.status.active">
                            <label class="form-check-label" for="status_active_no">No</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">                
                <div class="col-12">
                    <button type="button" class="btn btn-success btn-sm mr-1" v-on:click="save_status()">
                        Save
                    </button>
                    <button type="button" v-on:click="reset_forms()" class="btn btn-outline-secondary btn-sm">
                        <x-icon size="1.5x" class="custom-class"></x-icon>
                        Close
                    </button>
                </div>
            </div>

        </div>
    </sidebar-form>

</main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import { AlertTriangleIcon, EditIcon, XIcon  } from 'vue-feather-icons';
import Treeselect from '@riophae/vue-treeselect';

import SidebarForm from '../../SidebarForm.vue';

export default {
    name: 'SettingsStatuses',
    components:{
        MainLayout,
        SidebarForm,
        Treeselect,
        AlertTriangleIcon, EditIcon, XIcon
    },
    data:()=>{
        return {
            list:{
                status_groups:[],
                statuses:[]
            },
            form:{
                status_group:{
                    id:'',
                    name:'',
                    code:'',
                    active: true
                },
                status:{
                    id:'',
                    name:'',
                    code:'',
                    status_group_id:null,
                    value:'',
                    serial:'',
                    data:'',
                    active:true
                },
                filter_status:[]
            },
            flag:{
                show_status_group_form: false,
                show_status_form: false
            }
        }
    },
    methods:{
        init_data: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/get_statuses_with_groups');

            try{

                var res=await jq.get(url);
                ref.list=res.data;

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        reset_forms: function(){
            var ref=this;
            var f=ref.form;

            ref.flag.show_status_group_form=false;
            ref.flag.show_status_form=false;

            f.status_group.id='';
            f.status_group.name='';
            f.status_group.code='';
            f.status_group.active=true;

            f.status.id='';
            f.status.name='';
            f.status.value='';
            f.status.code='';
            f.status.data='';
            f.status.status_group_id=null;
            f.status.active=true;

        },
        toggle_status_group_modal_form: function(){
            var ref=this;
            ref.flag.show_status_group_form=!ref.flag.show_status_group_form;
        },
        toggle_status_form: function(){
            var ref=this;
            ref.flag.show_status_form=!ref.flag.show_status_form;
        },
        edit_status_group: function(row){

            var ref=this;
            var tmp=ref.clone(row);
            if(tmp.active==1){
                tmp.active=true;
            }else{
                tmp.active=false; 
            }

            ref.form.status_group=tmp;
            ref.flag.show_status_group_form=true;

        },
        save_status_group: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/add_edit_status_group');
            var form_data=ref.form.status_group;

            try{

                ref.wait_me('.sidebar-form');
                var res=await jq.post(url, form_data);
                ref.init_data();
                ref.alert(res.msg, 'success', ()=>{
                    ref.reset_forms();
                });

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.sidebar-form', 'hide');
            }

        },
        edit_status: function(row){

            var ref=this;
            var tmp=ref.clone(row);
            if(tmp.active==1){
                tmp.active=true;
            }else{
                tmp.active=false; 
            }

            ref.form.status=tmp;
            ref.flag.show_status_form=true;

        },
        save_status: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/add_edit_status');
            var form_data=ref.form.status;

            try{

                ref.wait_me('.sidebar-form');
                var res=await jq.post(url, form_data);
                ref.init_data();
                ref.alert(res.msg, 'success', ()=>{
                    ref.reset_forms();
                });

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.sidebar-form', 'hide');
            }

        }
    },
    computed: {
        computed_status_groups: function(){
            return this.list.status_groups.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        computed_filtered_statuses: function(){

            var ref=this;

            if(ref.form.filter_status.length){
                return ref.list.statuses.filter(each=>{
                    return ref.form.filter_status.includes(each.status_group_id);
                });
            }else return ref.list.statuses

        }
    },
    created: function(){
        this.setup_urls();
        this.init_data();
    }
}
</script>
