<template>
<section class="region-list">
    <main-layout>

        <div class="row">
            <div class="col-md-6 col-6 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h5 class="content-header-title float-left mb-0">Manage Agent</h5> -->
                        <h5 class="content-header-title float-left mt-50">Import Agencies</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active"><router-link to="/agency-list">Manage Agent</router-link></li>
                                <li class="breadcrumb-item active"><router-link to="/add-new-agent">Add Agent</router-link></li>
                                <li class="breadcrumb-item active">Import Agencies</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-6 text-right">
                <router-link to="/agency-list" class="btn btn-outline-primary mr-1">Go Back</router-link>
                <router-link to="/add-new-agent" class="dt-button btn btn-primary btn-add-record mr-1">Add Agent Manually</router-link>
            </div>
        </div>

            <!-- <div class="content-header row">
                <div class="content-header-left col-md-6 col-12 mb-2">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="content-header-title">Add Agency</h2>
                        </div>
                    </div>
                </div>
                 <div class="content-header-right text-md-right col-md-6 col-12 d-md-block d-none">
                    <div class="form-group breadcrumb-right">
                        <router-link to="/agency-list" class="btn btn-outline-primary mr-1">Go Back</router-link>
                        <router-link to="/add-new-agent" class="dt-button btn btn-primary btn-add-record mr-1">Add Agent Manually</router-link>
                    </div>
                </div>
            </div> -->
            <div class="content-body">
                 <!-- Dropzone section start -->
                <section id="dropzone-examples"> 

                    <div class="row">
                        <div class="col-12">
                            <div class="card" v-show="!excelFileImported">
                                <div class="card-header">
                                    <h4 class="card-title">Add Multiple Agents Through EXCEL File Upload</h4>
                                    <!-- <a :href="url('static/agent_company_upload_file_sample.xlsx')" download>
                                        Download a sample EXCEL format
                                    </a> -->
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-6 guideline_div">
                                            <div class="row">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h4 class="card-title m-0">Guidelines:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <p class="card-text">1.<a :href="url('static/agent_company_upload_file_sample_for_uclan.xlsx')"> Click here to download the sample excel file</a>.</p>
                                                        <p class="card-text">2. Insert territory from <router-link to="/settings/territory-list" target="_blank">Territory List</router-link></p>
                                                        <p class="card-text">3. Fill up the file</p>
                                                        <p class="card-text">4. Reupload the completed file</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <button @click="clearDropzone()" id="clear-dropzone" class="btn btn-outline-primary mb-1">
                                                <i data-feather="trash" class="mr-25"></i>
                                                <span class="align-middle">Clear Dropzone</span>
                                            </button>
                                            <vue-dropzone 
                                                ref="agent_list_upload_dropzone"
                                                class="dropzone dropzone-area"
                                                id="dpz-remove-all-thumb"
                                                @vdropzone-success="vsuccess"
                                                @vdropzone-error="verror"
                                                :uploadMultiple=false
                                                :duplicateCheck=false
                                                :useCustomSlot=true
                                                :options="dropzone_configs"
                                                >
                                                <div class="dropzone-custom-content">
                                                    <h1 class="dropzone-custom-title text-primary">Click to upload.</h1>
                                                    <h2 class="dropzone-custom-title text-primary">Maximum 200 rows</h2>
                                                </div>
                                            </vue-dropzone>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="card wait_me_to_process" v-show="excelFileImported">
                                <!-- <div class="card-header">
                                    <h4 class="card-title">Email already exist</h4>
                                </div> -->
                                <div class="card-body p-0">
                                    <!-- <div v-for="(item, index) in importedList" :key="index">
                                        <p><span class="badge badge-dark" v-if="item.duplicate_item == 1">{{ item.email }}</span></p>
                                    </div> -->
                                    <button @click="excelFileImported = false" class="btn btn-primary m-1 pull-right">Back To New Upload</button>
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sl. No.</th>
                                                    <th>Banner Code</th>
                                                    <th>Country</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <!-- <th>Mobile</th> -->
                                                    <th>Contact Person Info</th>
                                                    <th>Primary Territory</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in importedList" :key="index">
                                                    <td v-html="index + 1"></td>
                                                    <td>
                                                        <span v-html="item.agent_code"></span>
                                                        <i v-if="!item.agent_code" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Banner code missing'"></i>
                                                    </td>
                                                    <td>
                                                        <span v-html="item.country_name"></span>
                                                        <i v-if="!item.country_name" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Country name missing'"></i>
                                                    </td>
                                                    <td>
                                                        <span v-html="item.name"></span>
                                                        <i v-if="!item.name" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Name missing'"></i>
                                                    </td>
                                                    <td>
                                                        <span v-html="item.email"></span>
                                                        <i v-if="!item.email" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Email missing'"></i>
                                                        <i v-if="item.duplicate_item == 1" title="This email or banner code already exist!" class="fa fa-clone text-danger ml-1" aria-hidden="true"></i>
                                                    </td>
                                                    <!-- <td v-html="'+' + item.calling_code  + item.agent_mobile"></td> -->
                                                    <td>
                                                        <span v-html="item.contact_person"></span>
                                                        <i v-if="!item.contact_person" class="fa fa-exclamation-triangle text-warning" v-tooltip="'contact person missing'"></i><br>
                                                        <span v-if="item.contact_number" v-html="'+' + item.calling_code + item.contact_number"></span>
                                                        <i v-if="!item.contact_number" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Contact number missing'"></i><br>
                                                        <span v-html="item.contact_email"></span>
                                                        <i v-if="!item.contact_email" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Contact email missing'"></i>
                                                    </td>
                                                    <td>
                                                        <span v-html="item.territory_name"></span>
                                                        <i v-if="!item.territory_name" class="fa fa-exclamation-triangle text-warning" v-tooltip="'Terrritory name missing'"></i>
                                                    </td>
                                                    <td>
                                                        <button @click="deleteItem(index)" class="btn btn-outline-danger btn-sm">
                                                            <i title="Delete" class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button @click="processToUploadData(importedList)" class="btn btn-success mb-2 pull-right">Process To Upload Data</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                </section> 
            </div>    
    </main-layout>
</section>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
// import Treeselect from '@riophae/vue-treeselect';
import swal from 'bootstrap-sweetalert';

export default{
    name: 'AddNewMultipleAgent',
    components:{
        MainLayout,
        vueDropzone: vue2Dropzone
        // Treeselect,
    },
    data:()=>{
        return {
            saveData: true,
            excelFileImported: false,
            success: false,
            importedList: [],
            dropzone_configs: {}
        };
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                    return {
                        id: each.id,
                        label: each.name
                    }
                });
        }
    },
    created () {
        this.setup_urls();
        this.getRegionList();
        this.dropzoneOptions()
    },
    methods:{
        dropzoneOptions: function () {
            var ref = this;
            ref.dropzone_configs = {
                url: ref.url('api/v1/university/ajax/mulitiple_agent_file_upload'),
                // url: ref.url('api/v1/university/ajax/agent_file_upload_data'),
                thumbnailWidth: 200,
                addRemoveLinks: true,
                multipleUpload: false,
                maxFiles: 1,
                acceptedFiles: '.xls,.xlsx',
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('uni_api_token'),
                    'Access-Control-Allow-Origin': '*'
                }
            }
        },
        deleteItem: function (index) {
            swal({
                title: "Are you sure?",
                text: "Press Yes to confirm or Cancel",
                type: "warning",
                showCancelButton: true,
                cancelButtonClass: "btn-light",
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            }, (isConfirm)=>{

                if(!isConfirm) return;
                this.importedList.splice(index, 1);
                

            });
        },
        vsuccess: function (file, response) {
            // swal(response.msg, "", "success")
            this.importedList = response.data.imported_list;
            this.excelFileImported = true;
            this.$refs.agent_list_upload_dropzone.removeAllFiles();
        },
        verror: function (file, response) {
            swal(response.msg, "", "danger")
        },
        clearDropzone: function () {
            this.$refs.agent_list_upload_dropzone.removeAllFiles();
        },
        regionCountry (region) {
            const countryList = region.region_countries.map(item => {
                return {
                    id: item.country.id,
                    name: item.country.name
                }
            })
            return countryList
        },
        getRegionList: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.region-list');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_region_list'));
                // ref.alert(res.msg);
                this.regionList = res.data.regionList

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.region-list', 'hide');
            }
        },
        processToUploadData: async function (data) {

            var ref=this;
            var jq=ref.jq();
            try{
                var formData = {
                    agents: data
                }
                ref.wait_me('.wait_me_to_process');
                var res = await jq.post(ref.url('api/v1/university/ajax/add_agency'), formData);
                ref.alert(res.msg);
                this.$router.push('/agency-list');
                this.excelFileImported = false

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_to_process', 'hide');
            }
        },
    },
    mounted: function(){
        feather.replace();
    }
}
</script>

<style>
    @import '../../static/vuexy/app-assets/vendors/css/file-uploaders/dropzone.min.css';
    @import '../../static/vuexy/app-assets/css/plugins/forms/form-file-uploader.css';

</style>