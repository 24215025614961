<template>
<section class="component-data-list">
    <main-layout>
        <!-- BEGIN: Content-->
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-2">
                        <div class="row breadcrumbs-top">
                            <div class="col-12">
                                <h2 class="content-header-title float-left mb-0">Settings</h2>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                        <li class="breadcrumb-item active">Univesity Profile</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                    <!-- Input Sizing start -->
                    <section id="input-sizing">
                        <div class="row match-height">
                            <div class="col-md-12 col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Update Univesity Profile</h4>
                                    </div>
                                    <div class="card-body wait_me_insert_form">
                                        <div class="form-row">
                                            <div class="col-md-2">
                                                <div class="form-row">
                                                    <div class="col">
                                                        <h4 class="text-primary">Profile Logo</h4>
                                                        <vue-upload-avatar class="company-logo-uploader" :init_img="form.university_profile.logo_url" v-on:confirmedUpload="upload_company_logo"></vue-upload-avatar>
                                                    </div>
                                                </div>
                                                <!-- <div class="form-row mt-3">
                                                    <div class="col">
                                                        <h4 class="text-primary">Cover Logo</h4>
                                                        <vue-upload-avatar class="company-cover-uploader" :init_img="form.university_profile.cover_url" v-on:confirmedUpload="upload_company_cover"></vue-upload-avatar>
                                                    </div>
                                                </div> -->
                                                <div class="form-row mt-1">
                                                    <div class="col">
                                                        <small class="text-warning">
                                                            <alert-triangle-icon size="1.5x" class="custom-class"></alert-triangle-icon>
                                                            Please upload .jpg and .png formatted image.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="form-row">
                                                    <div class="col-md-4 form-group">
                                                            <label for="name">Name <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="form.university_profile.name" class="form-control" id="name" placeholder="Enter name"/>
                                                    </div>
                                                    <div class="col-md-4 form-group">
                                                            <label for="short_name">Short Name <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="form.university_profile.short_name" class="form-control" id="short_name" placeholder="Enter short name"/>
                                                    </div>
                                                    <div class="col-md-4 form-group">
                                                            <label for="email">Email <span class="text-danger">*</span></label>
                                                            <input type="email" v-model="form.university_profile.email" class="form-control" id="email" placeholder="Enter email"/>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col form-group">
                                                        <label for="address">Address <span class="text-danger">*</span></label>
                                                        <input type="text" v-model="form.university_profile.address" class="form-control" id="address" placeholder="Enter address"/>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-md-3 form-group">
                                                        <label for="city">City <span class="text-danger">*</span></label>
                                                        <input type="text" v-model="form.university_profile.city" class="form-control" id="city" placeholder="Enter city"/>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label for="post_code">Post Code <span class="text-danger"></span></label>
                                                        <input type="text" v-model="form.university_profile.post_code" class="form-control" id="post_code" placeholder="Enter post code"/>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label for="country_id">Country <span class="text-danger">*</span></label>
                                                        <treeselect v-model="form.university_profile.country_id" :options="countryList" placeholder="Select Coutry" id="country_id" autocomplete="off"/>
                                                    </div>
                                                    <div class="col-md-3 form-group">
                                                        <label for="web_address">Web Address <span class="text-danger">*</span></label>
                                                        <input type="url" v-model="form.university_profile.web_address" class="form-control" id="web_address" placeholder="Enter web address"/>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-md-6 form-group">
                                                        <label for="description">Description <span class="text-danger">*</span></label>
                                                        <textarea v-model="form.university_profile.description" class="form-control" id="description" placeholder="Enter description"></textarea>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label for="contact_details">Contact Details</label>
                                                        <textarea v-model="form.university_profile.contact_details" class="form-control" id="contact_details" placeholder="Enter contact_details"></textarea>
                                                    </div>
                                                </div>
                                                <dib class="form-row">
                                                    <div class="col text-right">
                                                        <button type="button" class="btn btn-success" @click.prevent="updateFormData()">Update</button>
                                                    </div>
                                                </dib>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- Input Sizing end -->
                </div>
        <!-- END: Content-->


        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
    </main-layout>
</section>
</template>

<script>
import VueUploadAvatar from '../../VueUploadAvatar.vue'
import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect';
// import swal from 'bootstrap-sweetalert';
// import { EditIcon } from 'vue-feather-icons'
import { AlertTriangleIcon } from 'vue-feather-icons'

export default{
    name: 'UniversityProfile',
    components:{
        MainLayout,
        VueUploadAvatar,
        Treeselect,
        AlertTriangleIcon
        // EditIcon,
    },
    data:()=>{
        return {
            saveData: true,
            showModal: false,
            icon: 'edit',
            flag:{
                show_modal_form:false
            },
            form: {
                university_profile: {
                    id: '',
                    country_id: null,
                    name: '',
                    description: '',
                    address: '',
                    email: '',
                    web_address: '',
                    contact_details: '',
                    city: '',
                    post_code: '',
                    uni_type: '',
                    short_name: '',
                    logo_url: '',
                    cover_url: ''
                }
            },
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            editItem: {},
            listData: [],
            countryList: [],
        };
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                    return {
                        id: each.id,
                        label: each.name
                    }
                });
        },
    },
    created: async function () {
        this.setup_urls();
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        })
    },
    methods:{
        upload_company_logo: function(form_data){

          var ref=this;
          ref.ajax_setup('uni_api_token');
          var jq=ref.jq();
          var url=ref.url('api/v1/university/ajax/upload_uni_logo');

         ref.wait_me('.company-logo-uploader');

          jq.ajax({
            url: url,
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{

                // console.log('res.data.uni.logo_url', res.data.uni.logo_url)

                ref.form.university_profile.logo_url=res.data.uni.logo_url;

                ref.$store.commit('siteInfo', {
                    key: 'university',
                    val: res.data.uni
                });

                ref.alert(res.msg);
                
            }
          }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
          ).always(()=>ref.wait_me('.company-logo-uploader', 'hide'));
          
        },
        upload_company_cover: function(form_data){

          var ref=this;
          ref.ajax_setup('uni_api_token');
          var jq=ref.jq();
          var url=ref.url('api/v1/university/ajax/upload_uni_cover');

         ref.wait_me('.company-cover-uploader');

          jq.ajax({
            url: url,
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{
                console.log('res.data.uni.cover_url', res.data.uni.cover_url)

                ref.form.university_profile.cover_url = res.data.uni.cover_url;

                ref.$store.commit('siteInfo', {
                    key: 'university',
                    val: res.data.uni
                });

                ref.alert(res.msg);
                
            }
          }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
          ).always(()=>ref.wait_me('.company-cover-uploader', 'hide'));
          
        },
        getUniversityProfileData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_university_data_by_auth_user'));
                ref.form.university_profile = res.data.university
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_university_profile_data'), ref.form.university_profile);
                this.getUniversityProfileData()
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
    },
    mounted: function(){
        feather.replace();
        this.getUniversityProfileData();
    }
}
</script>

<style scoped>
    /* .regional-form-section {
        min-height: 100% !important;
    } */

</style>