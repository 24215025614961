<template>
<section class="root">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-6 col-6 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Task</h2> -->
                        <h5 class="content-header-title float-left mt-50">Task</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <!-- <li class="breadcrumb-item"><router-link to="/">Gateway</router-link></li> -->
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active">Task List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-6 text-right">
                <button type="button" @click="toggle_filter()" class="btn btn-primary waves-effect waves-float waves-light mr-1"><i data-feather='filter'></i></button>
            </div>
        </div>


        <div class="card bg-light-gray" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-2">
                        <input type="text" v-model="search.list_code" class="form-control" id="list_id" placeholder="List Ref">
                    </div>
                    <div class="col-md-2">
                        <flat-pickr
                            id="due_date"
                            v-model="search.due_date"
                            :config="configs.flatpickr"
                            class="form-control"
                            placeholder="Select Deadline"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.user_id" :options="userList" placeholder="Select Responsible" id="user_id" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.agent_code" class="form-control" id="agent_code" placeholder="Banner Code">
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.status_priority_id" :options="priorityStatusesTreeselect" placeholder="Select Priority" id="status_priority_id" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.tagged_user_id" :options="userList" placeholder="Select Tagged To" id="user_id" autocomplete="off"/>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3"></div>
                    <div class="col-md-3"></div>
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <div class="col-md-6">
                                <button class="btn btn-primary btn-block" @click="getTaskListData()">Search</button>
                            </div>
                            <div class="col-md-6">
                                <button class="btn btn-outline-danger btn-block" @click="clearSearchData()">Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-1 as-list-req">
            <div class="card-header d-inline">
                <!-- <div class="row">
                    <div class="col">
                        <button @click="toggle_filter()" class="btn btn-primary waves-effect waves-float waves-light pull-right"><i data-feather='filter'></i></button>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col">
                        <div class="d-inline mr-1">
                            <span class="badge badge-pill badge-light-primary hand" v-on:click="getTaskListData()">
                                Total - <span v-html="taskListDataCount.total"></span>
                            </span>
                        </div>
                        <div class="d-inline mr-1">
                            <span class="badge badge-pill badge-light-warning hand" v-on:click="getTaskListDataByStatus('pending')">
                                Pending - <span v-html="taskListDataCount.pending"></span>
                            </span>
                        </div>
                        <div class="d-inline mr-1">
                            <span class="badge badge-pill badge-light-info hand" v-on:click="getTaskListDataByStatus('on_going')">
                                Ongoing - <span v-html="taskListDataCount.on_going"></span>
                            </span>
                        </div>
                        <div class="d-inline mr-1">
                            <span class="badge badge-pill badge-light-success hand" v-on:click="getTaskListDataByStatus('completed')">
                                Completed - <span v-html="taskListDataCount.completed"  ></span>
                            </span>
                        </div>
                    </div>
                    <div class="col text-right">
                        <!-- <button class="btn btn-sm btn-primary" v-on:click="toggle_list_request_form()">
                            <i class="fa fa-plus-square-o mr-25"></i>
                            Add New
                        </button> -->
                    </div>
                </div>

            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="2%">#</th>
                                <th width="15%">Agent Company</th>
                                <th width="40%">Title</th>
                                <!-- <th>Description</th> -->
                                <th width="5%" class="text-center">Responsible</th>
                                <th width="15%">Due Date</th>
                                <!-- <th width="5%">Priority</th> -->
                                <!-- <th>Tagged</th> -->
                                <th width="5%">Status</th>
                                <th width="5%">View</th>
                            </tr>
                        </thead>
                        <tbody v-if="taskListData && taskListData.length > 0">
                            <tr v-for="(item, index) in taskListData" :key="index">
                                <td v-html="index + pagination.slOffset"></td>
                                <td>
                                    <!-- <span v-if="item.agent_student_list.agent_company" v-html="item.agent_student_list.agent_company.name"></span><br> -->
                                    <div v-if="item.agent_student_list">
                                        <span @click="gotoAgentProfilePage(item.agent_student_list)" class=" cursor-pointer" v-if="item.agent_student_list.agent_company" v-html="item.agent_student_list.agent_company.name"></span><br/>
                                        <span @click="gotoAgentProfilePage(item.agent_student_list)" class="badge badge-pill badge-light-primary cursor-pointer" v-if="item.agent_student_list.agent_company" v-html="item.agent_student_list.agent_company.agent_code"></span>
                                    </div>
                                    
                                </td>
                                <td>
                                    <span v-html="item.title"></span><br>
                                    <span v-tooltip="'List Ref'" class="hand text-uclan" @click="gotoAgentWiseStudentListPage(item)" v-if="item.agent_student_list" v-html="item.agent_student_list.list_code"></span>
                                <!-- </td>
                                <td>
                                    <span v-html="item.description"></span>
                                </td> -->
                                <td class="text-center">
                                    <!-- <span class="badge badge-pill badge-light-primary" v-if="item.task_responsible" v-html="item.task_responsible.user.name"></span> -->
                                    <div class="avatar-wrapper align-center" v-if="item.task_responsible" v-tooltip="item.task_responsible.user.name">
                                        <div class="avatar bg-light-primary">
                                            <small class="avatar-content" v-html="avatarText(item.task_responsible.user.name)" ></small>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span v-html="dDate(item.due_date)" :class="warningIconShow(item) && item.status < 2 ? 'text-danger' : ''"></span>
                                    <i v-if="item.status < 2 && warningIconShow(item)" class="fa fa-exclamation-triangle ml-25 text-danger" aria-hidden="true" v-tooltip="'Due date already over'"></i><br/>
                                    <span :class="'text-' + priority_status_color(item)" v-if="item.priority" v-html="item.priority.name"></span>
                                </td>
                                <!-- <td>
                                    <span :class="'text-' + priority_status_color(item)" v-if="item.priority" v-html="item.priority.name"></span>
                                </td> -->
                                <!-- <td>
                                    <div v-for="(tagged, index2) in item.task_tagged" :key="index2">
                                        <span class="badge badge-pill badge-light-primary" v-if="tagged.user" v-html="tagged.user.name"></span>
                                    </div>
                                </td> -->
                                <td>
                                    <div v-if="item.status == 0">
                                        <span class="badge badge-light-warning">Pending</span>
                                        <!-- <i v-if="warningIconShow(item)" class="fa fa-exclamation-triangle ml-25 text-warning" aria-hidden="true" v-tooltip="'Due date already over'"></i> -->
                                    </div>
                                    <span v-if="item.status == 1" class="badge badge-light-info">Ongoing</span>
                                    <div v-if="item.status == 2">
                                        <span class="badge badge-light-success">Completed</span><br>
                                        <span v-html="dDate(item.finish_time)"></span>
                                    </div>
                                    <!-- <span v-show="item.status=='Open'" class="badge badge-pill badge-light-warning hand" v-on:click="update_list_req_status(item)">Open</span> -->
                                    <!-- <span v-show="item.status=='Closed'" class="badge badge-pill badge-light-success hand" v-on:click="update_list_req_status(item)">Closed</span> -->
                                </td>
                                <td class="text-center">
                                    <i class="fa fa-search hand" v-on:click="show_view_data(item)"></i>
                                </td>
                            </tr>
                            <!-- <tr v-show="!list.as_list_requests.data.length">
                                <td colspan="8" class="text-center">
                                    <strong>
                                        <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                                        You have no Communications here.
                                    </strong>
                                </td>
                            </tr> -->
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="8" class="text-center">
                                    <span v-if="taskListData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        You have no task here.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-pagination
                    class="mt-1 mr-1"
                    align="right"
                    v-model="pagination.currentPage"
                    pills
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="getTaskListData()"
                    >
                </b-pagination>
            </div>
        </div>

    <sidebar-form
      :show_sidebar="flag.show_assign_task_modal"
      v-on:toggle-sidebar="show_modal()"
    >
      <div>
        <div class="modal_form_heading">
          <!-- <h5>
            Task For
            <span class="badge badge-pill badge-light-secondary" v-if="editItem.agent_student_list" v-html="editItem.agent_student_list.list_code"></span>
          </h5> -->
          <!-- <h5 v-html="editItem.title"></h5> -->
        </div>

        <div class="tab_section">
                <div class="row wait_me_insert_form mt-1">
                    <div class="col-12">
                        <div class="form-group row bg-white box-shadow p-1 mb-2">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="title"><b>{{ editItem.title }}</b></label>
                                    <div class="d-flex">
                                        <div v-if="editItem.agent_student_list">
                                            <label for="status_priority_id"><b>List Ref</b></label>: <span class="hand text-uclan" @click="gotoAgentWiseStudentListPage(editItem)" v-html="editItem.agent_student_list.list_code"></span>
                                        </div>
                                        <div class="ml-1">
                                            <label for="edit_end_date"><b>Due Date</b></label>: <span v-html="editItem.due_date"></span>
                                        </div>
                                        <div class="ml-1">
                                            <label for="status_priority_id"><b>Priority</b>:</label> <span v-if="editItem.priority" v-html="editItem.priority.name"></span>
                                        </div>
                                    </div>
                                    <!-- <input type="text" disabled v-model="editItem.title" class="form-control" id="title" placeholder="Enter titles"> -->
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="description"><b>Description</b>: <span v-html="editItem.description"></span></label>
                                <!-- <p v-html="editItem.description" style="background: #F9F9F9; border-radius: 5px; border: 1px solid lightgray; padding: 10px;"></p> -->
                                <!-- <textarea disabled id="description" class="form-control" v-model="editItem.description" placeholder="Enter description"></textarea> -->
                                <!-- <vue-editor disabled id="description editor1" :editor-toolbar="bodyContentCustomToolbar" v-model="editItem.description" placeholder="Enter description"></vue-editor> -->
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="user_id"><b>Responsible</b></label>: <span v-if="editItem.task_responsible" v-html="editItem.task_responsible.user.name" class="badge badge-light-secondary"></span>
                                            <!-- <treeselect  disabled v-model="editItem.user_id" :options="userList" placeholder="Select user" id="user_id" autocomplete="off"/> -->
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="user_id"><b>Tag</b>:</label>
                                    <span v-if="editItem.task_tagged" class="ml-50">
                                        <span v-for="(tag, index) in editItem.task_tagged" :key="index">
                                            <span v-html="tag.user.name" class="badge badge-light-secondary mr-50"></span>
                                        </span>
                                    </span>
                                    <!-- <treeselect  disabled multiple v-model="editItem.tagged_user_id" :options="userList" placeholder="Select user" id="user_id" autocomplete="off"/> -->
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="status"><b>Status</b> <span class="text-danger">*</span></label>
                                            <treeselect :disabled="editItemFixed.status == 2 ? true : false" v-model="editItem.status" :options="taskStatusList" placeholder="Select status" id="status" autocomplete="off"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="editItemFixed.status != 2">
                                        <div class="mt-2">
                                            <button class="btn btn-primary mr-1 waves-effect waves-float waves-light float-right" @click="updateFormData()">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row bg-white box-shadow p-1">
                            <div class="col-12">
                                <label for="remarks"><b>Remarks</b> <span class="text-danger">*</span></label>
                                <textarea id="remarks" class="form-control" v-model="editItem.remarks" placeholder="Enter remarks"></textarea>
                            </div>
                            <div class="col-12 mt-1">
                                <button class="btn btn-primary btn-sm mr-1 waves-effect waves-float waves-light float-right" @click="saveRemarksData()">Save Remarks</button>
                            </div>
                            <div class="col-sm-12 student_remarks_list" v-if="taskRemarksListData && taskRemarksListData.length">
                                <h4>Remarks</h4>
                                <div class="media align-items-center mt-1" v-for="(item, index) in taskRemarksListData" :key="index">
                                    <div class="avatar bg-light-danger mr-50" v-if="item.created_by">
                                        <div class="avatar-content" v-html="avatarText(item.created_by.name)" v-tooltip="item.created_by.name"></div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mb-0">
                                            <small>{{ dNow(item.created_at) }}</small>
                                        </h6>
                                        <p class="mb-0" v-html="item.remarks"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

      </div>

    </sidebar-form>

    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
// import SidebarForm from '../../SidebarForm.vue';
import SidebarForm from '../../SidebarFormLighterBlue.vue';
import feather from 'feather-icons';
import { BPagination } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';
// import { VueEditor } from "vue2-editor";
// import { BTabs, BTab} from 'bootstrap-vue';
// import Swal from 'sweetalert2';
import moment from 'moment';

export default{
    name: 'TaskList',
    metaInfo: {
        titleTemplate: '%s | Task List'
    },
    components:{
        MainLayout, BPagination,
        // BTabs, BTab,
        SidebarForm,
        Treeselect,
        // VueEditor
    },
    data:()=>{
        return {
            taskListData: null,
            taskRemarksListData: [],
            priorityStatuses: [],
            priorityStatusesTreeselect: [],
            userList: [],
            bodyContentCustomToolbar: [
                ["bold", "italic", "underline", "link"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    // minDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            form: {
                list_req_status:{
                    id:'',
                    status:''
                },
            },
            flag:{
                show_assign_task_modal: false,
                show_filter: false,
            },
            editItemFixed: {},
            editItem: {
                user_id: null,
                tagged_user_id: []
            },
            taskListDataCount: {},
            search: {
                list_code: '',
                due_date: '',
                user_id: null,
                agent_code: '',
                status_priority_id: null,
                tagged_user_id: null,
            },
        };
    },
    methods:{
        gotoAgentWiseStudentListPage: function (item) {
            if(this.permission_check('agent_wise_students')) {
                if (item.agent_student_list) {
                    
                    let routeData = this.$router.resolve({ name: 'AgentWiseStudentList', params: { 
                        id: this.hash_id(item.agent_student_list.id),
                        intake_id: this.hash_id(item.agent_student_list.intake_id),
                        // active_tab: 'one',
                    }})

                    window.open(routeData.href, '_blank');
                }
            }
        },
        gotoAgentProfilePage: function (item) {
            if (item.agent_company) {
                // this.$router.push({ name: 'AgentProfile', params: { id: this.hash_id(item.agent_company.id) } })
                let routeData = this.$router.resolve({name: 'AgentProfile', params: { id: this.hash_id(item.agent_company.id) }});
                window.open(routeData.href, '_blank');
            }
        },
        clearSearchData(){
            this.search.list_code = ''
            this.search.due_date = ''
            this.search.user_id = null
            this.search.agent_code = ''
            this.search.status_priority_id = null
            this.search.tagged_user_id = null
            this.getTaskListData()
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        warningIconShow: function(item){
            // var todayDate = new Date()
            var today = new Date();
            var todayDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            if (item.due_date < todayDate) {
                return true
            } else {
                return false
            }
        },
        show_view_data: function(item){
            const ref = this;
            this.editItemFixed = ref.clone(item)
            this.editItem = ref.clone(item)
            this.editItem.tagged_user_id = []

            this.editItem.user_id = this.editItem.task_responsible ? this.editItem.task_responsible.user.id : null;
            // this.editItem.remarks = this.editItem.task_remarks ? this.editItem.task_remarks : [];
            if (this.editItem.task_tagged) {
                this.editItem.task_tagged.map(data => {
                    ref.editItem.tagged_user_id.push(data.user.id)
                })
            }
            this.editItem.due_date = moment(this.editItem.due_date).format("DD MMM YYYY");
            this.getTaskRemarksList()

            this.show_modal()
        },
        show_modal: function(){
            this.flag.show_assign_task_modal = !this.flag.show_assign_task_modal;
        },
        // init_data: async function(){

        //     var ref=this;
        //     var jq=ref.jq();
        //     var url=ref.url('api/v1/university/ajax/get_as_list_req_init_data');

        //     try{
        //         ref.wait_me('.as-list-req');
        //         var res=await jq.get(url);
        //         ref.info.list_req_counter=res.data.list_req_counter;
        //         ref.list.list_req_types=res.data.list_req_types;
        //     }catch(err){
        //         ref.alert(ref.err_msg(err), 'error');
        //     }finally{
        //         ref.wait_me('.as-list-req', 'hide');
        //     }

        // },
        getTaskListData: async function(){

            var ref=this;
            var jq=ref.jq();

            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })

            try{
                ref.wait_me('.as-list-req');
                var res=await jq.get(ref.url('api/v1/university/ajax/get_task_list_data_with_pagination'), params);
                ref.taskListData = res.data.list.data.map(item => {
                    item.priority = item.priority ? Object.assign({}, item.priority, JSON.parse(item.priority.data)) : ''
                    return Object.assign({}, item)
                });
                this.paginationData(res.data.list)
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.as-list-req', 'hide');
            }
        },
        getTaskListDataByStatus: async function(status){

            var ref=this;
            var jq=ref.jq();

            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, status: status })

            try{
                ref.wait_me('.as-list-req');
                var res=await jq.get(ref.url('api/v1/university/ajax/get_task_list_data_with_pagination'), params);
                ref.taskListData = res.data.list.data.map(item => {
                    item.priority = item.priority ? Object.assign({}, item.priority, JSON.parse(item.priority.data)) : ''
                    return Object.assign({}, item)
                });
                this.paginationData(res.data.list)
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.as-list-req', 'hide');
            }
        },
        getTaskListDataCount: async function(){

            var ref=this;
            var jq=ref.jq();

            try{
                ref.wait_me('.as-list-req');
                var res=await jq.get(ref.url('api/v1/university/ajax/get_task_list_data_count'));
                ref.taskListDataCount = res.data;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.as-list-req', 'hide');
            }
        },
        priority_status_color: function(row){

            const status =  this.priorityStatuses.find(item=>{
                if (item.id == row.status_priority_id) {
                    return item
                }
            });

            if (!status) {
                return 'secondary'
            } else {
                return status.color
            }

        },
        getPriorityStatuses: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'task_priorty' }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);
                this.priorityStatuses = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })
                
                this.priorityStatusesTreeselect = this.priorityStatuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getUsersList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_users_by_institute'))
                this.userList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                const params = Object.assign({}, this.editItem )
                var res = await jq.post(ref.url('api/v1/uclan/ajax/assign_task_data_update'), params);
                this.getTaskListData()
                this.getTaskListDataCount()
                ref.alert(res.msg);
                this.editItem = {}
                this.show_modal()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        saveRemarksData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                const params = Object.assign({}, this.editItem )
                var res = await jq.post(ref.url('api/v1/university/ajax/save_task_remarks_data'), params);
                this.editItem.remarks = ''
                this.getTaskRemarksList()
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getTaskRemarksList: async function(){

            var ref=this;
            var jq=ref.jq();

            const params = Object.assign({id: this.editItem ? this.editItem.id : ''}, this.search)
            console.log('params', params)

            try{
                ref.wait_me('.student_remarks_list',);
                var res=await jq.get(ref.url('api/v1/university/ajax/get_task_remarks_list_data'), params);
                ref.taskRemarksListData = res.data.list
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.student_remarks_list', 'hide');
            }
        },
    },
    computed: {
        treeselect_req_types: function(){

            return this.list.list_req_types.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            });

        }
    },
    created: async function(){
        this.setup_urls();
        // console.log('this.editItem.tagged_user_id', this.editItem.tagged_user_id)
        // this.init_data();
    },
    mounted: function(){
        feather.replace();
        this.getTaskListDataCount();
        this.getTaskListData();
        this.getPriorityStatuses();
        this.getUsersList();
    }
}
</script>

<style scoped>
.comment-block{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}

.attachment-list{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  /* margin-top: 8px; */
  border-radius: 25px;
}

.badge-border{
    border: 2px solid;    
}
.quillWrapper{
    height: 6rem;
}
</style>
