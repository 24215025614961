<template>
  <section class="agency-list">
    <main-layout>
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <!-- <h2 class="content-header-title float-left mb-0">Agent Profile</h2> -->
              <h5 class="content-header-title float-left mt-50">Agent Profile</h5>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/agency-list">Agent List</router-link>
                  </li>
                  <li class="breadcrumb-item active">Agent Profile</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body wait_me">
        <div id="user-profile">


          <!-- profile info section -->
          <section id="profile-info">
            <div class="row">
              <div class="col-lg-4 col-12">
                <!-- <div class="row">
                  <div class="col-12 order-2 order-lg-1">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center">
                          <div class="profile-img">
                            <img
                              :src="agentCompany.logo_url"
                              class="rounded img-fluid"
                              style="width: 140px; height: auto"
                              alt="Agent Profile Image"
                            />
                          </div>
                          <div class="p-50">
                            <h4 class="text-dark" v-html="agentCompany.name"></h4>
                            <p class="text-dark mt-1" v-html="'Banner Code: ' + agentCompany.agent_code"></p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="row">
                  <div class="col-12 order-2 order-lg-1">
                    <div class="card">
                      <div class="card-body">
                        <!-- <h5 class="mb-1">Company Details <i v-if="permission_check('agent_primary_market_edit')" @click="editData(agentCompany)" class="fa fa-pencil-square-o cursor-pointer pull-right"></i></h5> -->
                        <h5 class="mb-1"><i v-if="permission_check('agent_primary_market_edit')" @click="editData(agentCompany)" class="fa fa-pencil-square-o cursor-pointer pull-right"></i></h5>
                        
                        <div class="d-flex align-items-start">
                          <div class="profile-img" style="width: 80px">
                            <img
                              :src="agentCompany.logo_url"
                              class="rounded"
                              style="width: 80px;"
                              alt="Agent Profile Image"
                            />
                          </div>
                          <div class="pl-50">
                            <h4 class="text-dark" v-html="agentCompany.name"></h4>
                            <p class="text-dark mt-1" v-html="'Banner Code: ' + agentCompany.agent_code"></p>
                          </div>
                        </div>
                        <div class="d-flex mt-1">
                          <i class="fa fa-map-marker mr-1 mt-25"></i>
                          <address>
                            {{ cn(agentCompany, "office.address_one") }}
                            {{
                              cn(agentCompany, "office.address_one") ? ", " : ""
                            }}
                            {{ cn(agentCompany, "office.address_two") }}
                            {{
                              cn(agentCompany, "office.address_two") ? ", " : ""
                            }}
                            {{ cn(agentCompany, "office.state") }}
                            {{ cn(agentCompany, "office.state") ? ", " : "" }}
                            {{ cn(agentCompany, "office.city") }}
                            {{ cn(agentCompany, "office.city") ? ", " : "" }}
                            {{ cn(agentCompany, "country.name") }}
                          </address>
                        </div>
                        <div class="d-flex" v-if="agentCompany.agent_mobile">
                          <i class="fa fa-phone mr-1 mt-25" aria-hidden="true"></i>
                          <p v-html="'+' + cn(agentCompany, 'country.calling_code') + agentCompany.agent_mobile"></p>
                        </div>
                        <div class="d-flex" v-if="agentCompany.email">
                          <i class="fa fa-envelope mr-1 mt-25" aria-hidden="true"></i>
                          <a :href="'mailto:' + agentCompany.email" v-html="agentCompany.email"></a>
                        </div>
                        <div class="mt-1">
                          <p class="card-text">
                            <b>Expiry: </b>
                            <span class="cursor-pointer" v-if="agentCompany.agreement_expiry" @click="editAgreementData(agentCompany)">
                              <span v-if="checkCurrentDateLessThenDate(agentCompany.agreement_expiry)" v-html="dDate(agentCompany.agreement_expiry)"></span>
                              <span v-else v-html="'Expired'" class="badge badge-light-danger"></span>
                              <small class="badge badge-light-primary cursor-pointer ml-1" v-if="agentCompany.agreement_renewable == 1">Renewable</small>
                            </span>
                            <i v-else @click="editAgreementData(agentCompany)" class="fa fa-calendar"></i>
                          </p>
                        </div>
                        <div class="mt-1">
                          <p class="card-text">
                            <b>Renewal: </b>
                            <span v-html="agentCompany.agreement_renewable + ' times'"></span>
                          </p>
                        </div>
                        <div class="mt-1">
                          <p class="card-text">
                            <b>Active since: </b>
                            <span
                              v-if="agentCompany.signed_up_at"
                              v-html="dDate(agentCompany.signed_up_at)"
                            ></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 order-lg-1">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="mb-1">Contact Person <i v-if="permission_check('agent_primary_market_edit')" @click="editData(agentCompany)" class="fa fa-pencil-square-o cursor-pointer pull-right"></i></h5>
                        <h6
                          class="mb-1"
                          v-html="agentCompany.contact_person"
                        ></h6>
                        <div class="d-flex" v-if="agentCompany.contact_number">
                          <i
                            class="fa fa-phone mr-1 mt-25"
                            aria-hidden="true"
                          ></i>
                          <p
                            v-html="
                              '+' +
                              cn(agentCompany, 'country.calling_code') +
                              agentCompany.contact_number
                            "
                          ></p>
                        </div>
                        <div class="d-flex" v-if="agentCompany.contact_email">
                          <i
                            class="fa fa-envelope mr-1 mt-25"
                            aria-hidden="true"
                          ></i>
                          <a
                            :href="'mailto:' + agentCompany.contact_email"
                            v-html="agentCompany.contact_email"
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1 bank_account_info">
                  <div class="col-12 order-lg-1">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex justify-content-between mb-50">
                            <h5 class="mb-1">Bank Details </h5>
                            <div>
                              <!-- <i @click="showBankDetailsListModal()" class="fa fa-th-list mr-50 font-size-11 btn btn-outline-primary btn-sm"></i> -->
                              <i @click="toggle_agent_bank_modal_form()" class="fa fa-plus-square font-size-11 btn btn-outline-primary btn-sm"></i>
                            </div>
                        </div>
                        <div v-if="bankModalList">
                          <div class="mb-2" :class=" bank.verified_at ? 'bank_block_verified' : 'bank_block'"
                            v-for="(bank, index) in bankModalList" :key="index">
                            <!-- <p class="card-text">Bank Name: {{ bank.bank_name }} <button @click="verify_agent_bank_data(bank, index)" v-if="!bank.verified_at" class="btn btn-success btn-sm">Verify</button><i v-if="bank.verified_at"  class="fa fa-check ml-1 text-success" v-tooltip="'Verified at ' + dDate(bank.verified_at)"></i></p> -->
                            <h6 class="mb-1 text-dark font-weight-bold">
                              <i class="fa fa-university"></i>
                              {{ bank.bank_name }}
                              <i class="fa fa-square-o cursor-pointer ml-1 text-warning" @click="verify_agent_bank_data(bank)"
                                v-if="!bank.verified_at && permission_check('agent_bank_verification')" v-tooltip="'Verify Bank Acount'"></i>
                              <i v-if="bank.verified_at" class="fa fa-check ml-1 text-success" v-tooltip="'Verified at ' + dDate(bank.verified_at)"></i>
                              <small class="badge badge-light-success pull-right" v-if="bank.is_primary">Primary</small>
                              <small @click="make_agent_bank_primary(bank)" class="badge badge-light-info cursor-pointer pull-right" v-else>Make Primary</small>
                            </h6>
                            <p class="card-text" v-html="'Account Name: ' + bank.beneficiary_name"></p>
                            <p class="card-text" v-html="'Account Number: ' + bank.account_number"></p>
                            <p class="card-text">
                              {{ "Iban Number/Sort Code/Swift Code: " + bank.iban_number + " / " + bank.sort_code + " / " + bank.swift_code }}
                            </p>
                            <p class="card-text" v-html="'Address: ' + bank.address"></p>
                            <p class="card-text" v-html="'Country: ' + bank.country.name"></p>
                            <div class="d-flex justify-content-between">
                              <span v-if="bank.active" class="badge badge-light-success" v-html="'Active'"></span>
                              <span v-else class="badge badge-light-danger" v-html="'Inactive'"></span>
                              <div>
                                <i @click="editAgentBankData(bank)" class="fa fa-pencil-square-o font-size-16  text-info hand mr-1"></i>
                                <i @click="deleteAgentBankData(bank, index)" class="fa fa-trash font-size-16 text-danger hand"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <span>
                            <i class="fa fa-exclamation-triangle text-danger"></i>
                            Bank data not found.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row mt-1">
                                <div class="col-12 order-lg-1">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="mb-1">Which stage show ***</h5>
                                            <p class="card-text" v-html="'Which stage show Name'"></p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
              </div>
              <div class="col-lg-8 col-12">

                <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body p-0">
                        
                        <div class="row">
                          <div class="col-md-6">
                            <div class="m-1">
                              <p class="card-text">
                                <b class="badge badge-primary font-size-13 mr-25"><i class="fa fa-user-circle"></i> Account Manager:</b>
                                <span v-if="agentCompany.assigned_account_manager">
                                  <span v-html="agentCompany.assigned_account_manager.name"></span>
                                  <!-- <i class="fa fa-calendar cursor-pointer ml-2" v-tooltip="'Account Manager History'"></i> -->
                                </span>
                              </p>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="m-1">
                              <p class="card-text">
                                <b class="badge badge-primary font-size-13 mr-25"><i class="fa fa-user-circle"></i> Regional Manager:</b>
                                <span v-if="agentCompany.supervisor">
                                  <span v-if="agentCompany.supervisor.user" v-html="agentCompany.supervisor.user.name"></span>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h5 class="m-1">
                          Recruitment Territory
                          <i v-if="permission_check('agent_primary_market_edit')" @click="editData(agentCompany)" class="fa fa-pencil-square-o cursor-pointer pull-right"></i>
                        </h5>
                        <div class="row">
                          <div class="col-12">
                              <div class="table-responsive">
                                <!-- <p class="card-text" v-if="agentCompany.region_country.region">
                                    {{ agentCompany.region_country.region.title }} <i class="fa fa-arrow-right" aria-hidden="true"></i> {{ agentCompany.country.name  }}
                                </p> -->
                                <table class="table table-hover">
                                  <thead>
                                    <tr>
                                      <th>Territory Type</th>
                                      <th>Countries</th>
                                      <th>UK Domicile</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-if="agentCompany.recruitment_market_type">
                                      <td>
                                          <span v-if="agentCompany.recruitment_market_type == 1" class="badge badge-light-info mt-1" v-html="'Country'"></span>
                                          <span v-else-if="agentCompany.recruitment_market_type == 2" class="badge badge-light-blue mt-1" v-html="'Region'"></span>
                                          <span v-else class="badge badge-light-primary mt-1" v-html="'Global'"></span>
                                      </td>
                                      <td>
                                        <!-- <span
                                          v-for="recMarket in agentCompany.recruitment_markets"
                                          :key="recMarket.id"
                                          class="badge badge-light-primary mr-1 mt-1"
                                          v-html="recMarket.country.name"
                                        ></span> -->

                                          <div class="b-avatar-group" v-if="agentCompany.recruitment_markets && agentCompany.recruitment_markets.length">
                                              <div class="b-avatar-group-inner" style="padding-left: calc(5.1px); padding-right: calc(5.1px);">
                                                  <span v-for="recMarket in agentCompany.recruitment_markets.slice(0, 5)" :key="recMarket.id" v-tooltip="recMarket.country.name" class="b-avatar pull-up badge-secondary rounded-circle" style="margin-left: calc(-5.1px); margin-right: calc(-5.1px); width: 30px; height: 30px; margin-right: 10px">
                                                      <span class="b-avatar-img"><img :src="recMarket.country.flag_url" alt="avatar"></span>
                                                  </span>
                                                  <h6 v-if="agentCompany.recruitment_markets.length > 5" class="align-self-center cursor-pointer ml-25" @click="showRecruitmentTerritoryListModal(agentCompany)"> +{{ (agentCompany.recruitment_markets.length - 5) }} </h6>
                                              </div>
                                          </div>
                                      </td>
                                      <td>
                                        <span v-if="agentCompany.domicile == 1" class="badge badge-light-success mt-1" v-html="'Yes'"></span>
                                        <span v-else-if="agentCompany.domicile == 0" class="badge badge-light-danger mt-1" v-html="'No'"></span>
                                        <span v-else>-</span>
                                      </td>
                                    </tr>
                                    <tr v-else>
                                      <th colspan="3" class="text-center">
                                        <span>
                                          <i
                                            class="
                                              fa fa-exclamation-triangle
                                              text-danger
                                            "
                                            aria-hidden="true"
                                          ></i>
                                          Found no recruitment territory.
                                        </span>
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body p-0">
                        <h5 class="m-1">
                          Primary Market For Commission
                          <i v-if="permission_check('agent_primary_market_edit')" @click="editData(agentCompany)" class="fa fa-pencil-square-o cursor-pointer pull-right"></i>
                        </h5>
                        <div class="row">
                          <div class="col-12">
                              <div class="table-responsive">
                                <!-- <p class="card-text" v-if="agentCompany.region_country.region">
                                    {{ agentCompany.region_country.region.title }} <i class="fa fa-arrow-right" aria-hidden="true"></i> {{ agentCompany.country.name  }}
                                </p> -->
                                <table class="table table-hover">
                                  <thead>
                                    <tr>
                                      <th>Territory Name</th>
                                      <!-- <th>Region</th> -->
                                      <th>Countries</th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="agentCompany.territory">
                                    <tr>
                                      <td>
                                        <span class=" badge badge-light-info mt-1" v-html="agentCompany.territory.name"></span>
                                      </td>
                                      <!-- <td>
                                                                            <span v-for="regions_country in agentCompany.territory.regions_countries" :key="regions_country.id" class="badge badge-light-info mr-1 mt-1" v-html="regions_country.region.title"></span>
                                                                        </td> -->
                                      <td>
                                        <div v-if="agentCompany.territory">
                                          <div class="b-avatar-group" v-if="agentCompany.territory.maps && agentCompany.territory.maps.length">
                                              <div class="b-avatar-group-inner" style="padding-left: calc(5.1px); padding-right: calc(5.1px);">
                                                  <span v-for="map in agentCompany.territory.maps.slice(0, 5)" :key="map.id" v-tooltip="map.country.name"  class="b-avatar pull-up badge-secondary rounded-circle" style="margin-left: calc(-5.1px); margin-right: calc(-5.1px); width: 30px; height: 30px; margin-right: 10px">
                                                      <span class="b-avatar-img"><img :src="map.country.flag_url" alt="avatar"></span>
                                                  </span>
                                                  <h6 v-if="agentCompany.territory.maps.length > 5" class="align-self-center cursor-pointer ml-25" @click="showPrimaryMarketListModal(agentCompany)"> +{{ (agentCompany.territory.maps.length - 5) }} </h6>
                                              </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <th colspan="3" class="text-center">
                                        <span>
                                          <i
                                            class="
                                              fa fa-exclamation-triangle
                                              text-danger
                                            "
                                            aria-hidden="true"
                                          ></i>
                                          Found no territory.
                                        </span>
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="comm_structure.length" class="card mt-1">
                  <div class="card-header p-1">
                    <h5 class="mb-0">Commission Structure</h5>
                  </div>
                  <div class="card-body p-0">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th class="bg-light-primary text-center align-middle" v-for="(row, index) in comm_structure" :key="index">
                            <div v-if="row.stu_range_to">
                              <span v-html="row.stu_range_from"></span>- <span v-html="row.stu_range_to"></span>
                            </div>
                            <div v-else>
                              <span v-html="row.stu_range_from"></span>+
                            </div>                  
                            Enrollments
                          </th>
                        </tr>
                        <tr>
                          <td class="text-center align-middle" v-for="(row, index) in comm_structure" :key="index">
                            <strong v-html="row.percentage+'%'"></strong>
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>


                <div class="row mt-1">
                  <div class="col-12">
                    <div class="card wait_me_apex_chart">
                      <div class="card-body">
                        <h5>Joined Students</h5>
                        <!-- <div class="d-flex justify-content-between flex-wrap">
                                                <div class="mb-1 mb-sm-0">
                                                    <span class="text-muted">Commercial networks and enterprises</span>
                                                </div>
                                                <div class="d-flex align-content-center mb-1 mb-sm-0">
                                                    <h1 class="font-weight-bolder ">$ 183,382</h1>
                                                    <div class="pt-25 ml-75">
                                                    <div class="badge badge-light-secondary">
                                                        <span class="font-weight-bolder align-middle"> 24%</span>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div> -->

                        <!-- echart -->
                        <vue-apex-charts
                          ref="intake_wise_students_appex_chart"
                          type="line"
                          height="350"
                          :options="lineChartSimple.chartOptions"
                          :series="lineChartSimple.series"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body p-0">
                        <div class="invoice_list">
                          <h5 class="p-2">Invoice List</h5>
                          <div class="table-responsive">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th class="px-1" width="5%">#</th>
                                  <th class="px-1" width="15%">List ID</th>
                                  <th class="px-1" width="15%">Intake</th>
                                  <th class="px-1" width="15%">Submitted</th>
                                  <th class="px-1" width="10%">Amount</th>
                                  <th class="px-1" width="10%">Status</th>
                                  <th class="px-1" width="10%">Paid</th>
                                  <th class="text-center px-1" width="30%">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                v-if="
                                  agentCompany.agent_student_list_invoices &&
                                  agentCompany.agent_student_list_invoices
                                    .length > 0
                                "
                              >
                                <tr
                                  v-for="(
                                    item, index
                                  ) in agentCompany.agent_student_list_invoices"
                                  :key="index"
                                >
                                  <td class="px-1" v-html="'45545'"></td>
                                  <td class="px-1" width="5%">
                                    <small
                                      v-on:click="
                                        gotoAgentWiseStudentListPage(item)
                                      "
                                      class="badge badge-light-primary"
                                      v-html="item.list_code"
                                    ></small>
                                  </td>
                                  <td class="px-1" width="5%">
                                    <small v-html="item.intake.name"></small>
                                  </td>
                                  <td class="px-1">
                                    <small
                                      v-html="dDate(item.submitted_at)"
                                    ></small>
                                  </td>
                                  <td class="px-1">
                                    <small v-html="'£ ' + float2(5445)"></small>
                                  </td>
                                  <td class="px-1">
                                    <small v-html="''"></small>
                                  </td>
                                  <td class="px-1">
                                    <small v-html="''"></small>
                                  </td>
                                  <td class="text-center px-1">
                                    <i
                                      v-if="
                                        permission_check(
                                          'agent_profile_invoice_edit'
                                        )
                                      "
                                      @click="editData(item)"
                                      class="fa fa-pencil-square-o ml-1"
                                    ></i>
                                    <i
                                      @click="gotoAgentProfilePage(item)"
                                      class="fa fa-eye text-primary ml-1"
                                      v-tooltip="'Agent Profile'"
                                    ></i>
                                    <i
                                      @click="downloadInvoice(item)"
                                      class="
                                        fa fa-cloud-download
                                        text-info
                                        ml-1
                                      "
                                      v-tooltip="'Download Invoice'"
                                    ></i>
                                    <!-- <i class="fa fa-list ml-1" aria-hidden="true"></i> -->
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <th colspan="8" class="text-center">
                                    <span
                                      v-if="
                                        agentCompany.agent_student_list_invoices ===
                                        null
                                      "
                                      >Processing...</span
                                    >
                                    <span v-else>
                                      <i
                                        class="
                                          fa fa-exclamation-triangle
                                          text-danger
                                        "
                                        aria-hidden="true"
                                      ></i>
                                      Invoice data not found.
                                    </span>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <h5>Agent Remarks</h5>
                        <agent-remarks :agent_company_id="hash_id(this.$route.params.id, false)[0]" ref="agent_remarks_component"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <!-- Agent Company Info Edit -->
      <div class="row" v-if="this.editItem">
          <div class="col-md-6"></div>
          <div class="col-md-6">
              <sidebar-form 
                  :show_sidebar="flag.show_modal_form"
                  v-on:toggle-sidebar="toggle_modal_form()"
                  title="Edit Student"
                  >
                  <div class="mb-1">
                      <h4 class="">
                          <span class="align-middle">Edit {{ editItem.name }}</span>
                      </h4>
                  </div>
                  <div class="flex-grow-1 wait_me_edit_form">
                      <div class="row bg-white box-shadow p-1 mb-3">
                          <h5 class="text-center">Business Info</h5>
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="edit_name"><b>Business Name</b> <span class="text-danger">*</span></label>
                                  <input type="text" v-model="editItem.name" class="form-control" id="edit_name" placeholder="Enter Business Name">
                              </div>
                          </div>
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="edit_email"><b>Business Email</b> <span class="text-danger">*</span></label>
                                  <input type="email" disabled v-model="editItem.email" class="form-control" id="edit_email" placeholder="Enter Email">
                              </div>
                          </div>
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="country_id"><b>Headquater</b> <span class="text-danger">*</span></label>
                                  <treeselect v-model="editItem.country_id" :options="countryList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                              </div>
                          </div>
                      </div>
                      <div class="row bg-white box-shadow p-1 mb-3">
                          <h5 class="text-center">Key Contact Person</h5>
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="edit_contact_person"><b>Name</b> <span class="text-danger">*</span></label>
                                  <input type="text" v-model="editItem.contact_person" class="form-control" id="edit_contact_person" placeholder="Enter Key Contact Person">
                              </div>
                          </div>
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="edit_contact_email"><b>Email</b> <span class="text-danger">*</span></label>
                                  <input type="text" v-model="editItem.contact_email" class="form-control" id="edit_contact_email" placeholder="Enter Key Contact Email">
                              </div>
                          </div>
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="edit_contact_number"><b>Phone</b> <span class="text-danger"></span></label>
                                  <input type="text" v-model="editItem.contact_number" class="form-control" id="edit_contact_number" placeholder="Enter Key Contact Email">
                              </div>
                          </div>
                          <!-- <div class="col-12">
                              <div class="form-group">
                                  <label for="edit_end_date">Date of Birth</label>
                                      <flat-pickr
                                          id="edit_end_date"
                                          v-model="editItem.dob"
                                          :config="configs.flatpickr"
                                          class="form-control"
                                          placeholder="Select end date"/>
                              </div>
                          </div> -->
                          <!-- <div class="col-12">
                              <div class="form-group">
                                  <label for="region_id">Region</label>
                                  <treeselect v-model="editItem.region_id" :options="regionList" placeholder="Select region" id="region_id" autocomplete="off"/>
                              </div>
                          </div> -->
                      </div>
                      <div class="row bg-white box-shadow p-1">
                          <h4 class="text-center">Other Info</h4>
                          <div class="col-12 form-group">
                              <label for="accoun_tmanager_id"><b>Account Manager</b></label>
                              <treeselect v-model="editItem.account_manager_id" :options="treeselect_uni_users" placeholder="Select account manager" autocomplete="off" id="accoun_tmanager_id"/>
                          </div>
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="territory_id"><b>Primary Terrritory For Commission</b></label>
                                  <treeselect v-model="editItem.territory_id" :options="territoryList" placeholder="Select territory" id="territory_id" autocomplete="off"/>
                              </div>
                          </div>
                          <div class="col-12 form-group">
                              <b-form-group label="Recruitment Territory" class="font-weight-bold">
                                  <b-form-radio-group
                                      v-model="editItem.recruitment_market_type"
                                      :options="recruitmentMarketTypeList"
                                      class="demo-inline-spacing"
                                      >
                                  </b-form-radio-group>
                              </b-form-group>
                          </div>

                          <div class="col-12" v-if="editItem.recruitment_market_type == 2">
                              <div class="form-group">
                                  <label for="territory_ids"><b>Select Region</b></label>
                                  <treeselect multiple v-model="editItem.recruitment_market_territory_ids" :options="territoryList" placeholder="Select Region" id="territory_ids" autocomplete="off"/>
                              </div>
                          </div>

                          <div class="col-12" v-if="editItem.recruitment_market_type == 1">
                              <div class="form-group">
                                  <label for="country_ids"><b>Select Country</b></label>
                                  <treeselect multiple v-model="editItem.recruitment_market_country_ids" :options="countryList" placeholder="Select Country" id="country_ids" autocomplete="off"/>
                              </div>
                          </div>

                          <div class="col-12 form-group" v-if="editItem.recruitment_market_type">
                              <b-form-checkbox
                                  id="domicile"
                                  class="font-weight-bold"
                                  v-model="editItem.domicile"
                                  name="domicile"
                                  >
                                  UK Domicile
                                  </b-form-checkbox>
                          </div>

                          <div class="col-12 mt-1">
                              <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                          </div>
                      </div>
                  </div>
              </sidebar-form>
          </div>
      </div>

      <!-- Agent Agreement Info Edit -->
      <div class="row" v-if="this.editItem">
          <!-- <div class="col-md-6"></div> -->
          <div class="col-md-6">
              <sidebar-form 
                  :show_sidebar="flag.show_agreement_modal_form"
                  v-on:toggle-sidebar="toggle_modal_agreement()"
                  title="Edit Student"
                  >
                  <div class="mb-1">
                      <h5 class="">
                          <span class="align-middle">Edit Agent Agreement</span>
                      </h5>
                  </div>
                  <div class="flex-grow-1 wait_me_edit_form" >
                      <div class="row bg-white box-shadow p-1 mb-2">
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="agreement_expiry">Agreement Expire Date</label>
                                  <flat-pickr
                                      id="agreement_expiry"
                                      v-model="editItem.agreement_expiry"
                                      :config="configs.flatpickr"
                                      class="form-control"
                                      placeholder="Select date"/>
                              </div>
                          </div>
                          <div class="col-12">
                              <div class="form-group">
                                  <label for="agreement_renewable">Agreement Renewable</label>
                                      <b-form-radio-group
                                      id="agreement_renewable"
                                      v-model="editItem.agreement_renewable"
                                      :options="agreementRenewableOption"
                                  ></b-form-radio-group>
                              </div>
                          </div>
                          <div class="col-12">
                              <button type="button" class="btn btn-success mr-1" @click.prevent="updateAgentAgreementData()">Update</button>
                              <!-- <button type="button" @click="update_reset()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button> -->
                          </div>
                      </div>
                      <div class="row mt-2 bg-white box-shadow p-1" v-if="agentAgreementHisotryList && agentAgreementHisotryList.length">
                          <div class="col-md-12">
                              <h5><span class="align-middle">Agreement History</span></h5>
                              <ul class="timeline agent_agreement_history mt-2">
                                  <li class="timeline-item" v-for="(logItem, logIndex) in agentAgreementHisotryList" :key="logIndex">
                                      <span class="timeline-point timeline-point-indicator"></span>
                                      <div class="timeline-event">
                                          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                              <h6>Agreement Expiry</h6>
                                              <span class="timeline-event-time" v-html="dNow(logItem.created_at)"></span>
                                          </div>
                                          <p  v-html="'Date : ' + dDate(logItem.expiry_date)"></p>
                                          <p>Created by <b v-if="logItem.user" v-html="logItem.user.name"></b></p>
                                          <p>University <b v-if="logItem.university" v-html="logItem.university.name"></b></p>
                                          <p>Agent <b v-if="logItem.agent" v-html="logItem.agent.name"></b></p>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </sidebar-form>
          </div>
      </div>

      <b-modal ref="bank_details_list_modal" size="xl" class="text-center">
          <template #modal-title>
              <span class="text-uclan">Agent Bank List</span>
          </template>
          
          <div class="card-body p-0">
                <div class="table-responsive user-role-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th><span class="ml-1">#</span></th>
                                <th>bank name</th>
                                <th width="20%"><span>beneficiary name</span><br/><span>account number</span></th>
                                <th width="15%">iban number <br/> swift code <br/> sort code</th>
                                <th>address</th>
                                <th>Primary</th>
                                <th>Active</th>
                                <th class="text-center" width="205">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="bankModalList && bankModalList.length > 0">
                            <tr v-for="(item, index) in bankModalList" :key="index">
                                <td><span class="ml-1" v-html="index + 1"></span></td>
                                <td>
                                    <span class="text-dark" v-html="item.bank_name"></span>
                                </td>
                                <td>
                                    <span class="font-weight-bold text-dark" v-html="item.beneficiary_name"></span><br/>
                                    <span class="text-truncate" v-html="item.account_number"></span>
                                </td>
                                <td>
                                    <span v-html="item.iban_number" v-tooltip="'Iban Number'"></span><br/>
                                    <span v-html="item.swift_code" v-tooltip="'Swift Code'"></span><br/>
                                    <span v-html="item.sort_code" v-tooltip="'Sort Code'"></span>
                                </td>
                                <td>
                                    <span v-html="item.address"></span><br/>
                                    <span v-if="item.country" v-html="item.country.name"></span>
                                </td>
                                <td>
                                    <!-- <span v-if="item.active == 1" class="badge badge-pill badge-light-success">Active</span>
                                    <span v-else class="badge badge-pill badge-light-danger">Deactive</span> -->
                                    <b-form-checkbox
                                        v-model="item.is_primary"
                                        v-on:change="updateAgentBankIsPrimaryStatus(item)"
                                        :id="'is_primary'+item.id"
                                        class="custom-control-success"
                                        name="check-is_primary"
                                        switch
                                    />
                                </td>
                                <td>
                                    <b-form-checkbox
                                        v-model="item.active"
                                        v-on:change="updateAgentBankActiveStatus(item)"
                                        :id="'active'+item.id"
                                        class="custom-control-success"
                                        name="check-active"
                                        switch
                                    />
                                </td>
                                <td class="text-center">

                                    <button @click="editAgentBankData(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;">
                                        <!-- <edit-icon size="1.5x" class="custom-class"></edit-icon> -->
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </button>                                                

                                    <button @click="deleteAgentBankData(item, index)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                        <!-- <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon> -->
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>

                                    <!-- <i v-tooltip="'Edit'" @click="editData(item)" class="fa fa-pencil"></i>
                                    <i v-tooltip="'Delete'" @click="deleteAgentBankData(item, index)" class="fa fa-trash-o text-danger ml-1"></i> -->
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="7" class="text-center">
                                    <span v-if="bankModalList === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

          <template #modal-footer>
              <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.bank_details_list_modal.hide()">Close</button>
          </template>
      </b-modal>


      <!-- Agent Bank Account Info Edit -->
      <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
              <sidebar-form 
                  :show_sidebar="flag.show_agent_bank_modal_form"
                  v-on:toggle-sidebar="toggle_agent_bank_modal_form()"
                  title="Edit Student"
                  >
                  <div class="mb-1">
                    <h5 v-if="!form.bankAccountInfo.id">Add Bank Account</h5>
                    <h5 v-if="form.bankAccountInfo.id">Edit Bank Account</h5>
                  </div>
                  <div class="flex-grow-1 wait_me_edit_form">
                      <div class="row bg-white box-shadow p-1 mb-3">
                        <div class="col-12 form-group">
                            <label for="bank_name"><b>Bank Name</b> <span class="text-danger">*</span></label>
                            <input type="text" v-model="form.bankAccountInfo.bank_name" class="form-control" id="bank_name" placeholder="Enter bank name">
                        </div>
                        <div class="col-12 form-group">
                            <label for="beneficiary_name"><b>Beneficiary Name</b> <span class="text-danger">*</span></label>
                            <input type="text" v-model="form.bankAccountInfo.beneficiary_name" class="form-control" id="beneficiary_name" placeholder="Enter beneficiary name">
                        </div>
                        <div class="col-12 form-group">
                            <label for="account_number"><b>Account Number</b> <span class="text-danger">*</span></label>
                            <input type="text" v-model="form.bankAccountInfo.account_number" class="form-control" id="account_number" placeholder="Enter account number">
                        </div>
                        <div class="col-12 form-group">
                            <label for="iban_number"><b>IBAN Number</b> <span class="text-danger">*</span></label>
                            <input type="text" v-model="form.bankAccountInfo.iban_number" class="form-control" id="iban_number" placeholder="Enter iban number">
                        </div>
                        <div class="col-12 form-group">
                            <label for="swift_code"><b>Swift Code</b> <span class="text-danger"></span></label>
                            <input type="text" v-model="form.bankAccountInfo.swift_code" class="form-control" id="swift_code" placeholder="Enter swift code">
                        </div>
                        <div class="col-12 form-group">
                            <label for="sort_code"><b>Sort Code</b> <span class="text-danger"></span></label>
                            <input type="text" v-model="form.bankAccountInfo.sort_code" class="form-control" id="sort_code" placeholder="Enter sort code">
                        </div>
                        <div class="col-12 form-group">
                            <label for="address"><b>Address</b> <span class="text-danger"></span></label>
                            <input type="text" v-model="form.bankAccountInfo.address" class="form-control" id="address" placeholder="Enter address">
                        </div>
                        <div class="col-12 form-group">
                            <label for="country_id"><b>Country</b> <span class="text-danger">*</span></label>
                            <treeselect v-model="form.bankAccountInfo.country_id" :options="countryList" placeholder="Select country" id="country_id" autocomplete="off"/>
                        </div>
                        <div class="col-12 form-group">
                            <label for="active"><b>Active</b> <span class="text-danger">*</span></label>
                            <b-form-checkbox
                                v-model="form.bankAccountInfo.active"
                                id="active"
                                class="custom-control-success"
                                name="check-active"
                                switch
                            />
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-success pull-right" v-if="!form.bankAccountInfo.id" @click.prevent="insertAgentAccountData()">Save</button>
                            <button type="submit" class="btn btn-success pull-right" v-if="form.bankAccountInfo.id" @click.prevent="updateAgentAccountData()">Update</button>
                            <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
                        </div>
                      </div>
                  </div>
              </sidebar-form>
          </div>
      </div>

      <b-modal ref="primary_market_list_modal" :size="primaryMarketsModalTableSize" class="text-center">
          <template #modal-title>
              <span class="text-uclan">Primary Market Countries</span>
          </template>
          <!-- <div class="b-avatar-group" v-if="primaryMarketsModalList && primaryMarketsModalList.length">
              <div class="b-avatar-group-inner" style="padding-left: calc(5.1px); padding-right: calc(5.1px);">
                  <span v-for="map in primaryMarketsModalList" :key="map.id" v-tooltip="map.country.name"  class="b-avatar pull-up badge-secondary rounded-circle" style="margin-left: calc(-5.1px); margin-right: calc(-5.1px); width: 30px; height: 30px; margin: 10px">
                      <span class="b-avatar-img"><img :src="map.country.flag_url" alt="avatar"></span>
                  </span>
              </div>
          </div> -->

          <table class="table table-bordered table-sm">
                <tr>
                    <th :colspan="primaryMarketsModalTableThColSpan" class="text-center">Countries</th>
                </tr>
                <template v-if="primaryMarketsModalList && primaryMarketsModalList.length">
                    <tr v-for="(chunk, index) in primaryMarketsModalList" :key="index">
                        <td v-for="(chunkRow, chunkIndex) in chunk" :key="chunkIndex" class="align-items-center">
                            <span class="b-avatar pull-up badge-secondary rounded-circle mr-1" style="width: 30px; height: 30px;">
                                <span class="b-avatar-img"><img :src="chunkRow.country.flag_url" alt="avatar"></span>
                            </span>
                            <span v-html="chunkRow.country.name"></span>
                        </td>
                    </tr>
                </template>
            </table>

          <template #modal-footer>
              <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.primary_market_list_modal.hide()">Close</button>
          </template>
      </b-modal>

      <b-modal ref="recruitment_territory_list_modal" :size="recruitmentMarketsModalTableSize" class="text-center">
            <template #modal-title>
                <span class="text-uclan">Recruitment Territory Countries</span>
            </template>

            <table class="table table-bordered table-sm">
                <tr>
                    <th :colspan="recruitmentMarketsModalTableThColSpan" class="text-center">Countries</th>
                </tr>
                <template v-if="recruitmentMarketsModalList && recruitmentMarketsModalList.length">
                    <tr v-for="(chunk, index) in recruitmentMarketsModalList" :key="index">
                        <td v-for="(chunkRow, chunkIndex) in chunk" :key="chunkIndex" class="align-items-center">
                            <span class="b-avatar pull-up badge-secondary rounded-circle mr-1" style="width: 30px; height: 30px;">
                                <span class="b-avatar-img"><img :src="chunkRow.country.flag_url" alt="avatar"></span>
                            </span>
                            <span v-html="chunkRow.country.name"></span>
                        </td>
                    </tr>
                </template>
            </table>

            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.recruitment_territory_list_modal.hide()">Close</button>
            </template>
        </b-modal>

      <activity-log :log_data="agentActivitylog" ref="agent_activity_log_modal"></activity-log>

    </main-layout>
  </section>
</template>

<script>
import MainLayout from "./MainLayout.vue";
import feather from "feather-icons";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "./Partials/apexChartData";
import { $themeColors } from "./Partials/themeConfig";
import Swal from "sweetalert2";
// import SidebarForm from "../SidebarForm";
import SidebarForm from "../SidebarFormLighterBlue";
import Treeselect from "@riophae/vue-treeselect";
import AgentRemarks from "./Partials/AgentRemarks.vue";
import ActivityLog from './Partials/ActivityLog.vue';
import { BFormGroup, BFormRadioGroup, BFormCheckbox, BModal } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';

export default {
  name: "AgentProfile",
  // metaInfo: {
  //   titleTemplate: '%s | Agent Profile'
  // },
  metaInfo () {
    return {
      titleTemplate: '%s | ' + this.cn(this.agentCompany, 'name', 'Agent Profile')
    }
  },
  components: {
    MainLayout,
    VueApexCharts,
    SidebarForm,
    Treeselect,
    AgentRemarks,
    ActivityLog,
    BFormGroup, BFormRadioGroup, BFormCheckbox,
    flatPickr,
    BModal
  },
  data: () => {
    return {
      apexChatData,
      agent_id: "",
      agentCompany: {},
      agentActivitylog: [],
      countryList: [],
      intakeStudnetList: [],
      territoryList: [],
      usersList: [],
      agentAgreementHisotryList: [],
      flag: {
        show_agent_form: false,
        show_modal_form: false,
        show_agreement_modal_form: false,
        show_modal_activity_log: false,
        show_agent_bank_modal_form: false,
      },
      configs: {
          flatpickr: {
              dateFormat: 'd M Y',
              // maxDate: new Date()
          }        
      },
      form: {
          bankAccountInfo: {
              id: '',
              bank_name: null,
              beneficiary_name: null,
              account_number: '',
              iban_number: '',
              swift_code: '',
              sort_code: '',
              country_id: null,
              address: '',
              is_primary: '',
              active: true,
          }
      },
      editItem: {},
      lineChartSimple: {
        series: [
          {
            name: "Confirmed Students",
            type: "column",
            data: [],
          },
          {
            name: "Commission",
            type: "line",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: true,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          // colors: [$themeColors.primary, $themeColors.success],
          colors: [$themeColors.primary, $themeColors.success],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
            width: [2, 3],
          },
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            // custom(data) {
            //     return `${'<div class="px-1 py-50"><span>'}${
            //         data.series[data.seriesIndex][data.dataPointIndex]
            //     } students</span></div>`
            // },
          },
          xaxis: {
            labels: {
              rotate: -45,
            },
            categories: [],
            title: {
              text: "Intake",
            },
            tickPlacement: "on",
          },
          yaxis: [
            {
              title: {
                text: "Confimred Students",
              },
              axisBorder: {
                show: true,
              },
            },
            {
              opposite: true,
              seriesName: "Line C",
              axisTicks: {
                show: true,
              },
              title: {
                text: "Commission",
              },
              axisBorder: {
                show: true,
              },
            },
          ],
          legend: {
            horizontalAlign: "left",
            offsetX: 40,
          },
        },
      },
      comm_structure: [],
      recruitmentMarketsModalList: [],
      recruitmentMarketsModalTableSize: 'md',
      recruitmentMarketsModalTableThColSpan: '',
      primaryMarketsModalList: [],
      primaryMarketsModalTableSize: 'md',
      primaryMarketsModalTableThColSpan: '',
      bankModalList: null,
    };
  },
  methods: {
    showPrimaryMarketListModal: function (item) {
        if (item.territory.maps.length > 10 && item.territory.maps.length <= 28) {
            this.primaryMarketsModalList = this.chunk(item.territory.maps, 2)
            this.primaryMarketsModalTableSize = 'md'
            this.primaryMarketsModalTableThColSpan = 2
        } else if (item.territory.maps.length > 28) {
            this.primaryMarketsModalList = this.chunk(item.territory.maps, 3)
            this.primaryMarketsModalTableSize = 'lg'
            this.primaryMarketsModalTableThColSpan = 3
        } else {
          this.primaryMarketsModalList = this.chunk(item.territory.maps, 1)
          this.primaryMarketsModalTableSize = 'sm'
          this.primaryMarketsModalTableThColSpan = ''
        }
        this.$refs.primary_market_list_modal.show()
    },
    showRecruitmentTerritoryListModal: function (item) {
        if (item.recruitment_markets.length > 10 && item.recruitment_markets.length <= 28) {
            this.recruitmentMarketsModalList = this.chunk(item.recruitment_markets, 2)
            this.recruitmentMarketsModalTableSize = 'md'
            this.recruitmentMarketsModalTableThColSpan = 2
        } else if (item.recruitment_markets.length > 28) {
            this.recruitmentMarketsModalList = this.chunk(item.recruitment_markets, 3)
            this.recruitmentMarketsModalTableSize = 'lg'
            this.recruitmentMarketsModalTableThColSpan = 3
        } else {
            this.recruitmentMarketsModalList = this.chunk(item.recruitment_markets, 1)
            this.recruitmentMarketsModalTableSize = 'sm'
            this.recruitmentMarketsModalTableThColSpan = ''
        }
        this.$refs.recruitment_territory_list_modal.show()
    },
    showBankDetailsListModal: function () {      
      this.$refs.bank_details_list_modal.show()
    },
    toggle_modal_form: function () {
      this.flag.show_modal_form = !this.flag.show_modal_form;
    },
    toggle_agent_bank_modal_form: function () {
      this.form.bankAccountInfo = {}
      this.flag.show_agent_bank_modal_form = !this.flag.show_agent_bank_modal_form;
    },
    editData: function (item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.flag.show_modal_form = !this.flag.show_modal_form;
      // console.log('edit data', item)
    },
    toggle_modal_agreement: function(){
        this.flag.show_agreement_modal_form = !this.flag.show_agreement_modal_form;
    },
    editAgreementData: function (item) {
        if (!this.permission_check('agent_renewable_update')) {
            return;
        }
        this.editItem = JSON.parse(JSON.stringify(item))
        this.editItem.agreement_expiry = moment(this.editItem.agreement_expiry).format("DD MMM YYYY");
        this.flag.show_agreement_modal_form = !this.flag.show_agreement_modal_form;
        this.getAgentAgreementHistoryData(item)
    },
    getAgentAgreementHistoryData: async function (item) {
        var ref=this;
        var jq=ref.jq();
        const params = { id: item.id }
        var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_agreement_hisotry_list'), params);
        this.agentAgreementHisotryList = res.data.list
    },
    updateAgentAgreementData: async function () {

        var ref=this;
        var jq=ref.jq();
        try{
            ref.wait_me('.wait_me_edit_form');
            var res = await jq.post(ref.url('api/v1/university/ajax/update_agent_agreement'), ref.editItem);
            this.getAgentItemDataById(this.agent_id);
            // this.toggle_modal_form()
            this.flag.show_modal_form = false
            this.flag.show_agreement_modal_form = false
            ref.alert(res.msg);
        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.wait_me_edit_form', 'hide');
        }
    },
    open_agent_activity_log: async function (item) {
        var ref=this;
        var jq=ref.jq();
        this.agentActivitylog = []
        this.$refs.agent_activity_log_modal.show_modal()

        try {
            const params = { id: item.id }
            var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_activity_log'), params);
            if (res.data.data.length) {
                this.agentActivitylog = res.data.data
            } else {
                this.agentActivitylog = null
            }

        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
    },
    gotoAgentWiseStudentListPage: function (item) {
      this.$router.push({
        name: "AgentWiseStudentList",
        params: {
          id: this.hash_id(item.id),
          intake_id: this.hash_id(item.intake.id),
        },
      });
    },
    getAgentItemDataById: async function (agent_id) {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me(".wait_me");
        const params = { id: agent_id };
        var res = await jq.get(ref.url("api/v1/university/ajax/get_agent_company_details_by_id"),params);
        this.agentCompany = res.data.agent_company;
        this.metaInfo = {
          title: 'OK',
          titleTemplate: res.data.agent_company.name
        }
        this.agentCompany.domicile = this.agentCompany.domicile == 1 ? true : false
        if (this.agentCompany.recruitment_markets.length) {
          this.agentCompany.recruitment_markets.sort(function(a, b) {
              var nameA = a.country.name.toUpperCase(); // ignore upper and lowercase
              var nameB = b.country.name.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                  return -1;
              }
              if (nameA > nameB) {
                  return 1;
              }

              // names must be equal
              return 0;
          });
        }
        
        if (this.agentCompany.territory) {
          if (this.agentCompany.territory.maps && this.agentCompany.territory.maps.length) {
            this.agentCompany.territory.maps.sort(function(a, b) {
                var nameA = a.country.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.country.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
          }
        }

        if (this.agentCompany.banks && this.agentCompany.banks.length) {
          this.bankModalList = this.agentCompany.banks.map(item => {
              const objData = { 
                  active : item.active == 1 ? true : false,
                  is_primary : item.is_primary == 1 ? true : false,
              }
              return Object.assign({}, item, objData)
          })
        } else {
          this.bankModalList = []
        }
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me", "hide");
      }
    },
    getIntakeWiseStudentsByAgentId: async function (agent_id) {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me(".wait_me_apex_chart");
        const params = { id: agent_id };
        var res = await jq.get(
          ref.url(
            "api/v1/university/ajax/get_intake_agent_students_by_agent_company_id"
          ),
          params
        );
        this.intakeStudnetList = res.data.list;

        this.$refs.intake_wise_students_appex_chart.updateSeries([
          {
            name: "Confirmed Students",
            type: "column",
            data: res.data.students_numbers,
          },
          {
            name: "Commission",
            type: "line",
            data: res.data.commission_amount_paid,
          },
        ]);
        this.$refs.intake_wise_students_appex_chart.updateOptions({
          xaxis: {
            labels: {
              rotate: -45,
            },
            categories: res.data.intake_names,
            tickPlacement: "on",
          },
        });
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_apex_chart", "hide");
      }
    },
    verify_agent_bank_data: async function (item) {
      if (!this.permission_check("agent_bank_verification")) {
        return;
      }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Verify Bank Details",
          html: "Are you sure to allow this bank information to be used as verified bank details for invoice payouts?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          // reverseButtons: true
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.verify_agent_bank_data_confirmed(item);
          }
        });
    },
    verify_agent_bank_data_confirmed: async function (item) {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me(".bank_account_info");
        var res = await jq.post(
          ref.url(
            "api/v1/university/ajax/verify_agent_bank_account_information"
          ),
          item
        );
        ref.alert(res.msg);
        // console.log("res.data.bank_item_data", res.data.bank_item_data);
        this.getAgentItemDataById(this.agent_id);
        // this.agentCompany.banks.map(bankItem => {
        //     if (bankItem.id == item.id) {
        //         console.log('bankItem.id',bankItem.id)
        //         console.log('item.id',item.id)
        //         return bankItem = res.data.bank_item_data
        //     }
        // })
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".bank_account_info", "hide");
      }
    },
    make_agent_bank_primary: async function (item) {
      if (!this.permission_check("agent_bank_verification")) {
        return;
      }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Make Bank Primary",
          html: "Are you sure do you want to make this bank as a primary bank?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          // reverseButtons: true
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.make_agent_bank_primary_confirmed(item);
          }
        });
    },
    make_agent_bank_primary_confirmed: async function (item) {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me(".bank_account_info");
        const params = {id: item.id, agent_company_id: this.agent_id }
        var res = await jq.post(ref.url("api/v1/university/ajax/make_agent_bank_account_primary"), params);
        ref.alert(res.msg);
        this.getAgentItemDataById(this.agent_id);
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".bank_account_info", "hide");
      }
    },
    getTerritoryList: async function () {
      var ref = this;
      var jq = ref.jq();
      var res = await jq.get(
        ref.url("api/v1/university/ajax/get_active_territory_list_data")
      );
      if (res.data.list.length) {
        this.territoryList = res.data.list.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      }
    },
    updateFormData: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        ref.wait_me(".wait_me_edit_form");
        var res = await jq.post(ref.url('api/v1/university/ajax/update_agent_info'), ref.editItem);
        this.getAgentItemDataById(this.agent_id);
        // this.toggle_modal_form()
        this.flag.show_modal_form = false;
        ref.alert(res.msg);
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_edit_form", "hide");
      }
    },
    fetch_uclan_agent_commission: async function (agent_id) {
      var ref = this;
      var jq = ref.jq();
      var url = ref.url(
        "api/v1/university/ajax/uclan_agent_commission_structure"
      );

      var qstr = {
        agent_id: agent_id,
      };


      try {
        var res = await jq.get(url, qstr);
        ref.comm_structure = res.data.comm_structure;
      } catch (err) {
        // console.log(err);
      }
    },
    getUsersList: async function () {
        var ref=this;
        var jq=ref.jq();
        var res = await jq.get(ref.url('api/v1/university/ajax/get_uni_users_list'));
        this.usersList = res.data.usersList
    },
    
    insertAgentAccountData: async function () {

        var ref=this;
        var jq=ref.jq();
        try {
            ref.wait_me('.wait_me_insert_form');
            const params = Object.assign({agent_company_id: this.agent_id }, ref.form.bankAccountInfo)
            var res = await jq.post(ref.url('api/v1/uclan/ajax/add_agent_bank_account_information'), params);
            this.getAgentItemDataById(this.agent_id);
            this.toggle_agent_bank_modal_form()
            // this.$refs.bank_details_list_modal.show()
            ref.alert(res.msg);
            // this.update_reset()

        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.wait_me_insert_form', 'hide');
        }
    },
    updateAgentAccountData: async function () {

        var ref=this;
        var jq=ref.jq();
        try{
            ref.wait_me('.wait_me_edit_form');
            var res = await jq.post(ref.url('api/v1/uclan/ajax/update_agent_bank_account_information'), ref.form.bankAccountInfo);
            this.getAgentItemDataById(this.agent_id);
            this.toggle_agent_bank_modal_form()
            // this.$refs.bank_details_list_modal.show()
            ref.alert(res.msg);
            // this.update_reset()

        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.wait_me_edit_form', 'hide');
        }
    },
    updateAgentBankIsPrimaryStatus: async function (item) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-2'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Make Primary Account',
            html: "Do you want to make " + item.bank_name + '-' + item.account_number + " this account as your primary bank account for receiving commissions?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            // reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this.updateAgentBankIsPrimaryStatusConfirmed(item)
            } else {
                if (item.is_primary) {
                    item.is_primary = false
                } else {
                    item.is_primary = true
                }
            }
        })
    },
    updateAgentBankIsPrimaryStatusConfirmed: async function (item) {

        var ref=this;
        var jq=ref.jq();
        try{
            ref.wait_me('.wait_me_edit_form');
            var res = await jq.post(ref.url('api/v1/uclan/ajax/update_agent_bank_account_information_is_primary'), item);
            ref.alert(res.msg);
            this.getAgentItemDataById(this.agent_id);

        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.wait_me_edit_form', 'hide');
        }
    },
    updateAgentBankActiveStatus: async function (item) {
        
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-2'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Active Status Change',
            html: "Do you want to change active status of this bank account?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            // reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this.updateAgentBankActiveStatusConfirmed(item)
            } else {
                if (item.active) {
                    item.active = false
                } else {
                    item.active = true
                }
            }
        })
    },
    updateAgentBankActiveStatusConfirmed: async function (item) {
        console.log('item', item)

        var ref=this;
        var jq=ref.jq();
        try{
            ref.wait_me('.wait_me_edit_form');
            var res = await jq.post(ref.url('api/v1/uclan/ajax/agent_bank_account_active_inactive'), item);
            ref.alert(res.msg);
            this.getAgentItemDataById(this.agent_id);
            // this.getListData()

        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.wait_me_edit_form', 'hide');
        }
    },
    deleteAgentBankData: async function (item, index) {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-2'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Delete!',
            html: "Are you sure to delete?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            // reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteAgentBankConfirm(item, index)
            }
        })
    },
    deleteAgentBankConfirm: async function (item) {
        var ref=this;
        var jq=ref.jq();
        try {
            var res = await jq.post(ref.url('api/v1/uclan/ajax/delete_agent_bank_account_information'), item);
            ref.alert(res.msg)
            this.getAgentItemDataById(this.agent_id);
        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
    },
    editAgentBankData: function (item) {
        // this.toggle_right_sidebar_form()
        this.$refs.bank_details_list_modal.hide()
        this.toggle_agent_bank_modal_form()
        this.saveData = false

        this.form.bankAccountInfo = JSON.parse(JSON.stringify(item))
    },
  },
  computed: {
    store_auth_user: function () {
      return this.cn(this.$store.state, "site.auth_user", null);
    },
    treeselect_uni_users: function(){
      if (this.usersList.length) {
        return this.usersList.map((row)=>{
            return {
                id: row.id,
                label: row.name
            }
        });
      }

      return []
    }
  },
  created: async function () {
    this.setup_urls();
    const countries = await this.countries();
    this.countryList = countries.map((item) => {
      return {
        id: item.id,
        label: item.name,
      };
    });
    this.agent_id = this.hash_id(this.$route.params.id, false)[0];
    if (this.agent_id) {
      this.getAgentItemDataById(this.agent_id);
      this.getIntakeWiseStudentsByAgentId(this.agent_id);
      this.fetch_uclan_agent_commission(this.agent_id);
    } else {
      this.$router.push("/agency-list");
    }
  },
  mounted: function () {
    feather.replace();
    this.getTerritoryList();
    this.getUsersList()
  },
};
</script>

<style scoped>
#user-profile {
  /*-------------profile header section---------*/
  /*-------- profile info section --------*/
}

#user-profile .profile-header {
  overflow: hidden;
}

#user-profile .profile-header .profile-img-container {
  position: absolute;
  bottom: -2rem;
  left: 2.14rem;
  z-index: 2;
}

#user-profile .profile-header .profile-img-container .profile-img {
  height: 8.92rem;
  width: 8.92rem;
  border: 0.357rem solid #fff;
  background-color: #fff;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

#user-profile .profile-header .profile-header-nav .navbar {
  padding: 0.8rem 1rem;
}

#user-profile .profile-header .profile-header-nav .navbar .navbar-toggler {
  line-height: 0;
}

#user-profile
  .profile-header
  .profile-header-nav
  .navbar
  .profile-tabs
  .nav-item
  i,
#user-profile
  .profile-header
  .profile-header-nav
  .navbar
  .profile-tabs
  .nav-item
  svg {
  margin-right: 0;
}

#user-profile #profile-info .profile-star {
  color: #babfc7;
}

#user-profile #profile-info .profile-star i.profile-favorite,
#user-profile #profile-info .profile-star svg.profile-favorite {
  fill: #ff9f43;
  stroke: #ff9f43;
}

#user-profile #profile-info .profile-likes {
  fill: #ea5455;
  stroke: #ea5455;
}

#user-profile #profile-info .profile-polls-info .progress {
  height: 0.42rem;
}

#user-profile .profile-latest-img {
  transition: all 0.2s ease-in-out;
}

#user-profile .profile-latest-img:hover {
  transform: translateY(-4px) scale(1.2);
  z-index: 10;
}

#user-profile .profile-latest-img img {
  margin-top: 1.28rem;
}

#user-profile .block-element .spinner-border {
  border-width: 0.14rem;
}

@media (max-width: 991.98px) {
  #user-profile .profile-latest-img img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .profile-header-nav .profile-tabs {
    width: 100%;
    margin-left: 13.2rem;
  }
}

@media (max-width: 575.98px) {
  /* #user-profile .profile-header .profile-img-container .profile-img {
    height: 100px;
    width: 100px;
  } */
  #user-profile .profile-header .profile-img-container .profile-title h2 {
    font-size: 1.5rem;
  }
}

#user-profile .profile-header .profile-img-container {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 2;
}
.bank_block {
  /* border: 2px solid #3BAAFF;
    background: #F6FCFF; */
  border: 2px solid #acacac;
  background: #f3f2f7;
  padding: 15px;
  border-radius: 10px;
}
.bank_block_verified {
  border: 2px solid #28c76f;
  padding: 15px;
  border-radius: 10px;
  /* background: #f6fff8; */
  background: #fffffa;;
}
</style>
