<template>
<section class="component-data-list">
    <main-layout>
        <!-- BEGIN: Content-->
                <div class="content-body">
                    <!-- Input Sizing start -->
                    <section id="input-sizing">
                        <div class="row match-height">
                            <div class="col-md-4 col-md-offset-4 m-auto">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Password Reset</h4>
                                    </div>
                                    <div class="card-body wait_me_insert_form">
                                        <div class="form-row">
                                            <div class="col-md-12">
                                                <p class="text-warning">Hi {{ form.user.name }}! Please reset your password </p>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="password">New password <span class="text-danger">*</span></label>
                                                    <input type="password" v-model="form.user.password" class="form-control" id="password" placeholder="Enter new password" @keyup.enter="resetPassword()"/>
                                                </div>
                                                <div class="form-group">
                                                    <label for="confirm_password">Confirm password <span class="text-danger">*</span></label>
                                                    <input type="password" v-model="form.user.confirm_password" class="form-control" id="confirm_password" placeholder="Enter confirm password" @keyup.enter="resetPassword()"/>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col text-right">
                                                        <button type="button" class="btn btn-success" @click.prevent="resetPassword()">Reset Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- Input Sizing end -->
                </div>
        <!-- END: Content-->


        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
    </main-layout>
</section>
</template>

<script>
import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
// import swal from 'bootstrap-sweetalert';
// import { EditIcon } from 'vue-feather-icons'
// import { AlertTriangleIcon } from 'vue-feather-icons'

export default{
    name: 'PasswordReset',
    components:{
        MainLayout,
        // EditIcon,
    },
    data:()=>{
        return {
            saveData: true,
            showModal: false,
            icon: 'edit',
            flag:{
                show_modal_form:false
            },
            form: {
                user: {
                    id: '',
                    name: '',
                    email: '',
                    password: '',
                    confirm_password: '',
                }
            },
            users_count: 0,
        };
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                    return {
                        id: each.id,
                        label: each.name
                    }
                });
        },
    },
    created: async function () {
        this.setup_urls();
        this.universityUsers()
    },
    methods:{
        universityUsers: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_auth_university_users_count'));
                ref.users_count = res.data.users_count
                if (ref.users_count > 1) {
                    this.$router.push('/dashboard')
                }
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        getUniversityProfileData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.get(ref.url('api/v1/university/ajax/auth_user_info'));
                ref.form.user = res.data.auth_user
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        resetPassword: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/reset_user_password'), ref.form.user);
                ref.$store.commit('siteInfo', {
                    key: 'auth_user',
                    val: res.data.user
                });
                
                // ref.$store.commit('siteInfo', {
                //     key: 'permission_disable',
                //     val: res.data.permission_disable
                // });

                // ref.$store.commit('siteInfo', {
                //     key: 'university',
                //     val: res.data.university
                // });

                this.$router.push('/dashboard')
                // this.$router.reload()
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
    },
    mounted: function(){
        feather.replace();
        this.getUniversityProfileData();
    }
}
</script>

<style scoped>
    /* .regional-form-section {
        min-height: 100% !important;
    } */

</style>