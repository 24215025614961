<template>
<section class="agency-list">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Gateway</h2> -->
                        <h5 class="content-header-title float-left mt-50">Gateway</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active"><router-link to="/gateway/all-application">All Lists</router-link></li>
                                
                                <!-- <li class="breadcrumb-item active"><router-link :to="`/gateway/intake-wise-agent-list/${$route.params.intake_id}`">{{ agentStudentListItem.list_code }}</router-link></li> -->
                                <li class="breadcrumb-item active"><router-link :to="`/gateway/intake-wise-agent-list/${$route.params.intake_id}`">{{ agentStudentListItem.intake ? agentStudentListItem.intake.name : '' }}</router-link></li>
                                <li class="breadcrumb-item active">Student List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div class="content-body">
                <section class="app-user-list">
                <section class="modern-horizontal-wizard">
                    <div class="bs-stepper wizard-modern modern-wizard-example">
                        <div class="bs-stepper-header">

                             <!-- <div>
                                <button v-on:click="goto_agent_wise_bulk_students()" class="step-trigger" style="text-decoration: none;">
                                    <span class="bs-stepper-box" style="width: 38px;height: 38px; color: #babfc7; background-color: rgba(186, 191, 199, 0.12); padding: 0.5em 0; border-radius: 0.35rem; font-weight: 500;">0</span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Bulk Verification 
                                        </span> 
                                    </span>
                                </button>
                            </div>

                            <div class="line">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div> -->

                            <!-- <div v-if="permission_check('enrolled_list_by_uni')" class="step" :class="{ active: isActive('zero') }">
                                <button  @click="setActive('zero')" type="button" class="step-trigger" aria-selected="true">
                                    <span class="bs-stepper-box">0</span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Enrolled List by Uni</span>
                                    </span>
                                </button>
                            </div>
                            <div v-if="permission_check('enrolled_list_by_uni')" class="line">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div> -->
                            <div class="step" :class="{ active: isActive('one') }">
                                <button  @click="setActive('one')" type="button" class="step-trigger" aria-selected="true">
                                    <span class="bs-stepper-box">1</span>
                                    <span class="bs-stepper-label">
                                            <span class="bs-stepper-title">International Office
                                                <span class="badge badge-primary badge-pill">
                                                    <span v-html="ss_counter.io_completed"></span> / <span v-html="ss_counter.io_total"></span>
                                            </span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                            <div class="step" :class="{ active: isActive('two') }">
                                <button  @click="setActive('two')" type="button" class="step-trigger" aria-selected="false">
                                    <span class="bs-stepper-box">2</span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Finance Office <span class="badge badge-primary badge-pill">
                                                <span v-html="ss_counter.fo_completed"></span> / <span v-html="ss_counter.fo_total"></span>
                                            </span>
                                        </span> 
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                            <div class="step" :class="{ active: isActive('three') }">
                                <button @click="setActive('three')" type="button" class="step-trigger" aria-selected="false">
                                    <span class="bs-stepper-box">3</span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"> Confirmed List <span class="badge badge-primary badge-pill" v-html="ss_counter.confirmed_total">
                                        </span></span> 
                                    </span>
                                </button>
                            </div>
                        </div>
                        <!-- <div class="bs-stepper-content"> -->
                        <div class="">
                            <div id="bulk-student" class="content wait_me_list_data" :class="{ active: isActive('zero') }">

                                <enrolled-uni-student-list></enrolled-uni-student-list>

                            </div>
                            <div id="international-office" class="content wait_me_list_data" :class="{ active: isActive('one') }">
                                <international-office-student-list ref="io_student_list"
                                    :asVisitorListInfo="asVisitorListInfo"
                                    :userTreeselect="userTreeselectList"
                                    :statusGroupList="statusGroupList"
                                    :courseLevelList="courseLevelList"
                                    :programmeList="programmeList"
                                    :list_id="list_id">
                                </international-office-student-list>
                            </div>

                            <div id="finance-office" class="content wait_me_list_data" :class="{ active: isActive('two') }"> 
                                <finance-office-student-list ref="fo_student_list"
                                    :asVisitorListInfo="asVisitorListInfo"
                                    :userTreeselect="userTreeselectList"
                                    :statusGroupList="statusGroupList"
                                    :courseLevelList="courseLevelList"
                                    :programmeList="programmeList"
                                    :list_id="list_id"
                                    v-on:s-confirm="getAgentListInfoByListId">
                                </finance-office-student-list>
                            </div>

                            <div id="admin-step" class="content wait_me_list_data" :class="{ active: isActive('three') }"> 
                                <confirmed-student-list ref="confirm_student_list"
                                    :asVisitorListInfo="asVisitorListInfo"
                                    :userTreeselect="userTreeselectList"
                                    :statusGroupList="statusGroupList"
                                    :courseLevelList="courseLevelList"
                                    :programmeList="programmeList"
                                    :list_id="list_id">
                                </confirmed-student-list>
                            </div>

                        </div>
                    </div>
                </section>
                </section>

                <!-- Remark here -->
                <student-remarks :list_id="list_id" ref="student_remarks_component"/>
            </div>

    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import EnrolledUniStudentList from './EnrolledUniStudentList .vue';
import InternationalOfficeStudentList from './InternationalOfficeStudentList .vue';
import FinanceOfficeStudentList from './FinanceOfficeStudentList .vue';
import ConfirmedStudentList from './ConfirmedStudentList .vue';
import StudentRemarks from '../Partials/StudentRemarks.vue';

export default{
    name: 'AgentWiseStudentList',
    components:{
        MainLayout,
        EnrolledUniStudentList,
        InternationalOfficeStudentList,
        FinanceOfficeStudentList,
        ConfirmedStudentList,
        StudentRemarks
    },
    data:()=>{
        return {
            list_id: null,
            intake_id: null,
            activeItem: 'one',
            statusGroupList: [],
            courseLevelList: [],
            programmeList: [],
            userTreeselectList: [],
            asVisitorListInfo: [],
            agentStudentListItem: {},
            agentListInfo: {},
            ss_counter:{
                io_total:0,
                io_completed:0,
                fo_total:0,
                fo_completed:0,
                confirmed_total:0
            }
        };
    },
    methods:{
        setActive: function (menuItem) {
            this.activeItem = menuItem // no need for Vue.set()
            console.log('menuItem', menuItem)
            if (menuItem == 'one') {
                this.$refs.io_student_list.getInternationListData();
            } else if (menuItem == 'two') {
                this.$refs.fo_student_list.getFinanceOfficeListData();
            } else if (menuItem == 'three') {
                this.$refs.confirm_student_list.getConfirmedListData();
            }
            this.gateway_count_student_by_statuses();
        },
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
        gotoBulkVerficationUploadPage: function () {
            this.$router.push({ name: 'BulkVerficationUpload', params: { id: this.$route.params.intake_id } })
        },
        goto_agent_wise_bulk_students: function () {
            this.$router.push({ name: 'AgencyWiseBulkStudent', params: { hash: this.hash_id(this.list_id) } })
        },
        getAgentStudentListCodeByListId: async function(){

            var ref=this;
            var jq=ref.jq();

            if (!ref.$route.params.id) {
               this.$router.push({ name: 'IntakeWiseAgentList' })
            } else {
                this.list_id = this.hash_id(this.$route.params.id, false)[0]
                if (!this.list_id) {
                    this.$router.push({ name: 'IntakeWiseAgentList', params: { id: this.list_id } })
                }
                this.intake_id = this.hash_id(this.$route.params.intake_id, false)[0]
                if (!this.intake_id) {
                    this.$router.push({ name: 'IntakeWiseAgentList', params: { id: this.list_id } })
                }
                console.log('this.list_id', this.list_id)
            }

            try {
                const params = Object.assign({}, { list_id: this.list_id })
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_wise_student_list_code'), params);
                this.agentStudentListItem = res.data.item

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }

            try {
                const params = Object.assign({}, { list_id: this.list_id })
                await jq.post(ref.url('api/v1/university/ajax/store_agent_student_list_visitor_information'), params);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getAgentListInfoByListId: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { list_id: this.list_id })
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_info_by_list_id'), params);
                this.agentListInfo = res.data.data

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getAgentStudentListVisitorList: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { list_id: this.list_id })
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_student_list_visitor_information'), params);
                this.asVisitorListInfo = res.data.list

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        gateway_count_student_by_statuses: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/gateway_count_student_by_statuses');

            try {

                var res=await jq.get(url, { list_id: ref.list_id });
                ref.ss_counter=res.data.ss_counter;

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        getUniversityUsers: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_users_by_institute'));
                
                this.userTreeselectList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_university_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.course_level.id,
                        label: item.course_level.name
                    }
                });

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getStatusesByGroup: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_status_groups_with_statuses'));
                this.statusGroupList = res.data.status_groups
                // console.log('this.statusGroupList', this.statusGroupList)
                // this.ioStatuses = res.data
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getProgrammeList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.user-role-table');
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_dropdown_course_list'));
                if (res.data.list && res.data.list.length) {
                    this.programmeList = res.data.list.map(item => {
                        return {
                            id: item.id,
                            label: item.name
                        }
                    })
                }
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.user-role-table', 'hide');
            }
        },
    },
    computed: {
    },
    created: function(){
        this.setup_urls();
        this.list_id = this.hash_id(this.$route.params.id, false)[0]
        if (this.$route.params.active_tab) {
            this.activeItem = this.$route.params.active_tab
        }
        this.getStatusesByGroup()
    },
    mounted: function(){
        feather.replace();
        this.getAgentStudentListCodeByListId()
        this.gateway_count_student_by_statuses();
        this.getUniversityUsers();
        this.getAgentStudentListVisitorList();
        this.getCourseLevelList()
        this.getProgrammeList()
        // this.getAgentListInfoByListId()
    }
}
</script>
<style>
.bs-stepper .bs-stepper-content {
    padding: 0rem 0rem !important;
}
.card {
    margin-bottom: 0rem!important;
}
</style>