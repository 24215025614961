<template>
  <section class="agency-list">
    <main-layout>
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <!-- <h2 class="content-header-title float-left mb-0">Gateway</h2> -->
              <h5 class="content-header-title float-left mt-50">Gateway</h5>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item active">Dispute Intake List</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card wait_me_list_data">
        <div class="card-header d-block">
          <div class="row">
            <div class="col-6 col-md-6">
              <h3>Dispute Intake List</h3>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Intake Name</th>
                  <th class="text-center">Agent</th>
                  <th class="text-center">Student</th>
                  <th class="text-center">Total Case</th>
                  <th class="text-center">Case Solved</th>
                  <th class="text-center">Case Unsolved</th>
                </tr>
              </thead>
              <tbody v-if="listData && listData.length > 0">
                <tr v-for="(item, index) in listData" :key="index">
                  <td v-html="index + pagination.slOffset"></td>

                  <td class="intake" @click="gotoDisputeStudentListPage(item)">
                    <h5>
                      <span
                        class="badge badge-pill badge-light-primary"
                        v-html="item.name"
                      ></span>
                    </h5>
                  </td>

                  <td class="text-center agent">
                    <!-- <div v-if="cn(item, 'intake_status.code', null) &&item.intake_status.code == 'intake_status_published'"> -->
                    <div v-if="item.related_agents">
                      <div>
                        <span v-if="checkCurrentDateGreaterThenDate(item.deadline)" class="d-block"
                          v-show="item.related_agents"
                          v-html="item.related_agents"
                          v-tooltip="item.related_agents > 1 ? item.related_agents + ' Lists Submitted' : item.related_agents + ' List Submitted'"></span>
                        <strong
                          v-else
                          class="d-block"
                          v-show="item.related_agents"
                          v-html="item.related_agents"
                          v-tooltip="item.related_agents > 1 ? item.related_agents + ' Lists Submitted' : item.related_agents + ' List Submitted'"
                        ></strong>
                        <!-- <span class="text-danger" v-html="random_number()" v-tooltip="random_number() + ' Agents Have Dispute'"></span> -->
                      </div>
                      <!-- <div v-else>
                                            <strong class="d-block">10</strong>  
                                            <span class="text-danger">2</span>
                                        </div> -->
                    </div>
                    <span v-else>-</span>
                  </td>

                  <td class="text-center students">
                    <!-- <div v-if="cn(item, 'intake_status.code', null) && item.intake_status.code == 'intake_status_published'"> -->
                    <div v-if="item.related_students">
                      <div>
                        <span v-if="checkCurrentDateGreaterThenDate(item.deadline)" class="d-block"
                          v-show="item.related_students"
                          v-html="item.related_students"
                          v-tooltip="item.related_students > 1 ? item.related_students + ' Students Submitted' :  item.related_students + ' Student Submitted'"></span>
                        <strong
                          v-else
                          class="d-block"
                          v-show="item.related_students"
                          v-html="item.related_students"
                          v-tooltip="item.related_students > 1 ? item.related_students + ' Students Submitted' :  item.related_students + ' Student Submitted'"></strong>
                        <!-- <span class="text-danger" v-html="random_number()"  v-tooltip="random_number() + ' Students Have Dispute'"></span> -->
                      </div>
                      <!-- <div v-else>
                                            <strong class="d-block">50</strong>  
                                            <span class="text-danger">10</span>
                                        </div> -->
                    </div>
                    <span v-else>-</span>
                  </td>
                  <td class="text-center">
                    <span class="font-weight-bolder" v-html="item.total_case"></span>
                  </td>
                  <td class="text-center">
                    <span class="badge badge-light-success font-weight-bolder" v-html="item.case_solved"></span>
                  </td>
                  <td class="text-center">
                    <span class="badge badge-light-danger font-weight-bolder" v-html="item.case_unsolved"></span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <th colspan="9" class="text-center">
                    <span v-if="listData === null">Processing...</span>
                    <span v-else>
                      <i
                        class="fa fa-exclamation-triangle text-danger"
                        aria-hidden="true"
                      ></i>
                      Found no agent data.
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
            <b-pagination
              class="mt-1 mr-1"
              align="right"
              v-model="pagination.currentPage"
              pills
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              @input="searchData"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </main-layout>
  </section>
</template>

<script>
import MainLayout from "../../MainLayout.vue";
import feather from "feather-icons";
// import { BFormCheckbox } from 'bootstrap-vue'
//import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { BPagination } from "bootstrap-vue";
// import { BPagination } from 'bootstrap-vue'
// import swal from "bootstrap-sweetalert";
// import { UserCheckIcon } from "vue-feather-icons";

// import { BModal } from 'bootstrap-vue';

// import Swal from 'sweetalert2';

export default {
  name: "AllApplication",
  metaInfo: {
      titleTemplate: '%s | Dispute Intake List'
  },
  components: {
    MainLayout,
    BPagination,
    //BDropdown,
    //BDropdownItem,
    // UserCheckIcon,
  },
  data: () => {
    return {
      filterCountData: {},
      agencyList: [],
      listData: null,
      bv_status: [],
      flag: {
        show_agent_form: false,
      },
      form: {
        uni_course_level: {
          checked_all: false,
          agent_ids: [],
        },
      },
      search: {
        intake_id: null
      }
    };
  },
  methods: {
    async searchData() {
      this.getListData();
    },
    gotoDisputeStudentListPage: function (item) {
      if (this.permission_check("intake_wise_agents")) {
        this.$router.push({
          name: "DisputedStudentListByIntake",
          query: { intake_id: this.hash_id(item.id) },
        });
      }
    },
    getListData: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage });
        ref.wait_me(".wait_me_list_data");
        var res = await jq.get(ref.url("api/v1/uclan/ajax/get_dispute_intake_list"), params);
        console.log('res.data.dispute_intakes', res.data.dispute_intakes)
        this.listData = res.data.dispute_intakes.data
        this.paginationData(res.data.dispute_intakes);
        // console.log('this.listData', this.listData)
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_list_data", "hide");
      }
    },
    getIntakeStatusCount: async function () {
        var ref=this;
        var jq=ref.jq();
        try {
            ref.wait_me('.intake-list-table');
            var res = await jq.get(ref.url('api/v1/university/ajax/get_university_intake_list'), this.search);
            this.filterCountData = res.data
        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.intake-list-table', 'hide');
        }
    },
    getStatusWiseIntakeListData: async function(status){

        var ref=this;
        var jq=ref.jq();

        try {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, status: status });
          ref.wait_me(".wait_me_list_data");
          var res = await jq.get(ref.url("api/v1/uclan/ajax/gateway_all_applications"), params);
          this.listData = res.data.map((item) => {
            const instakeStatus = Object.assign({}, item.intake_status, JSON.parse(item.intake_status.data));
            return Object.assign({}, item, JSON.parse(item.bulk_verification_status_data), { intake_status: instakeStatus });
          });
          this.paginationData(res);
        } catch (err) {
          ref.alert(ref.err_msg(err), "error");
        } finally {
          ref.wait_me(".wait_me_list_data", "hide");
        }
    },
  },
  computed: {
    store_countries: function () {
      return this.$store.state.site.countries;
    },
    treeselect_countries: function () {
      return this.store_countries.map((each) => {
        return {
          id: each.id,
          label: each.name,
        };
      });
    },
  },
  created: function () {
    this.setup_urls();
  },
  mounted: function () {
    feather.replace();
    this.getListData();
    this.getIntakeStatusCount()
  },
};
</script>
