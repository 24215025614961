<template>
<div class="card">
  <div class="card-header p-1 border-bottom">
    <h3>Commission Configuration Details</h3>
    
    <button class="btn btn-outline-info btn-sm p-50 mr-25" v-on:click="open_add_edit_comm(active_comm.id)">
      <i class="fa fa-pencil"></i>
    </button>
    <!-- <div>
      <span class="badge badge-light-secondary badge-pill mr-25" v-if="active_comm" v-html="active_comm.intake.name"></span>
      <span class="badge badge-light-primary badge-pill" v-if="active_comm" v-html="active_comm.comm_type.name"></span>
    </div> -->
  </div>
  <div class="card-body">
    <h4 class="text-center my-5" v-if="!active_comm">
      <i class="fa fa-info text-info" aria-hidden="true"></i>
      Please select a commission configuration first.
    </h4>
    <section class="uni-comm-details-section" v-else>

      <div class="form-row">
        <div class="col mt-50">
          <small>Configuration Title:</small><br>
          <strong v-html="active_comm.parsed_comm_configs.title"></strong>
        </div>
        <div v-if="cn(active_comm, 'parsed_comm_configs.intake_from', null)" class="col mt-50 text-right">
          <small>Intake Duration</small><br>
          <strong v-html="active_comm.parsed_comm_configs.intake_from+' - '+active_comm.parsed_comm_configs.intake_to"></strong>
        </div>
      </div><!--End of info row-->

      <div class="form-row mt-3" v-for="(territory, index) in active_comm.parsed_comm_configs.territories" :key="index">
        <div class="col">

          <table class="table table-sm table-bordered">
            <tbody>
              <tr>
                <th class="bg-light-primary">
                  <span v-html="index+1"></span>.
                  Selected Territories
                </th>
                <th class="bg-light-primary text-center" v-for="(pd, pd_index) in territory.percentage_distributions" :key="pd_index">
                  <div v-if="pd.stu_range_to">
                    <span v-html="pd.stu_range_from"></span>- <span v-html="pd.stu_range_to"></span>
                  </div>
                  <div v-else>
                    <span v-html="pd.stu_range_from"></span>+
                  </div>                  
                  <span>Students</span>
                </th>
              </tr>
              <tr>
                <td>
                  <span v-for="(tt, tt_index) in get_territories(territory.territory_ids)" class="badge badge-light-info mr-25" :key="tt_index" v-html="tt.name"></span>
                </td>
                <td class="text-center" v-for="(pd, pd_index) in territory.percentage_distributions" :key="pd_index">
                  <strong v-html="pd.percentage"></strong>%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col text-right">
          Added By <strong v-html="active_comm.creator.name"></strong>
          On <strong v-html="dDate(active_comm.created_at)"></strong>
        </div>
      </div>

    </section>
  </div>

  <add-edit-commission ref="add_edit_commission" :args="args"></add-edit-commission>
</div>
</template>

<script>
import AddEditCommission from './AddEditCommission.vue';
export default {
  name:'UniCommDetails',
  props:['active_comm', 'args'],
  components:{
		AddEditCommission,
	},
  data:()=>{
    return {
      uni_comm_config_list: []
    }
  },
  methods:{
		open_add_edit_comm: function(edit_comm_id=null){
			this.$refs.add_edit_commission.toggle_comm_form(edit_comm_id);
		},
    get_territories: function(territory_ids){

      if(territory_ids && territory_ids.length){
        return this.args.territories.filter(row=>{
          return territory_ids.includes(row.id);
        });
      }else{
        return [];
      }

    }
  },
  created: async function () {
  },
  mounted () {
  }
}
</script>

<style>

</style>
