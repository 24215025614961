<template>
<section class="agent-account-managers">
<main-layout>

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                    <h5 class="content-header-title float-left mt-50">Manage Territory</h5>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                            <li class="breadcrumb-item">Settings</li>
                            <li class="breadcrumb-item active">Territory List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card wait_me_territory_card">
        <div class="content-header row p-1">
            <div class="col">
                <h3 class="mt-0">Territory List</h3>
                <small @click="getListData()" v-if="listData && listData.length" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + listData.length"></small>
            </div>
            <div class="col text-right">
                <button class="btn btn-primary waves-effect waves-float waves-light mr-1" v-on:click="toggle_territory_form()">
                    <i data-feather="plus-square"></i>
                    Add New Territory
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <tr>
                            <th class="text-center" width="20px">#</th>
                            <th>Territory Name</th>
                            <th>Territory Manager</th>
                            <th>Countries</th>
                            <th class="text-center" width="100px">Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="listData && listData.length">
                        <tr v-for="(item, index) in listData" :key="item.id">
                            <td v-html="index+1" class="text-center"></td>
                            <td>
                                <span v-html="item.name"></span>
                            </td>
                            <td>
                                <span v-if="item.manager" v-html="item.manager.name"></span>
                            </td>
                            <!-- <td>
                                <span v-for="regions_country in item.regions_countries" :key="regions_country.id" class="badge badge-secondary mr-1 mt-1" v-html="regions_country.region.title"></span>
                            </td> -->
                            <td>
                                <!-- <span v-for="map in item.maps" :key="map.id" class="badge badge-primary mr-1 mt-1" v-html="map.country.name"></span> -->
                                <!-- <div class="multiple-avatars" v-for="map in item.maps" :key="map.id" v-tooltip="map.country.name">
                                    <img class="rounded-circle" :src="map.country.flag_url" height="20"/>
                                </div> -->

                                <div class="b-avatar-group">
                                    <div class="b-avatar-group-inner" style="padding-left: calc(5.1px); padding-right: calc(5.1px);">
                                        <span v-for="map in item.maps.slice(0, 5)" :key="map.id" v-tooltip="map.country.name"  class="b-avatar pull-up badge-secondary rounded-circle" style="margin-left: calc(-5.1px); margin-right: calc(-5.1px); width: 30px; height: 30px; margin-right: 10px; margin-bottom: 10px">
                                            <span class="b-avatar-img"><img :src="map.country.flag_url" alt="avatar"></span>
                                        </span>
                                        
                                        <h6 v-if="item.maps.length > 5" class="align-self-center cursor-pointer zoom" @click="showRecruitmentTerritoryListModal(item)"> +{{ (item.maps.length - 5) }} </h6>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <button @click="editData(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;">
                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                </button>                                                

                                <button @click="deleteData(item, index)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                    <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th colspan="5" class="text-center">
                                <span v-if="listData === null" >Processing...</span>
                                <span v-else >
                                    <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                    Found no territory.
                                </span>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <b-modal ref="recruitment_territory_list_modal" :size="territoryModalTableSize" class="text-center">
        <template #modal-title>
            <span class="text-uclan">Territory Countries</span>
        </template>

        <table class="table table-bordered table-sm">
            <tr>
                <th :colspan="territoryModalTableThColSpan" class="text-center">Countries</th>
            </tr>
                <tr v-for="(chunk, index) in territoryModalList" :key="index">
                    <td v-for="(chunkRow, chunkIndex) in chunk" :key="chunkIndex" class="align-items-center">
                        <span class="b-avatar pull-up badge-secondary rounded-circle mr-1" style="width: 30px; height: 30px;">
                            <span class="b-avatar-img"><img :src="chunkRow.country.flag_url" alt="avatar"></span>
                        </span>
                        <span v-html="chunkRow.country.name"></span>
                    </td>
                </tr>
        </table>

        <template #modal-footer>
            <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.recruitment_territory_list_modal.hide()">Close</button>
        </template>
    </b-modal>


    <sidebar-form
        :show_sidebar="flag.show_sidebar_form"
        v-on:toggle-sidebar="toggle_territory_form()"
        title="Add/Edit Account Manager"
    >

        <div class="card-body add_territory_form">
            <h5 class="card-title text-primary" v-if="!form.territory.id">Add Territory</h5>
                <h5 class="card-title text-primary" v-if="form.territory.id">Edit Territory</h5>

            <div class="form-row">
                <div class="col form-group">
                    <label for="">
                        <b>Terrtitory Name</b>
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control form-control-sm" placeholder="Enter terrtitory name" v-model="form.territory.name"/>
                </div>
            </div>

            <!-- <div class="form-row">
                <div class="col form-group">
                    <label for="">
                        Select Region
                        <span class="text-danger">*</span>
                    </label>
                    <treeselect multiple v-model="form.territory.maps.region_ids" :options="regionTreeSelectList"/>
                </div>
            </div> -->
            <div class="form-row">
                <div class="col form-group">
                    <label for="title"><b>Territory Manager</b> <span class="text-danger">*</span></label>
                    <treeselect v-model="form.territory.territory_manager_id" :options="usersList" id="uni_user_id" placeholder="Select user" autocomplete="off"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col form-group">
                    <label for="country_ids">
                        <b>Select Countries</b>
                        <span class="text-danger">*</span>
                    </label>
                    
                    <!-- <v-select multiple v-model="form.territory.maps.country_ids" :options="countryTreeSelectList" :reduce="(item) => item.id" placeholder="Select Countries" id="country_ids" autocomplete="off"/> -->
                    <treeselect multiple v-model="form.territory.maps.country_ids" :options="countryList"/>
                </div>
            </div>
            <button type="submit" class="btn btn-success pull-right" v-if="!form.territory.id" @click.prevent="addTerritory()">Save</button>
            <button type="submit" class="btn btn-success pull-right" v-if="form.territory.id" @click.prevent="updateTerritory()">Update</button>
            <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
        </div>
    </sidebar-form>
</main-layout>
</section>
</template>

<script>
import MainLayout from '../MainLayout.vue';
import { EditIcon, Trash2Icon, XIcon } from 'vue-feather-icons';
import { BModal } from 'bootstrap-vue';
import SidebarForm from '../../SidebarForm.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect'
import Swal from 'sweetalert2'

export default {
    name: 'TerritoryList',
    metaInfo: {
        titleTemplate: '%s | Manage Territory'
    },
    components:{
        MainLayout, SidebarForm,
        Treeselect,
        EditIcon,
        Trash2Icon,
        XIcon,
        BModal
    },
    data: ()=>{
        return {
            listData: null,
            flag:{
                show_sidebar_form: false
            },
            form:{
                territory:{
                    id:'',
                    name:'',
                    territory_manager_id: null,
                    maps: {
                        region_ids: null,
                        country_ids: null,
                    }
                }
            },
            regionTreeSelectList: [],
            regionList: [],
            countryList: [],
            usersList: [],
            territoryModalList: [],
            territoryModalTableSize: 'md',
            territoryModalTableThColSpan: '',
        };
    },
    methods:{
        showRecruitmentTerritoryListModal: function (item) {
            if (item.maps.length > 10 && item.maps.length <= 28) {
                this.territoryModalList = this.chunk(item.maps, 2)
                this.territoryModalTableSize = 'md'
                this.territoryModalTableThColSpan = 2
            } else if (item.maps.length > 28) {
                this.territoryModalList = this.chunk(item.maps, 3)
                this.territoryModalTableSize = 'lg'
                this.territoryModalTableThColSpan = 3
            } else {
                this.territoryModalList = this.chunk(item.maps, 1)
                this.territoryModalTableSize = 'sm'
                this.territoryModalTableThColSpan = ''
            }
            this.$refs.recruitment_territory_list_modal.show()
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/uclan/ajax/get_territory_list_data');

            try{
                ref.wait_me('.wait_me_territory_card');
                var res=await jq.get(url);
                this.listData = res.data.list;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.wait_me_territory_card', 'hide');
            }

        },
        toggle_territory_form: function(){

            this.flag.show_sidebar_form=!this.flag.show_sidebar_form;

            if(!this.flag.show_sidebar_form){
                this.reset_territory_form();
            }

        },
        formClose: function() {
            this.toggle_territory_form()
        },
        reset_territory_form: function(){
            var territory=this.form.territory;
            territory.id = '';
            territory.name = '',
            territory.territory_manager_id = null,
            territory.maps = {
                region_ids: null,
                country_ids: null,
            }
        },
        addTerritory: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.add_territory_form');
                var res = await jq.post(ref.url('api/v1/uclan/ajax/store_territory_data'), ref.form.territory);
                ref.alert(res.msg);
                this.getListData()
                this.formClose()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add_territory_form', 'hide');
            }
        },
        updateTerritory: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.add_territory_form');
                var res = await jq.post(ref.url('api/v1/uclan/ajax/update_territory_data'), ref.form.territory);
                this.getListData()
                ref.alert(res.msg);
                this.formClose()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add_territory_form', 'hide');
            }
        },
        editData: function (item) {
            console.log('item', item)
            this.form.territory = {}
            this.toggle_territory_form()
            const editItem = {
                id: item.id,
                name: item.name,
                territory_manager_id: item.territory_manager_id,
                maps: {
                    // region_ids: item.regions_countries.length ? item.regions_countries.map(data => {
                    //     return data.region_id
                    // }) : null,
                    country_ids: item.maps.map(data => {
                        return data.country_id
                    })
                }
            }

            this.form.territory = JSON.parse(JSON.stringify(editItem))
        },
        getRegionList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_region_list'));
                this.regionTreeSelectList = res.data.regionList.map(item => {
                    return {
                        id: item.id,
                        label: item.title,
                    }
                })
                this.regionList = res.data.regionList
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getCountryList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_country_list'));
                this.countryList = res.data.country.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        deleteData: async function (item, index) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function (item, index) {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.post(ref.url('api/v1/uclan/ajax/delete_territory_data'), item);
                this.listData.splice(index, 1)
                ref.alert(res.msg)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getUsersList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_uni_users_list'));
            this.usersList = res.data.usersList.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        },
    },
    computed:{
        // countryTreeSelectList: function () {
        //     if (this.form.territory.maps.region_ids && this.form.territory.maps.region_ids.length) {
        //         let selectedRegions = this.regionList.filter(item => {
        //             return this.form.territory.maps.region_ids.includes(item.id)
        //         })

        //         let regionCountryIds = []

        //         selectedRegions.forEach((data) => {
        //             data.region_countries.forEach(item => {
        //                 regionCountryIds.push(item.country_id)
        //             })
        //         })

        //         return this.countryList.filter(item => {
        //             return regionCountryIds.includes(item.id)
        //         })
        //     } else {
        //         return this.countryList
        //     }
        // },
        countryTreeSelectList: function () {
            return this.countryList
        },
    },
    created: async function(){
        var ref=this;
        ref.setup_urls();
    },
    mounted: function(){
        feather.replace();
        this.getListData();
        this.getRegionList();
        this.getCountryList();
        this.getUsersList()
    }
}
</script>

<style scoped>
.multiple-avatars{
    display: inline;
    margin-right: -4px;
    /* border: 1px solid #777; */
    border-radius: 50%;
    padding: 2px;
    padding-bottom: 4px;
    background-color: #ddd;
}
</style>