<template>
<section class="user-role">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                        <h5 class="content-header-title float-left mt-50">Settings</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
									<li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
									<li class="breadcrumb-item">Settings</li>
                                <li class="breadcrumb-item active">Users</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Users</h3>
                        <small @click="getListData()" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + getListTotalLength(listData)"></small>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_right_sidebar_form()">
                            <i data-feather="plus-square"></i>
                            Add New User
                        </button>
                        <!-- <router-link class="btn btn-primary waves-effect waves-float waves-light" to="/settings/add-user">
                            <i data-feather="plus-square"></i>
                            Add New Region
                        </router-link> -->
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive user-role-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Active</th>
                                <th class="text-center" width="205">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + 1"></td>
                                <td>
                                    <span v-html="item.name"></span>
                                </td>
                                <td>
                                    <span v-html="item.email"></span>
                                </td>
                                <td>
                                    <span v-if="item.user_role" v-html="item.user_role.role.role_name"></span>
                                </td>
                                <td>
                                    <b-form-checkbox
                                        v-model="item.active"
                                        v-on:change="updateActiveStatus(item)"
                                        :id="'active'+item.id"
                                        class="custom-control-success"
                                        name="check-button"
                                        switch
                                    />
                                </td>
                                <td class="text-center">

                                    <button @click="editData(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;">
                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                    </button>                                                

                                    <button @click="deleteData(item, index)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                        <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="4" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <sidebar-form
            :show_sidebar="flag.show_right_sidebar_form"
            v-on:toggle-sidebar="toggle_right_sidebar_form()"
            title="Add New User Role"
        >
            
            <div class="card-body add-edit-user">
                <h5 class="card-title text-primary" v-if="!form.user.id">Add User</h5>
                <h5 class="card-title text-primary" v-if="form.user.id">Edit User</h5>
                <div class="add_user_form">
                    <div class="form-group">
                        <label for="name"><b>Name</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.user.name" class="form-control" id="name" placeholder="Enter name">
                    </div>
                    <div class="form-group">
                        <label for="email"><b>Email</b> <span class="text-danger">*</span></label>
                        <input type="email" v-model="form.user.email" class="form-control" id="email" placeholder="Enter email">
                    </div>
                    <!-- <div class="form-group">
                        <label for="password"><b>Password</b> <span class="text-danger">*</span></label>
                        <input type="password" v-model="form.user.password" class="form-control" id="password" placeholder="Enter password">
                    </div> -->
                    <div class="form-group">
                        <label for="role_id"><b>Role</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.user.role_id" :options="roleList" placeholder="Select role" id="role_id" autocomplete="off"/>
                    </div>
                    
                    <button type="submit" class="btn btn-success pull-right" v-if="!form.user.id" @click.prevent="insertFormData()">Save</button>

                    <button type="submit" class="btn btn-success pull-right" v-if="form.user.id" @click.prevent="updateFormData()">Update</button>

                    <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
                    
                </div>
            </div>
        </sidebar-form>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../../SidebarForm.vue';
import { EditIcon, Trash2Icon, XIcon } from 'vue-feather-icons'
import { BFormCheckbox } from 'bootstrap-vue'
// import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'

export default{
    name: 'UserRole',
    metaInfo: {
        titleTemplate: '%s | Users'
    },
    components:{
        MainLayout,
        Treeselect,
        SidebarForm,
        EditIcon,
        Trash2Icon,
        XIcon,
        BFormCheckbox
    },
    data:()=>{
        return {
            saveData: true,
            flag:{
                show_right_sidebar_form:false
            },
            form: {
                user: {
                    id: '',
                    name: '',
                    email: '',
                    // password: '',
                    role_id: null,
                }
            },
            listData: null,
            roleList:[],
            usersList:[]
        };
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
    },
    methods:{
        getListTotalLength: function (listData) {
            if (listData && listData.length) {
                return listData.length
            } else {
                return '0'
            }
        },
        toggle_right_sidebar_form: function(){
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
            this.form.user = {}
        },
        update_reset: function () {
            this.form.user = {};
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
        },
        formClose: function() {
            this.toggle_right_sidebar_form()
        },
        getListData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.user-role-table');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_uni_user_role_list'));
                if (res.data.list.length) {
                    this.listData = res.data.list.map(item => {
                        const objData = { active : item.active == 1 ? true : false }
                        return Object.assign({}, item, objData)
                    })
                } else {
                    this.listData = []
                }
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.user-role-table', 'hide');
            }
        },
        getRoleList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_university_role_list'));
                this.roleList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.role_name,
                    }
                });

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        insertFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.add-edit-user');
                var res = await jq.post(ref.url('api/v1/uclan/ajax/add_user_with_role'), ref.form.user);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add-edit-user', 'hide');
            }
        },
        updateActiveStatus: async function (item) {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_university_user_active_status'), item);
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.add-edit-user');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_user_with_role'), ref.form.user);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add-edit-user', 'hide');
            }
        },
        editData: function (item) {
            this.toggle_right_sidebar_form()
            this.saveData = false

            const editItem = {
                id: item.id,
                name: item.name,
                email: item.email,
                role_id: item.user_role ? item.user_role.role_id : null,
            }

            this.form.user = JSON.parse(JSON.stringify(editItem))
        },
        deleteData: async function (item, index) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function(item, index){

            var ref=this;
            var jq=ref.jq();

            var form_data={
                id: item.id
            }

            try{

                var res = await jq.post(
                    ref.url('api/v1/uclan/ajax/delete_user'), form_data
                );

                this.listData.splice(index, 1);
                ref.alert(res.msg);

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        }
    },
    mounted: function(){
        feather.replace();
        this.getRoleList();
        this.getListData();
    }
}
</script>
