<template>
<section class="intake-list">

        <div class="row justify-content-md-center">
            <div class="col-5">
                <h3>Marketing Suite Sample</h3>
                <div class="card">                    
                    <div class="card-body">
                        <h3 class="text-center">Header</h3>
                    </div>
                </div>
                <div class="card mb-1" v-for="(item, index) in marketingSuiteEmailItem.email_sections" :key="index">                    
                    <div class="card-body">
                        <div class="table-responsive">
                            <h3 class="text-center" v-html="item.title"></h3>
                            <span v-html="item.body_text"></span>
                            <div class="text-center">
                                <button class="btn btn-outline-primary" v-html="item.btn" @click="goto_url(item.url)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">                    
                        <h3 class="text-center">Footer</h3>
                </div>
            </div>
        </div>
</section>
</template>

<script>

import feather from 'feather-icons';
// import { EditIcon, Trash2Icon, UserCheckIcon, MailIcon} from 'vue-feather-icons'

export default{
    name: 'MarketingSuiteEmailSample',
    components:{
    },
    data:()=>{
        return {
            marketingSuiteEmailItem: {},
        };
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
        this.suiteId = this.hash_id(this.$route.params.id, false)[0]
        if (this.suiteId) {
            this.getMarketingSuiteDataById(this.suiteId)
        }
    },
    methods:{
        goto_url: function(url){
            if (url) {
                window.location.href = url;
            }
        },
        getMarketingSuiteDataById: async function (SuiteId) {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me');
                const params = { ms_email_id: SuiteId }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_marketing_suite_data_by_id'), params);
                console.log('res data by id ', res.data.data)
                this.marketingSuiteEmailItem = res.data.data
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me', 'hide');
            }
        }
    },
    mounted: function(){
        feather.replace();
    }
}
</script>
