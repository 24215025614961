<template>
  <section class="agency-list">
    <main-layout>
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <!-- <h2 class="content-header-title float-left mb-0">Gateway</h2> -->
              <h5 class="content-header-title float-left mt-50">Gateway</h5>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item active">All Lists</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card wait_me_list_data">
        <div class="card-header d-block">
          <div class="row">
            <div class="col-6 col-md-6">
              <h3>All Lists</h3>
              <small @click="getListData()" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + filterCountData.total_count"></small>
              <small @click="getStatusWiseIntakeListData('intake_status_published')" class="cursor-pointer badge badge-pill badge-light-success mr-1" v-html="'Active - ' + filterCountData.active_count"></small>
              <small @click="getStatusWiseIntakeListData('intake_status_draft')" class="cursor-pointer badge badge-pill badge-light-warning mr-1" v-html="'Draft - ' + filterCountData.draft_count"></small>
              <small @click="getStatusWiseIntakeListData('intake_status_closed')" class="cursor-pointer badge badge-pill badge-light-danger mr-1" v-html="'Closed - ' + filterCountData.closed_count"></small>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Intake Name</th>
                  <th class="text-center">Agent</th>
                  <th class="text-center">Student</th>
                  <!-- <th class="text-center">Auto Verified</th> -->
                  <!-- <th>Dispute Req</th> -->
                  <th>Submission Deadline</th>
                  <th>Status</th>
                  <th class="text-center">Found</th>
                  <!-- <th>Matched</th> -->
                  <th class="text-center">Verified</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody v-if="listData && listData.length > 0">
                <tr v-for="(item, index) in listData" :key="index">
                  <td v-html="index + pagination.slOffset"></td>

                  <td class="intake" @click="gotoIntakeWiseAgentListPage(item)">
                    <h5>
                      <span
                        class="badge badge-pill badge-light-primary"
                        v-html="item.intake_name"
                      ></span>
                    </h5>
                  </td>

                  <td class="text-center agent">
                    <!-- <div v-if="cn(item, 'intake_status.code', null) &&item.intake_status.code == 'intake_status_published'"> -->
                    <div v-if="item.related_agents">
                      <div>
                        <span v-if="checkCurrentDateGreaterThenDate(item.deadline)" class="d-block"
                          v-show="item.related_agents"
                          v-html="item.related_agents"
                          v-tooltip="item.related_agents > 1 ? item.related_agents + ' Lists Submitted' : item.related_agents + ' List Submitted'"></span>
                        <strong
                          v-else
                          class="d-block"
                          v-show="item.related_agents"
                          v-html="item.related_agents"
                          v-tooltip="item.related_agents > 1 ? item.related_agents + ' Lists Submitted' : item.related_agents + ' List Submitted'"
                        ></strong>
                        <!-- <span class="text-danger" v-html="random_number()" v-tooltip="random_number() + ' Agents Have Dispute'"></span> -->
                      </div>
                      <!-- <div v-else>
                                            <strong class="d-block">10</strong>  
                                            <span class="text-danger">2</span>
                                        </div> -->
                    </div>
                    <span v-else>-</span>
                  </td>

                  <td class="text-center students">
                    <!-- <div v-if="cn(item, 'intake_status.code', null) && item.intake_status.code == 'intake_status_published'"> -->
                    <div v-if="item.related_students">
                      <div>
                        <span v-if="checkCurrentDateGreaterThenDate(item.deadline)" class="d-block"
                          v-show="item.related_students"
                          v-html="item.related_students"
                          v-tooltip="item.related_students > 1 ? item.related_students + ' Students Submitted' :  item.related_students + ' Student Submitted'"></span>
                        <strong
                          v-else
                          class="d-block"
                          v-show="item.related_students"
                          v-html="item.related_students"
                          v-tooltip="item.related_students > 1 ? item.related_students + ' Students Submitted' :  item.related_students + ' Student Submitted'"></strong>
                        <!-- <span class="text-danger" v-html="random_number()"  v-tooltip="random_number() + ' Students Have Dispute'"></span> -->
                      </div>
                      <!-- <div v-else>
                                            <strong class="d-block">50</strong>  
                                            <span class="text-danger">10</span>
                                        </div> -->
                    </div>
                    <span v-else>-</span>
                  </td>

                  <td class="timeline">
                    <div v-if="item.intake_status.color != 'danger'">
                      <div :class=" beforeStartCheck(new Date(), new Date(item.start)) == true ? 'font-weight-bolder text-dark' : ''">
                        <span
                          class="d-block"
                          v-html="dDate(item.start)"
                          v-tooltip="'Submission Start Date'"
                        ></span>
                      </div>
                      <div :class="withinDeadlineCheck(new Date(), new Date(item.start), new Date(item.deadline)) == true ? 'font-weight-bolder text-dark' : ''">
                        <span class="d-block" v-html="dDate(item.deadline)" v-tooltip="'Submission End Date'"></span>
                        <!-- <small v-if="checkCurrentDateGreaterThenDate(item.deadline)" class="badge badge-light-danger">Expired</small> -->
                      </div>
                    </div>
                    <div v-else class="ml-3">
                      <span class="d-block">-</span>
                    </div>
                  </td>
                  <td class="status">
                    <span
                      :class="
                        'badge badge-pill badge-light-' +
                        item.intake_status.color
                      "
                      v-html="item.intake_status.name"
                      v-tooltip="'Intake status is '+item.intake_status.name"
                    ></span>
                  </td>

                  <td class="found text-center">
                    <router-link v-if="item.bulk_students" :to="'/gateway/bulk-verfication-list/'+hash_id(item.id)">
                        <span v-tooltip="'Open Bulk Verification List'" v-html="item.bulk_students">
                        </span>
                    </router-link>
                    <span v-else>-</span>
                  </td>
                  <!-- <td class="matched text-center">
                    <span>-</span>
                  </td> -->
                  <td class="verified text-center">
                    <span v-if="item.bulk_v_students" v-html="item.bulk_v_students"></span>
                    <span v-else>-</span>
                  </td>

                  <td class="Auto-Verify">
                    <div v-if="permission_check('auto_verification_import_n_verify')">
                      <span
                        v-show="cn(item, 'verification_status.code', null) && item.verification_status.code == 'upload_bulk_student'"
                        class="badge badge-light-info mr-1"
                        v-on:click="goto_bulk_import(item)"
                        v-tooltip="'Upload data for auto verification'"
                      >
                        <upload-icon
                          size="1.5x"
                          class="custom-class"
                        ></upload-icon>
                        Upload
                      </span>

                      <span
                        v-if="row_vstatus_check(item, 'uploaded_bulk_student')"
                        class="badge badge-light-warning"
                        v-on:click="verify_bulk_students(item)"
                        v-tooltip="'Click to verify bulk students'"
                      >
                        <user-check-icon size="1.5x" class="custom-class"></user-check-icon>
                        Verify
                      </span>

                      <!-- <router-link :to="'/gateway/bulk-verfication-list/'+hash_id(item.id)" v-show="row_vstatus_check(item, 'uploaded_bulk_student')">
                        <span                          
                          class="badge badge-light-warning"
                        >
                          <user-check-icon
                            size="1.5x"
                            class="custom-class"
                          ></user-check-icon>
                          Verify
                        </span>
                      </router-link> -->

                      <span
                        v-if="row_vstatus_check(item, 'uploaded_bulk_student')"
                        class="badge badge-light-danger ml-50"
                        v-tooltip="'Remove Uploaded Data'"
                        v-on:click="remove_imported_data(item)"
                      >
                        <i class="fa fa-trash-o"></i>
                      </span>

                    </div>

                    <div v-show="row_vstatus_check(item, 'bulk_verfication_processing')">
                      <router-link class="d-block" :to="'/gateway/bulk-verfication-list/'+hash_id(item.id)">
                        <span
                          v-if="item.verified_at"
                          class="badge badge-pill badge-light-warning"
                          v-tooltip="dDate(item.verified_at.created_at)"
                        >
                          <i class="fa fa-clock-o" aria-hidden="true"></i>
                          Processing...
                        </span>
                      </router-link>
                      <small>You will be notified once completed</small>                      
                    </div>

                    <div v-show="row_vstatus_check(item, 'bulk_student_verified')">
                      <span v-if="item.verified_at" class="badge badge-pill badge-light-success" v-tooltip="dDate(item.verified_at.created_at)">
                        <i class="fa fa-check mr-25 text-success"></i>
                        Verified
                      </span>
                      <router-link :to="'/gateway/bulk-verfication-list/'+hash_id(item.id)">
                        <span v-if="item.verified_at" class="badge badge-pill badge-light-success ml-50" v-tooltip="'Bulk Verification List'">
                          <i class="fa fa-list text-success"></i>
                        </span>
                      </router-link>
                      <div>
                        <small class="d-block" v-if="item.bulk_v_students">Total Verified: {{ item.bulk_v_students }}</small>
                        <small class="d-block" v-if="item.bulk_v_agent_not_matched">Agent Not Matched: {{ item.bulk_v_agent_not_matched }}</small>
                        <small class="d-block" v-if="item.bulk_v_student_not_matched">Student Not Matched: {{ item.bulk_v_student_not_matched }}</small>
                        <small class="d-block" v-if="item.bulk_v_intake_not_matched">Intake Not Matched: {{ item.bulk_v_intake_not_matched }}</small>
                      </div>
                    </div>

                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <th colspan="9" class="text-center">
                    <span v-if="listData === null">Processing...</span>
                    <span v-else>
                      <i
                        class="fa fa-exclamation-triangle text-danger"
                        aria-hidden="true"
                      ></i>
                      Found no agent data.
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
            <b-pagination
              class="mt-1 mr-1"
              align="right"
              v-model="pagination.currentPage"
              pills
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              @input="searchData"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </main-layout>
  </section>
</template>

<script>
import MainLayout from "../MainLayout.vue";
import feather from "feather-icons";
// import { BFormCheckbox } from 'bootstrap-vue'
//import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { BPagination } from "bootstrap-vue";
// import { BPagination } from 'bootstrap-vue'
import swal from "bootstrap-sweetalert";
import { UploadIcon, UserCheckIcon } from "vue-feather-icons";

// import { BModal } from 'bootstrap-vue';

import Swal from 'sweetalert2';

export default {
  name: "AllApplication",
  metaInfo: {
      titleTemplate: '%s | All List'
  },
  components: {
    MainLayout,
    BPagination,
    //BDropdown,
    //BDropdownItem,
    UploadIcon,
    UserCheckIcon,
  },
  data: () => {
    return {
      filterCountData: {},
      agencyList: [],
      listData: null,
      bv_status: [],
      flag: {
        show_agent_form: false,
      },
      form: {
        uni_course_level: {
          checked_all: false,
          agent_ids: [],
        },
      },
    };
  },
  methods: {
    async searchData() {
      this.getListData();
    },
    gotoIntakeWiseAgentListPage: function (item) {
      if (this.permission_check("intake_wise_agents")) {
        this.$router.push({
          name: "IntakeWiseAgentList",
          params: { id: this.hash_id(item.id) },
        });
      }
    },
    beforeStartCheck: function (currentDate, startDate) {
      if (currentDate < startDate) {
        return true;
      } else {
        return false;
      }
    },
    withinDeadlineCheck: function (currentDate, startDate, endDate) {
      if (currentDate >= startDate && currentDate <= endDate) {
        return true;
      } else {
        return false;
      }
    },
    verifiedCount: function (item) {
      const verfiedData = item.filter((data) => data.verified_at != null);
      return verfiedData.length;
    },
    unverifiedCount: function (item) {
      const verfiedData = item.filter((data) => data.verified_at == null);
      return verfiedData.length;
    },
    verification_status_name: function (row) {
      var ref = this;
      const status = ref.bv_status.find((item) => {
        if (item.id == row.bulk_verification_status_id) {
          return item;
        }
      });

      if (!status) {
        return "Loading ...";
      } else {
        return status.name;
      }
    },
    verification_status_color: function (row) {
      var ref = this;
      const status = ref.bv_status.find((item) => {
        if (item.id == row.bulk_verification_status_id) {
          return item;
        }
      });

      if (!status) {
        return "dark";
      } else {
        return status.color;
      }
    },
    statusDropdownDisable: function (dropdown, item) {
      if (
        dropdown.code == "uploaded_bulk_student" ||
        dropdown.code == "bulk_student_verified" ||
        item.bulk_verification_status_code == "bulk_student_verified"
      ) {
        return true;
      } else {
        return false;
      }
    },
    uni_course_level_insert_or_delete: async function (item) {
      var ref = this;
      var jq = ref.jq();
      try {
        var res = await jq.post(
          ref.url("api/v1/university/ajax/uni_course_level_insert_or_delete")
        );
        ref.alert(res.msg);
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
        item.mapped_with_uni = !item.mapped_with_uni;
      }
    },
    getListData: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage });
        ref.wait_me(".wait_me_list_data");
        var res = await jq.get(ref.url("api/v1/uclan/ajax/gateway_all_applications"), params);
        this.listData = res.data.map((item) => {
          const instakeStatus = Object.assign({}, item.intake_status, JSON.parse(item.intake_status.data));
          return Object.assign({}, item, JSON.parse(item.bulk_verification_status_data), { intake_status: instakeStatus });
        });
        this.paginationData(res);
        // console.log('this.listData', this.listData)
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_list_data", "hide");
      }
    },
    getBulkStudentVerificationStatusList: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        var res = await jq.get(
          ref.url(
            "api/v1/university/ajax/get_bulk_student_verification_status_list"
          )
        );
        this.bv_status = res.data.bv_status.map((item) => {
          return Object.assign({}, item, JSON.parse(item.data));
        });

        // console.log('this.bv_status', this.bv_status)
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }
    },
    goto_bulk_import: function (item) {
      this.$router.push({
        name: "BulkVerficationUpload",
        params: { id: this.hash_id(item.id) },
      });
    },
    verify_bulk_students: async function (item) {

			var swal_confirm = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-success mr-1',
					cancelButton: 'btn btn-light'
				},
				buttonsStyling: false
			});

			var {isConfirmed} = await swal_confirm.fire({
				title: 'Are you sure you want to auto verify the uploaded data?',
				text: 'This action will take time and can not be reverse or undo. Press Verify to start this operation or Cancel to close.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Verify'
			});

			if(isConfirmed){

        var ref = this;
        var jq = ref.jq();

        ref.wait_me(".table-responsive");

        try {
          const params = Object.assign({}, { intake_id: item.id });
          jq.post(
            ref.url(
              "api/v1/uclan/ajax/intake_wise_bulk_agent_student_verify"
            ),
            params
          );

          ref.reload_to_all_lists();
          
        } catch (err) {
          ref.alert(ref.err_msg(err), "error");
        } finally {
          ref.wait_me(".table-responsive", "hide");
        }

      }
    },
    bulk_student_status_update: async function (item, dropdown) {
      var ref = this;
      var jq = ref.jq();

      if (dropdown.code == "upload_bulk_student") {
        this.$router.push({
          name: "BulkVerficationUpload",
          params: { id: this.hash_id(item.id) },
        });
      } else if (dropdown.code == "verify_bulk_student") {
        try {
          const params = Object.assign(
            {},
            { intake_id: item.id, status_id: dropdown.id }
          );
          var response = await jq.post(
            ref.url(
              "api/v1/university/ajax/intake_wise_bulk_agent_student_verify"
            ),
            params
          );
          this.getListData();
          // ref.alert(res.msg);
          swal("Bulk Student Verify!", response.msg, "success");
        } catch (err) {
          ref.alert(ref.err_msg(err), "error");
        }
      } else {
        try {
          const params = Object.assign(
            {},
            { id: item.id, bulk_verification_status_id: dropdown.id }
          );
          var res = await jq.post(
            ref.url("api/v1/university/ajax/update_bulk_student_verification"),
            params
          );
          this.getListData();
          // ref.alert(res.msg);
          swal("Bulk Student Verify!", res.msg, "success");
        } catch (err) {
          ref.alert(ref.err_msg(err), "error");
        }
      }
    },
    uni_course_level_email: async function () {
      this.$refs.invitation_confirmation.hide();

      var ref = this;
      var jq = this.jq();
      var url = ref.url("api/v1/university/ajax/uni_course_level_emails");

      try {
        ref.wait_me(".wait_me_list_data");
        var res = await jq.post(url, ref.form.uni_course_level);

        ref.form.uni_course_level.checked_all = false;
        ref.form.uni_course_level.agent_ids = [];
        ref.alert(res.msg);
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_list_data", "hide");
      }
    },
    row_vstatus_check: function(row, code){
      return this.cn(row, 'verification_status.code', null) && row.verification_status.code==code
    },
    remove_imported_data: async function(intake){

			var swal_confirm = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-danger mr-1',
					cancelButton: 'btn btn-light'
				},
				buttonsStyling: false
			});

			var {isConfirmed} = await swal_confirm.fire({
				title: 'Confirmation!',
				text: 'Do you want to remove this imported data.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Remove'
			});

			if(isConfirmed){

				var ref=this;
				var jq=ref.jq();
				var url=ref.url('api/v1/uclan/ajax/remove_imported_bulk_student_data');

				var form_data={
          intake_id: intake.id
				};

				try{

					ref.wait_me('.uni-comm-configs');
					var res=await jq.post(url, form_data);
					ref.alert(res.msg);
          ref.getListData();

				}catch(err){
					ref.alert(ref.err_msg(err), 'error');
				}finally{
					ref.wait_me('.uni-comm-configs', 'hide');
				}

			}

		},
    reload_to_all_lists: function(){

      Swal.fire({
        title: 'Processing...',
        text: 'This process will take time. Meanwhile you can leave this page or visit other page.',
        showCancelButton: false,
        confirmButtonText: 'Ok',
				customClass: {
					confirmButton: 'btn btn-success',
				},
				buttonsStyling: false
      }).then(()=>{        
        this.getListData();
      });

    },
    getIntakeStatusCount: async function () {
        var ref=this;
        var jq=ref.jq();
        try {
            ref.wait_me('.intake-list-table');
            var res = await jq.get(ref.url('api/v1/university/ajax/get_university_intake_list'));
            this.filterCountData = res.data
        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.intake-list-table', 'hide');
        }
    },
    getStatusWiseIntakeListData: async function(status){

        var ref=this;
        var jq=ref.jq();

        try {
          const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage, status: status });
          ref.wait_me(".wait_me_list_data");
          var res = await jq.get(ref.url("api/v1/uclan/ajax/gateway_all_applications"), params);
          this.listData = res.data.map((item) => {
            const instakeStatus = Object.assign({}, item.intake_status, JSON.parse(item.intake_status.data));
            return Object.assign({}, item, JSON.parse(item.bulk_verification_status_data), { intake_status: instakeStatus });
          });
          this.paginationData(res);
        } catch (err) {
          ref.alert(ref.err_msg(err), "error");
        } finally {
          ref.wait_me(".wait_me_list_data", "hide");
        }
    },
  },
  computed: {
    store_countries: function () {
      return this.$store.state.site.countries;
    },
    treeselect_countries: function () {
      return this.store_countries.map((each) => {
        return {
          id: each.id,
          label: each.name,
        };
      });
    },
  },
  created: function () {
    this.setup_urls();
  },
  mounted: function () {
    feather.replace();
    this.getBulkStudentVerificationStatusList();
    this.getListData();
    this.getIntakeStatusCount()
  },
};
</script>
