<template>
    <div>
        <div class="card bg-light-gray" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <treeselect v-model="search.course_level_id" :options="courseLevelList" id="course_level_id" placeholder="Select Course Level" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <treeselect v-model="search.country_id" :options="countryList" id="country_id" placeholder="Select Country" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Student ID">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.agent_code" class="form-control" id="agent_code" placeholder="Banner Code">
                    </div>
                    <!-- <div class="col-md-3">
                        <div class="form-row">
                            <div class="col-6 form-group">
                                <treeselect v-model="search.intake_month" :options="monthList" placeholder="Select Month" id="intake_month" autocomplete="off"/>
                            </div>
                            <div class="col-6 form-group">
                                <treeselect v-model="search.intake_year" :options="yearList" placeholder="Select Year" id="intake_year" autocomplete="off"/>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-2">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="First name">
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last name">
                    </div>
                    
                    <div class="col text-right">
                        <button class="btn btn-primary mr-50" @click="searchData()"><i data-feather='search'></i> Search</button>
                        <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card student-list-card">
            <div class="card-body p-0">
                <div class="row p-2">
                    <div class="col-md-6">
                        <h3>Dispute Rejected Student List</h3>
                        <span v-html="intakeItem.name"></span>
                    </div>
                    <div class="col-md-6 text-right">
                        <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light mr-1"><i data-feather='filter'></i></button>
                    </div>
                </div> 

                <div class="table-responsive">
                    <table class="table" style="width:100%;">
                        <thead>
                            <tr>
                                <!-- <th width="2%">#</th> -->
                                <th class="px-1 text-center" width="10%">Student ID</th>
                                <th class="px-1" width="20%">Student Details</th>
                                <th class="px-1" width="20%">Agent</th>
                                <th class="px-1" width="15%">COURSE Details</th>
                                <!-- <th width="10%" class="text-right px-1">Fees/ Commissionable amount</th>   -->
                                <th style="width:12%;" class="text-center px-1">Start Date</th>
                                <th style="width:10%;" class="text-center px-1" >Intake</th>
                                <th style="width:2%;" class="text-center px-1">Communication</th>
                                <th style="width:10%;" class="text-center px-1">Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="studentList && studentList.length > 0">
                            <template v-for="(student_item, index) in studentList">
                                <!-- <tr :key="student_item.uni_ref_no">
                                    <td :rowspan="studentList.length">
                                        <small class="badge badge-pill badge-light-primary" v-html="student_item.uni_ref_no"></small>
                                    </td>
                                </tr> -->
                                <template v-for="(item, index2) in student_item.agent_students">
                                    <tr :key="item.id" :class="index % 2 ? 'bg-light-primary' : ''">
                                        <td v-if="index2 == 0" :rowspan="student_item.agent_students.length" class="border">
                                            <small class="badge badge-pill badge-light-primary" v-html="student_item.uni_ref_no"></small>
                                        </td>
                                        <td class="px-1" :key="'item1' + index2">
                                            <div @click="editData(item)" class="d-flex justify-content-left align-items-center cursor-pointer">
                                                <!-- <div class="avatar-wrapper">
                                                    <div class="avatar mr-1">
                                                        <b-avatar variant="primary" :text="avatarText(item.student_name)"></b-avatar>
                                                    </div>
                                                </div> -->
                                                <div class="avatar-wrapper">
                                                    <div class="avatar  mr-1" v-tooltip="item.country.name">
                                                        <img :src="item.flag_url" alt="Avatar" height="32" width="32">
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <div class="text-dark">
                                                    <span class="font-weight-bold" style="font-size: 12px" v-html="item.first_name+' '+item.last_name">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="feather feather-check"
                                                    >
                                                        <polyline points="20 6 9 17 4 12"></polyline></svg></span>
                                                    </div>
                                                    <small class="emp_post text-muted" >
                                                        <span v-html="dDate(item.dob)"></span>
                                                    </small>
                                                    <small>
                                                        <small v-on:click="gotoAgentWiseStudentListPage(item)" class="badge badge-light-success" v-html="item.list_info.list_code"></small>
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-1"  :key="'item2' + index2">
                                            <div class="d-flex justify-content-left align-items-center cursor-pointer">
                                                <!-- <div class="avatar-wrapper">
                                                    <div class="avatar mr-1">
                                                        <b-avatar variant="primary" :text="avatarText(item.student_name)"></b-avatar>
                                                    </div>
                                                </div> -->
                                                <div class="avatar-wrapper">
                                                    <div class="avatar  mr-1" v-tooltip="item.agent_company.country.name">
                                                        <img :src="item.agent_flag_url" alt="Avatar" height="32" width="32">
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <div class="text-dark">
                                                    <span class="font-weight-bold" style="font-size: 12px" v-html="item.agent_company.name">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        class="feather feather-check"
                                                    >
                                                        <polyline points="20 6 9 17 4 12"></polyline></svg></span>
                                                    </div>
                                                    <small @click="gotoAgentProfilePage(item)" class="text-truncate align-middle cursor-pointer">
                                                        <small class="badge badge-pill badge-light-primary" v-html="item.agent_company.agent_code"></small>
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-1" :key="'item3' + index2">
                                            <!-- <span class="text-truncate align-middle cursor-pointer" style="font-size: 13px">
                                                <span v-tooltip="item.subject" v-html="limit_str(item.subject, 15)"></span>
                                            </span> -->
                                            <span v-if="item.programme_name" class="align-middle cursor-pointer d-block" style="font-size: 13px">
                                                <span v-tooltip="item.programme_name" v-html="limit_str(item.programme_name, 30)"></span>
                                            </span>

                                            <small class="emp_post text-muted" v-html="cn(item, 'course_level_data.name', '-')"></small>
                                        </td>
                                        <!-- <td class="px-1" :key="'item4' + index2"> 
                                            <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.tuition_fee)"></small><br>
                                            <small class="emp_post text-muted text-truncate align-middle float-right" v-html="'£ ' + float2(item.scholarship)"></small><br>
                                            <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.commissionable_tuition_fee)"></small><br>
                                        </td> -->
                                        <td class="text-center"  :key="'item5' + index2">
                                            <span v-html="dMonth(item.intake_month)"></span>
                                        </td>
                                        <td class="text-center" :key="'item6' + index2">
                                            <small class="badge badge-light-secondary" v-if="item.intake" v-html="item.intake.name"></small>
                                        </td>
                                        <td class="text-center" :key="'item7' + index2">

                                            <div class="position-relative d-inline-block  mr-1">
                                                <i class="fa fa-list-ul hand" v-tooltip="'Requirements for Agent'" aria-hidden="true"  v-on:click="open_stu_requirements(item)"></i>
                                                <span v-show="item.as_requirements" class="badge badge-pill badge-light-success badge-up text-success" v-html="item.as_requirements"></span>
                                            </div>

                                            <div class="position-relative d-inline-block">
                                                <i class="fa fa-comment-o hand mx-25" v-tooltip="'Communicate with the agent about the student'" aria-hidden="true" v-on:click="open_list_req_with_student(item)"></i>
                                                <span v-show="item.communication_count" class="badge badge-pill badge-light-success badge-up text-success" v-html="item.communication_count"></span>
                                            </div>
                                            
                                            <!-- <i class="fa fa-comment-o hand mx-25" v-tooltip="'Communicate with the agent about the student'" aria-hidden="true" v-on:click="open_list_req_with_student(item)"></i> -->
                                        </td>
                                        <td class="text-center d-flex align-items-center" :key="'item8' + index2">
                                            <div>
                                                <div v-if="student_item.dispute_resolved_at">
                                                    <div v-if="item.id == student_item.resolved_as_id" >
                                                        <span class="badge badge-light-success">Dispute Resolved</span><br>
                                                        <div v-if="student_item.resolved_by" class="d-flex justify-content-left align-items-center mt-25">
                                                            <div class="avatar-wrapper">
                                                                <div class="avatar bg-light-primary mr-50">
                                                                    <small class="avatar-content avater_size_25" v-html="avatarText(student_item.resolved_by.name)" v-tooltip="student_item.resolved_by.name"></small>
                                                                </div>
                                                            </div>
                                                            <small v-if="item.id == student_item.resolved_as_id" v-html="dDate(student_item.dispute_resolved_at)"></small>
                                                        </div>
                                                    </div>
                                                    <div v-else class=" mt-1">
                                                        <span class="badge badge-light-danger">Rejected</span><br>
                                                    </div>
                                                </div>
                                                <button v-if="!student_item.dispute_resolved_at" @click="disputeResoved(item, student_item.agent_students, student_item.uni_ref_no)" class="btn btn-sm btn-success mt-1">Resolve</button>
                                            </div>
                                            <div>
                                                <i class="fa fa-info-circle ml-1 text-info hand mt-1" v-tooltip="'Click to view remarks'" @click="showDisputeRemarksModal(item, student_item)" v-if="item.agent_remarks"></i>
                                                <i class="fa fa-clock-o hand ml-1 mt-1" v-on:click="open_agent_student_activity_log(item)" v-tooltip="'Activity Log'"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="9" class="text-center">
                                    <span v-if="studentList === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no student data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    
                    <!-- <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination> -->
                </div>
            </div>
        </div>

        <b-modal ref="dispute_remarks_modal" class="text-center">
            <template #modal-title>
                <span class="text-uclan">Dispute Resolution Summary - Student ID: {{ studentDisputeModalObj.uni_ref_no }}</span>
            </template>
            <div>
                <p class="text-right">Resolve at <span v-html="dDate(studentDisputeModalObj.agent_remarks.added_at)"></span> by <span v-if="studentDisputeModalObj.student_item.resolved_by" v-html="studentDisputeModalObj.student_item.resolved_by.name"></span></p>
                <p class="text-justify" v-if="studentDisputeModalObj.agent_remarks.remarks" v-html="studentDisputeModalObj.agent_remarks.remarks"></p>
                <p class="text-center" v-else>
                    <span class="text-warning">Remarks Empty</span>
                </p>
            </div>

            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.dispute_remarks_modal.hide()">Close</button>
            </template>
        </b-modal>

        <student-list-request ref="student_list_request"/>
        <student-requirements ref="stu_reqs"/>
        <activity-log :log_data="agentStudentActivitylog" ref="agent_student_activity_log_modal"></activity-log>

    </div>
</template>

<script>
import feather from 'feather-icons';
// import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
// import { BPagination, BFormCheckbox, BCard, BRow, BCol } from 'bootstrap-vue'
import { BModal } from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect';
// import flatPickr from 'vue-flatpickr-component';
// import { EditIcon, MinusSquareIcon } from 'vue-feather-icons';
import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'
import Hashids from 'hashids';
import moment from 'moment';
import StudentListRequest from '../../Partials/StudentListRequest.vue';
import StudentRequirements from '../../Partials/StudentRequirements.vue';
import ActivityLog from '../../Partials/ActivityLog.vue';

export default{
    name: 'DisputedAllStudentListByIntake',
    metaInfo: {
        titleTemplate: '%s | Disputed All Student List By Intake'
    },
    props: ['intakeItem'],
    components:{
        StudentListRequest,
        StudentRequirements,
        // BPagination,
        // BFormCheckbox,
        // BCard, BRow, BCol,
        // BDropdown, BDropdownItem,
        Treeselect,
        BModal,
        ActivityLog
        // flatPickr,
        // MoreVerticalIcon
        // EditIcon,
        // MinusSquareIcon
    },
    data:()=>{
        return {
            reqType: 'as_list_req_dispute',
            studentList: null,
            regionList: [],
            regionCountryList: [],
            statusTreeSlectList: [],
            statusList: [],
            countryList: [],
            student_io_statuses: [],
            agentAcceptStatusList: [],
            inDeadline: true,
            disabled: false,
            studentDisputeModalObj: {
                uni_ref_no: '',
                agent_remarks: {},
                student_item: {},
            },
            intakeItem: {},
            studentListItem: {},
            flag:{
                show_agent_form:false,
                show_filter:false,
                show_modal_form:false
            },
            form:{
                send_invitation:{
                    checked_all: false,
                    agent_ids:[]
                },
                agent_status_update:{
                    agent_id:'',
                    new_status: ''
                }
            },
            search: {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                name: '',
                last_name: '',
                agent_code: '',
                start_date: '',
                intake_month: null,
                intake_year: null,
                submission_status_id: null,
                intake_id: null,
                list_id: null,
            },
            list:{
                agent_statuses:[{
                    id: 'inactive',
                    label: 'Inactive',
                    btn_color: 'outline-danger',
                    text_color: 'text-danger'
                }, {
                    id: 'pending',
                    label: 'Pending',
                    btn_color: 'outline-warning',
                    text_color: 'text-warning'
                },{
                    id: 'approved',
                    label: 'Approved',
                    btn_color: 'outline-success',
                    text_color: 'text-success'
                }]
            },
            editItem: {},
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    maxDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            agentStudentActivitylog: [],
            courseLevelList: [],
            courseTitleList: [],
            intakeList: [],
            monthList: [],
            yearList: [],
            info: {
                download_url:'#',
            },
        };
    },
    watch: {
    },
    methods:{
        gotoAgentWiseStudentListPage: function (item) {
            if(this.permission_check('agent_wise_students')) {
                if (item.list_info) {
                    
                    let routeData = this.$router.resolve({ name: 'AgentWiseStudentList', params: { 
                        id: this.hash_id(item.list_info.id),
                        intake_id: this.hash_id(item.list_info.intake_id),
                        // active_tab: 'one',
                    }})

                    window.open(routeData.href, '_blank');
                }
            }
        },
        showDisputeRemarksModal: function (item, student_item) {
            this.studentDisputeModalObj.student_item = student_item
            this.studentDisputeModalObj.agent_remarks = item.agent_remarks
            this.studentDisputeModalObj.uni_ref_no = item.uni_ref_no
            this.$refs.dispute_remarks_modal.show()
        },
        open_agent_student_activity_log: async function (item) {
            var ref=this;
            var jq=ref.jq();
            this.agentStudentActivitylog = []
            this.$refs.agent_student_activity_log_modal.show_modal()

            try {
                const params = Object.assign({id: item.id})
                var res = await jq.get(ref.url('api/v1/university/ajax/get_student_activity_log_by_model_id'), params);
                 if (res.data.data.length) {
                    this.agentStudentActivitylog = res.data.data
                } else {
                    this.agentStudentActivitylog = null
                }

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        gotoAgentProfilePage: function (item) {
            if (item.agent_company) {
                // this.$router.push({ name: 'AgentProfile', params: { id: this.hash_id(item.agent_company.id) } })
                let routeData = this.$router.resolve({name: 'AgentProfile', params: { id: this.hash_id(item.agent_company.id) }});
                window.open(routeData.href, '_blank');
            }
        },
        open_list_req_with_student: function(agent_student){
            this.$refs.student_list_request.open_list_req_with_student(agent_student);
        },
        open_stu_requirements: function(agent_student){
            this.$refs.stu_reqs.open_stu_reqs(agent_student);
        },
        rowSpanMerge: function (item) {
            let rowCount = 1
            if (item.uni_ref_no == item.uni_ref_no) {
                rowCount ++
            }
            // console.log('rowCount', rowCount)
            return rowCount
        },
        rowSpanMergeTrue: function (item) {
            let dataId = null
            if (item.uni_ref_no == item.uni_ref_no) {
                dataId = item.id
            }
            return dataId
        },
        getRowSpanUniRef: function (item) {
            let uni_ref_no = ''
            if (item.uni_ref_no == item.uni_ref_no) {
                uni_ref_no = item.uni_ref_no
            }
            // console.log('uni_ref_no', uni_ref_no)
            return uni_ref_no
        },
        toggleDetails: function (item) {
            return item.detailsShowing = !item.detailsShowing
        },
        getBackgroundColor: function(item){

            if (item.finance_office_status != 60 && item.cl_status) {
                // return 'bg-light-warning'
                return ''
            } else {
                return ''
            }

        },
        agt_accept_status_color: function(row){

            var ref=this;
            const status =  ref.agentAcceptStatusList.find(item=>{
                if (item.id == row.agent_accept_status) {
                    return item
                }
            });

            if (!status) {
                return 'secondary'
            } else {
                return status.color
            }

        },
        agt_accept_status_name: function(row){

            var ref=this;
            const status =  ref.agentAcceptStatusList.find(item=>{
                if (item.id == row.agent_accept_status) {
                    return item
                }
            });
            // console.log('row', row)

            if (!status) {
                return '-'
            } else {
                return status.name
            }

        },
        disputeResoved: function(item, group_students, uni_ref_no){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })
  
            swalWithBootstrapButtons.fire({
                title: 'Resolve Dispute Case?',
                html: 'Do you want to resolve the dispute of Student ID: <b>' + uni_ref_no + '</b> and tag to agent <br/><b>' + item.agent_company.name +' (' + item.agent_company.agent_code + ')</b>',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.disputeResovedConfirm(item, group_students)

                }
            })
        },
        disputeResovedConfirm: function(item, group_students){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            let level = '<b style="color: red">Please specify reason *</b>';
            let placeholder = 'Enter reason';
            let html_input = '<div class="form-group"><label for="usr">'+ level +':</label><textarea id="swal_input" class="form-control" placeholder="'+placeholder+'"></textarea></div>';

            swalWithBootstrapButtons.fire({
                title: 'Please provide the resolution summary',
                html: html_input,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                preConfirm: () => {
                    return [
                        document.getElementById('swal_input').value
                    ]
                }
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    if (result.value[0]) {
                        this.disputeResovedConfirmFinal(item, group_students, result.value[0])
                    } else {
                        this.alert('Please specify the reason', 'error')
                    }
                }
            })
        },
        disputeResovedConfirmFinal: async function(item, group_students, swal_input){
            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, item, { group_students: group_students, swal_input: swal_input })
                var res = await jq.post(ref.url('api/v1/university/ajax/agent_student_agent_tagged_from_dispute_list'), params);
                this.getListData();
                swal("Dispute Status Updated", res.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        ClearSearchData () {
            this.search = {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                name: '',
                last_name: '',
                agent_code: '',
                start_date: '',
                intake_month: null,
                intake_year: null,
                submission_status_id: null,
                intake_id: null,
                list_id: null,
            }
            this.getListData();
        },
        check_all_invitaion: function(check_all=false){

            var ref=this;
            var invitation_from=ref.form.send_invitation;

            if(check_all){

                if(invitation_from.checked_all){
                   ref.studentList.forEach(row=>{
                       invitation_from.agent_ids.push(row.id);
                   });
                }else  invitation_from.agent_ids=[];

            }else{
                
                if(invitation_from.agent_ids.length==ref.studentList.length){
                    invitation_from.checked_all=true;
                }else invitation_from.checked_all=false;
            }

        },
        async searchData () {
            this.getListData()
            this.gen_download_url();
        },
        gen_download_url: function () {
            var ref=this;
            var qstr = new URLSearchParams(this.search).toString()
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            ref.info.download_url = ref.url('download/agent_students_export_excel/' + hash_ids.encode(ref.store_auth_user.institute_id)+ '?'+ qstr);
        },
        toggle_agent_form: function(){
            this.flag.show_agent_form=!this.flag.show_agent_form;
        },
        getIntakeById: async function(){
            var ref=this;
            var jq=ref.jq();

            if (this.search.intake_id != null) {
                try {
                    ref.wait_me('.student-list-card');
                    const params = Object.assign({}, this.search)
                    var res = await jq.get(ref.url('api/v1/agent/ajax/get_intake_by_id'), params);
                    ref.intakeItem = res.data.intake

                } catch (err) {
                    // ref.alert(ref.err_msg(err), 'error');
                }
                finally {
                    ref.wait_me('.student-list-card', 'hide');
                }
            } else {
                this.getListData()
            }
        },
        checkStudentListSubmitted: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/agent/ajax/check_student_list_submitted'), params);
                ref.studentListItem = res.data.data
            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();
            if (this.$route.params.uni_ref_no) {
                this.search.uni_ref_no = this.$route.params.uni_ref_no
            }
            
            const query = this.$route.query
            this.search.intake_id = this.hash_id(query.intake_id, false)[0]
            
            // if (query && query.uni_ref_no) {
            //     this.search.uni_ref_no = query.uni_ref_no
            // }

            try {
                ref.wait_me('.student-list-card');
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_disputed_rejected_student_list_by_intake'), params);
                this.studentList = res.data.studentList
                // this.studentList = res.data.studentList.data.map(item => {
                //     let submissionStatusData = {}
                //     let financialStatusData = {}
                //     if (item.submission_status) {
                //         submissionStatusData = Object.assign({}, item.submission_status, JSON.parse(item.submission_status.data))
                //     }
                //     if (item.finance_office_status_data) {
                //         financialStatusData = Object.assign({}, item.finance_office_status_data, JSON.parse(item.finance_office_status_data.data))
                //     }
                //     const extraData = { detailsShowing: false }
                //     return Object.assign({}, item, extraData, { submission_status: submissionStatusData, financial_office_status: financialStatusData })
                // });

                // console.log('this.studentList', this.studentList)
                // this.paginationData(res.data.studentList)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.student-list-card', 'hide');
            }
        },
        submitAllStudent: function(){
            // this.$refs.invitation_confirmation.show();
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                // title: 'Agent Student Submission',
                title: 'Student Submission To ' + this.intakeItem.name + ' List',
                html: "Do you want to submit the students to this list?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitAllStudentConfirm()
                }
            })
        },
        submitAllStudentConfirm: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                ref.wait_me('.student-list-card');
                const params = Object.assign({}, { intake_id: this.search.intake_id })
                var res = await jq.post(ref.url('api/v1/agent/ajax/submit_agent_all_student'), params);
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                // ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.student-list-card', 'hide');
            }
        },
        getRegionList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_region_dropdown_list'));
            this.regionList = res.data.regionList.map(item => {
                return {
                    id: item.id,
                    label: item.title
                }
            })
        },
        getRegionCountryList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_region_countries_list'));
            this.regionCountryList = res.data.regionCountryList
        },
        agent_status: function(row){

            var ref=this;
            return ref.list.agent_statuses.find(each=>{
                return each.id==row.status;
            });

        },
        update_agent_status: async function(){

            this.$refs.agent_status_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/update_agent_status');

            try{

                ref.wait_me('.student-list-card');
                var res=await jq.post(url, ref.form.agent_status_update);

                ref.form.agent_status_update.agent_id='';
                ref.form.agent_status_update.new_status='';
                ref.alert(res.msg);
                ref.getListData();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.student-list-card', 'hide');
            }

        },
        send_single_mail: function(agent_id){

            var ref=this;
            ref.form.send_invitation.checked_all=false;
            ref.form.send_invitation.agent_ids.push(agent_id);
            ref.invitation_confirmation();

        },
        getCountryList: function (regionId) {
            if (regionId) {
                const regionCountryList = this.regionCountryList.filter(item => item.region_id == regionId)
                const countryMappingData = regionCountryList.map(item => {
                    const countryObj = this.$store.state.site.countries.find(country => country.id == item.country_id)
                    const countryData = {
                        id: countryObj.id,
                        label: countryObj.name,
                    }
                    return Object.assign({}, countryData)
                })
                return countryMappingData
            }
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_university_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.course_level.id,
                        label: item.course_level.name
                    }
                });
                this.courseTitleList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                });
                // console.log('this.courseLevelList', res.data.list)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        editData: function (item) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dob = moment(this.editItem.dob).format("DD MMM YYYY");
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        deleteFromIntake: function (item) {
            this.editItem = item
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Remove From Intake',
                html: "Are you sure do you want to add to intake?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteFromConfirm(item)
                }
            })
        },
        deleteFromConfirm: async function(item){

            var ref=this;
            var jq=ref.jq();

            try {
                var res = await jq.post(ref.url('api/v1/agent/ajax/delete_from_intake_agent_student'), item);
                this.getListData()
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/agent/ajax/update_agent_student_info'), ref.editItem);
                this.getListData()
                this.toggle_modal_form()
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        getStatusList: async function () {
            var ref=this;
            var jq=ref.jq();

            // agent statuses
            try {
                const params = { group_code: 'agent_accept_status' }
                var agent_accept_res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);
                this.agentAcceptStatusList = agent_accept_res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }

            try {
                const params = { group_code: 'student_international_office_statuses' }
                var io_status_res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);
                this.student_io_statuses = io_status_res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getIntakeList: async function () {
            var ref=this;
            var jq=ref.jq();

            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_intake_list'));
                this.intakeList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getMonthYearData: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/mulitiple_uni_agent_student_import_init_data');

            try{
                var res=await jq.get(url);
                this.monthList = res.data.months;
                this.yearList = res.data.years;

            } catch (err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        processQueryString: function () {
            const query = this.$route.query
            if (query && query.action == 'requirement') {
                this.$refs.stu_reqs.open_stu_reqs(query);
            }
        }
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        }
    },
    created: async function(){
        this.setup_urls();
        // this.search.uni_ref_no = this.$route.query.uni_ref_no        
            
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        })
        
        this.gen_download_url();
    },
    mounted: function(){
        // this.getIntakeById();
        this.getListData();
        this.getCourseLevelList()
        this.getIntakeList()
        this.getMonthYearData()
        feather.replace();
        this.processQueryString()
    }
}
</script>
<style type="text/css">
    .badge.badge-light-blue {
        background-color: rgba(51, 51, 255, 0.12);
        color: #3333ff !important;
    }        
    .badge.badge-light-declined {
        background-color: rgba(255, 51, 0, 0.12);
        color: #ff3300 !important;
    }
    .table th, .table td {
         padding: 0.72rem 0rem; 
        vertical-align: middle;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.486rem .7rem !important; 
    }
    .avater_size_25 {
        width: 25px !important;
        height: 25px !important;
        font-size: 10px !important;
        font-weight: 700 !important;
    }
</style>
