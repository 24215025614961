<template>
    <div>
        <div class="card bg-light-gray mb-1" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-2">
                        <input type="text" v-model="search.agent_name" class="form-control" id="name" placeholder="Agency name">
                    </div>
                    <div class="col-md-2">
                        <input type="text" v-model="search.agent_code" class="form-control" id="agent_code" placeholder="Banner Code">
                    </div>
                    <!-- <div class="col-md-2">
                        <treeselect v-model="search.intake_id" :options="intakeList" id="intake_id" placeholder="Select Intake" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.intake_year" :options="yearList" id="year_id" placeholder="Select Year" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.account_manager_id" :options="treeselect_uni_users" id="supervisor_id" placeholder="Account Manager" autocomplete="off"/>
                    </div> -->
                    <div class="col text-right">
                        <button class="btn btn-primary mr-50" @click="searchData()"><i data-feather='search'></i> Search</button>
                        <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card wait_me_list_data">
            <div class="card-body p-0">
                <div class="row p-2">
                    <div class="col-md-6">
                        <h3>Agent Wise Report</h3>
                        <div class="d-flex">
                            <div class="pr-1 border-right-light-gray">
                                <b v-if="grandTotal.student_count" v-html="'Total Students: ' + float2(grandTotal.student_count)"></b>
                                <b v-else v-html="'Total Students: - '"></b>
                            </div>
                            <div class="pl-1">
                                <b v-if="grandTotal.commission" v-html="'Total Commission: £ ' + float2(grandTotal.commission)"></b>
                                <b v-else v-html="'Total Commission: - '"></b>
                            </div>
                       </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                        <div style="width: 365px;" class="mr-1 form-group row justify-content-end">
                            <label for="intake_id" class="col-sm-4 col-form-label">Select Intake</label>
                            <div class="col-sm-7 p-0">
                                <treeselect v-model="search.intake_id" :options="intakeList" id="intake_id" placeholder="Select Intake" autocomplete="off"/>
                            </div>
                        </div>
                        <a :href="download_url" download v-tooltip="'Download to excel'" class="mr-1">
                          <i class="fa fa-cloud-download text-uclan font-size-30" aria-hidden="true"></i>
                        </a>
                        <div>
                          <button type="button" @click="toggle_filter()" class="btn btn-secondary btn-sm waves-effect waves-float waves-light"><i data-feather='filter'></i></button>
                        </div>
                    </div>
                </div> 

                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                              <th>SL</th>
                              <th>Agency Name</th>
                              <th>Banner Code</th>
                              <th class="text-center">Students</th>
                              <th class="text-center">Comission</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                            <td v-html="index + pagination.slOffset"></td>
                            <td>
                                <span v-html="item.agent_name"></span>
                            </td>
                            <td>
                                <span @click="gotoAgentProfilePage(item)" class="badge badge-pill badge-light-primary hand" v-html="item.agent_code"></span>
                            </td>
                            <td class="text-center">
                                <span v-html="float2(item.student_count)"></span>
                            </td>
                            <td class="text-center">
                                &#163; <span v-html="float2(item.total_commission)"></span>
                            </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                            <th colspan="9" class="text-center">
                                <span v-if="listData === null">Processing...</span>
                                <span v-else>
                                <i
                                    class="fa fa-exclamation-triangle text-danger"
                                    aria-hidden="true"
                                ></i>
                                Found no agent data.
                                </span>
                            </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import feather from "feather-icons";
import Treeselect from '@riophae/vue-treeselect';
import Hashids from 'hashids';

export default {
  name: "AgentWiseReport",
  metaInfo: {
      titleTemplate: '%s | Agent Wise Report'
  },
  components: {
    Treeselect
    //BDropdown,
    //BDropdownItem,
    // UserCheckIcon,
  },
  data: () => {
    return {
      grandTotal: null,
      listData: null,
      intakeList: [],
      flag: {
        show_filter: false,
      },
      search: {
          country_id: null,
          agent_name: '',
          agent_code: '',
          start_date: '',
          intake_month: null,
          intake_year: null,
          intake_id: null,
          account_manager_id: null,
          tab: 'agent_wise',
      },
      monthList: [],
      yearList: [],
      treeselect_uni_users: [],
      download_url:'#',
    };
  },
  watch: {
    'search.intake_id': function () {
        this.getListData()
    },
  },
  methods: {
    gotoAgentProfilePage: function (item) {
        if (this.permission_check('agent_profile')) {
            let routeData = this.$router.resolve({name: 'AgentProfile', params: { id: this.hash_id(item.agent_company_id) }});
            window.open(routeData.href, '_blank');
        }
    },
    gotoAgentWiseStudentListPageClTab: function (item) {
        if(this.permission_check('confirmed_list')) {
            let routeData = this.$router.resolve({ name: 'AgentWiseStudentList', params: { 
                id: this.hash_id(item.list_id),
                intake_id: this.hash_id(item.intake_id),
                active_tab: 'three',
            }})
            window.open(routeData.href, '_blank');
        }
    },
    toggle_filter : function(){
        this.flag.show_filter=!this.flag.show_filter;
    },
    ClearSearchData () {
        this.search = {
            country_id: null,
            agent_name: '',
            agent_code: '',
            start_date: '',
            intake_month: null,
            intake_year: null,
            // intake_id: null,
            account_manager_id: null,
            tab: 'agent_wise',
        }
        this.getIntakeList()
        this.getListData()
    },
    async searchData() {
      this.getListData();
    },
    gotoDisputeStudentListPage: function (item) {
      if (this.permission_check("intake_wise_agents")) {
        this.$router.push({
          name: "DisputedStudentListByIntake",
          query: { intake_id: this.hash_id(item.id) },
        });
      }
    },
    getListData: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        // const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage });
        const params = Object.assign({}, this.search);
        ref.wait_me(".wait_me_list_data");
        var res = await jq.get(ref.url("api/v1/uclan/ajax/get_report_list_by_territory_country_agent_wise"), params);
        this.listData = res.data.report_list
        this.grandTotal = res.data.grand_total
        this.gen_download_url();
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_list_data", "hide");
      }
    },
    getIntakeList: async function () {
        var ref=this;
        var jq=ref.jq();

        try {
            var res = await jq.get(ref.url('api/v1/uclan/ajax/get_intake_list'));
            this.intakeList = res.data.list.map(item => {
                return {
                    id: item.id,
                    label: item.name,
                }
            })
            if (res.data.list && res.data.list.length) {
                const intakeObj = res.data.list.find(item => item.confirmed_student_exist == true)
                if (intakeObj) {
                    this.search.intake_id = intakeObj.id
                }
            }
        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
    },
    getMonthYearData: async function(){

        var ref=this;
        var jq=ref.jq();
        var url=ref.url('api/v1/university/ajax/mulitiple_uni_agent_student_import_init_data');

        try{
            var res=await jq.get(url);
            this.monthList = res.data.months;
            this.yearList = res.data.years;

        } catch (err){
            ref.alert(ref.err_msg(err), 'error');
        }

    },
    getUsersList: async function () {
        var ref=this;
        var jq=ref.jq();
        var res = await jq.get(ref.url('api/v1/university/ajax/get_uni_users_list'));
        if (res.data.usersList && res.data.usersList.length) {
            this.treeselect_uni_users = res.data.usersList.map((row)=>{
                return {
                    id: row.id,
                    label: row.name
                }
            });
        }
    },
    gen_download_url: function () {
        var ref=this;
        var jq=this.jq();
        var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
        var institute_id = ref.auth_user.institute_id;
        this.download_url = ref.url('download/download_agent_country_territory_wise_report_list/'+hash_ids.encode(institute_id));
        this.search.auth_id = ref.auth_user.id
        var search_query = jq.param(this.search)
        this.download_url += '?' + search_query

    },
  },
  computed: {
    store_countries: function () {
      return this.$store.state.site.countries;
    },
    treeselect_countries: function () {
      return this.store_countries.map((each) => {
        return {
          id: each.id,
          label: each.name,
        };
      });
    },
  },
  created: function () {
    this.setup_urls();
  },
  mounted: function () {
    feather.replace();
    this.getListData();
    this.getIntakeList();
    this.getMonthYearData()
    this.getUsersList()
  },
};
</script>

