<template>
<section class="role-permit">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                        <h5 class="content-header-title float-left mt-50">Settings</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item">Settings</li>
                                <li class="breadcrumb-item active">Role Permissions</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Role Permissions</h3>
                    </div>
                    <div class="col-6 text-right">
                        <router-link to="/settings/add-edit-role" type="button" class="btn btn-primary waves-effect waves-float waves-light">
                            <i data-feather="plus-square"></i>
                            Add New Role
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive role-permit-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Role</th>
                                <th>Role Code</th>
                                <!-- <th>Permissions</th> -->
                                <th>Active</th>
                                <th class="text-center" width="205">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + 1"></td>
                                <td>
                                    <span v-html="item.role_name"></span>
                                </td>
                                <td>
                                    <span v-html="item.role_code"></span>
                                </td>
                                <!-- <td>
                                    <div v-if="item.permits">
                                        <span class="font-weight-bold" v-if="getRelatedPermissions(item.permits).length">Pages</span><br/>
                                        <span class="badge badge-light-info mr-1 mt-1" v-for="(item2, index2) in getRelatedPermissions(item.permits)" :key="index2">
                                            <span v-if="item2.permit" v-html="item2.permit.permit_title"></span>
                                        </span><br/><br/>
                                        <span class="font-weight-bold pt-1" v-if="getRelatedPermissions(item.permits, 86).length">Operations</span><br/>
                                        <span class="badge badge-light-primary mr-1 mt-1" v-for="(item2, index2) in getRelatedPermissions(item.permits, 86)" :key="index2">
                                            <span v-if="item2.permit" v-html="item2.permit.permit_title"></span>
                                        </span>
                                    </div>
                                </td> -->
                                <td>
                                    <b-form-checkbox
                                        v-model="item.active"
                                        v-on:change="updateActiveStatus(item)"
                                        :id="'active'+item.id"
                                        class="custom-control-success"
                                        name="check-button"
                                        switch
                                    />
                                </td>
                                <td class="text-center">

                                    <button @click="editData(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;">
                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                    </button>                                                

                                    <button @click="deleteData(item, index)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                        <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="6" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
// import Treeselect from '@riophae/vue-treeselect';
// import SidebarForm from '../../SidebarForm.vue';
import { EditIcon, Trash2Icon } from 'vue-feather-icons'
// import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'
import { BFormCheckbox } from 'bootstrap-vue'

export default{
    name: 'RolePermissions',
    metaInfo: {
        titleTemplate: '%s | Role Permissions'
    },
    components:{
        MainLayout,
        // Treeselect,
        // SidebarForm,
        EditIcon,
        Trash2Icon,
        // XIcon,
        BFormCheckbox
    },
    data:()=>{
        return {
            saveData: true,
            flag:{
                show_right_sidebar_form:false
            },
            form: {
                role_permit: {
                    id: '',
                    permit_id: null,
                    role_id: null,
                }
            },
            listData: null,
            roleList:[],
            permitList:[]
        };
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
    },
    methods:{
        updateActiveStatus: async function (item) {

            var ref=this;
            var jq=ref.jq();
            try{
                var res = await jq.post(ref.url('api/v1/university/ajax/update_role_active_status'), item);
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        toggle_right_sidebar_form: function(){
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
            this.form.role_permit = {}
        },
        update_reset: function () {
            this.form.role_permit = {};
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
        },
        formClose: function() {
            this.toggle_right_sidebar_form()
        },
        getListData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.role-permit-table');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_role_permit_list'));
                this.listData = res.data.list.map(item => {
                    const objData = { active : item.active == 1 ? true : false }
                    return Object.assign({}, item, objData)
                })
                // console.log('this.listData', this.listData)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.role-permit-table', 'hide');
            }
        },
        getRoleList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'role_user_types' }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);
                const userTypeList = res.data.statuses.filter(item => item.code == 'role_user_type_university')

                if(userTypeList[0] && userTypeList[0].roles){

                    this.roleList = userTypeList[0].roles.map(item => {
                        return {
                            id: item.id,
                            label: item.role_name
                        }
                    });

                }else{
                    this.roleList=[];
                }

            } catch (err) {
                // console.log('here...');
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getPermitList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_permits_list'));
            this.permitList = res.data.list.map(item => {
                return {
                    id: item.id,
                    label: item.permit_name
                }
            })
        },
        insertFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/add_role_permit'), ref.form.role_permit);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_role_permit'), ref.form.role_permit);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        editData: function (item) {
            this.$router.push({ name: 'AddEditRole', params: { id: this.hash_id(item.id) } })
            // this.toggle_right_sidebar_form()
            // this.saveData = false

            // this.form.role_permit = JSON.parse(JSON.stringify(item))
        },
        deleteData: async function (item, index) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function (item, index) {
            var ref=this;
                var jq=ref.jq();
                try {
                    var res = await jq.get(ref.url('api/v1/university/ajax/delete_role_permit'), item);
                    this.listData.splice(index, 1)
                    ref.alert(res.msg)
                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
        },
        getRelatedPermissions: function (permissions, type_id = 87) {
            console.log('permissions', permissions)
            return permissions.filter(item => {
                return item.permit.type_id == type_id
            })
        },
    },
    mounted: function(){
        feather.replace();
        this.getPermitList();
        this.getRoleList();
        this.getListData();
    }
}
</script>
