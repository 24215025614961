<template>
<section class="login">
    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <a class="brand-logo" href="javascript:void(0);">
                            <img :src="url('static/img/sams_pay_logo.png')" width="300">
                        </a>
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 login_cover_image">
                            <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"><img class="img-fluid" :src="url('static/vuexy/app-assets/images/pages/login-page.jpg')" alt="Login V2" /></div> -->
                            <img :src="url('static/img/info-graphics/intro-img.svg')" alt="Login V2" />
                        </div>
                        <!-- /Left Text-->
                        <!-- Login-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                <button class="btn btn-outline-primary toast-basic-toggler mt-2" style="display:none">Toast</button>
                                <h2 class="card-title font-weight-bold mb-1">Welcome !</h2>
                                <p class="card-text mb-2">Please sign-in to your account.</p>
                                <div class="auth-login-form mt-2">
                                    <div class="form-group">
                                        <label class="form-label">Email</label>
                                        <input class="form-control" type="text" placeholder="Email Address" aria-describedby="login-email" autofocus="" tabindex="1" v-model="form.login.email" v-on:keyup.enter="login()"/>
                                    </div>
                                    <div class="form-group">
                                        <div class="d-flex justify-content-between">
                                            <label>Password</label>
                                            <router-link  to="/forgot-password">
                                                <small>Forgot Password?</small>
                                            </router-link>

                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" type="password" placeholder="Password" aria-describedby="login-password" tabindex="2" v-model="form.login.password"  v-on:keyup.enter="login()"/>
                                            <!-- <div class="input-group-append"><span class="input-group-text cursor-pointer"><i data-feather="eye"></i></span></div> -->
                                        </div>
                                    </div>
<!--                                     <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3" />
                                            <label class="custom-control-label" for="remember-me"> Remember Me</label>
                                        </div>
                                    </div> -->
                                    <button type="button" class="btn btn-primary btn-block" tabindex="4" v-on:click="login()">Sign in</button>
                                </div>
<!--                                 <p class="text-center mt-2"><span>New on our platform?</span><a href="a-re.html"><span>&nbsp;Create an account</span></a></p>
                                <div class="divider my-2">
                                    <div class="divider-text">or</div>
                                </div> -->
                            </div>
                                <p class="text-center copyright">
                                    <span>Copyright &copy; {{ dYear(new Date()) }} SAMS Pay. All rights reserved.</span>
                                </p>
                        </div>
                        <!-- /Login-->
                    </div>
                </div>
            </div>
        </div>
    </div>



</section>
</template>

<script>

export default {
    name: 'Login',
    data: function(){
        return {
            form:{
                login:{
                    email:'',
                    password:''
                }
            }
        };
    },
    methods: {
        reset_forms: function(){
            var ref=this;
            ref.form.login.email='';
            ref.form.login.password='';
        },
        login: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/auth/login');
            ref.wait_me('.auth-login-form');

            var form_data=ref.form.login;

            try{

                ref.wait_me('.email-fields', 'hide');
                var res=await jq.post(url, form_data);
                ref.alert(res.msg);
                ref.reset_forms();
                localStorage.setItem('uni_api_token', res.data.api_token);
                localStorage.setItem('base_url', ref.base_url);
                ref.$store.commit('setApiToken', res.data.api_token);
                ref.ajax_setup('uni_api_token');

                ref.$store.commit('siteInfo', {
                    key: 'auth_user',
                    val: res.data.user
                });
                
                ref.$store.commit('siteInfo', {
                    key: 'permission_disable',
                    val: res.data.permission_disable
                });
                ref.$store.commit('siteInfo', {
                    key: 'permissions',
                    val: res.data.permissions
                });

                ref.$store.commit('siteInfo', {
                    key: 'university',
                    val: res.data.university
                });

                if (res.data.user.reset_password == 1) {
                    ref.$router.push('/reset-password');
                } else {
                    ref.$router.push('/dashboard');
                }


            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.auth-login-form', 'hide');
            }

        }
    },
    mounted: function(){
        var jq=this.jq();
        jq('body').addClass('blank-page');
    },
    created: function(){
        this.setup_urls();
        localStorage.removeItem('uni_api_token');
    }
}
</script>

<style scoped>
@import "../../static/vuexy/app-assets/css/pages/page-auth.css";

.login_cover_image {
    position: relative;
}

.login_cover_image img {
    position: absolute;
    left: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.copyright{
    position: absolute;
    bottom: 0;
    left: 90px;
    color:#d8d6de;
}
</style>
