<template>
<section class="logout">
   <main-layout>
        <!-- BEGIN: Content-->
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-2">
                        <div class="row breadcrumbs-top">
                            <div class="col-12">
                                <!-- <h2 class="content-header-title float-left mb-0">Invoices</h2> -->
                                <h5 class="content-header-title float-left mt-50">Invoices</h5>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                        <li class="breadcrumb-item active">Invoices</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="content-body">
                    <div class="card">
                    <div class="card-body my-5">
                        <h3 class="text-center text-primary">
                        Coming Soon...
                        </h3>
                    </div>
                    </div>
                </div>
        <!-- END: Content-->


        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
    </main-layout>
</section>
</template>

<script>
import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';

export default{
	name: 'Invoices',
    metaInfo: {
        titleTemplate: '%s | Invoices'
    },
	components:{
		MainLayout
	},
	data:()=>{
		return {};
	},
	methods:{},
	mounted: function(){
		feather.replace();
	}
}
</script>
