<template>
  <section class="agency-list">
    <main-layout>

      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <!-- <h2 class="content-header-title float-left mb-0">Gateway</h2> -->
              <h5 class="content-header-title float-left mt-50">Gateway</h5>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                  <li class="breadcrumb-item"><router-link to="/gateway/all-application">All Lists</router-link></li>
                  <li class="breadcrumb-item" v-html="cn(intake, 'name', 'Loading...')"></li>
                  <li class="breadcrumb-item active">Bulk Verification List</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card wait_me_list_data">
        <div class="card-header d-block p-1">
          <div class="row">
            <div class="col-6 col-md-6">
              <h3>
                Bulk Verfication for
                <router-link
                  to="/gateway/all-application"
                  class="text-primary hand"
                  v-html="cn(intake, 'name', 'Loading...')"
                >
                </router-link>
                Intake Data
              </h3>
            </div>

            <div class="col text-right">
              <router-link class="btn btn-sm btn-light" to="/gateway/all-application">
                Go Back To All Lists
              </router-link>
            </div>

            <!-- <div v-if="show_actions()" class="col text-right">
              <button class="btn btn-sm btn-danger mr-50" v-on:click="remove_imported_data(true)">Remove All Data</button>
              <button class="btn btn-sm btn-success" v-on:click="verify_bulk_students()">Verify All Data</button>
            </div> -->

          </div>
        </div>
        <div class="card-body p-0">

          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-center" colspan="7">
                    <h5 v-if="info.bulk_students" class="mb-0 d-inline mr-1">
                      Students Found
                      <span class="badge badge-pill badge-info" v-html="integer(info.bulk_students)"></span>
                    </h5>
                    <h5 v-if="info.bulk_v_students" class="mb-0 d-inline mr-1">
                      Students Verified
                      <span class="badge badge-pill badge-success" v-html="integer(info.bulk_v_students)"></span>
                    </h5>
                    <h5 v-if="info.bulk_v_agent_not_matched" class="mb-0 d-inline mr-1">
                      Agent Not Matched
                      <span class="badge badge-pill badge-danger" v-html="integer(info.bulk_v_agent_not_matched)"></span>
                    </h5>
                    <h5 v-if="info.bulk_v_intake_not_matched" class="mb-0 d-inline mr-1">
                      Intake Not Matched
                      <span class="badge badge-pill badge-danger" v-html="integer(info.bulk_v_intake_not_matched)"></span>
                    </h5>
                    <h5 v-if="info.bulk_v_student_not_matched" class="mb-0 d-inline mr-1">
                      Students Not Matched
                      <span class="badge badge-pill badge-info" v-html="integer(info.bulk_v_student_not_matched)"></span>
                    </h5>

                  </th>
                  <th>
                    <button type="button" @click="toggle_filter()" class="btn btn-secondary btn-sm waves-effect waves-float waves-light btn-block">
                      <i class="fa fa-filter" aria-hidden="true"></i>
                    </button>
                  </th>
                </tr>

                <!-- Search form -->
                <tr id="filter-form" v-if="flag.show_filter">
                  <th colspan="8">
                    <div class="form-row">
                        <div class="col-md-2">
                            <input type="text" v-model="search.agent_name_or_code" class="form-control" placeholder="Agent name or code" autocomplete="off">
                        </div>
                        <div class="col-md-2">
                            <input type="text" v-model="search.uni_ref_no" class="form-control" placeholder="Student ID" autocomplete="off">
                        </div>
                        <div class="col-md-2">
                            <input type="text" v-model="search.student_name" class="form-control" placeholder="Student name" autocomplete="off">
                        </div>
                        <div class="col-md-2">
                            <treeselect v-model="search.course_level" :options="list.course_levels" placeholder="Select Course Level" autocomplete="off"/>
                        </div>

                        <div class="col-md-2">
                            <treeselect v-model="search.verification_status" :options="list.verification_statuses" placeholder="Verification Status" autocomplete="off"/>
                        </div>

                        <div class="col-md-2">
                            <div class="form-row">
                                <div class="col-md-6">
                                    <button class="btn btn-primary btn-block" @click="searchData()">Search</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-outline-danger btn-block" @click="clearSearchData()">Clear</button>
                                </div>
                            </div>
                        </div>

                    </div>
                  </th>
                </tr>
                <!-- End of Search form -->

                <tr>
                  <th width="75px">#</th>
                  <th>Banner Code</th>
                  <th>Student ID</th>
                  <th>Student</th>
                  <th>Intake</th>
                  <!-- <th>University</th> -->
                  <!-- <th>Start Date</th>
                  <th>Added By</th> -->
                  <th>Course Level</th>
                  <th>Course Name</th>
                  <th>Verification status</th>
                </tr>
              </thead>
              <tbody v-if="listData && listData.length > 0">
                <tr v-for="(item, index) in listData" :key="index">
                  <td v-html="index + pagination.slOffset"></td>
                  <td>
                    <span v-html="item.agent_ref_no"></span>
                    <i v-if="!item.agent_company_id" class="fa fa-exclamation-triangle text-warning ml-50" v-tooltip="'Invalid Banner Code'"></i>
                  </td>
                  <td v-html="item.uni_ref_no"></td>
                  <td v-html="item.first_name + ' ' + item.last_name"></td>
                  <td>

                    <span v-if="item.intake" v-html="item.intake.name"></span>
                    <i v-else class="fa fa-exclamation-triangle text-warning" v-tooltip="'Invalid Intake'"></i>

                    <i v-if="item.intake_id && item.intake_id!=intake.id" class="fa fa-exclamation-triangle text-warning ml-50" v-tooltip="'Mismatched Intake'"></i>

                  </td>
                  <!-- <td v-html="item.university.name"></td> -->
                  
                  <!-- <td v-html="dDate(item.start_date)"></td> -->
                  <!-- <td v-html="item.added_by.name"></td> -->
                  <td class="course_level">

                    <div v-if="cn(item.course_level, 'name', null)" class="text-left" v-html="item.course_level.name">
                    </div>
                    <div v-else class="text-center">
                      <i class="fa fa-exclamation-triangle text-warning" v-tooltip="'Invalid Course Level'"></i>
                    </div>                   
                    
                  </td>
                  <td class="course_name" v-html="item.subject"></td>
                  <td class="text-center verification-status">
                    <span class="badge badge-pill" :class="sv_class(item)" v-html="cn(item, 'bulk_verification_status.name', 'Unverified')"></span>

                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <th colspan="9" class="text-center">
                    <span v-if="listData === null" >Processing...</span>
                    <span v-else>
                      <i
                        class="fa fa-exclamation-triangle text-danger"
                        aria-hidden="true"
                      ></i>
                      Found no student data.
                    </span>                    
                  </th>
                </tr>
              </tbody>
            </table>
            <b-pagination
              class="mt-1 mr-1"
              align="right"
              v-model="pagination.currentPage"
              pills
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              @input="searchData"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </main-layout>
  </section>
</template>

<script>
import MainLayout from "../MainLayout.vue";
import feather from "feather-icons";
import { BPagination } from "bootstrap-vue";
import Swal from 'sweetalert2';
import Treeselect from '@riophae/vue-treeselect';

export default {
  name: "BulkVerficationList",
  metaInfo: {
      titleTemplate: '%s | Bulk Verfication List'
  },
  components: {
    MainLayout,
    BPagination,
    Treeselect
  },
  data: () => {
    return {
      intake: null,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 2,
        slOffset: 1,
      },
      agencyList: [],
      listData: null,
      flag: {
        show_filter: false
      },
      form: {
        uni_course_level: {
          checked_all: false,
          agent_ids: [],
        },
      },
      info:{
        bulk_students:0,
        bulk_v_students:0,
        bulk_v_agent_not_matched:0,
        bulk_v_student_not_matched:0,
        bulk_v_intake_not_matched:0,
      },
      search:{
        agent_name_or_code: '',
        student_name: '',
        uni_ref_no: '',
        course_level: null,
        course_name: '',
        verification_status: null
      },
      list:{
        course_levels:[],
        verification_statuses:[]
      }
    };
  },
  methods: {
    toggle_filter : function(){
        this.flag.show_filter=!this.flag.show_filter;
    },
    clearSearchData: function () {
        this.search = {
            agent_name_or_code: '',
            student_name: '',
            uni_ref_no: '',
            course_level: null,
            course_name: '',
            verification_status: null,
        }
        this.getListData()
    },
    async searchData() {
      // this.getListData();
      this.get_intake_info();
    },
    gotoIntakeWiseAgentListPage: function (item) {
      this.$router.push({
        name: "IntakeWiseAgentList",
        params: { id: this.hash_id(item.id) },
      });
    },
    getListData: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        const params = Object.assign(
          this.search,
          {
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage,
            intake_id: this.intake.id,
          }
        );

        ref.wait_me(".wait_me_list_data");
        var res = await jq.get(
          ref.url("api/v1/uclan/ajax/get_bulk_agent_student_list"),
          params
        );
        this.listData = res.data;
        this.paginationData(res);
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_list_data", "hide");
      }
    },
    uni_course_level_email: async function () {
      this.$refs.invitation_confirmation.hide();

      var ref = this;
      var jq = this.jq();
      var url = ref.url("api/v1/university/ajax/uni_course_level_emails");

      try {
        ref.wait_me(".wait_me_list_data");
        var res = await jq.post(url, ref.form.uni_course_level);

        ref.form.uni_course_level.checked_all = false;
        ref.form.uni_course_level.agent_ids = [];
        ref.alert(res.msg);
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_list_data", "hide");
      }
    },
    get_intake_info: async function () {
      var ref = this;
      var intake_id = null;

      if (!ref.$route.params.intake_hash_ids) {
        ref.$router.push({ name: "AgentWiseStudentList" });
      } else {
        intake_id = ref.hash_id(ref.$route.params.intake_hash_ids, false)[0];
        if (!intake_id) {
          ref.$router.push({ name: "AgentWiseStudentList" });
          ref.alert("list argument missing", "error");
        }
      }

      var jq = ref.jq();
      var url = ref.url(
        "api/v1/uclan/ajax/bulk_student_upload_intake_info"
      );

      const params = Object.assign({}, this.search, { intake_id: intake_id } )

      try {
        var res = await jq.get(url, params);
        ref.intake = res.data.intake;
        ref.info.bulk_students = res.data.bulk_students;
        ref.info.bulk_v_students = res.data.bulk_v_students;
        ref.info.bulk_v_agent_not_matched = res.data.bulk_v_agent_not_matched;
        ref.info.bulk_v_student_not_matched = res.data.bulk_v_student_not_matched;
        ref.info.bulk_v_intake_not_matched = res.data.bulk_v_intake_not_matched;
        ref.list.course_levels=res.data.course_levels;

        await this.getListData();
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      }
    },
    remove_imported_data: async function(re_upload=false){

			var swal_confirm = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-danger mr-1',
					cancelButton: 'btn btn-light'
				},
				buttonsStyling: false
			});

			var {isConfirmed} = await swal_confirm.fire({
				title: 'Confirmation!',
				text: 'Do you want to remove this imported data.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Remove'
			});

			if(isConfirmed){

				var ref=this;
				var jq=ref.jq();
				var url=ref.url('api/v1/uclan/ajax/remove_imported_bulk_student_data');

				var form_data={
          intake_id: this.intake.id
				};

				try{
					ref.wait_me('.uni-comm-configs');
					var res=await jq.post(url, form_data);
					ref.alert(res.msg);

          if(re_upload) ref.goto_bulk_import();
          else{
            ref.$router.push({
              name: 'AllApplication'
            });
          }

				}catch(err){
					ref.alert(ref.err_msg(err), 'error');
				}finally{
					ref.wait_me('.uni-comm-configs', 'hide');
				}

			}

		},
    goto_bulk_import:function(){

      this.$router.push({
        name: "BulkVerficationUpload",
        params: { id: this.hash_id(this.intake.id) },
      });

    },
    show_actions: function(){

      if(!this.intake) return false;

      var ivs=this.cn(this, 'intake.verification_status.code', null);
      if(ivs=='bulk_student_verified') return false;
      else return true;
      
    },
    sv_class: function(row){

      var color=this.cn(row, 'bulk_verification_status.parsed_data.color', 'warning');
      return `badge-light-${color}`;

    },
    verify_bulk_students: async function () {

			var swal_confirm = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-success mr-1',
					cancelButton: 'btn btn-light'
				},
				buttonsStyling: false
			});

			var {isConfirmed} = await swal_confirm.fire({
				title: 'Are you sure you want to auto verify the uploaded data?',
				text: 'This action will take time and can not be reverse or undo. Press Verify to start this operation or Cancel to close.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Verify'
			});

			if(isConfirmed){

        var ref = this;
        var jq = ref.jq();

        try {
          const params = Object.assign({}, { intake_id: this.intake.id });
          jq.post(
            ref.url(
              "api/v1/uclan/ajax/intake_wise_bulk_agent_student_verify"
            ),
            params
          );
          ref.alert("This process will take time. Meanwhile you can leave this page or visit other page.");
          //ref.get_intake_info();
          //ref.getListData();
          this.$router.push('/gateway/all-application');
        } catch (err) {
          ref.alert(ref.err_msg(err), "error");
        }

      }
    },
    getStatusesByGroup: async function(){

        var ref=this;
        var jq=ref.jq();
        try {
            var res = await jq.get(ref.url('api/v1/university/ajax/get_status_groups_with_statuses'));
            const bulk_verification_student_statuses = res.data.status_groups.find(item => item.code == 'bulk_verification_student_statuses')
            console.log('bulk_verification_student_statuses', bulk_verification_student_statuses)
            this.list.verification_statuses = bulk_verification_student_statuses.statuses.map(item => {
                        return {
                          id: item.id,
                          label: item.name,
                        }
            })
        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
    },
  },
  computed: {
    store_countries: function () {
      return this.$store.state.site.countries;
    },
    treeselect_countries: function () {
      return this.store_countries.map((each) => {
        return {
          id: each.id,
          label: each.name,
        };
      });
    }
  },
  created: async function () {
    this.setup_urls();
  },
  mounted: async function () {
    feather.replace();
    await this.get_intake_info();
    this.getStatusesByGroup()
  },
};
</script>
