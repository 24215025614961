<template>
<section class="root">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Communications</h2> -->
                        <h5 class="content-header-title float-left mt-50">Communications</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <!-- <li class="breadcrumb-item"><router-link to="/">Gateway</router-link></li> -->
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active">Inbox</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="card">
            <div class="card-body p-1">
                <div class="d-inline mr-1">
                    Total <span class="badge badge-pill badge-primary" v-html="info.list_req_counter.total"></span>
                </div>
                <div class="d-inline mr-1">
                    Open <span class="badge badge-pill badge-primary" v-html="info.list_req_counter.open"></span>
                </div>
                <div class="d-inline mr-1">
                    Closed <span class="badge badge-pill badge-primary" v-html="info.list_req_counter.closed"></span>
                </div>
            </div>
        </div> -->

        <div class="card mt-1 as-list-req">
            <div class="card-header d-inline">

                <div class="row">
                    <div class="col">
                        <div class="d-inline mr-1">
                            <span class="badge badge-pill badge-light-primary hand" :class="form.comm_filter.type=='total'?'badge-border':''" v-on:click="activate_badge_filter('total')">
                                Total - <span v-html="info.list_req_counter.total"></span>
                            </span>
                        </div>
                        <div class="d-inline mr-1">
                            <span class="badge badge-pill badge-light-warning hand" :class="form.comm_filter.type=='status_open'?'badge-border':''"  v-on:click="activate_badge_filter('status_open')">
                                Open - <span v-html="info.list_req_counter.open"></span>
                            </span>
                        </div>
                        <div class="d-inline mr-1">
                            <span class="badge badge-pill badge-light-success hand" :class="form.comm_filter.type=='status_closed'?'badge-border':''" v-on:click="activate_badge_filter('status_closed')">
                                Closed - <span v-html="info.list_req_counter.closed"></span>
                            </span>
                        </div>
                        <div class="d-inline mr-1">
                            <span class="badge badge-pill badge-light-danger hand" :class="form.comm_filter.type=='type_dispute'?'badge-border':''" v-on:click="activate_badge_filter('type_dispute')">
                                Dispute - <span v-html="info.list_req_counter.dispute"  ></span>
                            </span>
                        </div>
                    </div>
                    <div class="col text-right">
                        <!-- <button class="btn btn-sm btn-primary" v-on:click="toggle_list_request_form()">
                            <i class="fa fa-plus-square-o mr-25"></i>
                            Add New
                        </button> -->
                    </div>
                </div>

            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="2%">#</th>
                                <th>Agent Company</th>
                                <th>Student Details</th>
                                <th width="40%">Title</th>
                                <th width="20%">Last Reply</th>
                                <th class="text-center">Type</th>
                                <th class="text-center">User</th>
                                <th>Status</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody v-if="list.as_list_requests.data && list.as_list_requests.data.length > 0">
                            <tr v-for="(row, index) in list.as_list_requests.data" :key="index">
                                <td v-html="index+1"></td>
                                <td>
                                    <div v-if="row.agent_student_list">
                                        <span v-if="row.agent_student_list.agent_company" v-tooltip="'Agent Company'" v-html="row.agent_student_list.agent_company.name" class="text-uclan hand" @click="gotoAgentWiseStudentListPage(row)"></span><br>
                                        <span v-html="row.agent_student_list.list_code" class="badge badge-light-primary hand" v-tooltip="'List Ref'" @click="gotoAgentWiseStudentListPage(row)"></span>
                                    </div>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <div v-if="row.agent_student" >
                                        <span v-tooltip="'Student Name'" v-html="row.agent_student.first_name  + ' ' + row.agent_student.last_name"></span><br/>
                                        <span v-tooltip="'Student ID'" v-html="row.agent_student.uni_ref_no"></span>
                                    </div>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <span class="m-0" v-html="limit_str(row.message, 40)"></span><br>
                                    <!-- <div v-if="cn(row, 'attachments.length', 0)">
                                        <a class="mr-25" v-for="(attachment, index) in row.attachments" :href="url('tmp_aws_media/'+attachment.file)" :key="index">
                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                        </a>
                                    </div> -->
                                    <a  v-for="(attachment, index) in row.attachments" :key="index" :href="url('tmp_aws_media/'+attachment.file)" target="_blank" class="download-attachment" v-tooltip="'Download Attachment'">
                                    <!-- <i class="fa fa-cloud-download mr-25" aria-hidden="true"></i> -->
                                    <span class="fa-stack mr-25">
                                        <i class="fa fa-circle fa-stack-2x"></i>
                                        <i class="fa fa-cloud-download fa-stack-1x fa-inverse"></i>
                                    </span>              
                                    <!-- <span v-if="attachment.note" v-html="limit_str(attachment.note, 20)"></span>
                                    <span v-else v-html="limit_str(attachment.original_name, 20)"></span> -->
                                    </a>
                                    <span v-if="attachment_exists(row)" class="download-attachment hand text-warning" v-tooltip="'Download All Attachment'" v-on:click="download_all_attachment(row)">
                                        <span class="fa-stack mr-25">
                                            <i class="fa fa-circle fa-stack-2x"></i>
                                            <i class="fa fa-cloud-download fa-stack-1x fa-inverse"></i>
                                        </span> 
                                        All
                                    </span>
                                    
                                    <span v-if="row.request_type" v-html="row.request_type.name" class="badge badge-pill" :class="'badge-light-'+row.request_type.parsed_data.color"></span>
                                    <span v-else>-</span>  
                                </td>
                                <td >
                                    <span v-if="row.last_comment" v-html="limit_str(row.last_comment.comment, 16)"></span>
                                    <div v-if="row.last_comment_at_diff">
                                        <small class="text-muted">
                                            <i class="fa fa-clock-o mr-25" aria-hidden="true"></i>
                                        </small>                                      
                                        <small class="text-muted" v-html="row.last_comment_at_diff"></small>
                                    </div>                                    
                                </td>
                                <td class="text-center">
                                    <div v-if="cn(row, 'added_by.user_type', null) && row.added_by.user_type=='agent'">
                                        <span class="badge badge-light-secondary badge-pill" v-tooltip="'Agent'">A</span>
                                    </div>
                                    <div v-else>
                                        <span class="badge badge-light-primary badge-pill" v-tooltip="'University'">U</span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="avatar bg-light-primary" v-if="row.added_by">
                                        <small class="avatar-content" v-html="avatarText(row.added_by.name)" v-tooltip="row.added_by.name"></small>
                                    </div>
                                </td>
                                <td>
                                    <span v-show="row.status=='Open'" class="badge badge-pill badge-light-warning hand" v-on:click="update_list_req_status(row)">Open</span>
                                    <span v-show="row.status=='Closed'" class="badge badge-pill badge-light-success hand" v-on:click="update_list_req_status(row)">Closed</span>
                                </td>
                                <td class="text-center">
                                    <i class="fa fa-search hand" v-on:click="toggle_list_req_comments(true, row)"></i>
                                </td>
                            </tr>
                            <!-- <tr v-show="!list.as_list_requests.data.length">
                                <td colspan="8" class="text-center">
                                    <strong>
                                        <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                                        You have no Communications here.
                                    </strong>
                                </td>
                            </tr> -->
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="8" class="text-center">
                                    <span v-if="list.as_list_requests.data === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        You have no Communications here.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-pagination
                    class="mt-1 mr-1"
                    align="right"
                    v-model="pagination.currentPage"
                    pills
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="get_as_list_requests()"
                    >
                </b-pagination>
            </div>
        </div>

        <sidebar-form
            :show_sidebar="flag.show_list_req_comments"
            v-on:toggle-sidebar="toggle_list_req_comments()"
        >
            <div class="mb-1">
                <h5>
                    <span class="align-middle">Communication Details</span>
                </h5>
            </div>

            <div class="card mb-1" v-if="info.active_list_req.agent_student_list">
                <div class="card-body">
                    <div class="form-row">
                        <div class="col-lg-12 text-right pb-50" v-if="cn(info.active_list_req, 'request_type', null)">
                            <span v-html="info.active_list_req.request_type.name" class="badge badge-pill mr-1" :class="'badge-light-'+cn(info.active_list_req, 'request_type.parsed_data.color', 'primary')"></span>

                            <span v-show="info.active_list_req.status=='Open'" v-tooltip="'Click to update status'" class="badge badge-light-warning badge-pill hand" v-on:click="toggle_change_list_status(info.active_list_req)">Open</span>
                            <span v-show="info.active_list_req.status=='Closed'" v-tooltip="'Click to update status'" class="badge badge-light-success badge-pill hand" v-on:click="toggle_change_list_status(info.active_list_req)">Closed</span>
                        </div>
                    </div>

                    <!-- Change communication status within details  -->
                    <div class="card-body p-0" v-if="info.active_list_req.change_status">
                        <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" :name="'student_list_status_'+info.active_list_req.id" :id="'open_'+info.active_list_req.id" value="Open" v-model="form.list_req_status.status">
                            <label class="form-check-label text-warning" :for="'open_'+info.active_list_req.id">
                                Open
                            </label>
                            </div>
                            <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" :name="'student_list_status_'+info.active_list_req.id" :id="'closed_'+info.active_list_req.id" value="Closed" v-model="form.list_req_status.status">
                            <label class="form-check-label text-success" :for="'closed_'+info.active_list_req.id">
                                Closed
                            </label>
                            </div>
                        </div>
                        <div class="flex-grow-1 mr-1">
                            <textarea rows="1" type="text" class="form-control form-control-sm" v-model="form.list_req_status.status_remarks" placeholder="Enter status remarks (Optional)"></textarea>
                        </div>
                        <button class="btn btn-outline-primary btn-sm hand" v-on:click="update_list_req_details_status()">
                            Update
                        </button>
                        <i v-on:click="toggle_change_list_status(info.active_list_req)" class="fa fa-times-circle font-size-22 cursor-pointer text-danger ml-1" aria-hidden="true"></i>
                        </div>
                    </div>
                    <!-- /Change communication status within details -->
                    <div class="row mb-75">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-left align-items-center" v-if="info.active_list_req.agent_student_list">
                                <div class="avatar-wrapper" v-if="info.active_list_req.agent_student_list.agent_company">
                                    <div class="avatar  mr-1" v-tooltip="info.active_list_req.agent_student_list.agent_company.country ? 'Agent Country: ' + info.active_list_req.agent_student_list.agent_company.country.name : ''">
                                        <img :src="info.active_list_req.agent_student_list.agent_company.country.flag_url" alt="Avatar" height="32" width="32">
                                    </div>
                                </div>
                                <div class="d-flex flex-column" v-if="cn(info, 'active_list_req.agent_student_list.agent_company')">
                                    <small v-tooltip="'Agent Company Name'" class="font-weight-bolder font-size-16" v-html="cn(info, 'active_list_req.agent_student_list.agent_company.name', 'Company Name')"></small>
                                    <div>
                                        <small v-tooltip="'Banner Code'" class="badge badge-pill badge-light-primary cursor-pointer font-size-12" @click="gotoAgentProfilePage(info.active_list_req.agent_student_list.agent_company)" v-html="info.active_list_req.agent_student_list.agent_company.agent_code"></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-50 border-top" v-if="info.active_list_req.agent_student">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-left align-items-center">
                                <!-- <div class="avatar-wrapper" v-if="info.active_list_req.agent_student.country">
                                    <div class="avatar  mr-1" v-tooltip="info.active_list_req.agent_student.country ? 'Student Country: ' + info.active_list_req.agent_student.country.name : ''">
                                        <img :src="info.active_list_req.agent_student.country.flag_url" alt="Avatar" height="32" width="32">
                                    </div>
                                </div> -->
                                <div>
                                    <small v-tooltip="'Student Name'" class="font-weight-bolder" v-html="info.active_list_req.agent_student.first_name + ' ' + info.active_list_req.agent_student.last_name"></small>
                                    (<small v-tooltip="'Student ID'" v-html="'Student ID: ' + info.active_list_req.agent_student.uni_ref_no"></small>), 
                                    <small v-tooltip="'Student Country'" class="font-weight-bolder" v-if="info.active_list_req.agent_student.country" v-html="info.active_list_req.agent_student.country.name"></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-50 border-top" v-else>
                        <div class="col-md-12">
                            <small><i v-html="'This comment is related to a list.'"></i></small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card p-1">
                <div class="d-flex justify-content-between">
                    <div v-if="cn(info.active_list_req, 'added_by.user_type', null) && info.active_list_req.added_by.user_type=='agent'">
                        <span class="badge badge-light-secondary badge-pill"><i class="fa fa-user mx-25"></i> Agent</span>
                    </div>
                    <div v-else>
                        <span class="badge badge-light-primary badge-pill"><i class="fa fa-university mx-25"></i> University</span>
                    </div>
                    <span class="badge badge-light-primary badge-pill hand ml-1" v-tooltip="'List Ref'" @click="gotoAgentWiseStudentListPage(info.active_list_req)" v-if="info.active_list_req.agent_student_list" v-html="'List Ref - ' + info.active_list_req.agent_student_list.list_code"></span>
                </div>
                <div class="font-size-18 font-weight-bold py-50">
                    <span v-html="info.active_list_req.message"></span>

                    <div v-if="cn(info.active_list_req, 'attachments.length', 0)">
                        <a class="mr-25" v-for="(attachment, index) in info.active_list_req.attachments" :href="url('tmp_aws_media/'+attachment.file)" :key="index">
                            <span class="fa-stack font-size-14">
                                <i class="fa fa-circle fa-stack-2x"></i>
                                <i class="fa fa-cloud-download fa-stack-1x fa-inverse"></i>
                            </span>
                        </a>
                    </div>

                </div>
                <div class="border-0">
                    <!-- <span v-show="info.active_list_req.status=='Open'" class="badge badge-light-warning badge-pill">Open</span>
                    <span v-show="info.active_list_req.status=='Closed'" class="badge badge-light-success badge-pill">Closed</span> -->

                    <small>
                        <!-- <span v-html="cn(info.active_list_req, 'added_by.name', 'N/A')"></span> -->
                        <i class="fa fa-clock-o mx-25" aria-hidden="true"></i>
                        <!-- <span v-html="info.active_list_req.created_at_diff"></span> -->
                        <span v-html="dDateTime(info.active_list_req.created_at) + ' (' + dNow(info.active_list_req.created_at) + ')'"></span>
                    </small>
                </div>
            </div>

            <div class="row" v-show="info.active_list_req.status=='Open'">
                <div class="col text-right mt-50">
                    <span class="badge badge-light-secondary hand" v-on:click="toggle_comment_form()">Add a Reply</span>
                </div>
            </div>

            <div class="list-req-comment-form" v-show="form.list_req_comment.show_form">
                <div class="row mt-50">
                    <div class="col form-group">
                    <textarea class="form-control" rows="2" v-model="form.list_req_comment.comment" placeholder="Add reply here..."></textarea>
                    </div>
                </div>

                <div class="attachment-list d-flex justify-content-between align-items-center mb-50" v-for="(attachment, index) in form.attachments" :key="index">
                    <div class="mr-50">
                    <span class="fa-stack fa-lg">
                        <i class="fa fa-circle fa-stack-2x text-info"></i>
                        <i class="fa fa-paperclip fa-stack-1x fa-inverse"></i>
                    </span>
                    </div>
                    <div class="flex-grow-1">
                    <input type="text" class="form-control form-control-sm" :placeholder="attachment.file.name+' (Change file name)'" v-model="attachment.remarks"/>
                    </div>
                    <div class="ml-50">
                        <i class="fa fa-times-circle text-danger fa-2x hand mr-25" aria-hidden="true" v-on:click="remove_attachment(index)"></i>
                    </div>
                </div>

                <div class="row">
                    <div class="col form-group">
                        <label for="req_attachment" class="form-label">
                            Attachments:
                            <span class="text-warning ml-25 d-block">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            Each attached file size limt is <strong>5MB</strong>.
                            </span>
                            <span class="text-warning ml-25 d-block">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            File extension must have to be <strong>.png, .jpg, .pdf, .xlsx, .xls, .doc, .docx, .csv or .txt</strong>
                            </span>
                        </label>
                        <input type="file" class="form-control-file" placeholder="Attachment" ref="comment_attachment" v-on:change="add_attachment"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col form-group">
                        <button class="btn btn-primary btn-sm waves-effect waves-float waves-light" v-on:click="save_list_req_comment()">
                            Save
                        </button>
                        <button class="btn btn-light btn-sm waves-effect waves-float waves-light ml-50"
                            v-on:click="toggle_comment_form(false)"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            <div class="req-comments-section">
                <div class="comment-wrapper" v-for="row in list.list_req_comments" :key="row.id">
                    <!-- <div class="comment-block mt-1 mb-25 p-2"> -->
                    <div class="mt-1 mb-25 p-2" :class="auth_user.id == row.added_by.id ? 'comment_block_white' : 'comment_block_white'">
                        <div class="d-flex justify-content-left align-items-center">
                        <div class="avatar-wrapper">
                            <div class="avatar  bg-light-primary  mr-1">
                                <span class="avatar-content" v-html="avatarText(row.added_by.name)"></span>
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <small class="font-weight-bolder font-size-13 text-justify" v-html="row.comment"></small>
                            <div v-if="row.attachments">
                                <a style="font-size: 13px" v-for="(attachment, index) in row.attachments" :key="index" :href="url('tmp_aws_media/'+attachment.file)" target="_blank" class="download-comment-attachment">
                                    <span class="fa-stack mr-25">
                                        <i class="fa fa-circle fa-stack-2x"></i>
                                        <i class="fa fa-cloud-download fa-stack-1x fa-inverse"></i>
                                    </span>
                                    <span v-if="attachment.note" v-html="attachment.note"></span>
                                    <span v-else v-html="attachment.original_name"></span>
                                </a>
                            </div>
                            <small class="emp_post text-muted mt-1">
                                <div v-if="auth_user.id == row.added_by.id" class="text-uclan-b5">
                                    <i v-if="cn(row, 'added_by.user_type', null) && row.added_by.user_type=='agent'" class="fa fa-user mx-25"></i>
                                    <i v-else class="fa fa-university mx-25"></i>
                                    <span v-html="cn(row, 'added_by.name', 'N/A')"></span>

                                    <i class="fa fa-calendar-plus-o ml-75 mr-25" aria-hidden="true"></i>
                                    <span v-html="row.created_at_date"></span>
                                    <i class="fa fa-clock-o ml-75 mr-25" aria-hidden="true"></i>
                                    <span v-html="row.created_at"></span>
                                </div>
                                <div v-else class="font-weight-bold">
                                    <i v-if="cn(row, 'added_by.user_type', null) && row.added_by.user_type=='agent'" class="fa fa-user mx-25"></i>
                                    <i v-else class="fa fa-university mx-25"></i>
                                    <span v-html="cn(row, 'added_by.name', 'N/A')"></span>

                                    <i class="fa fa-calendar-plus-o ml-75 mr-25" aria-hidden="true"></i>
                                    <span v-html="row.created_at_date"></span>
                                    <i class="fa fa-clock-o ml-75 mr-25" aria-hidden="true"></i>
                                    <span v-html="row.created_at"></span>
                                </div>
                            </small>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

        </sidebar-form>

        <sidebar-form
            :show_sidebar="flag.show_new_req_form"
            v-on:toggle-sidebar="toggle_list_request_form()"
        >

            <div class="mb-1">
                <h5>
                    <span class="align-middle">Add New</span>
                </h5>
            </div>

            <div class="list-request-form-section">
                <div class="row">
                    <div class="col form-group">
                    <textarea
                        class="form-control"
                        rows="2"
                        placeholder="Enter your message"
                        v-model="form.list_request.message"
                    ></textarea>
                    </div>
                </div>

                <div class="row">
                    <div class="col form-group">
                        <label for="req_types" class="form-label">
                            Select Type <span class="text-danger">*</span>
                        </label>
                        <treeselect v-model="form.list_request.req_type_id" :options="treeselect_req_types" placeholder="Select Communication Type" autocomplete="off" id="req_types"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-7 form-group">
                        <label for="req_attachment" class="form-label">
                            Add Attachment
                        </label>
                        <input type="file" class="form-control-file" placeholder="Attachment" ref="req_attachment" v-on:change="add_attachment"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col form-group">
                        <button
                            class="
                            btn btn-primary btn-sm
                            waves-effect waves-float waves-light
                            "
                            v-on:click="save_list_request()"
                        >
                            Save
                        </button>
                        <button
                            class="
                            btn btn-light btn-sm
                            waves-effect waves-float waves-light ml-50
                            "
                            v-on:click="toggle_list_request_form()"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        
        </sidebar-form>

    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
// import SidebarForm from '../SidebarForm.vue';
import SidebarForm from '../../SidebarFormLighterBlue.vue';
import feather from 'feather-icons';
import { BPagination } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';
import Swal from 'sweetalert2';
//import moment from 'moment';

export default{
    name: 'AsListReq',
    metaInfo: {
        titleTemplate: '%s | Inbox'
    },
    components:{
        MainLayout, BPagination, SidebarForm, Treeselect
    },
    data:()=>{
        return {
            list:{
                as_list_requests:{
                    data: null
                },
                list_req_types:[],
                list_req_comments:[],
                list_req_statuses:{
                    'Open':'Open',
                    'Closed': 'Closed'
                }
            },
            info:{
                list_req_counter:{
                    total: 0,
                    open: 0,
                    closed: 0,
                    dispute: 0
                },
                active_list_req:{}
            },
            form: {
                list_request:{
                    show_form: false,
                    student_id:'',
                    message:'',
                    list_id:'',
                    req_type_id:null
                },
                list_req_comment:{
                    show_form: false,
                    list_request_id:'',
                    comment:''
                },
                list_req_status:{
                    id:'',
                    status:'',
                    status_remarks:''
                },
                attachments:[],
                comm_filter:{
                    active: false,
                    type:''
                }
            },
            flag:{
                show_list_req_comments: false,
                show_new_req_form: false
            },
            listData: []
        };
    },
    methods:{
        gotoAgentProfilePage: function (item) {
            if (this.permission_check('agent_profile')) {
                // this.$router.push({ name: 'AgentProfile', params: { id: this.hash_id(item.id) } })
                let routeData = this.$router.resolve({name: 'AgentProfile', params: { id: this.hash_id(item.id) }});
                window.open(routeData.href, '_blank');
            }
        },
        gotoAgentWiseStudentListPage: function (item) {
            // console.log('request_type', item.request_type)
            if (item.request_type.code == "as_list_req_dispute") {
                if(this.permission_check('dispute_list')) {
                    if (item.agent_student) {
                        
                        let routeData = this.$router.resolve({ name: 'DisputedStudentListByIntake', query: { 
                            uni_ref_no: item.agent_student.uni_ref_no,
                            list_id: this.hash_id(item.agent_student.list_id),
                            intake_id: this.hash_id(item.agent_student.intake_id),
                        }})

                        window.open(routeData.href, '_blank');
                    } else {
                        
                        let routeData = this.$router.resolve({ name: 'DisputedStudentListByIntake', query: { 
                            list_id: this.hash_id(item.agent_student_list.id),
                            intake_id: this.hash_id(item.agent_student_list.intake_id),
                        }})

                        window.open(routeData.href, '_blank');
                    }
                }
            } else {
                if(this.permission_check('agent_wise_students')) {
                    if (item.agent_student_list) {
                        
                        let routeData = this.$router.resolve({ name: 'AgentWiseStudentList', params: { 
                            id: this.hash_id(item.agent_student_list.id),
                            intake_id: this.hash_id(item.agent_student_list.intake_id),
                            // active_tab: 'one',
                        }})

                        window.open(routeData.href, '_blank');
                    }
                }
            }
        },
        init_data: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/get_as_list_req_init_data');

            try{
                ref.wait_me('.as-list-req');
                var res=await jq.get(url);
                ref.info.list_req_counter=res.data.list_req_counter;
                ref.list.list_req_types=res.data.list_req_types;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.as-list-req', 'hide');
            }

        },
        get_as_list_requests: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/get_as_list_requests');
            
            var qstr=Object.assign(ref.form.comm_filter, {
                page: this.pagination.currentPage
            });

            try{
                ref.wait_me('.as-list-req');
                var res=await jq.get(url, qstr);
                ref.list.as_list_requests=res;
                this.listData = res
                ref.pagination.currentPage=res.current_page;
                ref.pagination.perPage=res.per_page;
                ref.pagination.totalRows=res.total;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.as-list-req', 'hide');
            }

        },
        toggle_list_request_form: function () {

            var ref = this;
            var form = ref.form.list_request;
            ref.flag.show_new_req_form = !ref.flag.show_new_req_form;

            if(!ref.flag.show_new_req_form){
                form.message = "";
                form.list_id = "";
                form.student_id = "";
                form.req_type_id = null;
                ref.form.attachments=[];
            }

        },
        save_list_request: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/save_student_list_request');

            var form_data=ref.form.file_form_data;

            form_data.append(
                'student_id',
                ref.form.list_request.student_id
            );

            form_data.append(
                'message',
                ref.form.list_request.message
            );

            form_data.append(
                'list_id',
                ref.form.list_request.list_id
            );

            form_data.append(
                'req_type_id',
                ref.form.list_request.req_type_id
            );

            ref.wait_me('.sidebar-form');

            jq.ajax({
                url: url,
                data: form_data,
                processData: false,
                contentType: false,
                type: 'POST',
                success: res=>{

                    ref.alert(res.msg);
                    ref.toggle_list_request_form(false);
                    ref.init_data();
                    ref.get_as_list_requests();

                }
            }).fail(
                err=>ref.alert(ref.err_msg(err), 'error')
            ).always(()=>ref.wait_me('.sidebar-form', 'hide'));

            /* try{
                ref.wait_me('.sidebar-form');
                var res=await jq.post(url, ref.form.list_request);
                ref.alert(res.msg);
                ref.close_new_request_form();
                ref.get_student_list_requests();
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.sidebar-form', 'hide');
            } */

        },
        get_list_req_comments: async function(){

            var ref=this;
            var jq=ref.jq();

            var qstr={
                list_request_id: ref.info.active_list_req.id
            }

            var url=ref.url('api/v1/university/ajax/get_student_list_req_comments');

            try{
                ref.list.list_req_comments=[];
                ref.wait_me('.req-comments-section');
                var res=await jq.get(url, qstr);
                ref.list.list_req_comments=res.data.list_req_comments;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.req-comments-section', 'hide');
            }

        },
        toggle_list_req_comments: async function(overwrite=null, row){
            var ref=this;

            if(overwrite===null){        
                ref.flag.show_list_req_comments=!ref.flag.show_list_req_comments;
            }else if(overwrite){
                ref.flag.show_list_req_comments=true;
            }else{
                ref.flag.show_list_req_comments=false;
            }

            if(ref.flag.show_list_req_comments){
                ref.info.active_list_req=row;
                ref.get_list_req_comments()
            }else{
                ref.info.active_list_req={};
                ref.list.list_req_comments=[];
                ref.toggle_comment_form(false);
            }

        },
        toggle_comment_form: function(overwrite=null){

            var ref=this;
            var form=ref.form.list_req_comment;

            if(overwrite===null){        
                form.show_form=!form.show_form;
            }else if(overwrite){
                form.show_form=true;
            }else{
                form.show_form=false;
            }

            if(form.show_form){
                form.list_request_id=ref.info.active_list_req.id;
                form.comment='';
            }else{
                form.list_request_id='';
                form.comment='';
                ref.form.attachments=[];
            }

        },
        save_list_req_comment: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/uclan/ajax/save_student_list_req_comment');

            var form_data=new FormData();

            ref.form.attachments.forEach((row, index)=>{
                form_data.append('attachments['+index+'][file]', row.file);
                form_data.append('attachments['+index+'][remarks]', row.remarks);
            });

            form_data.append(
                'list_request_id',
                ref.form.list_req_comment.list_request_id
            );

            form_data.append(
                'comment',
                ref.form.list_req_comment.comment
            );

            ref.wait_me('.sidebar-form');

            jq.ajax({
                url: url,
                data: form_data,
                processData: false,
                contentType: false,
                type: 'POST',
                success: res=>{

                    ref.alert(res.msg);
                    ref.toggle_comment_form(false);
                    ref.get_list_req_comments();

                }
            }).fail(
                err=>ref.alert(ref.err_msg(err), 'error')
            ).always(()=>ref.wait_me('.sidebar-form', 'hide'));

            /* try{
                ref.wait_me('.sidebar-form');
                var res=await jq.post(url, ref.form.list_req_comment);
                ref.alert(res.msg);
                ref.toggle_comment_form(false);
                ref.get_list_req_comments();
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.sidebar-form', 'hide');
            } */

        },
        update_list_req_status: async function(row){

            var jq=this.jq();

            var swal_confirm = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success mr-1',
                    cancelButton: 'btn btn-light'
                },
                buttonsStyling: false
            });

            var status_open_checked='';
            var status_closed_checked='';

            if(row.status=='Open'){
                status_open_checked="checked";
            }else status_closed_checked="checked";

            var status_note='';

            if(row.status_note){
                status_note=row.status_note;
            }

            var input_html='<div class="d-inline mr-1"><input type="radio" class="mr-25 form-check-input" id="comms_status_open" name="comms_status" value="Open" '+status_open_checked+'/><label class="mr-2 text-warning form-check-label" for="comms_status_open">Open</label></div>';

            input_html+='<div class="d-inline"><input type="radio" class="mr-25 form-check-input" id="comms_status_closed" name="comms_status" value="Closed" '+status_closed_checked+'/><label for="comms_status_closed" class="text-success form-check-label">Closed</label></div>';

            input_html+='<textarea rows="2" type="text" id="comms_status_remarks" class="form-control mt-1" name="status_remarks" placeholder="Status remarks">'+status_note+'</textarea>';

            var { value: formValues } = await swal_confirm.fire({
                title: 'Update communication Status',
                html: input_html,
                /* input: 'radio',
                inputValue: row.status,
                inputOptions: this.list.list_req_statuses, */
                showCancelButton: true,
                /* inputValidator: (value) => {
                    if (!value) {
                        return 'Please select a status'
                    }
                }, */
                preConfirm:()=>{
                    return {
                        status: jq("input[name='comms_status']:checked").val(),
                        status_remarks: document.getElementById('comms_status_remarks').value
                    }
                }
            });

            if(formValues){

                // console.log(formValues);

                var ref=this;
                //var jq=ref.jq();
                var url=ref.url('api/v1/university/ajax/update_list_req_status');

                var form_data={
                    id: row.id,
                    status: formValues.status,
                    status_remarks: formValues.status_remarks
                };

                try{
                    ref.wait_me('.sidebar-form');
                    var res=await jq.post(url, form_data);
                    ref.alert(res.msg);
                    ref.init_data();
                    ref.get_as_list_requests();
                }catch(err){
                    ref.alert(ref.err_msg(err), 'error');
                }finally{
                    ref.wait_me('.sidebar-form', 'hide');
                }

            }

        },
        add_attachment: function(e){

            var ref=this;

            var file_input=e.target.files[0];

            setTimeout(function(){
                e.target.value='';
                ref.form.attachments.push({
                    file: file_input,
                    remarks: ''
                });
            }, 1000);

        },
        remove_attachment: function(index){
            this.remove_row(this.form.attachments, index);
        },
        activate_badge_filter: function(
            type, overwrite=null
        ){

            var ref=this;
            var filter_data=ref.form.comm_filter;

            if(overwrite===null){

                
                //filter_data.active=!filter_data.active;

                filter_data.active=true;
                filter_data.type=type;

                // console.log(ref.form.comm_filter.type);

            }else if(overwrite===true){

                filter_data.active=true;
                filter_data.type=type;

            }else if(overwrite===false){

                filter_data.active=false;
                filter_data.type='';

            }

            ref.get_as_list_requests();

        },
        toggle_change_list_status: function(row){

            //alert('working...');

            var ref=this;
            var form=ref.form.list_req_status;
            var change_status=row.change_status;
            ref.list.as_list_requests.data.forEach(row=>row.change_status=false);

            row.change_status=!change_status;

            if(row.change_status){
                form.id=row.id;
                form.status=row.status;
                form.status_remarks=row.status_note;
            }else{
                form.id='';
                form.status='';
                form.status_remarks='';
            }

        },
        update_list_req_details_status: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/update_list_req_status');

            try{
            ref.wait_me('.sidebar-form');
            var res=await jq.post(url, ref.form.list_req_status);
            ref.alert(res.msg);
            ref.get_as_list_requests();

            if(ref.info.active_list_req){
                ref.info.active_list_req.status=ref.form.list_req_status.status;
                ref.info.active_list_req.status_note=ref.form.list_req_status.status_remarks;
                ref.info.active_list_req.change_status=false;
            }

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.sidebar-form', 'hide');
            }

        },
        download_all_attachment: function(row){

            var urls=[];

            row.attachments.forEach(item=>{
                urls.push(this.url('tmp_aws_media/'+item.file));
            });

            if(row.comments && row.comments.length){
                row.comments.forEach(comment=>{
                    if(comment.attachments && comment.attachments.length){
                        comment.attachments.forEach(comment_attachment=>{
                            urls.push(this.url('tmp_aws_media/'+comment_attachment.file));
                        });                            
                    }
                });
            }

            urls.forEach((row, index)=>{
                window.open(row, "file_"+index);
            });
           
        },
        attachment_exists: function(row){

            var attachment_exists=false;

            row.attachments.forEach(()=>{
                attachment_exists=true;
            });

            if(row.comments && row.comments.length){
                row.comments.forEach(comment=>{
                    if(comment.attachments && comment.attachments.length){
                        comment.attachments.forEach(()=>{
                            attachment_exists=true;
                        });                            
                    }
                });
            }

            return attachment_exists;

        },
        processQueryString: async function () {
            // console.log('this.listData', this.listData)
            const query = this.$route.query
            if (query && query.action == 'communication_request') {
                // this.$refs.stu_reqs.open_stu_reqs(query);
                var ref=this;
                var jq=ref.jq();
                
                var qstr=Object.assign({ id: query.list_request_id });

                try{
                    ref.wait_me('.as-list-req');
                    var res=await jq.get(ref.url('api/v1/uclan/ajax/get_as_list_requests_item_by_id'), qstr);
                    this.toggle_list_req_comments(true, res.data.studentListRequestItem)
                }catch(err){
                    ref.alert(ref.err_msg(err), 'error');
                }
            }
        }
    },
    computed: {
        treeselect_req_types: function(){

            return this.list.list_req_types.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            });

        }
    },
    created: async function(){
        this.setup_urls();
        this.init_data();
    },
    mounted: function(){
        this.get_as_list_requests();
        feather.replace();
        this.processQueryString()
    }
}
</script>

<style scoped>
.comment-block{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}

.attachment-list{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  /* margin-top: 8px; */
  border-radius: 25px;
}

.badge-border{
    border: 2px solid;    
}

.download-attachment{
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  margin-top: 3px;
  margin-right: 5px;
  /* padding: 1px 1px; */
  padding: 1px;
  padding-right: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 16px;
}

.download-comment-attachment{
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-top: 3px;
  margin-left: 5px;
  /* padding: 1px 1px; */
  padding: 1px;
  padding-right: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 16px;
}
</style>
