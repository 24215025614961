<template>
    <div>            
        <sidebar-form
            id="activity_log"
            :show_sidebar="flag.activity_log"
            v-on:toggle-sidebar="show_modal()"
            >
            <div class="mb-1">
                <h5 class="">
                    <span class="align-middle">Activity Log</span>
                </h5>
            </div>
            <div class="flex-grow-1 wait_me_edit_form" >
                <div class="row">
                    <div class="col-md-12" v-if="log_data != null">
                        <ul v-if="log_data.length" class="timeline agent_agreement_history mt-2">
                            <li class="timeline-item" v-for="(aslLog, aslLogIndex) in log_data" :key="aslLogIndex">
                                <span class="timeline-point timeline-point-indicator"></span>
                                <div class="timeline-event">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <h6 v-html="aslLog.action"></h6>
                                        <span class="timeline-event-time" v-html="dNow(aslLog.created_at)"></span>
                                    </div>
                                    <p><span v-html="aslLog.subject"></span> by <b v-if="aslLog.user" v-html="aslLog.user.name"></b></p>
                                </div>
                            </li>
                        </ul>
                        <h5 class="text-center" v-else>Loading ....</h5>
                    </div>
                    <div class="col-md-12" v-else>
                        <h5 class="text-center text-warning"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Found No Activity Log</h5>
                    </div>
                </div>
            </div>
        </sidebar-form>
        
    </div>
</template>

<script>

import feather from 'feather-icons';

import SidebarForm from "../../SidebarForm";

export default{
    name: 'ActivityLog',
    props: ['log_data'],
    components:{
        SidebarForm,
    },
    data:()=>{
        return {
            flag: {
                activity_log: false,
            }
        };
    },
    methods:{
        show_modal: function(){
            this.flag.activity_log = !this.flag.activity_log;
        },
        // showAgentStudentListActiviyLog: async function () {
            
        //     this.toggle_modal_agent_student_list_activity_log()
        //     var ref=this;
        //     var jq=ref.jq();
        //     try {
        //         var res = await jq.get(ref.url('api/v1/university/ajax/get_student_list_activity_log'));
        //         this.agentStudentListActivitylog = res.data.data

        //     } catch (err) {
        //         ref.alert(ref.err_msg(err), 'error');
        //     }

        // },
    },
    computed: {
    },
    created: function(){
        this.setup_urls();
    },
    mounted: async function(){
        feather.replace();
    }
}
</script>