<template>
<section class="logout">
  <main-layout>
      <div class="container">
        <div class="row">
          <div class="col">
            <h3 class="text-center text-primary">
              Logging Out...
            </h3>
          </div>
        </div>
      </div>
  </main-layout>
</section>
</template>

<script>
import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';

export default{
	name: 'Logout',
	components:{
		MainLayout
	},
	data:()=>{
		return {};
	},
	methods:{
		logout: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v1/university/auth/logout');

      try{
          await jq.post(url);
      }catch(err){
          console.log(err);
      }

      localStorage.removeItem('uni_api_token');
			ref.$router.push('/');

		}
	},
	mounted: function(){
		feather.replace();
    this.logout();
	}
}
</script>
