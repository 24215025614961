<template>
  <section class="component-data-list">
    <main-layout>
      <!-- BEGIN: Content-->
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <!-- <h2 class="content-header-title float-left mb-0">Email</h2> -->
              <h5 class="content-header-title float-left mt-50">Email</h5>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
									<li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
									<li class="breadcrumb-item">Settings</li>
                  <li class="breadcrumb-item active">Email Configuration</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <!-- <div class="card">
                        <div class="card-body my-5">
                            <h3 class="text-center text-primary">
                            Coming Soon...
                            </h3>
                        </div>
                    </div> -->

        <div class="card">
          <div class="card-header">
            <h3 class="m-0">
              <span v-html="str_title(form.mail_config.driver_type)"></span>
              Email Configuration
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8 email-config-form">
                <div class="form-row" v-if="form.mail_config.driver_type=='postmark'">
                  <div class="col-6 form-group">
                    <label for=""><b>Email Config Type</b></label>
                    <treeselect
                      v-model="form.mail_config.driver_type"
                      :options="list.mail_driver_types"
                      placeholder="Select email config type"
                      id="country_id"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div
                    v-if="form.mail_config.driver_type == 'postmark'"
                    class="col form-group"
                  >
                    <label for=""><b>Message Stream Name</b></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Insert message stream name"
                      v-model="form.mail_config.postmark_message_stream"
                      :disabled="disabled_email_config_form"
                    />
                  </div>
                  <div class="col form-group">
                    <label for=""><b>Sender Name</b></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Insert sender name"
                      v-model="form.mail_config.mail_from_name"
                      :disabled="disabled_email_config_form"
                    />
                  </div>
                  <div class="col form-group">
                    <label for=""><b>Sender Email Address</b></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Insert sender email address"
                      v-model="form.mail_config.mail_from_email"
                      :disabled="disabled_email_config_form"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-6 form-group">
                    <label for=""><b>Sender Domain</b></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Insert sender domain name"
                      v-model="form.mail_config.sender_domain"
                      :disabled="disabled_email_config_form"
                    />
                  </div>
                  <div class="col-6 form-group">
                    <label for=""><b>Private API Key</b></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Insert API key"
                      v-model="form.mail_config.api_key"
                      :disabled="disabled_email_config_form"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="col form-group text-right">
                    <button v-show="flag.save_form" class="btn btn-sm btn-primary" v-on:click="save_email_config">
                      Save
                    </button>
                    <button v-show="flag.edit_form" class="btn btn-sm btn-light mr-50" v-on:click="init">
                      Cancel
                    </button>
                    <button v-show="flag.edit_form" class="btn btn-sm btn-primary" v-on:click="save_email_config">
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-4 config-summary">
                <div class="form-row">
                  <div class="col" v-if="flag.show_config_summary">
                    <div class="border-bottom pb-50 mb-50">
                      <strong>Configuration Summary</strong>
                      <button v-show="info.mail_config && !flag.edit_form" class="btn btn-sm btn-primary ml-1" v-on:click="edit_email_config()">
                        Edit
                      </button>
                    </div>
                    <div v-if="info.mail_config && info.mail_config.driver_type=='postmark'">
                      Config Type:
                      <strong v-if="cn(info.mail_config, 'driver_type', null)"
                        v-html="str_title(info.mail_config.driver_type)"
                      ></strong>
                      <strong v-else class="text-danger">
                        N/A
                      </strong>
                    </div>
                    <div class="mt-50" v-show="info.mail_config && info.mail_config.driver_type=='postmark'">
                      Message Stream:
                      <strong v-if="cn(info.mail_config, 'postmark_message_stream', null)"
                        v-html="info.mail_config.postmark_message_stream"
                      ></strong>
                      <strong v-else class="text-danger">
                        N/A
                      </strong>
                    </div>
                    <div class="mt-50">
                      Sender Name:
                      <strong v-if="cn(info.mail_config, 'mail_from_name', null)"
                        v-html="info.mail_config.mail_from_name"
                      ></strong>
                      <strong v-else class="text-danger">
                        N/A
                      </strong>
                    </div>
                    <div class="mt-50">
                      Sender Email Address:
                      <strong v-if="cn(info.mail_config, 'mail_from_email', null)"
                        v-html="info.mail_config.mail_from_email"
                      ></strong>
                      <strong v-else class="text-danger">
                        N/A
                      </strong>
                    </div>
                    <div class="mt-50">
                      Sender Domain:
                      <strong v-if="cn(info.mail_config, 'sender_domain', null)"
                        v-html="info.mail_config.sender_domain"
                      ></strong>
                      <strong v-else class="text-danger">
                        N/A
                      </strong>
                    </div>
                    <div class="mt-50">
                      API Key:
                      <strong v-if="cn(info.mail_config, 'api_key', null)"
                        v-html="info.mail_config.api_key"
                      ></strong>
                      <strong v-else class="text-danger">
                        N/A
                      </strong>
                    </div>
                  </div>
                  <div v-else class="col text-center">
                    <span>Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Content-->

      <div class="sidenav-overlay"></div>
      <div class="drag-target"></div>
    </main-layout>
  </section>
</template>

<script>
// import VueUploadAvatar from '../../VueUploadAvatar.vue'
import MainLayout from "../MainLayout.vue";
import feather from "feather-icons";
import Treeselect from "@riophae/vue-treeselect";
// import { VueEditor } from "vue2-editor";
// import swal from 'bootstrap-sweetalert';
// import { EditIcon } from 'vue-feather-icons'

export default {
  name: "EmailConfiguration",
  metaInfo: {
      titleTemplate: '%s | Email Configuration'
  },
  components: {
    MainLayout,
    Treeselect,
    // VueUploadAvatar,
    // VueEditor
    // EditIcon,
  },
  data: () => {
    return {
      info: {
        mail_config: {
          driver_type: null
        },
      },
      list: {
        mail_driver_types: [
          { id: "postmark", label: "Postmark" },
          { id: "mailgun", label: "Mailgun" },
        ],
      },
      flag: {
        show_config_summary: false,
        save_form: false,
        edit_form: false
      },
      form: {
        mail_config: {
          id:'',
          driver_type:'mailgun',
          sender_domain:'',
          api_key:'',
          postmark_message_stream:'',
          mail_from_name:'',
          mail_from_email:''
        },
      },
    };
  },
  methods: {
    init: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v1/uclan/ajax/get_email_config');

      try{
        
        ref.wait_me('.config-summary');
        var res=await jq.get(url);
        ref.info.mail_config=res.data.mail_config;
        
        if(res.data.mail_config){
          ref.form.mail_config=ref.clone(res.data.mail_config);
          ref.flag.save_form=false;
        }else{
          ref.flag.save_form=true;
        }

        ref.flag.edit_form=false;

      }catch(err){
        ref.alert(ref.err_msg(err), 'error');        
      }finally{
        ref.wait_me('.config-summary', 'hide');
        ref.flag.show_config_summary=true;
      }

    },
    save_email_config: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v1/uclan/ajax/save_email_config');

      try{
        
        ref.wait_me('.email-config-form');
        var res=await jq.post(url, ref.form.mail_config);
        ref.alert(res.msg);
        ref.init();

      }catch(err){
        ref.alert(ref.err_msg(err), 'error');        
      }finally{
        ref.wait_me('.email-config-form', 'hide');
        ref.flag.show_config_summary=true;
      }

    },
    edit_email_config: function(){

      this.flag.edit_form=true;

    }
  },
  computed: {

    disabled_email_config_form: function(){

      if(this.flag.save_form) return false;
      else if(this.flag.edit_form)  return false;
      else return true;

    }
    
  },
  created(){
    this.setup_urls();
  },
  mounted: function () {
    feather.replace();
    this.init();
  },
};
</script>

<style scoped>
/* .regional-form-section {
        min-height: 100% !important;
    } */
.email_template p br {
  display: none !important;
}
</style>