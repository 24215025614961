<template>
<section class="user-role">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                        <h5 class="content-header-title float-left mt-50">Settings</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item">Settings</li>
                                <li class="breadcrumb-item active">Course</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card bg-light-gray mb-1" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <treeselect v-model="search.faculty_id" :options="facultyList" id="faculty_id" placeholder="Select faculty" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="Course name">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.short_name" class="form-control" id="short_name" placeholder="Course short name">
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-primary mr-50" @click="searchData()"><i data-feather='search'></i> Search</button>
                        <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Course List</h3>
                        <small @click="getListData()" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + totalRowCount"></small>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_right_sidebar_form()">
                            <i data-feather="plus-square"></i>
                            Add New Course
                        </button>
                        
                        <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light ml-1"><i data-feather='filter'></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive user-role-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Faculty</th>
                                <th>Course Name</th>
                                <th>Course Short Name</th>
                                <th class="text-center" width="205">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + pagination.slOffset"></td>
                                <td>
                                    <span v-html="item.faculty_name"></span>
                                </td>
                                <td>
                                    <span class="d-block" v-html="item.name"></span>
                                    <span v-if="item.course_type_name" class="badge badge-pill badge-light-info" v-html="item.course_type_name"></span>
                                </td>
                                <td>
                                    <span v-html="item.short_name"></span>
                                </td>
                                <td class="text-center">

                                    <button @click="editData(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;">
                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                    </button>                                                

                                    <button @click="deleteData(item, index)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                        <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="5" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                </div>
            </div>
        </div>

        <sidebar-form
            :show_sidebar="flag.show_right_sidebar_form"
            v-on:toggle-sidebar="toggle_right_sidebar_form()"
            title="Add New User Role"
        >
            
            <div class="card-body add-edit-form">
                <h5 class="card-title text-primary" v-if="!form.course.id">Add Course</h5>
                <h5 class="card-title text-primary" v-if="form.course.id">Edit Course</h5>
                <div class="add_user_form">
                    <div class="form-group">
                        <label for="name"><b>Course Name</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.course.name" class="form-control" id="name" placeholder="Enter course name">
                    </div>
                    <div class="form-group">
                        <label for="email"><b>Course Short Name</b> <span class="text-danger"></span></label>
                        <input type="email" v-model="form.course.short_name" class="form-control" id="email" placeholder="Enter course short name">
                    </div>

                    <div class="form-group">
                        <label for="course_type_id"><b>Course Type</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.course.course_type_id" :options="list.course_types" placeholder="Select Type" id="course_type_id" autocomplete="off"/>
                    </div>

                    <div class="form-group">
                        <label for="faculty_id"><b>Faculty</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.course.faculty_id" :options="facultyList" placeholder="Select faculty" id="faculty_id" autocomplete="off"/>
                    </div>
                    
                    <button type="submit" class="btn btn-success pull-right" v-if="!form.course.id" @click.prevent="insertFormData()">Save</button>

                    <button type="submit" class="btn btn-success pull-right" v-if="form.course.id" @click.prevent="updateFormData()">Update</button>

                    <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
                    
                </div>
            </div>
        </sidebar-form>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../../SidebarForm.vue';
import { EditIcon, Trash2Icon, XIcon } from 'vue-feather-icons'
import { BPagination } from 'bootstrap-vue';

// import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'

export default{
    name: 'Course',
    metaInfo: {
        titleTemplate: '%s | Course'
    },
    components:{
        MainLayout,
        Treeselect,
        SidebarForm,
        EditIcon,
        Trash2Icon,
        XIcon,
        BPagination
    },
    data:()=>{
        return {
            saveData: true,
            totalRowCount: 0,
            flag:{
                show_right_sidebar_form:false,
                show_filter:false
            },
            form: {
                course: {
                    id: '',
                    name: '',
                    short_name: '',
                    course_type_id:'',
                    faculty_id: null,
                }
            },
            search: {
                name: '',
                short_name: '',
                faculty_id: null,
            },
            listData: null,
            facultyList:[],
            CourseList:[],
            list:{
                course_types:[]
            }
        };
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
    },
    methods:{
        init: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/uclan/ajax/uni_courses_init_data');

            try{

                var res = await jq.get(url);

                this.facultyList = res.data.faculties;
                this.list.course_types = res.data.course_types;

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        searchData: function() {
            this.getListData()
        },
        ClearSearchData () {
            this.search = {
                name: '',
                short_name: '',
                faculty_id: null,
            }
            this.getListData()
        },
        getListTotalLength: function (listData) {
            if (listData && listData.length) {
                return listData.length
            } else {
                return '0'
            }
        },
        toggle_right_sidebar_form: function(){
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
            this.form.course = {}
        },
        update_reset: function () {
            this.form.course = {};
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
        },
        formClose: function() {
            this.toggle_right_sidebar_form()
        },
        getListData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.user-role-table');
                const params = Object.assign({ page: this.pagination.currentPage, per_page: this.pagination.perPage }, this.search)
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_course_list'), params);
                this.listData = res.data.list.data
                this.totalRowCount = res.data.total_row_count
                this.paginationData(res.data.list)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.user-role-table', 'hide');
            }
        },
        insertFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.add-edit-form');
                var res = await jq.post(ref.url('api/v1/uclan/ajax/store_course_data'), ref.form.course);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add-edit-form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.add-edit-form');
                var res = await jq.post(ref.url('api/v1/uclan/ajax/update_course_data'), ref.form.course);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add-edit-form', 'hide');
            }
        },
        editData: function (item) {
            this.toggle_right_sidebar_form()
            this.saveData = false

            this.form.course = JSON.parse(JSON.stringify(item))
        },
        deleteData: async function (item, index) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function(item, index){

            var ref=this;
            var jq=ref.jq();

            var form_data={
                id: item.id
            }

            try{

                var res = await jq.post(
                    ref.url('api/v1/uclan/ajax/delete_course_data'), form_data
                );

                this.listData.splice(index, 1);
                ref.alert(res.msg);

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        }
    },
    mounted: function(){
        feather.replace();
        this.init();
        this.getListData();
    }
}
</script>
