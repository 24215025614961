<template>
  <section class="BulkStudentImportSummary">
    <main-layout>
      <!-- BEGIN: Content-->
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-left mb-0">
                Bulk Student Import
              </h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/gateway/all-application">
                    All Lists
                    </router-link>
                  </li>
                  <li class="breadcrumb-item" v-html="cn(intake, 'name', 'Loading...')"></li>
                  <li class="breadcrumb-item active">Auto Verification</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <div class="card">
          <div class="card-header pb-50 border-bottom">
            <h3>
              Bulk Verification for
              <span v-html="cn(intake, 'name', 'Loading...')"></span>
              Intake Data
            </h3>

            <router-link class="btn btn-sm btn-light" to="/gateway/all-application">
              Go Back To Gateway List
            </router-link>
          </div>
          <div class="card-body p-1">
            <div class="row summary-section">
              <div class="col py-2 mt-25">

                <h4 class="d-inline">Total Students Found
                  <span class="badge badge-primary" v-html="integer(total_imported)"></span>
                </h4>

                <h4 class="d-inline ml-1">
                  Total Agents Found
                  <span class="badge badge-primary" v-html="integer(related_agents)"></span>
                </h4>

              </div>
              <div class="col actions-section py-2">

                <div class="form-row">
                  <div class="col text-right" v-if="intake">
                    <!-- <router-link
                      :to="'/gateway/bulk-verfication-list/'+hash_id(intake.id)"
                      class="btn btn-primary btn-block"
                    >
                      <i class="fa fa-th-list" aria-hidden="true"></i>
                      Show Data
                    </router-link> -->
                    <!-- <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light mr-50">
                      <i class="fa fa-filter" aria-hidden="true"></i>
                    </button> -->
                    <button class="btn btn-primary mr-50" v-on:click="toggle_show_data()">
                      <i class="fa fa-th-list mr-25" aria-hidden="true"></i>
                      <span v-show="!flag.show_data">Show Data</span>  
                      <span v-show="flag.show_data">Hide Data</span>  
                    </button>
                    <button class="btn btn-danger mr-50" v-on:click="remove_imported_data(true)">
                      <i class="fa fa-trash mr-25" aria-hidden="true"></i>
                      Remove All Data
                    </button>
                    <button class="btn btn-success" v-on:click="verify_bulk_students()">
                      <i class="fa fa-check-square-o" aria-hidden="true"></i>
                      Verify All Data
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div v-if="flag.show_data" class="card mt-1">
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th colspan="7">
                    </th>
                    <th>
                      <button type="button" @click="toggle_filter()" class="btn btn-secondary btn-sm waves-effect waves-float waves-light btn-block">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </th>
                  </tr>
                  <!-- Search form -->
                  <tr id="filter-form" v-if="flag.show_filter">
                    <th colspan="8">
                      <div class="form-row">
                          <div class="col-md-2">
                              <input type="text" v-model="search.agent_name_or_code" class="form-control" placeholder="Agent name or code" autocomplete="off">
                          </div>
                          <div class="col-md-2">
                              <input type="text" v-model="search.uni_ref_no" class="form-control" placeholder="Student ID" autocomplete="off">
                          </div>
                          <div class="col-md-2">
                              <input type="text" v-model="search.student_name" class="form-control" placeholder="Student name" autocomplete="off">
                          </div>
                          <div class="col-md-2">
                              <treeselect v-model="search.course_level" :options="list.course_levels" placeholder="Select Course Level" autocomplete="off"/>
                          </div>

                          <div class="col-md-2">
                              <treeselect v-model="search.verification_status" :options="list.verification_statuses" placeholder="Verification Status" autocomplete="off"/>
                          </div>

                          <div class="col-md-2">
                              <div class="form-row">
                                  <div class="col-md-6">
                                      <button class="btn btn-primary btn-block" @click="searchData()">Search</button>
                                  </div>
                                  <div class="col-md-6">
                                      <button class="btn btn-outline-danger btn-block" @click="clearSearchData()">Clear</button>
                                  </div>
                              </div>
                          </div>

                      </div>
                    </th>
                  </tr>
                  <!-- End of Search form -->
                  <tr>
                    <th width="75px">#</th>
                    <th>Banner Code</th>
                    <th>Student ID</th>
                    <th>Student</th>
                    <th>Intake</th>
                    <!-- <th>University</th> -->
                    <!-- <th>Start Date</th>
                    <th>Added By</th> -->
                    <th>Course Level</th>
                    <th>Course Name</th>
                    <th>Verification status</th>
                  </tr>
                </thead>
                <tbody v-if="listData && listData.length > 0">
                  <tr v-for="(item, index) in listData" :key="index">
                    <td v-html="index + pagination.slOffset"></td>
                    <td>
                      <span v-html="item.agent_ref_no"></span>
                      <i v-if="!item.agent_company_id" class="fa fa-exclamation-triangle text-warning ml-50" v-tooltip="'Invalid Banner Code'"></i>
                    </td>
                    <td v-html="item.uni_ref_no"></td>
                    <td>
                      <span v-if="item.first_name && item.last_name">
                        {{ item.first_name }} {{ item.last_name }}
                      </span>
                      <span v-else-if="item.first_name">
                        {{ item.first_name }}
                      </span>
                      <span v-else-if="item.last_name">
                        {{ item.last_name }}
                      </span>
                    </td>
                    <td>

                      <span v-if="item.intake" v-html="item.intake.name"></span>
                      <i v-else class="fa fa-exclamation-triangle text-warning" v-tooltip="'Invalid Intake'"></i>

                      <i v-if="item.intake_id && item.intake_id!=intake.id" class="fa fa-exclamation-triangle text-warning ml-50" v-tooltip="'Mismatched Intake'"></i>

                    </td>
                    <!-- <td v-html="item.university.name"></td> -->
                    
                    <!-- <td v-html="dDate(item.start_date)"></td> -->
                    <!-- <td v-html="item.added_by.name"></td> -->
                    <td class="course_level">

                      <div v-if="cn(item.course_level, 'name', null)" class="text-left" v-html="item.course_level.name">
                      </div>
                      <div v-else class="text-center">
                        <i class="fa fa-exclamation-triangle text-warning" v-tooltip="'Invalid Course Level'"></i>
                      </div>                   
                      
                    </td>
                    <td class="course_name" v-html="item.subject"></td>
                    <td class="text-center verification-status">
                      <span class="badge badge-pill" :class="sv_class(item)" v-html="cn(item, 'bulk_verification_status.name', 'Unverified')"></span>

                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <th colspan="9" class="text-center">
                      <span v-if="listData === null" >Processing...</span>
                      <span v-else>
                        <i
                          class="fa fa-exclamation-triangle text-danger"
                          aria-hidden="true"
                        ></i>
                        Found no student data.
                      </span>                    
                    </th>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                class="mt-1 mr-1"
                align="right"
                v-model="pagination.currentPage"
                pills
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                v-on:input="getListData()"
              >
              </b-pagination>
            </div>
          </div>
        </div>

      </div>
      <!-- END: Content-->

      <div class="sidenav-overlay"></div>
      <div class="drag-target"></div>
    </main-layout>
  </section>
</template>

<script>
import MainLayout from "./../MainLayout.vue";
import feather from "feather-icons";
import Swal from 'sweetalert2';
import { BPagination } from "bootstrap-vue";
import Treeselect from '@riophae/vue-treeselect';

export default {
  name: "BulkStudentImportSummary",
  components: {
    MainLayout,
    BPagination,
    Treeselect
  },
  data: () => {
    return {
      intake: null,
      ba_log: null,
      total_imported: 0,
      related_agents: 0,
      listData: null,
      flag:{
        show_data:false,
        show_filter: false
      },
      search:{
        agent_name_or_code: '',
        student_name: '',
        uni_ref_no: '',
        course_level: null,
        course_name: '',
        verification_status: null
      },
      list:{
        course_levels:[],
        verification_statuses:[]
      }
    };
  },
  methods: {
    toggle_filter : function(){
        this.flag.show_filter=!this.flag.show_filter;
    },
    clearSearchData: function () {
        this.search = {
            agent_name_or_code: '',
            student_name: '',
            uni_ref_no: '',
            course_level: null,
            course_name: '',
            verification_status: null,
        }
        this.getListData()
    },
    searchData() {
      this.getListData();
      this.init();
    },
    init: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v1/uclan/ajax/get_bulk_student_import_summary');

      var hash_ids=this.hash_id(this.$route.params.import_log_id, false);

      if(!hash_ids && hash_ids.length < 2){
        ref.alert('Unable to import this data please try again.', 'error');
        return;
      }

      const params = Object.assign({}, this.search, { import_log_id: hash_ids[0], intake_id: hash_ids[1] } )

      try{

        var res=await jq.get(url, params);
        ref.intake=res.data.intake;
        ref.bi_log=res.data.bi_log;
        ref.total_imported=res.data.total_imported;
        ref.related_agents=res.data.related_agents;
        ref.list.course_levels=res.data.course_levels;
        
      }catch(err){

        ref.alert(ref.err_msg(err), 'error');
        
      }

    },
    goto_bulk_import:function(){

      this.$router.push({
        name: "BulkVerficationUpload",
        params: { id: this.hash_id(this.intake.id) },
      });

    },
    remove_imported_data: async function(re_upload=false){

			var swal_confirm = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-danger mr-1',
					cancelButton: 'btn btn-light'
				},
				buttonsStyling: false
			});

			var {isConfirmed} = await swal_confirm.fire({
				title: 'Confirmation!',
				text: 'Do you want to remove this imported data.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Remove'
			});

			if(isConfirmed){

				var ref=this;
				var jq=ref.jq();
				var url=ref.url('api/v1/uclan/ajax/remove_imported_bulk_student_data');

				var form_data={
					bi_log_id: this.bi_log.id,
          intake_id: this.intake.id
				};

				try{
					ref.wait_me('.uni-comm-configs');
					var res=await jq.post(url, form_data);
					ref.alert(res.msg);

          if(re_upload) ref.goto_bulk_import();
          else{
            ref.$router.push({
              name: 'AllApplication'
            });
          }

				}catch(err){
					ref.alert(ref.err_msg(err), 'error');
				}finally{
					ref.wait_me('.uni-comm-configs', 'hide');
				}

			}

		},
    verify_bulk_students: async function () {

			var swal_confirm = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-success mr-1',
					cancelButton: 'btn btn-light'
				},
				buttonsStyling: false
			});

			var {isConfirmed} = await swal_confirm.fire({
				title: 'Are you sure you want to auto verify the uploaded data?',
				text: 'This action will take time and can not be reverse or undo. Press Verify to start this operation or Cancel to close.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Verify'
			});

			if(isConfirmed){

        var ref = this;
        var jq = ref.jq();

        ref.wait_me(".summary-section", {
          effect: 'facebook',
          text: 'Bulk verification is in progress..'
        });

        try {
          const params = Object.assign({}, { intake_id: this.intake.id });
          jq.post(
            ref.url(
              "api/v1/uclan/ajax/intake_wise_bulk_agent_student_verify"
            ),
            params
          );
          
          ref.redirect_to_all_lists();
          
        } catch (err) {
          ref.alert(ref.err_msg(err), "error");
        } finally {
          ref.wait_me(".summary-section", "hide");
        }

      }
    },
    getListData: async function () {
      var ref = this;
      var jq = ref.jq();
      try {
        const params = Object.assign(
          this.search,
          {
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage,
            intake_id: this.intake.id,
          }
        );

        ref.wait_me(".wait_me_list_data");
        var res = await jq.get(
          ref.url("api/v1/uclan/ajax/get_bulk_agent_student_list"),
          params
        );
        this.listData = res.data;
        this.paginationData(res);
      } catch (err) {
        ref.alert(ref.err_msg(err), "error");
      } finally {
        ref.wait_me(".wait_me_list_data", "hide");
      }
    },
    toggle_show_data: function(){

      var ref=this;
      ref.flag.show_data=!ref.flag.show_data;

      if(ref.flag.show_data){
        ref.getListData();
      }else ref.listData=null;

    },
    sv_class: function(row){

      var color=this.cn(row, 'bulk_verification_status.parsed_data.color', 'warning');
      return `badge-light-${color}`;

    },
    redirect_to_all_lists: function(){

      Swal.fire({
        title: 'Processing...',
        text: 'This process will take time. Meanwhile you can leave this page or visit other page.',
        showCancelButton: false,
        confirmButtonText: 'Ok',
				customClass: {
					confirmButton: 'btn btn-success',
				},
				buttonsStyling: false
      }).then(()=>{        
        this.$router.push('/gateway/all-application');
      });

    },
    getStatusesByGroup: async function(){

        var ref=this;
        var jq=ref.jq();
        try {
            var res = await jq.get(ref.url('api/v1/university/ajax/get_status_groups_with_statuses'));
            const bulk_verification_student_statuses = res.data.status_groups.find(item => item.code == 'bulk_verification_student_statuses')
            console.log('bulk_verification_student_statuses', bulk_verification_student_statuses)
            this.list.verification_statuses = bulk_verification_student_statuses.statuses.map(item => {
                        return {
                          id: item.id,
                          label: item.name,
                        }
            })
        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
    },
  },
  created: function(){
    this.setup_urls();
    this.init();
  },
  mounted: function () {
    feather.replace();
    this.getStatusesByGroup()
  },
};
</script>

