<template>
  <section class="region-list">
    <main-layout>
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row">
            <div class="col-12">
              <h2 class="content-header-title" v-if="intake">
                Bulk verfication student file upload for
                <span class="text-primary" v-html="intake.name"></span>
                intake
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <!-- Dropzone section start -->
        <section id="dropzone-examples">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    Add Student Data For Auto Verification Through EXCEL File Upload
                  </h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col guideline_div pt-1">
                      <h4 class="card-title">
                        Guidelines:
                        <small>
                          <strong>Please upload all bulk student information belongs to this intake.</strong>
                        </small>
                      </h4>
                      <p class="card-text">
                        1.<a :href="url('static/bulk_agent_student_upload_file_sample_v2.xlsx')" download
                        >
                          Click here to download the sample excel file</a
                        >.
                      </p>
                      <p class="card-text">
                        2. Please don't remove the header section in the excel.
                      </p>
                      <p class="card-text">
                        3. The example data is dummy for your understanding.
                        This is just to guide you how to fill up the form.
                        Please remove that example data.
                      </p>
                      <p class="card-text">4. Fill up the file</p>
                      <p class="card-text">5. Upload the completed file</p>
                    </div>
                    <div class="col dz-section">
                      <button
                        @click="clearDropzone()"
                        id="clear-dropzone"
                        class="btn btn-outline-primary mb-1"
                      >
                        <i data-feather="trash" class="mr-25"></i>
                        <span class="align-middle">Clear Dropzone</span>
                      </button>
                      <router-link class="btn btn-light pull-right" to="/gateway/all-application">
                          Go Back To Gateway List
                      </router-link>
                      <vue-dropzone
                        ref="agent_list_upload_dropzone"
                        class="dropzone dropzone-area"
                        id="dpz-remove-all-thumb"
                        @vdropzone-success="vsuccess"
                        @vdropzone-error="verror"
                        :uploadMultiple="false"
                        :duplicateCheck="false"
                        :useCustomSlot="true"
                        :options="dropzone_configs"
                        v-on:vdropzone-sending="dropzoneSendingEvent"
                      >
                        <!-- <vue-dropzone 
                          ref="agent_list_upload_dropzone"
                          class="dropzone dropzone-area"
                          id="dpz-remove-all-thumb"
                          @vdropzone-success="vsuccess"
                          @vdropzone-error="verror"
                          :uploadMultiple=false
                          :duplicateCheck=false
                          :useCustomSlot=true
                          :options="dropzone_configs"
                        > -->
                        <div class="dropzone-custom-content">
                          <h1 class="dropzone-custom-title text-primary">
                            Drop files here or click to upload.
                          </h1>
                        </div>
                      </vue-dropzone>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main-layout>
  </section>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import MainLayout from "../MainLayout.vue";
import feather from "feather-icons";

export default {
  name: "BulkVerficationUpload",
  components: {
    MainLayout,
    vueDropzone: vue2Dropzone,
  },
  data: () => {
    return {
      intake: null,
      importedList: [],
      dropzone_configs: {},
    };
  },
  computed: {},
  created() {
    this.setup_urls();
    this.dropzoneOptions();
  },
  methods: {
    dropzoneSendingEvent: function (file, xhr, formData) {
      this.wait_me(".dz-section");
      formData.append("intake_id", this.intake.id);

      /* var intake_id = null;
      if (!this.$route.params.id) {
        this.$router.push({ name: "AgentWiseStudentList" });
      } else {
        intake_id = this.hash_id(this.$route.params.id, false)[0];
        if (!intake_id) {
          this.$router.push({ name: "AgentWiseStudentList" });
          this.alert("list argument missing", "error");
        }
      }

      formData.append("intake_id", intake_id);
       */
    },
    dropzoneOptions: function () {
      var ref = this;
      ref.dropzone_configs = {
        //url: ref.url('api/v1/university/ajax/bulk_agent_student_file_upload'),
        url: ref.url("api/v1/uclan/ajax/uni_student_bulk_import"),
        thumbnailWidth: 200,
        addRemoveLinks: true,
        multipleUpload: false,
        maxFiles: 1,
        acceptedFiles: ".xls,.xlsx",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("uni_api_token"),
          "Access-Control-Allow-Origin": "*",
        },
      };
    },
    vsuccess:function(file, res){

      this.alert(res.msg);
      this.$refs.agent_list_upload_dropzone.removeAllFiles();
      this.wait_me(".dz-section", "hide");

      /* this.$router.push({
        name: "BulkVerficationList",
        params:{
          intake_hash_ids: this.$route.params.id
        }
      }); */

      this.$router.push({
        name: "BulkStudentImportSummary",
        params:{
          import_log_id: this.hash_id([
            res.data.import_log_id,
            this.intake.id
          ])
        }
      });

    },
    verror: function (file, response) {
      this.alert(response.msg, "error");
      this.wait_me(".dz-section", "hide");
    },
    clearDropzone: function () {
      this.$refs.agent_list_upload_dropzone.removeAllFiles();
    },
    get_intake_info: async function(){

      var ref=this;
      var intake_id=null;

      if(!ref.$route.params.id) {
        ref.$router.push({ name: "AgentWiseStudentList" });
      } else {
        intake_id = ref.hash_id(ref.$route.params.id, false)[0];
        if (!intake_id) {
          ref.$router.push({ name: "AgentWiseStudentList" });
          ref.alert("list argument missing", "error");
        }
      }

      var jq=ref.jq();
      var url=ref.url('api/v1/university/ajax/bulk_student_upload_intake_info');
      var qstr={
        intake_id: intake_id
      };

      try{

        var res=await jq.get(url, qstr);
        ref.intake=res.data.intake;

      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }

    }
  },
  mounted: function () {
    feather.replace();
    this.get_intake_info();
  },
};
</script>

<style>
@import "../../../static/vuexy/app-assets/vendors/css/file-uploaders/dropzone.min.css";
@import "../../../static/vuexy/app-assets/css/plugins/forms/form-file-uploader.css";
</style>