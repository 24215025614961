<template>
<section class="intake-list">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Email</h2> -->
                        <h5 class="content-header-title float-left mt-50">Email</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
									<li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
									<li class="breadcrumb-item">Settings</li>
                                <li class="breadcrumb-item active">Email Template List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block p-1">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3 class="m-0 mt-50">All Email Template List</h3>
                    </div>
                    <div class="col-6 text-right">
                        <!-- <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="add_new_email_template()">
                            <i data-feather="plus-square"></i>
                            Add new email template
                        </button> -->
                    </div>
                </div>
            </div>

        <b-modal ref="receiver_list_modal">
            <template #modal-title>
                <span class="text-uclan">Target Recipients</span>
            </template>
            <!-- <ul class="list-group">
                <li class="list-group-item" v-for="(receiver, index) in receiverAgentCompanyList" :key="index" v-html="index + 1 + '. ' +receiver.name"></li>
            </ul> -->
            <div class="recipents-badges">
                <span class="badge badge-pill badge-light-info m-25" v-for="(receiver, index) in receiverAgentCompanyList" :key="index">
                    <span @click="gotoAgentProfilePage(receiver)" class="cursor-pointer" v-html="receiver.name"></span>
                </span>
            </div>
            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.receiver_list_modal.hide()">Close</button>
                <!-- <button type="button" class="btn btn-success waves-effect waves-float waves-light" v-on:click="send_invitation_email()">Yes</button> -->
            </template>
        </b-modal>

        <b-modal size="lg" ref="email_template_sample_modal">
            <template #modal-title>
                <span class="text-uclan">Sample View</span>
            </template>
            <h4 v-if="!emailSectionList.length" class="text-center text-danger">
                Please set your email template before sending email
            </h4>
            <div class="form-row">
                <div class="col-12">
                    <div v-if="universityItem" class="text-center mb-2">
                        <img :src="universityItem.logo_url" width="150px" alt="logo">
                    </div>
                </div>
            </div>
            <div class="form-row  justify-content-center mb-1 mt-1" v-for="(row, index) in emailSectionList" :key="index">
                <div class="col-9">
                    <div class="card">
                        <div class="card-body wait_me_insert_form sameple_content">
                            <div class="form-row">
                                <div class="col-12">
                                    <!-- <div v-if="universityItem" class="text-center mb-2">
                                        <img :src="universityItem.logo_url" width="150px" alt="logo">
                                    </div> -->
                                    <!-- <h3 class="text-center title" v-html="row.title"></h3> -->
                                    <h3 class="text-center title" v-if="emailItem.header && index == 0" v-html="emailItem.header"></h3>
                                    <div v-if="row.image_url">
                                        <img :src="row.image_url" width="100%" alt="">
                                    </div>
                                    <p class="mt-1 body_text" v-html="row.body_text"></p>
                                    <div class="text-center" v-if="row.btn">
                                        <a :href="row.url" target="_blank" class="btn btn-outline-primary button" v-html="row.btn"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <!-- <div class="text-center mt-1">
                        <p style="line-height:20px;text-align:center;color:#999;font-family:'open sans',sans-serif;font-size:11px">You are receiving this email at  <a :href="'mailto:' + authUserData.email" v-html="authUserData.email"></a>  because it is officially registered  with the University Finance office</p>
                        <p class="footer_text" v-html="row.footer_text"></p>
                        <div v-if="universityItem" class="text-center mt-2 mb-5">
                            <img :src="universityItem.logo_url" width="150px" alt="logo">
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <div class="text-center mt-1">
                        <p style="line-height:20px;text-align:center;color:#999;font-family:'open sans',sans-serif;font-size:11px">You are receiving this email at  <a :href="'mailto:' + authUserData.email" v-html="authUserData.email"></a>  because it is officially registered  with the University Finance office</p>
                        <p style="line-height:20px;text-align:center;color:#999;font-family:'open sans',sans-serif;font-size:11px; font-weight: bold" class="footer_text" v-if="authUserData.university" v-html="authUserData.university.name + ', ' + authUserData.university.address"></p>
                        <div v-if="universityItem" class="text-center mt-2 mb-5">
                            <img :src="universityItem.logo_url" width="150px" alt="logo">
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.email_template_sample_modal.hide()">Close</button>
                <!-- <button type="button" class="btn btn-success waves-effect waves-float waves-light" v-on:click="send_invitation_email()">Yes</button> -->
            </template>
        </b-modal>
            
            <div class="card-body p-0">
                <div class="table-responsive intake-list-table">
                    <table class="table table-hover table-sm">
                        <thead>
                            <tr>
                                <th width="1%">#</th>
                                <th width="20%"> Template Name</th>
                                <!-- <th>Analytics</th> -->
                                <!-- <th width="30%">Subject</th> -->
                                <th width="5%">Preview</th>
                                <!-- <th>Analytics</th> -->
                                <!-- <th width="10%" class="text-center">Recipients</th> -->
                                <th width="15%" class="text-center">Last modified on</th>
                                <th width="15%" class="text-center">Last modified by</th>
                                <!-- <th>Status</th> -->
                                <th width="10%" class="text-center">Last Sent</th>
                                <th width="20%" class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + 1"></td>
                                <td>
                                    <span v-html="item.campaign_name"></span>
                                </td>
                                <!-- <td>
                                    <span v-html="item.subject"></span>
                                </td> -->
                                <!-- <td class="analytics"></td> -->
                                <td  class="text-center">
                                    <i class="fa fa-eye text-primary" @click="showEmailTemplateSampleModal(item)" v-tooltip="'View Sample'"></i>
                                </td>
                                <!-- <td></td> -->
                                <!-- <td class="text-center">
                                    <span v-html="item.total_receiver"></span>
                                    <i class="fa fa-list ml-1" @click="showAgentCompanyListModal(item)"></i>
                                    
                                </td> -->
                                <td class="text-center">
                                    <span v-if="item.draft_at" v-html="dDate(item.draft_at)"></span>
                                    <span v-else>-</span>

                                    <!-- <small v-if="!item.sent_at" class="badge badge-pill badge-light-primary" @click="send_mail_to_suite_agent_companies(item)">Send Mail <mail-icon size="1.5x" class="custom-class"></mail-icon></small> -->
                                </td>
                                <td class="last_modified_by text-center">
                                    <span v-if="item.updated_by" v-html="item.updated_by.name"></span>
                                </td>
                                <!-- <td>
                                    <span v-if="!item.sent_at && item.draft_at" class="badge badge-pill badge-light-warning">Draft</span>
                                    <span v-if="item.sent_at" class="badge badge-pill badge-light-success">Sent</span>
                                </td> -->
                                <td class="text-center last_sent">
                                    <div v-if="item.sent_at">
                                        <span v-html="dDate(item.sent_at)"></span><br>
                                        <!-- <small class="badge badge-pill badge-light-primary" @click="send_mail_to_suite_agent_companies(item)">Resend Mail <mail-icon size="1.5x" class="custom-class"></mail-icon></small> -->
                                    </div>
                                </td>
                                <td class="text-center">

                                    <!-- <button @click="send_mail_to_suite_agent_companies(item)" v-tooltip="'Send Mail'" class="btn btn-outline-success btn-sm"  style="margin-right: 5px;">
                                        Send
                                        <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                    </button> -->

                                    <button @click="editData(item)" class="btn btn-outline-primary btn-sm"  style="margin-right: 5px;" v-tooltip="'Edit'">
                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                    </button>
                                    <button @click="open_activity_log(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;" v-tooltip="'Activity Log'">
                                        <i class="fa fa-clock-o"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="10" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no email campaigns.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                </div>
            </div>
        </div>

        <activity-log :log_data="activitylogs" ref="activity_log_modal"></activity-log>

    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
// import { EditIcon, Trash2Icon, UserCheckIcon, MailIcon} from 'vue-feather-icons'
import { EditIcon} from 'vue-feather-icons'
import { BPagination, BModal } from 'bootstrap-vue';
// import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'
import swal from 'bootstrap-sweetalert';
import ActivityLog from '../Partials/ActivityLog.vue';

export default{
    name: 'EmailTemplateList',
    metaInfo: {
        titleTemplate: '%s | Email Template List'
    },
    components:{
        MainLayout,
        ActivityLog,
        EditIcon,
        // MailIcon,
        BPagination,
        BModal
    },
    data:()=>{
        return {
            saveData: true,
            emailItem: {},
            universityItem: {},
            emailSectionList: [],
            receiverAgentCompanyList: [],
            flag:{
                show_add_intake_form:false
            },
            listData: null,
            authUserData: {},
            activitylogs: [],
        };
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
    },
    methods:{
        gotoAgentProfilePage: function (item) {
            if (item.agent_company) {
                // let routeData = this.$router.push({ name: 'AgentProfile', params: { id: this.hash_id(item.agent_company.id) } })
                let routeData = this.$router.resolve({name: 'AgentProfile', params: { id: this.hash_id(item.agent_company.id) }});
                    window.open(routeData.href, '_blank');
            }
        },
        showAgentCompanyListModal: function (item) {
            this.receiverAgentCompanyList = item.receivers
            this.$refs.receiver_list_modal.show()
        },
        showEmailTemplateSampleModal: function (item) {
            this.emailItem = item
            this.emailSectionList = item.email_sections
            console.log('emailSectionList', this.emailSectionList)
            this.universityItem = item.university
            this.$refs.email_template_sample_modal.show()
        },
        async searchData () {
            this.getListData()
        },
        getListData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.intake-list-table');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_email_template_list_data'));
                this.listData = res.data.list.data.map(item => {
                    item.filter_query = item.filter_query ? Object.assign({}, JSON.parse(item.filter_query)) : ''
                    return Object.assign({}, item)
                });
                // console.log('this.listData', this.listData)
                this.paginationData(res.data.list)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.intake-list-table', 'hide');
            }
        },
        add_new_email_template: function () {
            this.$router.push('/settings/email-template')
        },
        editData: function (item) {
            // this.$router.push('/settings/marketing-suite')
            this.$router.push({ name: 'EmailTemplateAddAndUpdate', params: { 
                id: this.hash_id(item.id),
            }})
        },
        deleteData: async function (item, index) {
            // swal({
            //     title: "Are you sure to delete?",
            //     // text: "Press Yes to confirm or Cancel",
            //     html: "Press Yes to delete <b>" + this.dMonth(item.intake_month) + "</b>",
            //     type: "warning",
            //     showCancelButton: true,
            //     cancelButtonClass: "btn-light",
            //     confirmButtonClass: "btn-danger",
            //     confirmButtonText: "Yes",
            //     cancelButtonText: "Cancel",
            // }, (isConfirm)=>{
            //     if(!isConfirm) return;
            //     this.deleteDataConfirm(item, index)
            // });

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete this email suite?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function (item, index) {
            var ref=this;
                var jq=ref.jq();
                try {
                    var res = await jq.post(ref.url('api/v1/university/ajax/delete_marketing_suite_data'), item);
                    this.listData.splice(index, 1)
                    ref.alert(res.msg)
                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
        },
        send_mail_to_suite_agent_companies: async function(item){
            

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Send Mail!',
                html: "Do you want to send mail?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.send_mail_to_suite_agent_companies_confirm(item)
                }
            })
        },
        send_mail_to_suite_agent_companies_confirm: async function(item){

            var ref=this;
            var jq=ref.jq();

            ref.wait_me('.table-responsive');

            try {
                const params = Object.assign({}, item );
                var response = await jq.post(ref.url('api/v1/uclan/ajax/send_mail_to_marketing_suite_receivers'), params);
                this.getListData();
                swal("Marketing Suite Email Send!", response.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.table-responsive', 'hide');
            }

        },
        getAuthUserDataData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_auth_user_info_with_univesity_data'));
                this.authUserData = res.data.auth_user
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        open_activity_log: async function (item) {
            var ref=this;
            var jq=ref.jq();
            this.activitylogs = []
            this.$refs.activity_log_modal.show_modal()

            console.log('item', item)

            try {
                const params = { id: item.id }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_email_template_activity_log'), params);
                if (res.data.data.length) {
                    this.activitylogs = res.data.data
                } else {
                    this.activitylogs = null
                }

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
    },
    mounted: function(){
        feather.replace();
        this.getListData();
        this.getAuthUserDataData()
    }
}
</script>

<style>
.recipents-badges{
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 3px;
}
.sameple_content {

}
.sameple_content .title {
    padding: 30px;
    background: #F0F0FB;
    color: #000;
    margin: 0;
    font-size: 16px;
}
.sameple_content .body_text {
    margin-bottom: 60px;
}
.sameple_content .button {
    display:inline-block;
    background:#ffffff;
    color:#047eaf;
    font-family:Ubuntu, Helvetica, Arial, sans-serif;
    font-size:22px;
    font-weight:normal;
    line-height:120%;
    margin:0;
    text-decoration:none;
    text-transform:none;
    padding:10px 25px;
    border-radius:25px;
}
.sameple_content .footer_text {
    font-family:Ubuntu, Helvetica, Arial, sans-serif;
    line-height:20px;
    text-align:center;
    color:#999;
    font-family:'open sans',
    sans-serif;
    font-size:11px;
    font-weight:bold;
}
</style>
