<template>
    <main-layout>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-8 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Gateway</h2> -->
                        <h5 class="content-header-title float-left mt-50">Report</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active">Report List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-body">
            <section class="app-user-list">
            <section class="modern-horizontal-wizard">
                <div class="bs-stepper wizard-modern modern-wizard-example">
                    <div class="bs-stepper-header">
                        <div class="step ulan_custom_steper" :class="[isActive('one') ? activeClass : '', errorClass]">
                            <button @click="setActive('one')" type="button" class="step-trigger">
                                <span class="bs-stepper-box">Territory Wise</span>
                            </button>
                        </div>
                        <div class="step ulan_custom_steper" :class="[isActive('two') ? activeClass : '', errorClass]">
                            <button  @click="setActive('two')" type="button" class="step-trigger">
                                <span class="bs-stepper-box">Country Wise</span>
                            </button>
                        </div>
                        <div class="step ulan_custom_steper" :class="[isActive('three') ? activeClass : '', errorClass]">
                            <button  @click="setActive('three')" class="step-trigger">
                                <span class="bs-stepper-box">Agent Wise</span>
                            </button>
                        </div>
                    </div>
                    <!-- <div class="bs-stepper-content"> -->
                    <div class="">
                        <div id="territory-wise-report" class="content" :class="{ active: isActive('one') }"> 
                            <territory-wise-report ref="territory_wise_report_ref"/>
                        </div>

                        <div id="country-wise-report" class="content" :class="{ active: isActive('two') }">
                            <country-wise-report ref="country_wise_report_ref"/>
                        </div>

                        <div id="agent-wise-report" class="content" :class="{ active: isActive('three') }">
                            <agent-wise-report ref="agent_wise_report_ref"/>
                        </div>
                    </div>
                </div>
            </section>
            </section>
        </div>

    </main-layout>
</template>

<script>
import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';


import AgentWiseReport from './AgentWiseReport.vue';
import CountryWiseReport from './CountryWiseReport.vue';
import TerritoryWiseReport from './TerritoryWiseReport.vue';

export default{
    name: 'ReportList',
    metaInfo: {
        titleTemplate: '%s | Report List'
    },
    components:{
        MainLayout,
        AgentWiseReport,
        CountryWiseReport,
        TerritoryWiseReport
    },
    data:()=>{
        return {
            activeClass: 'border_bottom_5',
            errorClass: 'text-danger',
            activeItem: 'one',
        };
    },
    watch: {
    },
    methods:{
        setActive: function (menuItem) {
            this.activeItem = menuItem // no need for Vue.set()
            if (menuItem == 'one') {
                this.$refs.agent_wise_report_ref.getListData();
            } else if (menuItem == 'two') {
                this.$refs.country_wise_report_ref.getListData();
            } else if (menuItem == 'three') {
                this.$refs.territory_wise_report_ref.getListData();
            }
        },
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
    },
    mounted: function(){
        feather.replace();
    }
}
</script>
<style type="text/css">
    .badge.badge-light-blue {
        background-color: rgba(51, 51, 255, 0.12);
        color: #3333ff !important;
    }        
    .badge.badge-light-declined {
        background-color: rgba(255, 51, 0, 0.12);
        color: #ff3300 !important;
    }
    .table th, .table td {
         padding: 0.72rem 0rem; 
        vertical-align: middle;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.486rem .7rem !important; 
    }
    .avater_size_25 {
        width: 25px !important;
        height: 25px !important;
        font-size: 10px !important;
        font-weight: 700 !important;
    }
    /* .ulan_custom_steper {
        padding: 8px !important;
    }
    .ulan_custom_steper, .active {
        border-bottom: 4px solid rgb(4, 126, 175) !important;
    } */

    .bs-stepper .bs-stepper-header .ulan_custom_steper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.35rem !important;
        margin-right: 2px !important;
    }
    .border_bottom_5 {
        border-bottom: 5px solid rgb(0, 128, 160);
        color: #ff0000 !important;
    }
    .ulan_custom_steper .bs-stepper-box {
        min-width: 38px !important;
        min-height: 38px !important;
        width: auto !important;
        height: auto !important;
        padding: 8px !important;
        font-weight: 800 !important;
        background-color: rgb(108 108 108 / 12%) !important;
        color: #606060 !important;
    }
    .border_bottom_5 > button > span.bs-stepper-box {
        color: rgb(0, 128, 160) !important;
    }
    
</style>
