<template>
<section class="dashboard">
    <main-layout>
		<div class="row mb-1">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<!-- <div class="col-sm-5 mx-auto">
								<div class="row text-center">
									<label for="intake_id" class="col-sm-3 col-form-label">Intake Filter</label>
									<div class="col-sm-9">
										<treeselect v-model="search.intake_id" :options="treeselectIntakeList" @change="getDataByIntake()" id="intake_id" placeholder="Select intake" autocomplete="off"/>
									</div>
								</div>
							</div> -->
							<div class="col" v-if="intakeItem">
								<p class="text-center">Showing data for {{ intakeItem.name }} Intake. You can change Intake from the settings icon.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

        <div class="row match-height wait_me" style="margin-bottom:25px;">
            <!-- Agents Statistics Card -->
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card earnings-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-7">
                                <h4 class="card-title mb-1">Agents Statistics</h4>
                                <div class="font-small-2">Total Agent</div>
                                <h5 class="mb-1" v-html="cn(info, 'total_agents', 0)"></h5>
                                <p class="card-text text-muted font-small-2">
                                    <span class="font-weight-bolder" v-html="cn(info, 'newly_signed_agents', 0)">7</span><span> agents joined recently</span>
                                </p>
                            </div>
                            <div class="col-5 text-center">
                                <vue-apex-charts id="earnings-chart" type="donut" :options="agent_statistics.options" :series="agent_statistics.series"></vue-apex-charts>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/ Agents Statistics Card -->

            <!-- Intake Period Card -->
            <div class="col-xl-8 col-md-6 col-12">
                <div class="card card-statistics">
					<div class="row mt-2" v-if="flag.show_filter">
						<div class="col-sm-12 mx-auto">
							<div class="row text-center">
								<label for="intake_id" class="col-sm-3 col-form-label">Default Intake</label>
								<div class="col-sm-6">
									<treeselect v-model="search.intake_id" :options="treeselectIntakeList" id="intake_id" placeholder="Select intake" autocomplete="off"/>
								</div>
								<!-- <button class="btn btn-primary btn-sm waves-effect waves-float waves-light" @click="getDataByIntake()">Set Default</button> -->
								<button class="btn btn-primary btn-sm waves-effect waves-float waves-light" @click="setDefaultUniIntake()">Set Default</button>
							</div>
						</div>
					</div>

                    <div class="card-header">
						<h4 class="card-title" v-if="intakeItem" v-html="intakeItem.name"></h4>
						<!-- <div class="d-flex align-items-center">
								<p class="card-text font-small-2 mr-25 mb-0">
									Intake Period: {{ intakeItem ? dDate(intakeItem.start_date) : '' }} - {{ intakeItem ? dDate(intakeItem.end_date) : '' }}
								</p>
						</div> -->
						<button type="button" @click="toggle_filter()" v-tooltip="'Set Default Intake'" class="btn btn-primary btn-sm waves-effect waves-float waves-light"><i class="fa fa-cog"></i></button>
                    </div>
                    <div class="card-body statistics-body">
                        <div class="row">
                            <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-primary mr-2">
                                        <div class="avatar-content">
                                            <i data-feather="inbox" class="avatar-icon"></i>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="submission_statistics.agents_submitted"></h4>
                                        <p class="card-text font-small-3 mb-0">Agents Submitted</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-primary mr-2">
                                        <div class="avatar-content">
                                            <i data-feather="inbox" class="avatar-icon"></i>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="submission_statistics.agents_no_issue"></h4>
                                        <p class="card-text font-small-3 mb-0">No Issue Agents</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-info mr-2">
                                        <div class="avatar-content">
                                            <i data-feather="clock" class="avatar-icon"></i>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="submission_statistics.agent_due_to_submit"></h4>
                                        <p class="card-text font-small-3 mb-0">Agents Due to Submit</p>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-xl-3 col-sm-6 col-12 mb-2 mb-sm-0">
                                <div class="media">
                                    <div class="avatar bg-light-danger mr-2">
                                        <div class="avatar-content">
                                            <i data-feather="users" class="avatar-icon"></i>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="submission_statistics.students_submitted"></h4>
                                        <p class="card-text font-small-3 mb-0">Students Submitted</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-sm-6 col-12">
                                <div class="media">
                                    <div class="avatar bg-light-success mr-2">
                                        <div class="avatar-content">
                                            <i data-feather="thermometer" class="avatar-icon"></i>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <!-- <h4 class="font-weight-bolder mb-0">${{ submission_statistics.projected_commission }}</h4> -->
                                        <h4 class="font-weight-bolder mb-0">£ {{ integer(autoVerifyData.projected_commission) }}</h4>
                                        <p class="card-text font-small-3 mb-0">Projected Commission</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/ Intake Period Card -->
        </div>

        <div class="row match-height wait_me" style="margin-bottom:25px;">

            <!-- Io, FO, Cl, WI Card -->
            <div class="col-xl-12 col-md-12 col-12">
                <div class="card card-statistics">
					<!-- <div class="card-header">
						<div>
							<h4 class="card-title">Students' Statistics</h4>
							<p class="card-text font-small-2" v-if="intakeItem" v-html="intakeItem.name"></p>
						</div>
					</div> -->
                    <div class="card-body statistics-body">
						<div class="row">
							<div class="col-12">
								<h4 class="card-title p-0">Students' Statistics <br/><span class="font-small-2" v-if="intakeItem" v-html="intakeItem.name"></span></h4>
							</div>
						</div>
                        <div class="row">
                            <div class="col-xl-2 col-sm-6 col-12 mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-secodary mr-2">
                                        <div class="avatar-content" v-tooltip="'Working on International Office'">
                                            <!-- <i data-feather="inbox" class="avatar-icon"></i> -->
											<b class="text-dark font-size-15">IO</b>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="integer(agentStudentList.total_io_students)"></h4>
                                        <!-- <h4 class="font-weight-bolder mb-0" v-html="'9,999'"></h4> -->
                                        <!-- <h4 class="font-weight-bolder mb-0" v-html="agentStudentList.total_io_status +' Lists ' +agentStudentList.total_io_students +' Students'"></h4> -->
                                        <!-- <p class="card-text font-small-3 mb-0">Total <span class="font-weight-bolder" v-tooltip="'Working on International Office'">{{ agentStudentList.total_io_students }} IO</span> Students</p> -->
                                        <!-- <p class="card-text font-small-3 mb-0">Total <span class="font-weight-bolder" v-tooltip="'Working on International Office'">{{ agentStudentList.total_io_status }}</span></p> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-sm-6 col-12 mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-warning mr-2">
                                        <div class="avatar-content" v-tooltip="'Working on Finance Office'">
                                            <!-- <i data-feather="clock" class="avatar-icon"></i> -->
											<b class="font-size-15">FO</b>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="integer(agentStudentList.total_fo_students)"></h4>
                                        <!-- <h4 class="font-weight-bolder mb-0" v-html="agentStudentList.total_fo_status"></h4> -->
                                        <!-- <p class="card-text font-small-3 mb-0">Total <span class="font-weight-bolder" v-tooltip="'Working on Finance Office'">{{ agentStudentList.total_fo_students }} FO</span> Students</p> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-sm-6 col-12  mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-success mr-2">
                                        <div class="avatar-content" v-tooltip="'Preparing Confirmed List'">
                                            <!-- <i data-feather="users" class="avatar-icon"></i> -->
											<b class="font-size-15">CL</b>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="integer(agentStudentList.total_cl_students)"></h4>
                                        <!-- <h4 class="font-weight-bolder mb-0" v-html="agentStudentList.total_cl_status"></h4> -->
                                        <!-- <p class="card-text font-small-3 mb-0">Total <span class="font-weight-bolder" v-tooltip="'Preparing Confirmed List'">{{ agentStudentList.total_cl_students }} CL</span> Students</p> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-sm-6 col-12  mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-success mr-2">
                                        <div class="avatar-content" v-tooltip="'Waiting for Invoice'">
                                            <!-- <i data-feather="thermometer" class="avatar-icon"></i> -->
											<b class="font-size-15">WI</b>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="integer(agentStudentList.total_in_students)"></h4>
                                        <!-- <h4 class="font-weight-bolder mb-0" v-html="agentStudentList.total_in_status"></h4> -->
                                        <!-- <p class="card-text font-small-3 mb-0">Total <span class="font-weight-bolder" v-tooltip="'Waiting for Invoice'">{{ agentStudentList.total_in_students }} WI</span> Students</p> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-2 col-sm-6 col-12  mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-danger mr-2">
                                        <div class="avatar-content" v-tooltip="'Invoice Received'">
                                            <!-- <i data-feather="thermometer" class="avatar-icon"></i> -->
											<b class="font-size-15">IR</b>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="integer(agentStudentList.total_ir_students)"></h4>
                                        <!-- <h4 class="font-weight-bolder mb-0" v-html="agentStudentList.total_ir_status"></h4> -->
                                        <!-- <p class="card-text font-small-3 mb-0">Total <span class="font-weight-bolder" v-tooltip="'Invoice Received'">{{ agentStudentList.total_ir_students }} IR</span> Students</p> -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-2 col-sm-6 col-12 mb-2 mb-xl-0">
                                <div class="media">
                                    <div class="avatar bg-light-blue mr-2">
                                        <div class="avatar-content" v-tooltip="'Invoice Paid'">
                                            <!-- <i data-feather="thermometer" class="avatar-icon"></i> -->
											<b class="font-size-15">IP</b>
                                        </div>
                                    </div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0" v-html="integer(agentStudentList.total_ip_students)"></h4>
                                        <!-- <h4 class="font-weight-bolder mb-0" v-html="agentStudentList.total_ip_status"></h4> -->
                                        <!-- <p class="card-text font-small-3 mb-0">Total <span class="font-weight-bolder" v-tooltip="'Invoice Paid'">{{ agentStudentList.total_ip_students }} IP</span> Students</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/ Io, FO, Cl, WI Card -->
        </div>

        <div class="row match-height wait_me">
        <!-- Gateway Statistics -->
        <div class="col-lg-4 col-md-6 col-12">
            <div class="card card-browser-states">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Gateway Statistics</h4>
                        <p class="card-text font-small-2 font-weight-bold" v-if="intakeItem" v-html="intakeItem.name"></p>
                    </div>
										<!-- <b-dropdown  variant="link" toggle-class="text-decoration-none" no-caret size="sm">
											<template #button-content>
												<i data-feather="more-vertical" class="font-medium-3 cursor-pointer"></i>
											</template>
											<b-dropdown-item v-for="row in info.g_statistics_periods" href="javascript:void(0);" :key="row.id" :active="row.active">{{ row.label }}</b-dropdown-item>
										</b-dropdown> -->

										
                    <!-- <div class="dropdown chart-dropdown">
                        <i data-feather="more-vertical" class="font-medium-3 cursor-pointer" data-toggle="dropdown"></i>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0);">Last 28 Days</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                            <a class="dropdown-item" href="javascript:void(0);">Last Year</a>
                        </div>
                    </div> -->
                </div>
                <div class="card-body">
                    <div class="browser-states" v-for="(row, index) in gateway_statistics" :key="index">
                        <div class="media"> 
                            <h6 class="align-self-center mb-0" v-html="row.label"></h6>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <div class="font-weight-bold text-body-heading mr-1" v-html="row.series+'%'"></div>
                                <vue-apex-charts width="40" type="radialBar" :options="row.options" :series="[row.series]"></vue-apex-charts>
                        </div>
                    </div>
                    <!-- <div class="browser-states">
                        <div class="media"> 
                            <h6 class="align-self-center mb-0">Dispute</h6>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <div class="font-weight-bold text-body-heading mr-1">6.1%</div>
                            <vue-apex-charts width="40" type="radialBar" :options="gateway_statistics.options" :series="gateway_statistics.series"></vue-apex-charts>
                        </div>
                    </div>
                    <div class="browser-states">
                        <div class="media"> 
                            <h6 class="align-self-center mb-0">On Hold</h6>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <div class="font-weight-bold text-body-heading mr-1">14.6%</div>
                            <vue-apex-charts width="40" type="radialBar" :options="gateway_statistics.options" :series="gateway_statistics.series"></vue-apex-charts>
                        </div>
                    </div>
                    <div class="browser-states">
                        <div class="media"> 
                            <h6 class="align-self-center mb-0">Declined</h6>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <div class="font-weight-bold text-body-heading mr-1">4.2%</div>
                            <vue-apex-charts width="40" type="radialBar" :options="gateway_statistics.options" :series="gateway_statistics.series"></vue-apex-charts>
                        </div>
                    </div>
                    <div class="browser-states">
                        <div class="media"> 
                            <h6 class="align-self-center mb-0">Approved</h6>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <div class="font-weight-bold text-body-heading mr-1">8%</div>
                            <vue-apex-charts width="40" type="radialBar" :options="gateway_statistics.options" :series="gateway_statistics.series"></vue-apex-charts>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!--/ Gateway Statistics -->
            <!-- Auto Verify -->
        <div class="col-lg-4 col-md-6 col-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
					<div>
						<h4 class="card-title">Auto Verify</h4>
						<p class="card-text font-small-2 font-weight-bold" v-if="intakeItem" v-html="intakeItem.name"></p>
					</div>
                    <i data-feather="help-circle" class="font-medium-3 text-muted cursor-pointer"></i>
                </div>
                <div class="card-body p-0">
                    <!-- <div id="goal-overview-radial-bar-chart" class="my-2"></div> -->
                    <vue-apex-charts height="245" type="radialBar" :options="auto_verified_statistics.options" :series="auto_verified_statistics.series"></vue-apex-charts>
                    <div class="row border-top text-center mx-0">
                        <div class="col-6 border-right py-1">
                            <p class="card-text text-muted mb-0">Total Submitted</p>
                            <h3 class="font-weight-bolder mb-0" v-html="autoVerifyData.total_submitted_count">16,339</h3>
                        </div>
                        <div class="col-6 py-1">
                            <p class="card-text text-muted mb-0">Auto Verified</p>
                            <h3 class="font-weight-bolder mb-0" v-html="autoVerifyData.total_verify_count">13,561</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Auto Verify -->
        <!-- Conversion -->
        <div class="col-lg-4 col-md-6 col-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
					<div>
						<h4 class="card-title">Conversion</h4>
						<p class="card-text font-small-2 font-weight-bold" v-if="intakeItem" v-html="intakeItem.name"></p>
					</div>
                    <i data-feather="help-circle" class="font-medium-3 text-muted cursor-pointer"></i>
                </div>
                <div class="card-body p-0">
                    <vue-apex-charts height="245" type="radialBar" :options="conversion_statistics.options" :series="conversion_statistics.series"></vue-apex-charts>
                    <div class="row border-top text-center mx-0">
                        <div class="col-6 border-right py-1">
                            <p class="card-text text-muted mb-0">Total Submitted</p>
                            <h3 class="font-weight-bolder mb-0" v-html="autoVerifyData.total_submitted_count">16,339</h3>
                        </div>
                        <div class="col-6 py-1">
                            <p class="card-text text-muted mb-0">Approved</p>
                            <h3 class="font-weight-bolder mb-0" v-html="conversionData.total_approved_count">13,561</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Auto Verify -->
        </div>
				
    </main-layout>
</section>
</template>

<script>

import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
import VueApexCharts from 'vue-apexcharts';
// import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';

export default{
	name: 'Dashbaord',
    metaInfo: {
        titleTemplate: '%s | Dashbaord'
    },
	components:{
		MainLayout,
		VueApexCharts,
		// BDropdown, BDropdownItem,
		Treeselect
	},
	data:()=>{
		return {
            windowWidth: window.innerHeight,
			treeselectIntakeList: [],
			flag: {
				show_filter: false
			},
			search: {
				intake_id: null,
			},
			intakeItem: {},
			autoVerifyData: {},
			conversionData: {},
			info:{
				total_agents:0,
				newly_signed_agents:0,
				g_statistics_periods:[
					{id:'last_30_days', label:'Last 30 Days', active: true},
					{id:'last_month', label:'Last Month', active: false},
					{id:'last_year', label:'Last Year', active: false}
				]
			},
			submission_statistics:{
				agents_submitted: 0,
				agents_no_issue: 0,
				agent_due_to_submit: 0,
				students_submitted: 0,
				projected_commission: 0
			},
			agentStudentList:{},
			agent_statistics:{
				options:{
					toolbar: {
						show: false
					},
					dataLabels: {
					enabled: false
					},
					plotOptions: {
						pie: {
							startAngle: -10,
							donut: {
								labels: {
									show: true,
									name: {
										offsetY: 15
									},
									value: {
										offsetY: -15,
										formatter: function (val, m) {
											
											var total=0;

											m.config.series.forEach(row_val=>{
												total+=row_val;
											});

											if(total){
												return parseInt((val/total)*100)+'%';
											}else{
												return '0%';
											}

										}
									},
									total: {
										show: true,
										offsetY: 15,
										label: 'Active',
										formatter: function(m){

											var total=0;
											var active=0;

											m.config.series.forEach(val=>{
												total+=val;
											});

											active=m.config.series[0];

											if(active){
												return parseInt((active/total)*100)+'%';
											}else{
												return '0%';
											}

										}
									}
								}
							}
						}
					},
					/* responsive: [
						{
							breakpoint: 1325,
							options: {
								chart: {
									height: 100
								}
							}
						},
						{
							breakpoint: 1200,
							options: {
								chart: {
									height: 120
								}
							}
						},
						{
							breakpoint: 1045,
							options: {
								chart: {
									height: 100
								}
							}
						},
						{
							breakpoint: 992,
							options: {
								chart: {
									height: 120
								}
							}
						}
					], */
					legend: { show: false },
					comparedResult: [2, -3, 8],
					labels: ['Active', 'Inactive'],
					stroke: { width: 0 },
					colors: ['#28c76f', '#efd30d'],
					grid: {
						padding: {
							right: -20,
							bottom: -8,
							left: -20
						}
					}
				},
				series: [0, 0]
			},
			gateway_statistics: [],
			auto_verified_statistics:{
				options:{
					chart:{
						sparkline: {
							enabled: true
						},
						dropShadow: {
							enabled: true,
							blur: 3,
							left: 1,
							top: 1,
							opacity: 0.1
						}
					},
					plotOptions: {
							radialBar: {
								offsetY: -10,
								startAngle: -150,
								endAngle: 150,
								hollow: {
										//margin: 15,
										size: "77%"
								},
								track: {
									background: '#ebe9f1',
									strokeWidth: '50%'
								},
								dataLabels: {
									name: {
										show: false
									},
									value: {
										color: '#5e5873',
										fontSize: '2.86rem',
										fontWeight: '600'
									}
								}
							}
					},
					grid: {
						padding: {
							bottom: 30
						}
					},
					stroke: {
							lineCap: "round",
					},
					fill: {
						type: 'gradient',
						gradient: {
							shade: 'dark',
							type: 'horizontal',
							shadeIntensity: 0.5,
							gradientToColors: ['#28c76f'],
							inverseColors: true,
							opacityFrom: 1,
							opacityTo: 1,
							stops: [0, 100]
						}
					},
					labels: ["Auto Verify"],
				},
				series: [55]
			},
			conversion_statistics:{
					
				options:{
					chart:{
						sparkline: {
							enabled: true
						},
						dropShadow: {
							enabled: true,
							blur: 3,
							left: 1,
							top: 1,
							opacity: 0.1
						}
					},
					plotOptions: {
							radialBar: {
								offsetY: -10,
								startAngle: -150,
								endAngle: 150,
								hollow: {
										//margin: 15,
										size: "77%"
								},
								track: {
									background: '#ebe9f1',
									strokeWidth: '50%'
								},
								dataLabels: {
									name: {
										show: false
									},
									value: {
										color: '#5e5873',
										fontSize: '2.86rem',
										fontWeight: '600'
									}
								}
							}
					},
					grid: {
						padding: {
							bottom: 30
						}
					},
					stroke: {
							lineCap: "round",
					},
					fill: {
						type: 'gradient',
						gradient: {
							shade: 'dark',
							type: 'horizontal',
							shadeIntensity: 0.5,
							gradientToColors: ['#28c76f'],
							inverseColors: true,
							opacityFrom: 1,
							opacityTo: 1,
							stops: [0, 100]
						}
					},
					labels: ["Auto Verify"],
				},
				series: [80]

			}
		};
	},
	watch: {
		// 'search.intake_id': function(intake_id) {
		// 	this.getDataByIntake(intake_id)
		// }
	},
	methods:{
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
		getDataByIntake: async function(intake_id) {
			// const ObjectIntake = this.intakeList.find(item => item.id == intake_id)
			let ObjectIntake
			if (this.intakeList && this.intakeList.length) {
				ObjectIntake = this.intakeList.find(item => item.id == intake_id)
			}
			this.intakeItem = ObjectIntake ? ObjectIntake : {}
			this.dashboard_init_data(intake_id)
			this.dashboard_gateway_statistics(intake_id)
			this.getAutoVerifyStudentData(intake_id)
		},
		dashboard_init_data: async function(intake_id){

			var ref=this;
			var jq=ref.jq();
			var url=ref.url('api/v1/university/ajax/dashboard_init_data');

			try{
				const params = { intake_id: intake_id}
				var res=await jq.get(url, params);
				ref.info.total_agents=res.data.total_agents;
				ref.info.newly_signed_agents=res.data.newly_signed_agents;
				ref.agent_statistics.series=res.data.agent_statistics_series;
				ref.submission_statistics=res.data.submission_statistics;
				ref.agentStudentList=res.data.agentStudentList;
			}catch(error){
				console.log(error);
			}

		},
		dashboard_gateway_statistics: async function(intake_id){

			var ref=this;
			var jq=ref.jq();
			var url=ref.url('api/v1/university/ajax/dashboard_gateway_statistics');

			try{
				const params = { intake_id: intake_id}
				var res=await jq.get(url, params);
				ref.gateway_statistics=res.data.gateway_statistics
				// console.log('ref.gateway_statistics', ref.gateway_statistics)
			}catch(error){
				console.log(error);
			}

		},
        getIntakeList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_intake_list'));
				if (res.data.list) {
					this.intakeList = res.data.list
					this.treeselectIntakeList = res.data.list.map(item => {
						return {
							id: item.id,
							label: item.name
						}
					})
					if (this.treeselectIntakeList && this.treeselectIntakeList.length) {
						this.search.intake_id = this.treeselectIntakeList[0].id
					}
				}

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getAutoVerifyStudentData: async function(intake_id){

            var ref=this;
            var jq=ref.jq();
            try {
				ref.wait_me(".wait_me");
				const params = { intake_id: intake_id}
                var res = await jq.get(ref.url('api/v1/university/ajax/get_auto_verify_student_data_by_intake'), params);
				this.autoVerifyData = res.data.auto_verify_data
				this.auto_verified_statistics.series = [res.data.auto_verify_data.total_verify_percentage]
				this.conversionData = res.data.conversion_data
				this.conversion_statistics.series = [res.data.conversion_data.total_approved_percentage]
				// console.log('Res', res)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            } finally {
				ref.wait_me(".wait_me", "hide");
			}
        },
        getDefaultIntake: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
				ref.wait_me(".wait_me");
                var res = await jq.get(ref.url('api/v1/university/ajax/get_default_intake_by_auth_user'));
				if (res.data.intake) {
					this.search.intake_id = res.data.intake.intake_id
				}
				this.getDataByIntake(this.search.intake_id)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            } finally {
				ref.wait_me(".wait_me", "hide");
			}
        },
        setDefaultUniIntake: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
				ref.wait_me(".wait_me");
                var res = await jq.post(ref.url('api/v1/university/ajax/set_defualt_uni_intake_for_user'), this.search);
				this.getDefaultIntake()
				this.toggle_filter()
				ref.alert(res.msg)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            } finally {
				ref.wait_me(".wait_me", "hide");
			}
        },
	},
	created: function(){
		this.setup_urls();
	},
	mounted: function(){
		feather.replace();
		// this.dashboard_init_data();
		// this.dashboard_gateway_statistics();
		this.getIntakeList()
		this.getDefaultIntake()
	}
}
</script>

<style>
.card-browser-states .browser-states {
  margin-top: 0rem !important;
}
</style>
