<template>
<section class="forgot-password">
    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <a class="brand-logo" href="javascript:void(0);">
                            <img :src="url('static/vuexy/images/UCLan_logo_reverse_rgb-1.png')" width="300">
                        </a>
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 login_cover_image">
                            <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"><img class="img-fluid" :src="url('static/vuexy/app-assets/images/pages/login-page.jpg')" alt="Login V2" /></div> -->
                            <img :src="url('static/vuexy/app-assets/images/pages/login-page.jpg')" alt="Login V2" />
                        </div>
                        <!-- /Left Text-->
                        <!-- Login-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5  forgot-password-form">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                <h2 class="card-title font-weight-bold mb-1">Forgot Password? 🔒</h2>
                                <p class="card-text mb-2">Enter your email and we'll send you instructions to reset your password</p>
                                <div class="mt-2">
                                    <div class="form-group">
                                        <label class="form-label" for="forgot-password-email">Email</label>
                                        <input class="form-control"  type="text" name="email" placeholder="john@example.com" aria-describedby="forgot-password-email" v-model="form.forgot_password.email" autofocus="" tabindex="1" v-on:keyup.enter="send_reset_link()"/>
                                    </div>
                                    <button class="btn btn-primary btn-block" type="button" tabindex="2" v-on:click="send_reset_link()">Send reset link</button>
                                </div>
                                <p class="text-center mt-2">
                                    <router-link  to="/login">
                                        <i data-feather="chevron-left"></i> Back to login
                                    </router-link>
                                </p>
                            </div>
                        </div>
                        <!-- /Login-->
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
</template>

<script>

export default {
    name: 'forgotPassword',
    data: function(){
        return {
            form:{
                forgot_password:{
                    email:''
                }
            }
        };
    },
    methods: {
        reset_forms: function(){
            var ref=this;
            ref.form.forgot_password.email='';
        },
        send_reset_link: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/auth/send_reset_link');
            ref.wait_me('.forgot-password-form');

            var form_data=ref.form.forgot_password;

            try{

                var res=await jq.post(url, form_data);
                ref.alert(res.msg);
                ref.reset_forms();


            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.forgot-password-form', 'hide');
            }

        }
    },
    mounted: function(){
        var jq=this.jq();
        jq('body').addClass('blank-page');
    },
    created: function(){
        this.setup_urls();
        localStorage.removeItem('uni_api_token');
    }
}
</script>

<style scoped>
@import "../../static/vuexy/app-assets/css/pages/page-auth.css";

.login_cover_image {
    position: relative;
}

.login_cover_image img {
    position: absolute;
    left: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
</style>
