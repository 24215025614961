<template>
<section class="login">
    <!-- BEGIN: Content-->
    <div class="app-content content wait_me">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <a class="brand-logo" href="javascript:void(0);">
                            <img :src="url('static/vuexy/images/UCLan_logo_reverse_rgb-1.png')" width="300">
                        </a>
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 signup_cover_image">
                            <img :src="url('static/vuexy/app-assets/images/pages/login-page.jpg')" alt="Login V2" />
                        </div>
                        <!-- /Left Text-->
                        <!-- Login-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                <button class="btn btn-outline-primary toast-basic-toggler mt-2" style="display:none">Toast</button>
                                <h2 class="card-title font-weight-bold p-0">UCLan Commission Management System</h2>
                                <!-- <h5 class="card-title font-weight-bold mb-1 mt-m">UCLan Commission Management System.</h5> -->
                                <p class="card-text mb-2">Please activate your account to get start.</p>
                                <div class="alert alert-danger" role="alert" v-show="invalid_token">
                                    <div class="alert-body text-center" v-html="invalid_token"></div>
                                </div>
                                <div class="add_region_form mt-2">
                                    <div class="form-group">
                                        <label class="form-label">Email <span class="text-danger">*</span></label>
                                        <input readonly class="form-control" type="text" placeholder="Email Address" aria-describedby="login-email" autofocus="" tabindex="1" v-model="form.user.email"/>
                                    </div>
                                    <div class="form-group">
                                        <div class="d-flex justify-content-between">
                                            <label>Password <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" type="password" placeholder="Password" aria-describedby="login-password" tabindex="2" v-model="form.user.password" v-on:keyup="password_validation_check()"/>
                                        </div>
                                    </div>                                    
                                    <div class="form-group">
                                        <div class="d-flex justify-content-between">
                                            <label>Confirm Password <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" type="password" placeholder="Password" aria-describedby="login-password" tabindex="2" v-model="form.user.confirm_password" v-on:keyup="password_validation_check()"/>
                                        </div>
                                        <div class="mt-1">
                                            <span>
                                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.character"></check-icon>
                                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.character"></x-icon>
                                                At least 8 character long.
                                            </span><br>
                                            <span>
                                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.lowercase"></check-icon>
                                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.lowercase"></x-icon>
                                                One lowercase character
                                            </span><br>
                                            <span>
                                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.uppercase"></check-icon>
                                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.uppercase"></x-icon>
                                                One uppercase character
                                            </span><br>
                                            <span>
                                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.number"></check-icon>
                                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.number"></x-icon>
                                                One Number
                                            </span><br>
                                            <span>
                                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.symbol"></check-icon>
                                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.symbol"></x-icon>
                                                One special character
                                            </span><br>
                                            <span>
                                                <check-icon size="1.5x" class="custom-class text-success" v-if="errorMessages.confirm_password"></check-icon>
                                                <x-icon size="1.5x" class="custom-class text-warning" v-if="!errorMessages.confirm_password"></x-icon>
                                                Confirm password not match.
                                            </span><br>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" v-model="form.user.agree_check" id="register-privacy-policy" type="checkbox" tabindex="4" />
                                            <label class="custom-control-label" for="register-privacy-policy">I agree to<a href="https://www.uclan.ac.uk/legal/privacy-notices" target="_blank">&nbsp;privacy policy & terms</a></label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-12 text-right">
                                                <button type="button" class="btn btn-primary" tabindex="4" v-on:click="user_signup()">Sign up</button>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                <!-- <p class="text-center mt-2"><span>Already have an account?</span><a href="/login"><span>&nbsp;Sign in instead</span></a></p> -->

                            </div>
                        </div>
                        <!-- /Login-->
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
</template>

<script>
import { CheckIcon, XIcon } from 'vue-feather-icons'

export default{
    name: 'AddRegion',
    components: {
        CheckIcon, XIcon
    },
    data:()=>{
        return {
            form: {
                user: {
                    email: '',
                    password: '',
                    confirm_password: '',
                    agree_check: false,
                    signup_token: ''
                }
            },
            invalid_token: '',
            errorMessages: {}
        };
    },
    methods:{
        password_validation_check: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                var res = await jq.post(ref.url('api/v1/uclan/auth/password_validation_check'), ref.form.user);
                ref.errorMessages = res
                // console.log('errorMessages', ref.errorMessages)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        user_signup: async function () {

            var ref=this;
            var jq=ref.jq();

            if (!ref.form.user.agree_check) {
                // this.invalid_token = 'Please make sure have agreed to privacy policy & terms';
                ref.alert('Please make sure have agreed to privacy policy & terms', 'warning');
            } else {
                // this.invalid_token = '';

                try{

                    ref.wait_me('.add_region_form');
                    ref.form.user.signup_token = this.$route.params.token;
                    var res = await jq.post(ref.url('api/v1/uclan/auth/user-signup'), ref.form.user);
                    ref.alert(res.msg);
                    //ref.reset_user_form();
                    ref.$router.push('/login');

                }catch(err){
                    ref.alert(ref.err_msg(err), 'error');
                    ref.$router.push('/login');
                }
                finally{
                    ref.wait_me('.add_region_form', 'hide');
                }

            }
        },
        reset_user_form: function(){
            var ref=this;
            ref.form.user.email = '';
            ref.form.user.password = '';
            ref.form.user.confirm_password = '';
        },
        getDataByToken: async function(){

            var ref=this;
            var jq=ref.jq();

            try {

                ref.wait_me('.wait_me');

                var qstr = {
                    signup_token: this.$route.params.token
                }

                const res = await jq.get(ref.url('api/v1/uclan/auth/signup_token_verification'), qstr);
                console.log('res', res)
                ref.form.user.email = res.data.email;

            }catch(err){
                ref.err_msg(err, 'error');
            }finally{
                ref.wait_me('.wait_me', 'hide');
            }

        },
    },
    mounted: function(){
        var jq=this.jq();
        jq('body').addClass('blank-page');
        this.getDataByToken();
    },
    created () {
        localStorage.removeItem('uni_api_token');
        this.setup_urls();
    },
}
</script>

<style>
.signup_cover_image {
    position: relative;
}

.signup_cover_image img {
    position: absolute;
    left: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.mt-m {
    margin-top: -15px;
}
</style>
