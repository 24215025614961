<template>
<section class="login">
    <!-- BEGIN: Content-->
    <div class="app-content content wait_me">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v2">
                    <div class="auth-inner row m-0">
                        <!-- Brand logo-->
                        <a class="brand-logo" href="javascript:void(0);">
                            <!-- <img :src="url('static/vuexy/images/logo.png')" width="100"> -->
                            <img :src="url('static/vuexy/images/UCLan_logo_reverse_rgb-1.png')" width="300">
                        </a>
                        <!-- /Brand logo-->
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 signup_cover_image">
                            <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"><img class="img-fluid" :src="url('static/vuexy/app-assets/images/pages/register-v2.svg')" alt="Login V2" /></div> -->
                            <img class="img-fluid" :src="url('static/vuexy/app-assets/images/pages/login-page.jpg')" alt="University Logo" />
                        </div>
                        <!-- /Left Text-->
                        <!-- Login-->
                        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                <button class="btn btn-outline-primary toast-basic-toggler mt-2" style="display:none">Toast</button>
                                <h2 class="card-title font-weight-bold mb-1">University Sign Up</h2>
                                <div class="alert alert-danger" role="alert" v-show="invalid_token">
                                    <div class="alert-body text-center" v-html="invalid_token"></div>
                                </div>
                                <div class="university_signup_form mt-2">
                                    <div class="form-group">
                                        <label for="name">University Name <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" placeholder="University Name" id="name" v-model="form.university.name"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email <span class="text-danger">*</span></label>
                                        <input class="form-control" type="email" placeholder="Email Address" id="email" v-model="form.university.email"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="country_id">Country <span class="text-danger">*</span></label>
                                        <treeselect v-model="form.university.country_id" :options="countryList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="city">City <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" placeholder="University Name" id="city" v-model="form.university.city"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="post_code">Post Code <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" placeholder="University Name" id="post_code" v-model="form.university.post_code"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="address">Address <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" placeholder="University Name" id="address" v-model="form.university.address"/>
                                    </div>
                                    <div class="form-group">
                                        <div class="d-flex justify-content-between">
                                            <label>Password <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" type="password" placeholder="Password" aria-describedby="login-password" tabindex="2" v-model="form.university.password"/>
                                        </div>
                                    </div>                                    
                                    <div class="form-group">
                                        <div class="d-flex justify-content-between">
                                            <label>Confirm Password <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" type="password" placeholder="Password" aria-describedby="login-password" tabindex="2" v-model="form.university.confirm_password"/>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input class="custom-control-input" v-model="form.university.agree_check" id="register-privacy-policy" type="checkbox" tabindex="4" />
                                            <label class="custom-control-label" for="register-privacy-policy">I agree to<a href="javascript:void(0);">&nbsp;privacy policy & terms</a></label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-6"  style="padding-left: 0px;">
                                                <button type="button" class="btn btn-outline-secondary btn-block" tabindex="5" v-on:click="reset_agent_form()">Reset</button>
                                            </div>
                                            <div class="col-md-6">
                                                <button style="padding-right: 0px;" type="button" class="btn btn-primary btn-block" tabindex="4" v-on:click="university_signup()">Sign up</button>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                <p class="text-center mt-2"><span>Already have an account?</span><a href="#/login"><span>&nbsp;Sign in instead</span></a></p>

                            </div>
                        </div>
                        <!-- /Login-->
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';

export default{
    name: 'UniversitySignup',
    components: {
        Treeselect,
    },
    data:()=>{
        return {
            form: {
                university: {
                    name: '',
                    email: '',
                    country_id: null,
                    city: '',
                    post_code: '',
                    address: '',
                    uni_type: '',
                    description: '',
                    web_address: '',
                    password: '',
                    confirm_password: '',
                    agree_check: false,
                }
            },
            countryList: [],
            invalid_token: ''
        };
    },
    methods:{
        university_signup: async function () {

            var ref=this;
            var jq=ref.jq();

            if (!ref.form.university.agree_check) {
                this.invalid_token = 'Please make sure have agreed to privacy policy & terms';
            } else {
                this.invalid_token = '';

                try{
                    ref.wait_me('.university_signup_form');
                    var res = await jq.post(ref.url('api/v1/university/auth/signup'), ref.form.university);
                    ref.alert(res.msg);
                    ref.reset_agent_form();
                    
                    localStorage.setItem('uni_api_token', res.data.api_token);
                    ref.$store.commit('setApiToken', res.data.api_token);
                    ref.ajax_setup('uni_api_token');

                    ref.$store.commit('siteInfo', {
                        key: 'auth_user',
                        val: res.data.user
                    });

                    ref.$store.commit('siteInfo', {
                        key: 'agent_company',
                        val: res.data.agent_company
                    });

                    ref.$router.push('/account-settings');
                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
                finally {
                    ref.wait_me('.university_signup_form', 'hide');
                }

            }
        },
        reset_agent_form: function(){
            var ref=this;
            ref.form.university.email = '';
            ref.form.university.password = '';
            ref.form.university.confirm_password = '';
        },
        get_country_list: async function () {
            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_loader');
                var res = await jq.get(ref.url('api/v1/university/auth/get_country_list'),);
                ref.countryList = res.data.country.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_loader', 'hide');
            }
        },
        // getDataByToken: async function(){

        //     var ref=this;
        //     var jq=ref.jq();
        //     try {
        //         ref.wait_me('.wait_me');
        //         const params = Object.assign({}, { signup_token: this.$route.params.signup_token })
        //         const res = await jq.get(ref.url('api/v1/agent/auth/get_agent_invitation_data_by_signup_token'), params);
        //         console.log('res', res)

        //     }catch(err){
        //         this.invalid_token=ref.err_msg(err);
        //     }finally{
        //         ref.wait_me('.wait_me', 'hide');
        //     }
        // },
    },
    mounted: function(){
        var jq=this.jq();
        jq('body').addClass('blank-page');
        this.get_country_list()
        // this.getDataByToken()
    },
    created () {
        this.setup_urls();
    },
}
</script>

<style>
.signup_cover_image {
    position: relative;
}

.signup_cover_image img {
    position: absolute;
    left: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
</style>
