<template>
<section class="component-data-list ">
    <main-layout>
        <!-- BEGIN: Content-->
                
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-2">
                        <div class="row breadcrumbs-top">
                            <div class="col-12">
                                <!-- <h2 class="content-header-title float-left mb-0">Email</h2> -->
                                <h5 class="content-header-title float-left mt-50">Email</h5>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                        <li class="breadcrumb-item"><router-link to="/settings/email-template-list">Email Template List</router-link></li>
                                        <li class="breadcrumb-item active">{{ suiteId ? 'Update' : 'Add' }} Email Template</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="content-body">
                <section class="app-user-list">
                <section class="modern-horizontal-wizard">
                    <div class="bs-stepper wizard-modern modern-wizard-example">
                        <div class="bs-stepper-header">

                            <div class="step" :class="{ active: isActive('one') }">
                                <button type="button" class="step-trigger" aria-selected="false">
                                    <span class="bs-stepper-box">1</span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Design Template</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right font-medium-2"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                            <div class="step" :class="{ active: isActive('two') }">
                                <button type="button" class="step-trigger" aria-selected="false">
                                    <span class="bs-stepper-box">2</span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Preview &amp; Save</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div class="">

                            <div id="email_section" class="content wait_me_list_data" :class="{ active: isActive('one') }"> 
                                <div class="row justify-content-center">
                                    <div class="col-sm-6">
                                        <div class="form-row mb-1" v-for="(row, index) in form.suite_email_sections" :key="index">
                                            <div class="col-12">
                                                <div class="card">
                                                    <div class="text-right">
                                                        <i class="fa fa-plus-circle text-info cursor-pointer mr-1" @click="add_suite_email_seciton_form_row()" aria-hidden="true"></i>
                                                        <i class="fa fa-times text-danger cursor-pointer mt-1 mr-1" @click="remove_suite_email_section_form_row(index)"></i>
                                                    </div>
                                                    <div class="card-header">
                                                        <!-- <h4 class="card-title">Email Body Section</h4> -->
                                                    </div>
                                                    <div class="card-body wait_me_insert_form">
                                                        <div class="form-row">
                                                            <div class="col-12" v-if="index == 0">
                                                                <div class="form-group" v-if="form.suite_email.email_code != 'intake_invitation'">
                                                                    <label for="title"><b>Header</b> <span class="text-danger"></span></label>
                                                                    <!-- <input type="text" v-model="row.title" class="form-control" id="title" placeholder="Enter header"> -->
                                                                    <input type="text" v-model="form.suite_email.header" class="form-control" id="title" placeholder="Enter header">
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <div v-if="row.image_url" class="templage_image_section">
                                                                        <img :src="row.image_url" width="100%" alt="">
                                                                        <span>
                                                                            <i class="fa fa-minus-circle text-danger cursor-pointer" @click="remove_template_image_from_row(row)"></i>
                                                                        </span>
                                                                    </div>
                                                                    <label for="image_file"><b>Email Image (Allowed File Type: jpg/jpeg/png/gif)</b><span class="text-danger"></span></label>
                                                                    <!-- <b-form-file
                                                                        :ref="'image_file'+index"
                                                                        :id="'image_file'+index"
                                                                        v-model="row.image_file"
                                                                        accept=".jpg, .png, .gif"
                                                                        :hidden="true"
                                                                        placeholder="Choose a photo or drop it here..."
                                                                        drop-placeholder="Drop photo here..."
                                                                        browse-text="Upload Image"
                                                                        @input="image_file_upload_event(row.image_file)"
                                                                        ></b-form-file> -->
                                                                    <input type="file" class="form-control-file" placeholder="Attachment" v-on:change="add_attachment($event, row)"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label for="body_content"><b>Email Body</b> <span class="text-danger">*</span></label>
                                                                    <vue-editor :editor-toolbar="bodyContentCustomToolbar" v-model="row.body_text" id="body_content" placeholder="Enter email body content"></vue-editor>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-12" v-if="form.suite_email.email_code != 'intake_invitation'">
                                                                <div class="form-group">
                                                                    <label for="btn"><b>Button Text</b> <span class="text-danger"></span></label>
                                                                    <input type="text" v-model="row.btn" class="form-control" id="btn" placeholder="Enter button text">
                                                                </div>
                                                            </div>
                                                            <div class="col-12" v-if="form.suite_email.email_code != 'intake_invitation'">
                                                                <div class="form-group">
                                                                    <label for="url"><b>Button Url</b> <span class="text-danger"></span></label>
                                                                    <input type="url" v-model="row.url" class="form-control" id="url" placeholder="Enter button url">
                                                                </div>
                                                            </div> -->
                                                            <!-- <div class="col-12">
                                                                <div class="form-group">
                                                                    <label for="footer_text">Footer Text <span class="text-danger"></span></label>
                                                                    <textarea v-model="row.footer_text" class="form-control" id="footer_text" placeholder="Enter footer text"></textarea>
                                                                </div>
                                                            </div> -->
                                                            <!-- <div class="col-12 text-right">
                                                                <button class="btn btn-primary mr-1" @click="setActive('one')">Previous</button>
                                                                <button class="btn btn-primary" @click="setActive('three')">Next</button>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="card">
                                                    <div class="card-body p-50">
                                                        <div class="form-row">
                                                            <div class="col-12 text-right">
                                                                <i class="fa fa-plus-circle text-info cursor-pointer mr-1" @click="add_suite_email_seciton_form_row()" aria-hidden="true"></i>
                                                                <!-- <i class="fa fa-plus-circle text-info fs-30 cursor-pointer mr-1" @click="add_suite_email_seciton_form_row()" aria-hidden="true"></i> -->
                                                                <!-- <button class="btn btn-outline-primary btn-sm mr-50" @click="setActive('one')">Previous</button> -->
                                                                <button class="btn btn-outline-primary btn-sm" @click="setActivePreview('two')">Next</button>
                                                                <!-- <button type="button" class="btn btn-outline-success btn-sm mr-1" @click.prevent="updateFormData()" v-if="suiteId">Update</button>
                                                                <button type="button" class="btn btn-outline-warning btn-sm mr-1" @click.prevent="draftFormData()" v-else>Save as draft</button>
                                                                <button type="button" class="btn btn-outline-primary btn-sm" @click.prevent="send_invitation_email()">Send Mail</button> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="email_preview_section" class="content wait_me_list_data" :class="{ active: isActive('two') }"> 
                                <div class="row wait_me_form justify-content-center">
                                    <div class="col-sm-6">

                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="">
                                                    <div class="p-50">
                                                        <div class="form-row">
                                                            <div class="col text-right">
                                                                <button class="btn btn-outline-primary btn-sm mr-50" @click="setActive('one')">Previous</button>
                                                                <button type="button" class="btn btn-outline-success btn-sm mr-50" @click.prevent="updateFormData()" v-if="suiteId">
                                                                    <i class="fa fa-floppy-o" aria-hidden="true"></i>
                                                                    Save & Exit
                                                                </button>
                                                                <button type="button" class="btn btn-outline-warning btn-sm mr-50" @click.prevent="draftFormData()" v-else>
                                                                    <i class="fa fa-floppy-o" aria-hidden="true"></i>
                                                                    Save as draft
                                                                </button>
                                                                <!-- <button type="button" class="btn btn-outline-primary btn-sm" @click.prevent="send_mail_to_suite_agent_companies()">
                                                                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                                    Send Mail
                                                                </button> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="col-12">
                                                <div v-if="authUserData.university" class="text-center mb-2">
                                                    <img :src="authUserData.university.logo_url" width="150px" alt="logo">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row mb-1 mt-1" v-for="(row, index) in form.suite_email_sections" :key="index">
                                            <div class="col-12">
                                                <div class="card">
                                                    <div class="card-body wait_me_insert_form sameple_content">
                                                        <div class="form-row">
                                                            <div class="col-12">
                                                                <!-- <div v-if="authUserData.university" class="text-center mb-2">
                                                                    <img :src="authUserData.university.logo_url" width="150px" alt="logo">
                                                                </div> -->
                                                                <h3 class="text-center title"  v-if="form.suite_email.email_code != 'intake_invitation'" v-html="form.suite_email.header"></h3>
                                                                <div v-if="row.image_url">
                                                                    <img :src="row.image_url" width="100%" alt="">
                                                                </div>
                                                                <p class="mt-1" v-if="row.body_text" v-html="row.body_text"></p>
                                                                <div class="text-center" v-if="row.btn && form.suite_email.email_code != 'intake_invitation'">
                                                                    <a :href="row.url" target="_blank" class="btn btn-outline-primary" v-html="row.btn"></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                            <!-- <div class="col-12">
                                                <div class="text-center mt-1">
                                                    <p style="line-height:20px;text-align:center;color:#999;font-family:'open sans',sans-serif;font-size:11px">You are receiving this email at <a :href="'mailto:' + authUserData.email" v-html="authUserData.email"></a> because it is officially registered  with the University Finance office</p>
                                                    <p class="footer_text" v-if="authUserData.university" v-html="authUserData.university.name + ', ' + authUserData.university.address"></p>
                                                    <div v-if="authUserData.university" class="text-center mt-2 mb-5">
                                                        <img :src="authUserData.university.logo_url" width="150px" alt="logo">
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="text-center mt-1">
                                                    <p style="line-height:20px;text-align:center;color:#999;font-family:'open sans',sans-serif;font-size:11px">You are receiving this email at <a :href="'mailto:' + authUserData.email" v-html="authUserData.email"></a> because it is officially registered  with the University Finance office</p>
                                                    <p style="line-height:20px;text-align:center;color:#999;font-family:'open sans',sans-serif;font-size:11px; font-weight: bold" class="footer_text" v-if="authUserData.university" v-html="authUserData.university.name + ', ' + authUserData.university.address"></p>
                                                    <div v-if="authUserData.university" class="text-center mt-2 mb-5">
                                                        <img :src="authUserData.university.logo_url" width="150px" alt="logo">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                </section>

            </div>


                <div class="content-body">
                    <!-- Input Sizing start -->
                    <section id="input-sizing">
                        
                    </section>
                    <!-- Input Sizing end -->
                </div>
        <!-- END: Content-->

    </main-layout>
</section>
</template>

<script>
import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import { VueEditor } from "vue2-editor";
// import Treeselect from '@riophae/vue-treeselect';
// import { BFormCheckbox, BFormFile } from 'bootstrap-vue'
//import { BFormCheckbox } from 'bootstrap-vue'
import swal from 'bootstrap-sweetalert';
// import { EditIcon } from 'vue-feather-icons'

export default{
    name: 'EmailTemplateAddAndUpdate',
    components:{
        MainLayout,
        VueEditor,
        // Treeselect,
        //BFormCheckbox,
        // BFormFile
        // EditIcon,
    },
    data:()=>{
        return {
            saveData: true,
            showModal: false,
            msEmail: {},
            university: {},
            suiteId: '',
            icon: 'edit',
            activeItem: 'one',
            flag:{
                show_modal_form:false
            },
            form: {
                selectAll: false,
                suite_email: {
                    campaign_name: '',
                    subject: '',
                    header: '',
                    intake_id: null,
                    region_ids: null,
                    country_ids: null,
                    recipients_status: null,
                    agent_company_ids: null,
                },
                suite_email_sections: [{
                    id: '',
                    title: '',
                    body_text: '',
                    footer_text: '',
                    btn: '',
                    url: '',
                    image_file: '',
                    image_url: '',
                    preview_photo: '',
                    img_id: '',
                }],
            },
            row: [{
                id: '',
                title: '',
                body_text: '',
                footer_text: '',
                btn: '',
                url: '',
                image_file: '',
                preview_photo: '',
                img_id: '',
            }],
            bodyContentCustomToolbar: [
                ["bold", "italic", "underline", "link"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            footerContentCustomToolbar: [
                ["bold", "italic", "underline", "link"],
            ],
            editItem: {},
            authUniversityInfo: {},
            authUserData: {},
            listData: [],
            regionTreeSelectList: [],
            regionList: [],
            regionCountryList: [],
            countryList: [],
            agentCompanyList: [],
            statusList: [],
            treeselectStatusList: [],
            activeMsg: ''
        };
    },
    computed: {
        countryTreeSelectList: function () {
            if (this.form.suite_email.region_ids && this.form.suite_email.region_ids.length) {
                let selectedRegions = this.regionList.filter(item => {
                    return this.form.suite_email.region_ids.includes(item.id)
                })

                let regionCountryIds = []

                selectedRegions.forEach((data) => {
                    data.region_countries.forEach(item => {
                        regionCountryIds.push(item.country_id)
                    })
                })

                return this.countryList.filter(item => {
                    return regionCountryIds.includes(item.id)
                })
            } else {
                return this.countryList
            }
        },
        agentCompanyTreeSelectList: function () {
            if (this.form.suite_email.country_ids && this.form.suite_email.country_ids.length != 0 && this.form.suite_email.recipients_status) {
                const agentListFilterByCountry = this.agentCompanyList.filter(item => {
                    return this.form.suite_email.country_ids.includes(item.country_id)
                })
                return agentListFilterByCountry.filter(item => item.uni_status_id == this.form.suite_email.recipients_status)
            } else if (this.form.suite_email.recipients_status) {
                return this.agentCompanyList.filter(item => item.uni_status_id == this.form.suite_email.recipients_status)
            } else if (this.form.suite_email.country_ids && this.form.suite_email.country_ids.length != 0) {
                return this.agentCompanyList.filter(item => {
                    return this.form.suite_email.country_ids.includes(item.country_id)
                })
            } else {
                return this.agentCompanyList
            }
        },
        auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        }
    },
    created () {
        this.setup_urls();
        this.university = this.$store.state.site.university
        this.suiteId = this.hash_id(this.$route.params.id, false)[0]
        if (this.suiteId) {
            this.getMarketingSuiteDataById(this.suiteId)
        } else {
            this.getAuthUniversityData();
        }
        this.getAuthUserDataData()
    },
    watch: {
        // 'form.suite_email.recipients_status': function(status_id) {
        //     if (status_id) {
        //         if (status_id == 2) {
        //             this.activeMsg = 'If you select recipients status as active then your email suite will not be able to deletable and set as university default email template'
        //         } else {
        //             this.activeMsg = ''
        //         }
        //     }
        // }
    },
    methods:{
        setActive: function (menuItem) {
            if (!this.form.suite_email.subject) {
                this.alert('Please fill the subject field', 'error')
                return;
            }

            this.activeItem = menuItem // no need for Vue.set()
        },
        setActivePreview: function (menuItem) {
            if (!this.form.suite_email_sections) {
                this.alert('Please email section', 'error')
                return;
            }
            this.form.suite_email_sections.map(item => {
                if (!item.body_text) {
                    this.alert('Please fill the email body field', 'error')
                    return;
                }

                this.activeItem = menuItem // no need for Vue.set()
            })
        },
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
        add_attachment: function (e, row) {
            
            let file_input = e.target.files[0];
            let file_input_path = e.target.value;

            let reader = new FileReader();
            if(file_input['size'] < 5242880){
                let allowedExtensions = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
              
                if (!allowedExtensions.exec(file_input_path)) {
                    this.alert('File type should be jpg/jpeg/png/gif!', 'error')
                    return false;
                }

                reader.onloadend = () => {
                    row.image_url = reader.result;
                }
                reader.readAsDataURL(file_input);
                row.image_file = file_input
            } else {
                this.alert('File size should be maximus 5MB!', 'error')
            }
        },
        treeSelectAllAgentCompany: function () {
            this.form.suite_email.agent_company_ids = []
            if (this.form.selectAll) {
                if (this.form.suite_email.country_ids) {
                    return this.agentCompanyList.filter(item => {
                        return this.form.suite_email.country_ids.includes(item.country_id)
                    })
                } else {
                    this.agentCompanyList.map(item => {
                        this.form.suite_email.agent_company_ids.push(item.id)
                    })
                }
            } else {
                this.form.suite_email.agent_company_ids = []
            }
        },
        add_suite_email_seciton_form_row: function () {
            // let footer_text = ''
            // if(this.form.suite_email_sections.length) {
            //     footer_text = this.authUniversityInfo.name + this.authUniversityInfo.address
            // } else {
            //     footer_text = ''
                
            // }
            const sectionItem = {
                title: '',
                body_text: '',
                // footer_text: footer_text,
                btn: '',
                url: '',
                image_file: '',
                image_url: '',
                preview_photo: '',
                img_id: '',
            }
            this.form.suite_email_sections.push(sectionItem)
        },
        remove_suite_email_section_form_row: function (index) {
            if (index) {
                this.form.suite_email_sections.splice(index, 1);
            }
        },
        remove_template_image_from_row: function (row) {
            // console.log('this.form.suite_email_sections[index]', this.form.suite_email_sections[index])
            row.image_url = ''
        },
        getAuthUniversityData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_auth_university_info'));
                this.authUniversityInfo = res.data.auth_university
                // this.form.suite_email_sections[0].footer_text = this.authUniversityInfo.name + this.authUniversityInfo.address
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getAuthUserDataData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_auth_user_info_with_univesity_data'));
                this.authUserData = res.data.auth_user
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getRegionList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_region_list'));
                this.regionTreeSelectList = res.data.regionList.map(item => {
                    return {
                        id: item.id,
                        label: item.title,
                    }
                })
                this.regionList = res.data.regionList
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getRegionCountriesList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_region_countries_list'));
                this.countryList = res.data.regionCountryList.map(item => {
                    return {
                        id: item.country ? item.country.id : '',
                        label: item.country ? item.country.name : '',
                    }
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getCountryList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_country_list'));
                this.countryList = res.data.country.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getRecipientList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_company_list_by_auth_university'));
                this.agentCompanyList = res.data.list.map(item => {
                    return {
                        id: item.agent_company ? item.agent_company.id : null,
                        label: item.agent_company ? item.agent_company.name : null,
                        country_id: item.agent_company ? item.agent_company.country_id : null,
                        uni_status_id: item.agent_company ? item.agent_company.uni_status_id : null,
                    }
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getMarketingSuiteDataById: async function (SuiteId) {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                const params = { ms_email_id: SuiteId }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_marketing_suite_data_by_id'), params);
                this.msEmail = res.data.msEmail
                ref.form.suite_email = res.data.msEmail
                console.log('ref.form.suite_email', ref.form.suite_email)

                if (ref.cn(res, 'data.msEmail.parsed_filter_query.region_ids', null)) {
                    ref.form.suite_email.region_ids = res.data.msEmail.parsed_filter_query.region_ids
                }
                if (ref.cn(res, 'data.msEmail.parsed_filter_query.country_ids', null)) {
                    ref.form.suite_email.country_ids = res.data.msEmail.parsed_filter_query.country_ids
                }
                
                ref.form.suite_email.agent_company_ids = []
                res.data.msEmail.receivers.map(item => {
                    ref.form.suite_email.agent_company_ids.push(item.model_id)
                })
                if (res.data.msEmail.email_sections.length) {
                    this.form.suite_email_sections = res.data.msEmail.email_sections
                }
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        send_mail_to_suite_agent_companies: function() {
            if (this.msEmail.id) {
                this.updateFormData()
            }else {
                this.draftFormData()
            }
            this.send_mail(this.msEmail)
        },
        send_mail: async function(item){

            var ref=this;
            var jq=ref.jq();

            ref.wait_me('.table-responsive');

            try {
                const params = Object.assign({}, item );
                var response = await jq.post(ref.url('api/v1/uclan/ajax/send_mail_to_marketing_suite_receivers'), params);
                swal("Email Template Email Send!", response.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.table-responsive', 'hide');
            }

        },
        draftFormData: async function () {

            var ref=this;
            var jq=ref.jq();

            try{
                ref.wait_me('.wait_me_form');
                const params = Object.assign({}, this.form.suite_email, { total_receiver: this.form.suite_email.agent_company_ids.length })
                var res = await jq.post(ref.url('api/v1/university/ajax/draft_marketing_suite_data'), params);
                // ref.alert(res.msg);
                this.msEmail = res.data.data
                this.draftFileUploadByFormData(res.data.data.id)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_form', 'hide');
            }
        },
        draftFileUploadByFormData: async function (ms_email_id) {
            var ref=this;
            var jq=ref.jq();

            var form_data = new FormData();
            console.log('ref.form.suite_email_sections', ref.form.suite_email_sections)
            
            ref.form.suite_email_sections.forEach((item, index) => {

                if (item.image_file) {
                    form_data.append('sections['+ index +'][attachment]', item.image_file);
                } else if (item.image_url) {
                    form_data.append('sections['+ index +'][attachment]', null);
                } else {
                    form_data.append('sections['+ index +'][attachment]', false);
                }

                form_data.append('sections['+ index +'][id]', item.id);
                form_data.append('sections['+ index +'][title]', item.title);
                form_data.append('sections['+ index +'][body_text]', item.body_text);
                form_data.append('sections['+ index +'][footer_text]', item.footer_text);
                form_data.append('sections['+ index +'][btn]', item.btn);
                form_data.append('sections['+ index +'][url]', item.url);
                form_data.append('sections['+ index +'][preview_photo]', item.preview_photo);
            });

            form_data.append('ms_email_id', ms_email_id)

            ref.wait_me('.wait_me_form');

            jq.ajax({
                url: ref.url('api/v1/university/ajax/draft_marketing_suite_email_section_data'),
                data: form_data,
                processData: false,
                contentType: false,
                type: 'POST',
                success: res=>{

                    ref.alert(res.msg);
                    this.$router.push('/settings/email-template-list')

                }
            }).fail(
                err=>ref.alert(ref.err_msg(err), 'error')
            ).always(()=>ref.wait_me('.wait_me_form', 'hide'));
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_form');
                const suite_email = {
                    id: this.form.suite_email.id,
                    campaign_name: this.form.suite_email.campaign_name,
                    recipients_status: this.form.suite_email.recipients_status,
                    subject: this.form.suite_email.subject,
                    header: this.form.suite_email.header,
                    region_ids: this.form.suite_email.region_ids,
                    country_ids: this.form.suite_email.country_ids,
                    agent_company_ids: this.form.suite_email.agent_company_ids,
                }
                const params = Object.assign({}, suite_email, { total_receiver: this.form.suite_email.agent_company_ids.length })
                var res = await jq.post(ref.url('api/v1/university/ajax/update_email_template_marketing_suite_data'), params);
                // ref.alert(res.msg);
                this.draftFileUploadByFormData(res.data.data.id)
                this.msEmail = res.data.data

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_form', 'hide');
            }
        },
        getStatusList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'uni_agent_statuses' }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);
                this.statusList = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })

                this.treeselectStatusList = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
    },
    mounted: function(){
        feather.replace();
        this.getRegionList();
        this.getCountryList();
        this.getRecipientList();
        this.getStatusList();
    }
}
</script>

<style scoped>
    .bs-stepper .bs-stepper-content {
        padding: 0rem 0rem !important;
    }
    .card {
        margin-bottom: 0rem!important;
    }
    .email_template p br{
        display:none !important;
    }

    .templage_image_section {}
    .templage_image_section img {
        position: relative;
    }
    .templage_image_section span {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 18px;
        width: 18px;
        border-radius: 100%;
        line-height: 1.2;
        background: #FFF;
        text-align: center;
        font-size: 16px;
    }
    .body_content .ql-editor {
        min-height: 200px !important;
        font-size: 16px !important;
        max-height: 350px !important;
    }
</style>