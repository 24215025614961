<template>
<section class="main-header">
    <!-- {{ windowWidth }} -->
    <!-- BEGIN: Header-->
    <nav class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center" data-nav="brand-center">
        <!-- <div class="navbar-header d-xl-block d-none">
            <ul class="nav navbar-nav">
                <li class="nav-item">
                    <a class="navbar-brand" href="#">
                        <img v-if="cn(store_uni, 'logo_url', null)" :src="store_uni.logo_url" width="100">
                    </a>
                </li>
            </ul>
        </div> -->
    <!-- <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul> -->
        <div class="navbar-container d-flex content">
            <div class="d-flex align-items-center">
                <ul class="nav navbar-nav d-xl-none mr-50">
                    <li class="nav-item">
                        <!-- <a class="nav-link menu-toggle" href="javascript:void(0);">
                            <i class="ficon" data-feather="menu"></i>
                        </a> -->
                        <i class="fa fa-bars font-size-20 hand text-uclan" @click="toggle_modal_form()"></i>
                    </li>
                </ul>
                <!-- <ul class="nav navbar-nav bookmark-icons">
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-chat.html" data-toggle="tooltip" data-placement="top" title="Chat"><i class="ficon" data-feather="message-square"></i></a></li>
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-calendar.html" data-toggle="tooltip" data-placement="top" title="Calendar"><i class="ficon" data-feather="calendar"></i></a></li>
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-todo.html" data-toggle="tooltip" data-placement="top" title="Todo"><i class="ficon" data-feather="check-square"></i></a></li>
                </ul> -->
                <ul class="nav navbar-nav mr-auto">
                    <!-- <li class="nav-item d-none d-lg-block"><a class="nav-link bookmark-star"><i class="ficon text-warning" data-feather="star"></i></a>
                        <div class="bookmark-input search-input">
                            <div class="bookmark-input-icon"><i data-feather="search"></i></div>
                            <input class="form-control input" type="text" placeholder="Bookmark" tabindex="0" data-search="search">
                            <ul class="search-list search-list-bookmark"></ul>
                        </div>
                    </li> -->
                    <li class="nav-item">
                        <a class="navbar-brand" href="#">
                            <img :src="cn(store_uni, 'logo_url', '-')" height="50" v-on:error="img_on_error"/>
                        </a>
                    </li>
                </ul>
            </div>
            <ul class="nav navbar-nav align-items-center ml-auto">
                <!-- <li class="nav-item d-none d-lg-block"><a class="nav-link nav-link-style"><i class="ficon" data-feather="moon"></i></a></li>
                <li class="nav-item nav-search"><a class="nav-link nav-link-search"><i class="ficon" data-feather="search"></i></a>
                    <div class="search-input">
                        <div class="search-input-icon"><i data-feather="search"></i></div>
                        <input class="form-control input" type="text" placeholder="Explore Vuexy..." tabindex="-1" data-search="search">
                        <div class="search-input-close"><i data-feather="x"></i></div>
                        <ul class="search-list search-list-main"></ul>
                    </div>
                </li>-->

                <li class="nav-item dropdown dropdown-notification mr-25" :class="flag.show_bell_notifications?'show':''" v-click-outside="close_bell_notifications">
                    <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown" v-on:click="toggle_bell_notifications()">
                        <i class="ficon" data-feather="bell"></i>
                        <span class="badge badge-pill badge-danger badge-up" v-if="list.bell_notifications.length" v-html="list.bell_notifications.length"></span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right bell-n-dropdown-ul" :class="flag.show_bell_notifications?'show':''">
                        <li class="dropdown-menu-header">
                            <div class="dropdown-header d-flex">
                                <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
                                <div class="badge badge-pill badge-light-primary" v-if="list.bell_notifications.length">
                                    <span v-html="list.bell_notifications.length"></span> New
                                </div>
                            </div>
                        </li>
                        <li class="scrollable-container media-list" v-if="list.bell_notifications.length">
                            <a class="d-flex" v-for="(row, index) in list.bell_notifications" :key="index">
                                <div class="media d-flex align-items-start">
                                    <div class="media-left">
                                        <div class="avatar bg-light-primary">
                                            <div class="avatar-content" v-html="avatarText(row.creator)"></div>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <p class="media-heading">
                                            <span class="font-weight-bolder text-uclan" v-if="row.url" v-on:click="goto_url(row)" v-html="row.subject+': '"></span>
                                            <span class="font-weight-bolder" v-else v-html="row.subject+': '"></span>
                                            <span v-html="row.msg"></span>
                                        </p>
                                        <small class="notification-text">
                                            <i class="fa fa-user-o mr-25" aria-hidden="true"></i>
                                            <span v-html="row.creator"></span>
                                            <i class="fa fa-clock-o mx-25" aria-hidden="true"></i>
                                            <span v-html="dNow(row.created_at)"></span>
                                            <!-- &bull;
                                            <span v-html="dDate(row.created_at, 'hh:mm A')"></span> -->
                                        </small>
                                    </div>
                                    <div class="my-auto">                               
                                        <i class="fa fa-times-circle text-secondary font-size-18" aria-hidden="true" v-tooltip="'Close'" v-on:click="bell_notification_mark_as_read(row.id, index)"></i>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li v-show="!list.bell_notifications.length" class="dropdown-menu-footer text-center">
                            <strong>
                                <i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>
                                Found no notification.
                            </strong>
                        </li>
                        <!-- <li class="dropdown-menu-footer"><a class="btn btn-primary btn-block" href="javascript:void(0)">Read all notifications</a></li> -->
                    </ul>
                </li>
                
                <li class="nav-item dropdown dropdown-user" :class="flag.acc_dropdown?'show':''">
                    <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" v-on:click="acc_dropdown()"  v-click-outside="close_acc_dropdown">

                        <div class="user-nav d-sm-flex d-none">
                            <span class="user-name font-weight-bolder" v-html="cn(store_auth_user, 'name', 'User')"></span>
                            <span class="user-status">Admin</span>
                        </div>
                        
                        <span class="avatar">
                            <img class="round" :src="url('static/vuexy/images/no_avatar.png')" alt="avatar" height="40" width="40"/>
                            <span class="avatar-status-online"></span>
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" :class="flag.acc_dropdown?'show':''" aria-labelledby="dropdown-user">
                        <!-- <a class="dropdown-item" href="javascript:void(0)"><i class="mr-50" data-feather="user"></i> Profile</a> -->
                        <!-- <a v-on:click="logout()" class="dropdown-item" href="javascript:void(0)"><i class="mr-50" data-feather="power"></i> Logout</a> -->
                        <router-link to="/user/change-password" class="dropdown-item">
                            <i class="mr-50" data-feather="key"></i> Change Password
                        </router-link>
                        <router-link to="/logout" class="dropdown-item">
                            <i class="mr-50" data-feather="power"></i> Logout
                        </router-link>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
    <!-- <ul class="main-search-list-defaultlist d-none">
        <li class="d-flex align-items-center"><a href="javascript:void(0);">
                <h6 class="section-label mt-75 mb-0">Files</h6>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="mr-75"><img src="app-assets/images/icons/xls.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Two new item submitted</p><small class="text-muted">Marketing Manager</small>
                    </div>
                </div><small class="search-data-size mr-50 text-muted">&apos;17kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="mr-75"><img src="app-assets/images/icons/jpg.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">52 JPG file Generated</p><small class="text-muted">FontEnd Developer</small>
                    </div>
                </div><small class="search-data-size mr-50 text-muted">&apos;11kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="mr-75"><img src="app-assets/images/icons/pdf.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">25 PDF File Uploaded</p><small class="text-muted">Digital Marketing Manager</small>
                    </div>
                </div><small class="search-data-size mr-50 text-muted">&apos;150kb</small>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between w-100" href="app-file-manager.html">
                <div class="d-flex">
                    <div class="mr-75"><img src="app-assets/images/icons/doc.png" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Anna_Strong.doc</p><small class="text-muted">Web Designer</small>
                    </div>
                </div><small class="search-data-size mr-50 text-muted">&apos;256kb</small>
            </a></li>
        <li class="d-flex align-items-center"><a href="javascript:void(0);">
                <h6 class="section-label mt-75 mb-0">Members</h6>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-8.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">John Doe</p><small class="text-muted">UI designer</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-1.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Michal Clark</p><small class="text-muted">FontEnd Developer</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-14.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Milena Gibson</p><small class="text-muted">Digital Marketing Manager</small>
                    </div>
                </div>
            </a></li>
        <li class="auto-suggestion"><a class="d-flex align-items-center justify-content-between py-50 w-100" href="app-user-view.html">
                <div class="d-flex align-items-center">
                    <div class="avatar mr-75"><img src="app-assets/images/portrait/small/avatar-s-6.jpg" alt="png" height="32"></div>
                    <div class="search-data">
                        <p class="search-data-title mb-0">Anna Strong</p><small class="text-muted">Web Designer</small>
                    </div>
                </div>
            </a></li>
    </ul> -->
    <ul class="main-search-list-defaultlist-other-list d-none">
        <li class="auto-suggestion justify-content-between">
            <a class="d-flex align-items-center justify-content-between w-100 py-50">
                <div class="d-flex justify-content-start">
                    <span class="mr-75" data-feather="alert-circle"></span>
                    <span>No results found.</span>
                </div>
            </a>
        </li>
    </ul>
    <!-- END: Header-->

    <!-- BEGIN: Main Menu-->
    <div class="horizontal-menu-wrapper">
        <!-- <div class="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border container-xxl" role="navigation"> -->
        <div class="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border" role="navigation">
            <div class="navbar-header">
                <ul class="nav navbar-nav flex-row">
                    <li class="nav-item mr-auto">
                        <!-- <a class="navbar-brand" href="#">
                            <img src="images/logo.png" width="70">
                        </a> -->
                        
                    </li>
                    <li class="nav-item nav-toggle"><a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><i class="d-block d-xl-none text-primary toggle-icon font-medium-4" data-feather="x"></i></a></li>
                </ul>
            </div>
            <div class="shadow-bottom"></div>
            <!-- Horizontal menu content-->
            <div class="navbar-container main-menu-content" data-menu="menu-container">
                <!-- include includes/mixins-->

                <ul class="nav navbar-nav navbar-right" id="main-menu-navigation" data-menu="menu-navigation">
                    <template v-for="row in list.nav_menu">
                        <li v-if="permission_check(row.permission)" class="dropdown nav-item" data-menu="dropdown" :key="row.id" v-on:mouseover="open_nav_submenu(row)" v-on:mouseleave="close_nav_submenu(row)" :class="active_nav(row)">
                                <router-link v-if="row.url" :class="row.children?'dropdown-toggle':''" class="nav-link d-flex align-items-center" :to="row.url">
                                    <i v-if="!row.icon_type" :data-feather="row.icon"></i>
                                    <i v-if="row.icon_type=='fontawesome'" :class="row.icon"></i>
                                    <span v-html="row.label"></span>
                                </router-link>
                                <a v-else :class="row.children?'dropdown-toggle':''" class="nav-link d-flex align-items-center" href="javascript:void(0)">
                                    <i v-if="!row.icon_type" :data-feather="row.icon"></i>
                                    <i v-if="row.icon_type=='fontawesome'" :class="row.icon"></i>
                                    <span v-html="row.label"></span>
                                </a>
                            <ul class="dropdown-menu" :class="row.class ? 'settings-dropdown' : ''" v-if="row.children">
                                <template v-for="inner_row in row.children">
                                    <li v-if=" permission_check(inner_row.permission)" :key="inner_row.id">
                                        <div>
                                            <router-link v-if="inner_row.url" class="dropdown-item d-flex align-items-center" :to="inner_row.url" :class="active_nav(inner_row)">
                                                <i v-if="!inner_row.icon_type" :data-feather="inner_row.icon"></i>
                                                <i v-if="inner_row.icon_type=='fontawesome'" :class="inner_row.icon"></i>
                                                <span v-html="inner_row.label"></span>
                                            </router-link>
                                            <a v-else class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                                <i :data-feather="inner_row.icon"></i>
                                                <span v-html="inner_row.label"></span>
                                            </a>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </ul>

                <!-- <ul class="nav navbar-nav navbar-right" id="main-menu-navigation">
                    <template>
                        <li class="dropdown nav-item">

                            <a href="#" class="dropdown-toggle nav-link d-flex align-items-center">
                                <span v-html="'Hello'"></span>
                            </a>
                        </li>
                    </template>
                </ul> -->
                <!-- <button>New Button</button> -->
            </div>
        </div>
    </div>
    <!-- END: Main Menu-->

    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <!-- <div class="content-wrapper container-xxl p-0"> -->
        <div class="content-wrapper">

            <!-- <div class="content-header row">
                <div class="col">
                    <h2 class="content-header-title float-left mb-0">Agency</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item active">List</li>
                        </ol>
                    </div>
                </div>
            </div> -->

            <div class="content-body" style="min-height: 70vh;">
                <slot></slot>
            </div>

        </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>

    <!-- BEGIN: Footer-->
    <footer class="footer footer-static footer-light">
        <p class="clearfix mb-0"><span class="float-md-left d-block d-md-inline-block mt-25">COPYRIGHT &copy; University of Central Lancashire {{ dYear(new Date()) }}</span></p>
    </footer>
    <button class="btn btn-primary btn-icon scroll-top" type="button"><i data-feather="arrow-up"></i></button>
    <!-- END: Footer-->

    <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <sidebar-left 
                        :show_sidebar="flag.show_modal_form"
                        v-on:toggle-sidebar="toggle_modal_form()"
                        title="Mobile Menu"
                        >
                        <h4>
                            <span class="align-middle">Menu</span>
                        </h4>
                        <ul class="nav navbar-nav mobile_menu">
                            <template v-for="row in list.nav_menu">
                                <li v-if="permission_check(row.permission)" class="dropdown nav-item" :key="row.id" v-on:mouseover="open_nav_submenu(row)" v-on:mouseleave="close_nav_submenu(row)" :class="active_nav(row)">
                                        <router-link v-if="row.url" class="nav-link d-flex align-items-center" :to="row.url">
                                            <i v-if="!row.icon_type" :data-feather="row.icon"></i>
                                            <i v-if="row.icon_type=='fontawesome'" :class="row.icon"></i>
                                            <span v-html="row.label"></span>
                                        </router-link>
                                        <a v-else class="nav-link d-flex align-items-center" href="javascript:void(0)">
                                            <i v-if="!row.icon_type" :data-feather="row.icon"></i>
                                            <i v-if="row.icon_type=='fontawesome'" :class="row.icon"></i>
                                            <span v-html="row.label"></span>
                                        </a>
                                    <ul class="dropdown-menu" v-if="row.children">
                                        <template v-for="inner_row in row.children">
                                            <li v-if=" permission_check(inner_row.permission)" :key="inner_row.id">
                                                <div>
                                                    <router-link v-if="inner_row.url" class="dropdown-item d-flex align-items-center" :to="inner_row.url" :class="active_nav(inner_row)">
                                                        <i v-if="!inner_row.icon_type" :data-feather="inner_row.icon"></i>
                                                        <i v-if="inner_row.icon_type=='fontawesome'" :class="inner_row.icon"></i>
                                                        <span v-html="inner_row.label"></span>
                                                    </router-link>
                                                    <a v-else class="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                                        <i :data-feather="inner_row.icon"></i>
                                                        <span v-html="inner_row.label"></span>
                                                    </a>
                                                </div>
                                            </li>
                                        </template>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </sidebar-left>
                </div>
            </div>

</section>
</template>

<script>
import feather from 'feather-icons';
// import { Slide } from 'vue-burger-menu'
import SidebarLeft from '../SidebarLeft';

export default{
    name: 'MainLayout',
    // props: {
    //     toggleVerticalMenuActive: {
    //         type: Function,
    //         default: () => {},
    //     },
    // },
    components:{
        // Slide
        SidebarLeft
    },
    data:()=>{
        return {
            windowWidth: window.innerHeight,
            success: false,
            list:{
                nav_menu: [
                    {
                        id:'dashboard',
                        label:'Dashboard',
                        icon: 'home',
                        url: '/dashboard',
                        expand: false,
                        children: null,
                        permission: 'permitted',
                    }, {
                        id:'agency',
                        label:'Manage Agent',
                        icon:'users',
                        url: '/agency-list',
                        permission: 'agent_management',
                        active_routes: [
                            'AddNewAgent', 'AddNewMultipleAgent'
                        ],
                        // expand:false,
                        // children:[
                        //     {
                        //         id:'agency_list',
                        //         label:'Agency List',
                        //         url:'/agency-list',
                        //         icon:'users'
                        //     },{
                        //         id:'add_new_agent',
                        //         label:'Add Agency',
                        //         url:'/add-new-agent',
                        //         icon:'plus-square'
                        //     },{
                        //         id:'bulk_email',
                        //         label:'Bulk Email',
                        //         url:null,
                        //         icon:'mail'
                        //     }
                        // ]
                    },{
                        id:'gateway',
                        label:'Gateway',
                        icon:'users',
                        url:null,
                        expand:false,
                        permission: 'gateway',
                        active_routes: [
                            'AddAgentMultipleStudent',
                            'DisputedStudentList',
                            'IntakeWiseAgentList',
                            'AddAgentMultipleStudent',
                            'AgentWiseStudentList',
                            'BulkVerficationUpload',
                            'DisputedStudentListByIntake',
                        ],
                        children:[
                            {
                                id:'all_lists',
                                label:'All Lists',
                                url:'/gateway/all-application',
                                icon:'file-text',
                                permission: 'all_lists',
                            },/* {
                                id:'missed_deadline',
                                label:'Missed Deadline',
                                url:'',
                                icon:'file-text'
                            },{
                                id:'international_office',
                                label:'International Office',
                                url:'',
                                icon:'file-text'
                            },{
                                id:'finance_office',
                                label:'Finance Office',
                                url:'',
                                icon:'file-text'
                            }, */{
                                id:'dispute_list',
                                label:'Dispute List',
                                url:'/gateway/dispute-list',
                                icon:'file-text',
                                permission: 'dispute_list',
                            },
                        ]
                    },{
                        id:'invoices',
                        label:'Invoices',
                        icon:'file-text',
                        url:'/invoices',
                        expand:false,
                        children: null,
                        permission: 'invoices',
                    },
                    {
                        id: 'as_list_req',
                        // label: 'Communications',
                        label: 'Inbox',
                        url:'/gateway/communications',
                        expand:false,
                        children: null,
                        icon_type: 'fontawesome',
                        icon: 'fa fa-comment-o',
                        permission: 'communications',
                    },
                    {
                        id: 'task_list',
                        label: 'Task List',
                        url:'/gateway/task-list',
                        expand:false,
                        children: null,
                        icon_type: 'fontawesome',
                        icon: 'fa fa-check-square-o',
                        permission: 'task_list',
                    },
                    {
                        id:'email_marketing',
                        label:'Email Campaign',
                        icon:'mail',
                        expand:false,
                        children: null,
                        url:'/settings/marketing-suite-list',
                        permission: 'marketing_suite',
                        active_routes: [
                            'MarketingSuiteAddAndUpdate',
                        ],
                    },
                    {
                        id:'report',
                        label:'Reports',
                        icon_type: 'fontawesome',
                        icon:'fa fa-bar-chart',
                        expand:false,
                        children: null,
                        url:'/report-list',
                        permission: 'report_list',
                    }
                    /* ,{
                        id:'communication',
                        label:'Communication',
                        icon:'mail',
                        url:null,
                        expand:false,
                        children: null
                    },{
                        id:'email',
                        label:'Email',
                        icon:'mail',
                        url:null,
                        expand:false,
                        permission: 'email_settings',
                        children:[
                            {
                                id:'email_configs',
                                label:'Configurations',
                                icon_type: 'fontawesome',
                                icon:'fa fa-cogs',
                                url:'/settings/email-configuration',
                                permission: 'email_configuration',
                            },
                            {
                                id:'email_templates',
                                label:'Templates',
                                icon_type: 'fontawesome',
                                icon:'fa fa-th-large',
                                url:'/settings/email-template-list',
                                permission: 'email_templates_list',
                            },
                            {
                                id:'marketing_campaigns',
                                label:'Marketing Campaigns',
                                icon_type: 'fontawesome',
                                icon:'fa fa-bullhorn',
                                url:'/settings/marketing-suite-list',
                                permission: 'marketing_suite',
                            }
                        ]
                    } */,{
                        id:'settings',
                        // label:'Settings',
                        label:'',
                        icon:'settings',
                        url:null,
                        expand:false,
                        permission: 'settings',
                        class: 'ml-auto',
                        active_routes: [
                            'EmailTemplateAddAndUpdate',
                        ],
                        children:[
                            // {
                            //     id:'profile',
                            //     label:'University Profile',
                            //     url:'/settings/university-profile',
                            //     icon:'user',
                            //     permission: 'university_profile',
                            // },
                            /* {
                                id:'email_settings',
                                label:'Email Settings',
                                icon:'mail',
                                url:'/settings/email-settings',
                                permission: 'email_settings',
                            },
                            {
                                id:'email_templates',
                                label:'Email Templates',
                                icon:'mail',
                                url:'/settings/email-template-list',
                                permission: 'email_templates_list',
                            },
                            {
                                id:'marketing_suite_list',
                                label:'Email Campaign',
                                icon_type: 'fontawesome',
                                icon:'fa fa-briefcase',
                                url:'/settings/marketing-suite-list',
                                permission: 'marketing_suite',
                            }, */
                            // {
                            //     id:'marketing_suite',
                            //     label:'Marketing Suite',
                            //     icon_type: 'fontawesome',
                            //     icon:'fa fa-briefcase',
                            //     url:'/settings/marketing-suite'
                            // },
                            {
                                id:'intake',
                                label:'Manage Intake',
                                icon:'linkedin',
                                url:'/settings/intake',
                                permission: 'intake',
                            }/* ,{
                                id:'currency',
                                label:'Currency',
                                icon:'dollar-sign',
                                url:''
                            } */,{
                                id:'commissions',
                                label:'Commissions',
                                icon:'percent',
                                url:'/settings/commissions',
                                permission: 'commission_structure',
                            },{
                                id:'email_configs',
                                label:'Email Configurations',
                                icon_type: 'fontawesome',
                                icon:'fa fa-cogs',
                                url:'/settings/email-configuration',
                                permission: 'email_configuration',
                            },
                            {
                                id:'email_templates',
                                label:'Email Templates',
                                icon_type: 'fontawesome',
                                icon:'fa fa-th-large',
                                url:'/settings/email-template-list',
                                permission: 'email_templates_list',
                            },
                            // {
                            //     id:'region_list',
                            //     label:'Region List',
                            //     icon:'map-pin',
                            //     url:'/settings/region-list',
                            //     permission: 'region_list',
                            // },
                            {
                                id:'territory_list',
                                label:'Manage Territory',
                                icon:'map-pin',
                                url:'/settings/territory-list',
                                permission: 'territory_list',
                            },
                            {
                                id:'course_level',
                                label:'Course Level List',
                                icon:'tag',
                                url:'/settings/course-level',
                                permission: 'course_level',
                            },
                            // {
                            //     id: 'agent_account_managers',
                            //     label: 'Account Managers',
                            //     icon: 'users',
                            //     url:'/settings/agent-account-managers',
                            //     permission: 'account_managers',
                            // },
                            // {
                            //     id: 'permits',
                            //     label: 'Permission List',
                            //     icon: 'key',
                            //     url:'/settings/permits',
                            //     permission: 'permission_list',
                            // },
                            {
                                id: 'role_permissions',
                                label: 'Role Permissions',
                                icon: 'lock',
                                url:'/settings/role-permissions',
                                permission: 'role_permissions',
                            },
                            {
                                id: 'user_role',
                                label: 'Users',
                                icon_type: 'fontawesome',
                                icon: 'fa fa-users',
                                url:'/settings/users',
                                permission: 'user_management',
                            },
                            {
                                id: 'faculty_list',
                                label: 'Faculty',
                                icon_type: 'fontawesome',
                                icon: 'fa fa-bookmark',
                                url:'/settings/faculty_list',
                                permission: 'faculty_list',
                            },
                            {
                                id: 'course_list',
                                label: 'Course',
                                icon_type: 'fontawesome',
                                icon: 'fa fa-book',
                                url:'/settings/course_list',
                                permission: 'course_list',
                            },
                            {
                                id: 'course_tuition_fees_list',
                                label: 'Course Tuition Fees',
                                icon_type: 'fontawesome',
                                icon: 'fa fa-gbp',
                                url:'/settings/course_tuition_fees_list',
                                permission: 'course_tuition_fees_list',
                            },
                            // {
                            //     id: 'role_permit',
                            //     label: 'Role Permit',
                            //     icon: 'lock',
                            //     url:'/settings/role-permit'
                            // }
                            // },{
                            //     id:'intake_status',
                            //     label:'Intake Status',
                            //     icon:'percent',
                            //     url:'/settings/intake-status'
                            // },{
                            //     id:'invoice_status',
                            //     label:'Invoice Status',
                            //     icon:'percent',
                            //     url:'/settings/invoice-status'
                            // },{
                            //     id:'student_status',
                            //     label:'Student Status',
                            //     icon:'percent',
                            //     url:'/settings/student-status'
                            // },{
                            //     id:'agent_status',
                            //     label:'Agent Status',
                            //     icon:'percent',
                            //     url:'/settings/agent-status'
                            // }
                        ]
                    }

                ],
                bell_notifications:[]
            },
            flag:{
                acc_dropdown: false,
                show_bell_notifications: false,
                show_modal_form:false,
            }
        };
    },
    methods:{
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        acc_dropdown: function(){
            this.flag.acc_dropdown=!this.flag.acc_dropdown;
        },
		logout: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/auth/logout');

            try{
                await jq.post(url);
            }catch(err){
                console.log(err);
            }

            localStorage.removeItem('uni_api_token');
			ref.$router.push('/');

		},
        open_nav_submenu: function(row){
            var ref=this;
            ref.list.nav_menu.forEach(each=>{
                each.expand=false;
            });
            row.expand=true;
        },
        close_nav_submenu: function(row){
            row.expand=false;
        },
        active_nav: function(row){

            var ref=this;
            var route_path=ref.$route.path
            var route_name = ref.$route.name
            // console.log('route_name', route_name)
            var classes='';

            if (row.class) {
                classes += row.class;
            }

            if(row.url && route_path==row.url){
                classes += ' active';
            }else if(row.children){

                var children_urls=row.children.map(each=>{
                    return each.url;
                });

                if(children_urls.includes(route_path)) classes +=' active';

            }
            
            if (row.active_routes && row.active_routes.length) {
                var route_matched = row.active_routes.find(item => item == route_name)
                if (route_matched) {
                    classes += ' active';
                }
            }

            if(row.expand) classes+=' show';

            return classes;

        },
        toggle_bell_notifications: function(){

            this.flag.show_bell_notifications=!this.flag.show_bell_notifications;

            if(this.flag.show_bell_notifications){
                this.get_bell_notifications();
            }

        },
        get_bell_notifications: async function(){

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/get_bell_notifications');

            try {
                var res=await jq.get(url);
                ref.list.bell_notifications=res.data.bell_notifications;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        close_bell_notifications: function(){
            this.flag.show_bell_notifications=false;
        },
        bell_notification_mark_as_read: async function(id, index){
            
            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/set_notification_read_at');
            
            try{

                await jq.post(url, {
                    notification_id: id
                });
                console.log(index)

                // ref.remove_row(ref.list.bell_notifications, index);
                this.get_bell_notifications()

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        goto_url: function(row){
            if (row.url) {
                // window.location.href = row.url;
                window.open(row.url, '_blank')
            }
        },
        close_acc_dropdown: function(){
            this.flag.acc_dropdown=false;
        }
    },
    computed:{
        store_auth_user:function(){
            return this.$store.state.site.auth_user;
        },
        store_uni: function(){
            return this.$store.state.site.university;
        },
        store_permissions: function () {
            return this.$store.state.site.auth_user.user_permissions
        }
    },
    mounted: function(){

        var jq=this.jq();
        jq('body').removeClass('blank-page');
        feather.replace();
        // this.list.nav_menu = this.nav_static_menu

        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
        
    },
    created: function(){
        this.setup_urls();
        this.check_uni_auth();
        this.get_bell_notifications();
    },
    updated: function () {
        feather.replace();
    }
}
</script>

<style type="text/css">

.bookmark-wrapper{
    margin-left:15px;
}    
.navbar-nav{
    margin-right: 7px;
}

.bell-n-dropdown-ul{
    height: auto;
    overflow: auto;
}

.settings-dropdown {
    left: -140px !important
}

ul.mobile_menu {
    /* background: rgb(228, 228, 228); */
    margin-left: -6px;
    /* margin-right: -0px; */
}
ul.mobile_menu li{
    padding: 10px 20px;
}
ul.mobile_menu li a{
    color: rgb(58, 58, 58);
    font-size: 15px;
    font-weight: 400;
    transition: 400ms;
}
ul.mobile_menu li a:hover{
    padding-left: 10px;
}

ul.mobile_menu li a span{
    padding-left: 10px !important;
}
.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > a {
    padding: 0.715rem 10px !important;
}
</style>