<template>
<section class="agent-account-managers">
<main-layout>

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Settings</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                            <li class="breadcrumb-item active">Agent Account Managers</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card account-managers-card">
        <div class="content-header row p-1">
            <div class="col">
                <h3 class="mt-0">Agent Account Managers</h3>                    
            </div>
            <div class="col text-right">
                <button class="btn btn-primary waves-effect waves-float waves-light mr-1" v-on:click="toggle_manager_form()">
                    <users-icon size="1.5x" class="custom-class"></users-icon>
                    Add New Manager
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="table table-hover table-sm">
                    <thead>
                        <tr>
                            <th class="text-center" width="20px">SL</th>
                            <th>Full Name</th>
                            <th>Designation</th>
                            <th class="text-center">Active</th>
                            <th>Region</th>
                            <th>Countries</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="list.managers && list.managers.length">
                        <tr v-for="(row, index) in list.managers" :key="row.id">
                            <td v-html="index+1" class="text-center"></td>
                            <td>
                                <div>
                                <span style="margin-right: 3px;" v-html="row.given_name"></span>
                                <span v-html="row.family_name"></span>
                                </div>
                                <span class="badge badge-light-primary badge-pill" v-html="row.email"></span>
                            </td>
                            <td v-html="row.designation"></td>
                            <td class="text-center">
                                <span v-show="row.active" class="badge badge-light-success badge-pill">Yes</span>
                                <span v-show="!row.active" class="badge badge-light-danger badge-pill">No</span>
                            </td>
                            <td v-html="row.region.title"></td>
                            <td>
                                <div class="multiple-avatars" v-for="country in row.countries" :key="country.id" v-tooltip="country.name">
                                    <img class="rounded-circle" :src="country.flag_url" height="20"/>
                                </div>
                            </td>
                            <td class="text-center">
                                <button class="btn btn-outline-info btn-sm" style="margin-right: 5px;" v-on:click="edit_manager(row)">
                                    <edit-3-icon size="1.5x" class="custom-class"></edit-3-icon>
                                </button>
                                <button class="btn btn-outline-danger btn-sm">
                                    <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th colspan="7" class="text-center">
                                <span v-if="list.managers === null" >Processing...</span>
                                <span v-else >
                                    <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                    Found no agent account manager.
                                </span>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <sidebar-form
        :show_sidebar="flag.show_manager_form"
        v-on:toggle-sidebar="toggle_manager_form()"
        title="Add/Edit Account Manager"
    >

        <div class="card-body">
            <h5 class="card-title text-primary">
                Add/Edit Account Manager
            </h5>
            <div class="form-row mt-2">
                <div class="col-md-6 form-group">
                    <label for="">
                        Given Name
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control form-control-sm" placeholder="Given Name" v-model="form.manager.given_name"/>
                </div>
                <div class="col-md-6 form-group">
                    <label for="">
                        Family Name
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control form-control-sm" placeholder="Family Name" v-model="form.manager.family_name"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col form-group">
                    <label for="">
                        Designation
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control form-control-sm" placeholder="Enter Designation" v-model="form.manager.designation"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col form-group">
                    <label for="">
                        Email
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control form-control-sm" placeholder="Enter Email" v-model="form.manager.email"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col form-group">
                    <label for="">
                        Select Region
                        <span class="text-danger">*</span>
                    </label>
                    <treeselect v-model="form.manager.region_id" :options="list.regions" v-on:select="form.manager.countries=[]"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col form-group">
                    <label for="">
                        Select Countries
                        <span class="text-danger">*</span>
                    </label>
                    <treeselect v-model="form.manager.countries" :options="computed_manager_countries" :multiple="true"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col-md-6 form-group">
                    <label for="">Password</label>
                    <input type="password" class="form-control form-control-sm" placeholder="Password" v-model="form.manager.password"/>
                </div>
                <div class="col-md-6 form-group">
                    <label for="">Re-type Password</label>
                    <input type="password" class="form-control form-control-sm" placeholder="Type Again" v-model="form.manager.password_confirmation"/>
                </div>
            </div>

            <div class="form-row mt-1">
                <div class="col-md-6 form-group">
                    <button class="btn btn-light btn-block" v-on:click="toggle_manager_form()">
                        <x-icon size="1.5x" class="custom-class"></x-icon>
                        Cancel
                    </button>
                </div>
                <div class="col-md-6 form-group" v-on:click="save_manager()">
                    <button class="btn btn-primary btn-block">Save</button>
                </div>
            </div>

        </div>

    </sidebar-form>

</main-layout>
</section>
</template>

<script>
import MainLayout from '../MainLayout.vue';
import { UsersIcon, XIcon, Edit3Icon, Trash2Icon } from 'vue-feather-icons';
import SidebarForm from '../../SidebarForm.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect'

export default {
    name: 'AgentAccountManagers',
    components:{
        MainLayout, SidebarForm, Treeselect,
        UsersIcon, XIcon, Edit3Icon, Trash2Icon
    },
    data: ()=>{
        return {
            list:{
                managers: null,
                regions: []
            },
            flag:{
                show_manager_form: false
            },
            form:{
                manager:{
                    id:'',
                    given_name:'',
                    family_name:'',
                    designation:'',
                    email:'',
                    region_id:null,
                    countries:[],
                    password:'',
                    password_confirmation:''
                }
            }
        };
    },
    methods:{
        init_data: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/get_agent_account_managers');

            try{
                ref.wait_me('.account-managers-card');
                var res=await jq.get(url);
                ref.list.managers=res.data.managers;
                ref.list.regions=res.data.regions;
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.account-managers-card', 'hide');
            }

        },
        toggle_manager_form: function(){

            this.flag.show_manager_form=!this.flag.show_manager_form;

            if(!this.flag.show_manager_form){
                this.reset_manager_form();
            }

        },
        reset_manager_form: function(){
            var form=this.form.manager;
            form.id='';
            form.given_name='';
            form.family_name='';
            form.designation='';
            form.email='';
            form.region_id=null;
            form.countries=[];
            form.password='';
            form.password_confirmation='';
        },
        save_manager: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/save_agent_account_managers');
            var form_data=ref.form.manager;

            try{
                ref.wait_me('.sidebar-form');
                var res=await jq.post(url, form_data);
                ref.init_data();
                ref.alert(res.msg);
                ref.toggle_manager_form();
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.sidebar-form', 'hide');
            }

        },
        edit_manager: function(row){
            var ref=this;
            var form=ref.form.manager;

            form.id=row.id;
            form.given_name=row.given_name;
            form.family_name=row.family_name;
            form.designation=row.designation;
            form.region_id=row.region_id;
            form.email=row.email;

            var country_ids=row.countries.map(each=>{
                return each.id
            });

            // console.log(country_ids);

            this.form.manager.countries=country_ids;

            // console.log(form.countries);

            form.password='';
            form.password_confirmation='';
            ref.flag.show_manager_form=true;
        }
    },
    computed:{
        computed_manager_countries: function(){
            var ref=this;
            if(ref.form.manager.region_id){

                //ref.form.manager.countries=[];

                var region=ref.list.regions.find(row=>{
                    return row.id==ref.form.manager.region_id;
                });

                return region.countries;
                
            }else return [];
        }
    },
    created: async function(){
        var ref=this;
        ref.setup_urls();
    },
    mounted: function(){
        feather.replace();
        this.init_data();
    }
}
</script>

<style scoped>
.multiple-avatars{
    display: inline;
    margin-right: -4px;
    /* border: 1px solid #777; */
    border-radius: 50%;
    padding: 2px;
    padding-bottom: 4px;
    background-color: #ddd;
}
</style>