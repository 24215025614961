<template>
    <div>
        <!-- Remarks start here -->
        <div class="card wait_agent_remarks" v-if="permission_check('agent_remarks_view')">
            <!-- <div class="card-header">
                <h4 class="card-title">Blockquotes styling <small class="text-muted">Default</small></h4>
            </div> -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 d-flex" v-if="permission_check('agent_remarks_add')">
                        <input v-model="agent_remarks.remarks" @keyup.enter="saveRemarks()" placeholder="Enter remarks" class="form-control"/>
                        <div class="ml-1">
                            <button class="btn btn-primary waves-effect waves-float waves-light float-right" v-if="editItem.id" @click="updateRemarks(editItem)">Update</button>
                            <button class="btn btn-primary waves-effect waves-float waves-light float-right" v-else @click="saveRemarks()">Save</button>
                        </div>
                    </div>
                </div>
                <div class="form-row agent_remarks_list mt-1" v-if="permission_check('agent_remarks_view')">
                    <div class="col-12">
                        <div class="form-row mt-1" v-for="(item, index) in remarksListData" :key="index">
                            <div class="col-12" v-if="item.remarks_by_me">
                                <div class="form-row">
                                    <div class="col-sm-7">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar bg-light-danger mr-1" v-if="item.created_by">
                                                <div class="avatar-content" v-html="avatarText(item.created_by.name)" v-tooltip="item.created_by.name"></div>
                                            </div>
                                            <div>
                                                <div class="remarks_text_by_me">
                                                    <p class="p-0 m-0 mb-25" v-html="item.remarks_by_me"></p>
                                                </div>
                                                <small v-html="dNow(item.created_at, 'MMMM Do YYYY, h:mm:ss a')"></small>
                                            </div>
                                                <i v-tooltip="'Edit'" @click="editData(item)" class="fa fa-pencil cursor-pointer ml-1 mr-1"></i>
                                                <i v-tooltip="'Delete'"  @click="deleteData(item)" class="fa fa-trash-o cursor-pointer text-danger"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-else>
                                <div class="form-row" >
                                    <div class="col-sm-7">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar bg-light-danger mr-1" v-if="item.created_by">
                                                <div class="avatar-content" v-html="avatarText(item.created_by.name)" v-tooltip="item.created_by.name"></div>
                                            </div>
                                            <div>
                                                <div class="remarks_text_other">
                                                    <p class="p-0 m-0 mb-25" v-html="item.remarks_by_other"></p>
                                                </div>
                                                <small v-html="dNow(item.created_at)"></small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import feather from 'feather-icons';
// import { VueEditor } from "vue2-editor";
// import { BTabs, BTab} from 'bootstrap-vue';
import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'

export default{
    name: 'AgentRemarks',
    props: ['agent_company_id'],
    components:{
        // VueEditor,
        // BTabs, BTab,
    },
    data:()=>{
        return {
            bodyContentCustomToolbar: [
                ["bold", "italic", "underline", "link"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            remarksListData: [],
            agent_remarks: {
                remarks: '',
            },
            editItem: {}
        };
    },
    watch: {
    },
    methods:{
        editData: function (item) {
            this.editItem = item
            this.agent_remarks.remarks = item.remarks
        },
        clearRemarks: function () {
            this.agent_remarks = {
                remarks: '',
            }
        },
        saveRemarks: async function(){

            var ref=this;
            var jq=this.jq();

            try{
                const params = Object.assign({}, this.agent_remarks, { agent_company_id: this.agent_company_id }) 
                var res = await jq.post(ref.url('api/v1/university/ajax/store_agent_remarks'), params);
                this.clearRemarks()
                this.getRemarksListData();
                ref.alert(res.msg);

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }

        },
        updateRemarks: async function(item){

            var ref=this;
            var jq=this.jq();

            try{
                const params = Object.assign({id: item.id}, this.agent_remarks, { agent_company_id: this.agent_company_id }) 
                var res = await jq.post(ref.url('api/v1/university/ajax/update_agent_remarks'), params);
                this.clearRemarks()
                this.getRemarksListData();
                this.editItem = {}
                ref.alert(res.msg);

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }

        },
        getRemarksListData: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { agent_company_id: this.agent_company_id })
                ref.wait_me('.wait_agent_remarks');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_remarks_data'), params);
                const auth_user = this.auth_user
                if (res.data.list.length) {
                    this.remarksListData = res.data.list.map(item => {
                        item.remarks_by_other = ''
                        item.remarks_by_me = ''
                        if (item.created_by.id == auth_user.id) {
                            item.remarks_by_me = item.remarks
                            console.log('remarks_by_me')
                        } else {
                            console.log('remarks_by_other')
                            item.remarks_by_other = item.remarks
                        }
                        return Object.assign({}, item)
                    })
                }
                console.log('remarksListData', this.remarksListData)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_agent_remarks', 'hide');
            }
        },
        
        deleteData: function(item){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete Remarks!',
                html: `Are you sure to delete?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirmed(item)
                }
            })
        },
        deleteDataConfirmed: async function(item){
            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { id: item.id })
                var res = await jq.post(ref.url('api/v1/university/ajax/delete_agent_remark'), params);
                this.getRemarksListData();
                swal("Remarks Delete", res.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
        console.log('list id from componant ', this.list_id)
    },
    mounted: function(){
        this.getRemarksListData()
        feather.replace();
        console.log('this.agent_company_id', this.agent_company_id)
    }
}
</script>

<style>
.wait_agent_remarks {
    height: 352px !important;
}
.agent_remarks_list {
    /* width: 100% !important; */
    height: 258px !important;
    overflow-y: auto;
    /* overflow-x: hidden; */
}

.agent_remarks_list::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.agent_remarks_list::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}

.agent_remarks_list::-webkit-scrollbar-thumb
{
    background-color: #047EAF;
    border-radius: 10px;
    background-image: -webkit-linear-gradient(0deg,rgba(255, 255, 255, 0.5) 25%, transparent 25%, transparent 50%,rgba(255, 255, 255, 0.5) 50%,rgba(255, 255, 255, 0.5) 75%, transparent 75%,transparent)
}
.remarks_text_other {
    /* background: #197CF3; */
    background: #e4e6eb;
    /* background: #d7dae0; */
    color: #000;
    margin: 0px;
    padding: 10px 10px 10px 10px;
    border-radius: 20px 15px 15px 0px;
}
.remarks_text_by_me {
    /* background: #197CF3; */
    background: rgb(4, 126, 175);
    color: #FFF;
    padding: 10px 10px 10px 10px;
    border-radius: 15px 20px 0px 15px;
    /* width: 270px; */
    /* display: inline-block; */
    /* text-align: right; */
}
</style>