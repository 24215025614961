<template>
<section class="component-data-list">
    <main-layout>
        <!-- BEGIN: Content-->
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <h2 class="content-header-title mb-0">Student Status</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                    <!-- Input Sizing start -->
                    <section id="input-sizing">
                        <div class="row match-height">
                            <div class="col-md-4 col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            List of Student Status
                                        </h4>
                                    </div>
                                    <div class="card-body wait_me_list_data">
                                        <div class="row">
                                            <div class="col-12">
                                                <div v-for="(item, index) in listData" :key="index" class="alert alert-primary alert-dismissible fade show" role="alert" style="width: 100%;">
                                                    <div class="alert-body" v-html="item.name"></div>
                                                    <button type="button" @click="editData(item)" class="close" style="margin-right: 25px;">
                                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                    </button>                                                

                                                    <button type="button" @click="deleteData(item, index)"  class="close">
                                                        <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Create Student Status</h4>
                                    </div>
                                    <div class="card-body wait_me_insert_form">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="name">Status Name <span class="text-danger">*</span></label>
                                                    <input type="text" v-model="form.student_status.name" class="form-control" id="name" placeholder="Enter status name">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="colour_code">Color Code <span class="text-danger">*</span></label>
                                                    <input type="text" v-model="form.student_status.colour_code" class="form-control" id="colour_code" placeholder="Enter color code">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button type="button" class="btn btn-success mr-1" @click.prevent="insertFormData()">Save</button>
                                                <button type="button" @click="insert_reset()" class="btn btn-outline-secondary">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- Input Sizing end -->
                </div>
        <!-- END: Content-->

        <sidebar-form 
            :show_sidebar="flag.show_modal_form"
            v-on:toggle-sidebar="toggle_modal_form()"
            title="Add Regional Supervisor"
            >
            <div class="mb-1">
                <h5 class="">
                    <span class="align-middle">Edit Student Status</span>
                </h5>
            </div>
            <div class="flex-grow-1 wait_me_edit_form">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="edit_name">Status Name <span class="text-danger">*</span></label>
                            <input type="text" v-model="editItem.name" class="form-control" id="edit_name" placeholder="Enter status name">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="edit_colour_code">Color Code <span class="text-danger">*</span></label>
                            <input type="text" v-model="editItem.colour_code" class="form-control" id="edit_colour_code" placeholder="Enter color code">
                        </div>
                    </div>
                    <div class="col-12">
                        <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                        <button type="button" @click="update_reset()" class="btn btn-outline-secondary">Reset</button>
                    </div>
                </div>
            </div>
        </sidebar-form>

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import SidebarForm from '../../SidebarForm.vue';
import swal from 'bootstrap-sweetalert';
import { EditIcon, Trash2Icon } from 'vue-feather-icons'

export default{
    name: 'StudentStatus',
    components:{
        MainLayout,
        SidebarForm,
        EditIcon,
        Trash2Icon
    },
    data:()=>{
        return {
            saveData: true,
            showModal: false,
            icon: 'edit',
            flag:{
                show_modal_form:false
            },
            form: {
                student_status: {
                    id: '',
                    name: '',
                    colour_code: '',
                }
            },
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                }        
            },
            editItem: {},
            listData: [],
        };
    },
    computed: {
    },
    created () {
        this.setup_urls();
    },
    methods:{
        insert_reset: function () {
            this.form.student_status.name = ''
            this.form.student_status.colour_code = ''
        },
        update_reset: function () {
            this.editItem = {}
        },
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        formClose: function() {
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        editData: function(item) {
            this.editItem = this.clone(item);
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        deleteData: async function (item, index) {
            swal({
                title: "Are you sure to delete?",
                text: "Press Yes to confirm or Cancel",
                type: "warning",
                showCancelButton: true,
                cancelButtonClass: "btn-light",
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            }, (isConfirm)=>{
                if(!isConfirm) return;
                this.deleteDataConfirm(item, index)
            });
        },
        deleteDataConfirm: async function (item, index) {
            var ref=this;
                var jq=ref.jq();
                try {
                    var res = await jq.get(ref.url('api/v1/university/ajax/delete_student_status_data'), item);
                    this.listData.splice(index, 1)
                    ref.alert(res.msg)
                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
        },
        getStatusList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_student_status_list'));
                this.listData = res.data.list
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        insertFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/add_student_status'), ref.form.student_status);
                this.getStatusList()
                ref.alert(res.msg);
                this.insert_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_student_status'), ref.editItem);
                this.getStatusList()
                this.formClose()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
    },
    mounted: function(){
        feather.replace();
        this.getStatusList();
    }
}
</script>

<style scoped>
    /* .regional-form-section {
        min-height: 100% !important;
    } */

</style>