<template>
    <div class="card wait_me_verified_list_data">
        <div class="card-header d-block">
            <div class="row">
                <div class="col-6 col-md-6">
                    <h3>Enrolled List by Uni List</h3>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th width="75px">#</th>
                            <th>Agent Ref.</th>
                            <th>Student Name</th>
                            <th>Intake</th>
                            <!-- <th>University</th> -->
                            <th>University Ref.</th>
                            <th>Start Date</th>
                            <th>Added By</th>
                        </tr>
                    </thead>
                    <tbody v-if="bulkStudentListData.length > 0">
                        <tr v-for="(item, index) in bulkStudentListData" :key="index">
                            <td v-html="index + paginationVerified.slOffset"></td>
                            <td v-html="item.agent_ref_no"></td>
                            <td v-html="item.first_name + ' ' + item.last_name"></td>
                            <td>
                                <span v-if="item.intake"  v-html="item.intake.name"></span>
                                <!-- <span  @click="gotoIntakeWiseAgentListPage(item)" class="badge badge-pill badge-light-primary" v-html="item.intake_name"></span> -->
                            </td>
                            <!-- <td>
                                <span v-if="item.university"  v-html="item.university.name"></span>
                            </td> -->
                            <td v-html="item.uni_ref_no"></td>
                            <td v-html="dDate(item.start_date)"></td>
                            <td>
                                <span v-if="item.added_by"  v-html="item.added_by.name"></span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th colspan="7" class="text-center">
                                <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                Found no student data.
                            </th>
                        </tr>
                    </tbody>
                </table>
                <b-pagination
                    class="mt-1 mr-1"
                    align="right"
                    v-model="paginationVerified.currentPage"
                    pills
                    :per-page="paginationVerified.perPage"
                    :total-rows="paginationVerified.totalRows"
                    @input="searcVerifiedBulkStudenthData"
                    >
                </b-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import feather from 'feather-icons';
import { BPagination } from 'bootstrap-vue'

export default{
    name: 'EnrolledUniStudentList',
    metaInfo: {
        titleTemplate: '%s | Enrolled List by Uni List'
    },
    components:{
        BPagination
    },
    data:()=>{
        return {
            bulkStudentListData: [],
            editItem: {},
        };
    },
    methods:{
        editData: function (item) {
            this.editItem = item
            // console.log('edit data', item)
        },
        async searcVerifiedBulkStudenthData () {
            this.getbulkStudentListData()
        },
        getbulkStudentListData: async function(){

            var ref=this;
            var jq=ref.jq();

            if (!ref.$route.params.id) {
               this.$router.push({ name: 'AgentWiseStudentList' })
            } else {
                this.list_id = this.hash_id(ref.$route.params.id, false)[0]
                if (!this.list_id) {
                    this.$router.push({ name: 'AgentWiseStudentList', params: { id: this.list_id } })
                }
            }

            try {
                const params = Object.assign({}, { list_id: this.list_id, page: this.paginationVerified.currentPage, per_page: this.paginationVerified.perPage })
                ref.wait_me('.wait_me_verified_list_data');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_bulk_agent_student_list'), params);
                // console.log('this.bulkStudentListData', res.data)
                this.bulkStudentListData = res.data
                this.paginationVerifiedData(res)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_verified_list_data', 'hide');
            }
        },
        bulkAgentStudentVerify: async function(item){

            var ref=this;
            var jq=ref.jq();

            ref.wait_me('.wait_me_unverified_list_data');

            try {
                var res = await jq.post(ref.url('api/v1/university/ajax/verify_unverified_agent_student'), item);
                ref.alert(res.msg)
                this.getbulkStudentListData();
                this.getUnbulkStudentListData();

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
                //item.mapped_with_uni = !item.mapped_with_uni
            }finally{
                ref.wait_me('.wait_me_unverified_list_data', 'hide');
            }
        },
    },
    computed: {},
    created: function(){
        this.setup_urls();
    },
    mounted: function(){
        feather.replace();
        this.getbulkStudentListData()
    }
}
</script>
