<template>
<section class="region-list">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">Settings</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active">Region List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Region List</h3>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_add_region_form()">
                            <i data-feather="plus-square"></i>
                            Add New Region
                        </button>
                        <!-- <router-link class="btn btn-primary waves-effect waves-float waves-light" to="/settings/add-region">
                            <i data-feather="plus-square"></i>
                            Add New Region
                        </router-link> -->
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Serial No.</th>
                                <th  width="10%">Region Name</th>
                                <th>Supervisor</th>
                                <th width="65%">Country Names</th>
                                <th width="15%">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="regionList && regionList.length > 0">
                            <tr v-for="(region, index) in regionList" :key="index">
                                <td v-html="index + 1"></td>
                                <td v-html="region.title"></td>
                                <td>
                                    <div v-if="region.supervisor && region.supervisor.user">
                                        <span v-html="region.supervisor.user.name"></span>
                                    </div>
                                </td>
                                <td>
                                    <!-- {{ region_countries }} -->
                                    <!-- <div v-if="region.region_countries">
                                        <span  class="badge badge-primary mr-1 mt-1" v-for="(country, index2) in regionCountry(region)" :key="index2" v-html="country.name"></span>
                                    </div> -->
                                    <div class="b-avatar-group" v-if="region.region_countries">
                                        <div class="b-avatar-group-inner" style="padding-left: calc(5.1px); padding-right: calc(5.1px);">
                                            <span v-for="(country, index2) in regionCountry(region)" :key="index2" v-tooltip="country.name"  class="b-avatar pull-up badge-secondary rounded-circle" style="margin-left: calc(-5.1px); margin-right: calc(-5.1px); width: 34px; height: 34px; margin-right: 10px; margin-bottom: 10">
                                                <span class="b-avatar-img"><img :src="country.flag_url" alt="avatar"></span>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <!-- <button class="btn btn-info" @click="editData(region)">Edit</button> -->
                                    <button @click="editData(region)" class="btn btn-outline-info btn-sm mr-1">
                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                    </button>                                                

                                    <button @click="deleteData(region, index)" class="btn btn-outline-danger btn-sm">
                                        <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="4" class="text-center">
                                    <span v-if="regionList === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no region data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <sidebar-form
            :show_sidebar="flag.show_add_region_form"
            v-on:toggle-sidebar="toggle_add_region_form()"
            title="Add New Region"
        >
            
            <div class="card-body">
                <h5 class="card-title text-primary" v-if="!form.region.id">Add Region</h5>
                <h5 class="card-title text-primary" v-if="form.region.id">Edit Region</h5>
                <div class="add_region_form">
                    <div class="form-group">
                        <label for="title"><b>Region Title</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.region.title" class="form-control" id="title" placeholder="Enter region title">
                    </div>
                    <div class="form-group">
                        <label for="title"><b>Assign Countries</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.region.country_ids" :options="regionExcludedCountry" multiple placeholder="Select Coutries" autocomplete="off"/>
                    </div>
                    <div class="form-group">
                        <label for="title"><b>Regional Supervisor</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.region.uni_user_id" :options="usersList" id="uni_user_id" placeholder="Select user" autocomplete="off"/>
                    </div>
                    
                    <button type="submit" class="btn btn-success pull-right" v-if="!form.region.id" @click.prevent="addRegion()">Save</button>
                    <button type="submit" class="btn btn-success pull-right" v-if="form.region.id" @click.prevent="updateRegion()">Update</button>
                    <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
                </div>
            </div>
        </sidebar-form>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../../SidebarForm.vue';
import { EditIcon, Trash2Icon, XIcon } from 'vue-feather-icons'
import swal from 'bootstrap-sweetalert';

export default{
    name: 'RegionList',
    components:{
        MainLayout,
        Treeselect,
        SidebarForm,
        EditIcon,
        Trash2Icon,
        XIcon
    },
    data:()=>{
        return {
            saveData: true,
            flag:{
                show_add_region_form:false
            },
            form: {
                region: {
                    id: '',
                    title: '',
                    uni_user_id: '',
                    country_ids: []
                }
            },
            regionCountryList: [],
            regionList: null,
            usersList: [],
        };
    },
    computed: {
        regionExcludedCountry: function () {
            var ref = this;
            if (ref.cn(ref.form.region, 'id', null)) {
                var country_ids = []
                ref.regionList.forEach(item => {
                    if (item.id == ref.form.region.id) {
                        return ;
                    }

                    item.region_countries.forEach(country => {
                        country_ids.push(country.country_id);
                    })
                })
                return this.countryList.filter(item => {
                    return !country_ids.includes(item.id);
                })
            } else {
                return this.countryList
            }
        },
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                    return {
                        id: each.id,
                        label: each.name
                    }
                });
        }
    },
    created: async function(){
        this.setup_urls();
        
        
        this.getRegionList();
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        })
        // console.log('this.countryList', this.countryList)
    },
    methods:{
        toggle_add_region_form: function(){
            this.flag.show_add_region_form = !this.flag.show_add_region_form;
            this.form.region = {}
        },
        formClose: function() {
            this.toggle_add_region_form()
        },
        regionCountry (region) {
            const countryList = region.region_countries.map(item => {
                if (!item.country.flag_url) {
                    console.log('item.country.flag_url undefined')
                } else {
                    console.log('item.country.flag_url', item.country.flag_url)
                }
                // console.log('item.country.flag_url', item.country.flag_url)
                return {
                    id: item.country.id,
                    name: item.country.name,
                    flag_url: item.country.flag_url
                }
            })
            console.log('countryList', countryList)
            return countryList
        },
        getRegionList: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.region-list');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_region_list'));
                // ref.alert(res.msg);
                this.regionList = res.data.regionList

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.region-list', 'hide');
            }
        },
        addRegion: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.add_region_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/add_region'), ref.form.region);
                ref.alert(res.msg);
                this.getRegionList()
                this.toggle_add_region_form()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add_region_form', 'hide');
            }
        },
        updateRegion: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.add_region_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_region'), ref.form.region);
                this.getRegionList()
                ref.alert(res.msg);
                this.toggle_add_region_form()
                this.form.region.title = '';
                this.form.region.country_ids = [];
                this.saveData = true

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add_region_form', 'hide');
            }
        },
        editData: function (region) {
            this.form.region = {}
            this.toggle_add_region_form()
            this.saveData = false
            const editItem = {
                id: region.id,
                title: region.title,
                uni_user_id: region.supervisor.uni_user_id,
                supervisor_id: region.supervisor.id,
                country_ids: region.region_countries.map(item => {
                    return item.country.id
                })
            }

            this.form.region = JSON.parse(JSON.stringify(editItem))
        },
        deleteData: async function (item, index) {
            swal({
                title: "Are you sure to delete?",
                text: "Press Yes to confirm or Cancel",
                type: "warning",
                showCancelButton: true,
                cancelButtonClass: "btn-light",
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            }, (isConfirm)=>{
                if(!isConfirm) return;
                this.deleteDataConfirm(item, index)
            });
        },
        deleteDataConfirm: async function (item, index) {
            var ref=this;
                var jq=ref.jq();
                try {
                    var res = await jq.get(ref.url('api/v1/university/ajax/delete_region_data'), item);
                    this.regionList.splice(index, 1)
                    ref.alert(res.msg)
                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
        },
        getUsersList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_uni_users_list'));
            this.usersList = res.data.usersList.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        },
    },
    mounted: function(){
        feather.replace();
        this.getUsersList()
    }
}
</script>
