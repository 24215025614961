<template>
    <main-layout>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-8 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Gateway</h2> -->
                        <h5 class="content-header-title float-left mt-50">Gateway</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item"><router-link :to="`/gateway/dispute-list`">Dispute Intake List</router-link></li>
                                <li class="breadcrumb-item"><router-link :to="`/gateway/dispute-list`">{{ intakeItem.name }}</router-link></li>
                                <li class="breadcrumb-item active">Dispute Student List By Intake</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="content-body">
                <section class="app-user-list">
                <section class="modern-horizontal-wizard">
                    <div class="bs-stepper wizard-modern modern-wizard-example">
                        <div class="bs-stepper-header">
                            <div class="step ulan_custom_steper" :class="[isActive('one') ? activeClass : '', errorClass]">
                                <button  @click="setActive('one')" class="step-trigger">
                                    <span class="bs-stepper-box">Dispute All Students</span>
                                    <!-- <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Dispute All Students</span> 
                                    </span> -->
                                </button>
                            </div>
                            <div  class="step ulan_custom_steper" :class="[isActive('two') ? activeClass : '', errorClass]">
                                <button  @click="setActive('two')" type="button" class="step-trigger">
                                    <span class="bs-stepper-box">Resolved Students</span>
                                    <!-- <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Resolved Students</span> 
                                    </span> -->
                                </button>
                            </div>
                            <div class="step ulan_custom_steper" :class="[isActive('three') ? activeClass : '', errorClass]">
                                <button @click="setActive('three')" type="button" class="step-trigger">
                                    <span class="bs-stepper-box">Rejected Students</span>
                                    <!-- <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Rejected Students</span> 
                                    </span> -->
                                </button>
                            </div>
                        </div>
                        <!-- <div class="bs-stepper-content"> -->
                        <div class="">
                            <div id="bulk-student" class="content wait_me_list_data" :class="{ active: isActive('one') }">

                                <disputed-all-student-list-by-intake
                                 :intakeItem="intakeItem"
                                 ref="disputed_all_student_list_by_intake"
                                 />

                            </div>
                            <div id="international-office" class="content wait_me_list_data" :class="{ active: isActive('two') }">
                                <disputed-student-resolved-list-by-intake 
                                :intakeItem="intakeItem"
                                ref="disputed_resolved_student_list_by_intake"
                                />
                            </div>

                            <div id="finance-office" class="content wait_me_list_data" :class="{ active: isActive('three') }"> 
                                <disputed-student-rejected-list-by-intake
                                :intakeItem="intakeItem"
                                ref="disputed_rejected_student_list_by_intake"
                                />
                            </div>

                        </div>
                    </div>
                </section>
                </section>
            </div>

    </main-layout>
</template>

<script>
import MainLayout from '../../MainLayout.vue';
import feather from 'feather-icons';


import DisputedAllStudentListByIntake from './DisputedAllStudentListByIntake.vue';
import DisputedStudentResolvedListByIntake from './DisputedStudentResolvedListByIntake.vue';
import DisputedStudentRejectedListByIntake from './DisputedStudentRejectedListByIntake.vue';

export default{
    name: 'DisputedStudentListByIntake',
    metaInfo: {
        titleTemplate: '%s | Disputed Student List By Intake'
    },
    components:{
        MainLayout,
        DisputedAllStudentListByIntake,
        DisputedStudentResolvedListByIntake,
        DisputedStudentRejectedListByIntake
    },
    data:()=>{
        return {
            activeClass: 'border_bottom_5',
            errorClass: 'text-danger',
            activeItem: 'one',
            intakeItem: {},
            studentListItem: {},
            flag:{
                show_agent_form:false,
                show_filter:false,
                show_modal_form:false
            },
            search: {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                name: '',
                last_name: '',
                agent_code: '',
                start_date: '',
                intake_month: null,
                intake_year: null,
                submission_status_id: null,
                intake_id: null,
                list_id: null,
            },
            editItem: {},
        };
    },
    watch: {
    },
    methods:{
        setActive: function (menuItem) {
            this.activeItem = menuItem // no need for Vue.set()
            if (menuItem == 'one') {
                this.$refs.disputed_all_student_list_by_intake.getListData();
            } else if (menuItem == 'two') {
                this.$refs.disputed_resolved_student_list_by_intake.getListData();
            } else if (menuItem == 'three') {
                this.$refs.disputed_rejected_student_list_by_intake.getListData();
            }
            this.gateway_count_student_by_statuses();
        },
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
        getIntakeById: async function(){
            var ref=this;
            var jq=ref.jq();

            if (this.search.intake_id != null) {
                try {
                    ref.wait_me('.student-list-card');
                    const params = Object.assign({}, this.search)
                    var res = await jq.get(ref.url('api/v1/agent/ajax/get_intake_by_id'), params);
                    ref.intakeItem = res.data.intake

                } catch (err) {
                    // ref.alert(ref.err_msg(err), 'error');
                }
                finally {
                    ref.wait_me('.student-list-card', 'hide');
                }
            } else {
                this.getListData()
            }
        },
        processQueryString: function () {
            const query = this.$route.query
            if (query && query.action == 'requirement') {
                this.$refs.stu_reqs.open_stu_reqs(query);
            }
        }
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
        
        this.search.uni_ref_no = this.$route.params.uni_ref_no
        const query = this.$route.query
        this.search.intake_id = this.hash_id(query.intake_id, false)[0]
        
        if (query && query.uni_ref_no) {
            this.search.uni_ref_no = query.uni_ref_no
        }
    },
    mounted: function(){
        this.getIntakeById();
        feather.replace();
    }
}
</script>
<style type="text/css">
    .badge.badge-light-blue {
        background-color: rgba(51, 51, 255, 0.12);
        color: #3333ff !important;
    }        
    .badge.badge-light-declined {
        background-color: rgba(255, 51, 0, 0.12);
        color: #ff3300 !important;
    }
    .table th, .table td {
         padding: 0.72rem 0rem; 
        vertical-align: middle;
    }
    .btn-sm, .btn-group-sm > .btn {
        padding: 0.486rem .7rem !important; 
    }
    .avater_size_25 {
        width: 25px !important;
        height: 25px !important;
        font-size: 10px !important;
        font-weight: 700 !important;
    }
    /* .ulan_custom_steper {
        padding: 8px !important;
    }
    .ulan_custom_steper, .active {
        border-bottom: 4px solid rgb(4, 126, 175) !important;
    } */

    .bs-stepper .bs-stepper-header .ulan_custom_steper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.35rem !important;
        margin-right: 2px !important;
    }
    .border_bottom_5 {
        border-bottom: 5px solid rgb(0, 128, 160);
        color: #ff0000 !important;
    }
    .ulan_custom_steper .bs-stepper-box {
        min-width: 38px !important;
        min-height: 38px !important;
        width: auto !important;
        height: auto !important;
        padding: 8px !important;
        font-weight: 800 !important;
        background-color: rgb(108 108 108 / 12%) !important;
        color: #606060 !important;
    }
    .border_bottom_5 > button > span.bs-stepper-box {
        color: rgb(0, 128, 160) !important;
    }
    
</style>
