<template>
<section class="intake-list">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                        <h5 class="content-header-title float-left mt-50">Manage Intake</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item">Settings</li>
                                <li class="breadcrumb-item active">Intake List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Intake List</h3>
                        <small @click="getIntakeList()" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + filterCountData.total_count"></small>
                        <small @click="getStatusWiseIntakeListData('intake_status_published')" class="cursor-pointer badge badge-pill badge-light-success mr-1" v-html="'Active - ' + filterCountData.active_count"></small>
                        <small @click="getStatusWiseIntakeListData('intake_status_draft')" class="cursor-pointer badge badge-pill badge-light-warning mr-1" v-html="'Draft - ' + filterCountData.draft_count"></small>
                        <small @click="getStatusWiseIntakeListData('intake_status_closed')" class="cursor-pointer badge badge-pill badge-light-danger mr-1" v-html="'Closed - ' + filterCountData.closed_count"></small>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_add_intake_form()">
                            <i data-feather="plus-square"></i>
                            Add New Intake
                        </button>
                        <!-- <router-link class="btn btn-primary waves-effect waves-float waves-light" to="/settings/add-intake">
                            <i data-feather="plus-square"></i>
                            Add New Region
                        </router-link> -->
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive intake-list-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th width="1%" class="px-1">#</th>
                                <th width="8%" class="px-1">Intake</th>
                                <th width="10%" class="px-1">Gateway Open</th>
                                <th width="8%" class="px-1">Deadline</th>
                                <th width="10%" class="px-1 text-center">Total Agents</th>
                                <th width="10%" class="px-1 text-center">Total Students</th>
                                <!-- <th width="10%" class="px-1 text-center">Auto Verified</th> -->
                                <!-- <th width="8%" class="px-1 text-center">Not Tagged</th> -->
                                <th width="5%" class="px-1 text-center">Status</th>
                                <th width="15%" class="px-1 text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + 1" class="px-1"></td>
                                <td class="px-1">
                                    <!-- <span class="d-block" v-html="dMonth(item.intake_month)"></span> -->
                                    <span class="badge badge-light-info" v-html="item.name"></span>
                                </td>
                                <td class="px-1" v-html="dDate(item.start_date)"></td>
                                <td class="px-1">
                                    <div v-if="checkCurrentDateLessThenDate(item.end_date)">
                                        <span v-html="dDate(item.end_date)"></span>
                                    </div>
                                    <div v-else>
                                        <span v-html="dDate(item.end_date)"></span>
                                        <small v-html="'Expired'" class="badge badge-light-danger"></small>
                                    </div>
                                </td>
                                <td class="px-1 total-agents text-center" v-html="item.related_agent_list.length"></td>
                                <td class="px-1 total-students text-center" v-html="item.total_submitted_students"></td>
                                <!-- <td class="px-1 auto-verified text-center">
                                    <span v-html="item.verified_students"></span>/<span v-html="item.unverified_students"></span>
                                </td> -->

                                <!-- <td class="px-1 not-tagged text-center">
                                    <span v-if="cn(item, 'verification_status.code', null) && item.verification_status.code=='bulk_student_verified'" v-html="item.unverified_bulk_students"></span>
                                    <span v-else>-</span>
                                </td> -->

                                <td class="px-1 text-center">
                                    <span :class="'ml-1 badge badge-pill badge-light-' + item.status_color" v-html="item.status_name"></span>
                                </td>
                                <td class="text-right px-1">

                                    <!-- <button v-show="cn(item, 'verification_status.code', null) && item.verification_status.code=='upload_bulk_student'" class="btn btn-outline-warning btn-sm" v-on:click="goto_bulk_import(item)" style="margin-right: 5px;" v-tooltip="'Import'">
                                        <upload-icon size="1.5x" class="custom-class"></upload-icon>
                                    </button> -->

                                    <!-- <button v-show="cn(item, 'verification_status.code', null) && item.verification_status.code=='uploaded_bulk_student'" class="btn btn-outline-warning btn-sm" v-on:click="verify_bulk_students(item)"  style="margin-right: 5px;" v-tooltip="'Varify'">
                                        <user-check-icon size="1.5x" class="custom-class"></user-check-icon>
                                    </button> -->

                                    <button v-tooltip="'Edit Intake'" @click="editData(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;">
                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                    </button>                                                

                                    <button v-tooltip="'Delete Intake'" @click="deleteData(item, index)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                        <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                    </button>

                                    <button v-tooltip="'Send Intake Invitation Email to Agents'" @click="intake_email(item)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                        <mail-icon size="1.5x" class="custom-class"></mail-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="9" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no intake data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <sidebar-form
            :show_sidebar="flag.show_add_intake_form"
            v-on:toggle-sidebar="toggle_add_intake_form()"
            title="Add New Intake"
        >
            
            <div class="card-body">
                <h5 class="card-title text-primary" v-if="!form.intake.id">Add Intake</h5>
                <h5 class="card-title text-primary" v-if="form.intake.id">Edit Intake</h5>
                <div class="add_intake_form">
                    <div class="form-group">
                        <label for="intake_name"><b>Intake Name</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.intake.name" class="form-control" id="intake_name" placeholder="Enter intake name">
                    </div>
                    <div class="form-group">
                        <label for="start_date"><b>Start Date</b> <span class="text-danger">*</span></label>
                        <flat-pickr
                            id="start_date"
                            v-model="form.intake.start_date"
                            :config="configs.flatpickr_start"
                            class="form-control"
                            @input="set_intake_end_date"
                            placeholder="Select start date"/>
                    </div>
                    <div class="form-group">
                        <label for="end_date"><b>End Date</b> <span class="text-danger">*</span></label>
                        <flat-pickr
                            id="end_date"
                            v-model="form.intake.end_date"
                            :config="configs.flatpickr_end"
                            class="form-control"
                            placeholder="Select end date"/>
                    </div>
                    <div class="form-group">
                        <label for="status"><b>Status</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.intake.status" :options="statusTreeSlectList" placeholder="Select Status" id="status" autocomplete="off"/>
                    </div>
                    <div class="form-group">
                        <label for="status"><b>Intake Year</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.intake.year" :options="yearList" placeholder="Select Year" id="status" autocomplete="off"/>
                    </div>
                    <div class="form-group">
                        <label for="status"><b>Intake Month</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.intake.month" :options="monthList" placeholder="Select Month" id="status" autocomplete="off"/>
                    </div>
                    
                    <button type="submit" class="btn btn-success pull-right" v-if="!form.intake.id" @click.prevent="insertFormData()">Save</button>

                    <button type="submit" class="btn btn-success pull-right" v-if="form.intake.id" @click.prevent="updateFormData()">Update</button>

                    <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
                    
                </div>
            </div>
        </sidebar-form>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../../SidebarForm.vue';
import { EditIcon, Trash2Icon, XIcon, MailIcon} from 'vue-feather-icons'
// import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import swal from 'bootstrap-sweetalert';

export default{
    name: 'IntakeList',
    metaInfo: {
        titleTemplate: '%s | Manage Intake'
    },
    components:{
        MainLayout,
        Treeselect,
        SidebarForm,
        EditIcon,
        Trash2Icon,
        XIcon,
        flatPickr,
        //UserCheckIcon,
        MailIcon
    },
    data:()=>{
        return {
            saveData: true,
            filterCountData: {},
            flag:{
                show_add_intake_form:false
            },
            form: {
                intake: {
                    id: '',
                    uni_id: null,
                    status: null,
                    name: '',
                    intake_month: null,
                    start_date: '',
                    end_date: '',
                    year: null,
                    month: null,
                }
            },
            statusTreeSlectList: [],
            configs: {
                flatpickr_start: {
                    dateFormat: 'd M Y',
                },
                flatpickr_end: {
                    dateFormat: 'd M Y',
                    minDate: ''
                }        
            },
            intakeCountryList: [],
            listData: null,
            statusList:[]
        };
    },
    computed: {
        intakeExcludedCountry: function () {
            var ref = this;
            if (ref.cn(ref.form.intake, 'id', null)) {
                var country_ids = []
                ref.listData.forEach(item => {
                    if (item.id == ref.form.intake.id) {
                        return ;
                    }

                    item.intake_countries.forEach(country => {
                        country_ids.push(country.country_id);
                    })
                })
                return this.countryList.filter(item => {
                    return !country_ids.includes(item.id);
                })
            } else {
                return this.countryList
            }
        },
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                    return {
                        id: each.id,
                        label: each.name
                    }
                });
        }
    },
    created: async function(){
        this.setup_urls();
    },
    methods:{
        toggle_add_intake_form: function(){
            this.flag.show_add_intake_form = !this.flag.show_add_intake_form;
            this.form.intake = {}
        },
        update_reset: function () {
            this.form.intake = {};
            this.flag.show_add_intake_form = !this.flag.show_add_intake_form;
        },
        formClose: function() {
            this.toggle_add_intake_form()
        },
        getIntakeList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.intake-list-table');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_university_intake_list'));
                this.listData = res.data.list.map(item => {
                    const data = { icon: 'edit' }
                    const statusObj = this.statusList.find(status => status.id == item.status)
                    const yearObj = this.yearList.find(year => year.label == item.intake_month.split('-')[0])
                    const monthObj = this.monthList.find(month => month.id == parseInt(item.intake_month.split('-')[1], 10))

                    const statusData = {
                        status_name: statusObj != undefined ? statusObj.name : '',
                        status_color: statusObj != undefined ? statusObj.color : '',
                        month: monthObj != undefined ? monthObj.id : '',
                        year: yearObj != undefined ? yearObj.id : ''
                    }
                    return Object.assign({}, item, data, statusData)
                })
                this.filterCountData = res.data
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.intake-list-table', 'hide');
            }
        },
        getStatusWiseIntakeListData: async function(status){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, 
                {
                    status: status,
                })
                console.log('status', params)
                ref.wait_me('.wait_me_list_data');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_university_intake_list'), params);
                this.listData = res.data.list.map(item => {
                    const data = { icon: 'edit' }
                    const statusObj = this.statusList.find(status => status.id == item.status)
                    const yearObj = this.yearList.find(year => year.label == item.intake_month.split('-')[0])
                    const monthObj = this.monthList.find(month => month.id == parseInt(item.intake_month.split('-')[1], 10))

                    const statusData = {
                        status_name: statusObj != undefined ? statusObj.name : '',
                        status_color: statusObj != undefined ? statusObj.color : '',
                        month: monthObj != undefined ? monthObj.id : '',
                        year: yearObj != undefined ? yearObj.id : ''
                    }
                    return Object.assign({}, item, data, statusData)
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_list_data', 'hide');
            }
        },
        getStatusList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'uni_intake_statuses' }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);
                this.statusTreeSlectList = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })
                this.statusList = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        insertFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                const yearData = this.yearList.find(item => item.id == ref.form.intake.year)
                const params = Object.assign({}, ref.form.intake, { year: yearData ? yearData.label : '' })
                // const params = Object.assign({}, ref.form.intake)
                var res = await jq.post(ref.url('api/v1/university/ajax/add_university_intake'), params);
                this.getIntakeList()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                const yearData = this.yearList.find(item => item.id == ref.form.intake.year)
                const params = Object.assign({}, ref.form.intake, { year: yearData ? yearData.label : '' })
                var res = await jq.post(ref.url('api/v1/university/ajax/update_university_intake'), params);
                this.getIntakeList()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        editData: function (item) {
            this.toggle_add_intake_form()
            this.saveData = false

            this.form.intake = JSON.parse(JSON.stringify(item))
            this.form.intake.start_date = moment(this.form.intake.start_date).format("DD MMM YYYY");
            this.form.intake.end_date = moment(this.form.intake.end_date).format("DD MMM YYYY");
        },
        deleteData: async function (item, index) {
            // swal({
            //     title: "Are you sure to delete?",
            //     // text: "Press Yes to confirm or Cancel",
            //     html: "Press Yes to delete <b>" + this.dMonth(item.intake_month) + "</b>",
            //     type: "warning",
            //     showCancelButton: true,
            //     cancelButtonClass: "btn-light",
            //     confirmButtonClass: "btn-danger",
            //     confirmButtonText: "Yes",
            //     cancelButtonText: "Cancel",
            // }, (isConfirm)=>{
            //     if(!isConfirm) return;
            //     this.deleteDataConfirm(item, index)
            // });

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete <b>"+ this.dMonth(item.intake_month) + "</b>?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function (item, index) {
            var ref=this;
                var jq=ref.jq();
                try {
                    var res = await jq.get(ref.url('api/v1/university/ajax/delete_intake_data'), item);
                    this.listData.splice(index, 1)
                    ref.alert(res.msg)
                } catch (err) {
                    ref.alert(ref.err_msg(err), 'error');
                }
        },
        goto_bulk_import: function(item){
            this.$router.push({ name: 'BulkVerficationUpload', params: { id: this.hash_id(item.id) } })
        },
        verify_bulk_students: async function(item){

            var ref=this;
            var jq=ref.jq();

            ref.wait_me('.table-responsive');

            try {
                const params = Object.assign({}, { intake_id: item.id });
                var response = await jq.post(ref.url('api/v1/university/ajax/intake_wise_bulk_agent_student_verify'), params);
                this.getListData();
                swal("Bulk Student Verified!", response.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.table-responsive', 'hide');
            }

        },
        intake_email: function (item) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Send Email!',
                html: `Are you sure to send email on intake <b>${item.name}</b> to all active agents?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.intake_email_confirm(item)
                }
            })
        },
        intake_email_confirm: async function(item){

            var ref=this;
            var jq=ref.jq();

            ref.wait_me('.table-responsive');

            try {
                const params = Object.assign({}, { intake_id: item.id });
                var response = await jq.post(ref.url('api/v1/university/ajax/intake_email'), params);
                this.getIntakeList();
                swal("Intake Email Send!", response.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.table-responsive', 'hide');
            }

        },
        set_intake_end_date: function (ev) {
            console.log('ev', ev)
            this.configs.flatpickr_end.minDate = ev
        }
    },
    mounted: function(){
        feather.replace();
        this.getStatusList();
        this.getIntakeList();
    }
}
</script>
