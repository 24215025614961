<template>
<section class="add-new-agency">
    <main-layout>
        <div class="row">
            <div class="col-md-6 col-6 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h5 class="content-header-title float-left mb-0">Manage Agent</h5> -->
                        <h5 class="content-header-title float-left mt-50">Add Agent</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active"><router-link to="/agency-list">Manage Agent</router-link></li>
                                <li class="breadcrumb-item active">Add Agent</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-6 text-right">
                <router-link to="/agency-list" class="btn btn-outline-primary mb-2">Go Back</router-link>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-12 text-right">
                <router-link to="/agency-list" class="btn btn-outline-primary mb-2">Go Back</router-link>
            </div>
        </div> -->

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Add Agent</h3>
                    </div>
                    <div class="col-6 text-right">
                        <!-- <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_agent_form()"></button> -->
                        <router-link class="btn btn-primary waves-effect waves-float waves-light" to="/add-new-multiple-agent">
                            <i data-feather="plus-square"></i>
                            Import Excel
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="card-body new-agent-form">

                <div class="form-row mt-1" v-for="(row, index) in form.agents" :key="index">
                    <!-- <div class="col">
                        <select name="" id="" class="form-control">
                            <option value="">Select Supervisor</option>
                        </select>
                    </div> -->
                    <div class="col">
                        <input v-model="row.name" type="text" class="form-control" placeholder="Business Name">
                    </div>
                    <div class="col">
                        <input v-model="row.agent_code" type="text" class="form-control" placeholder="Banner Code">
                    </div>
                    <div class="col">
                        <treeselect v-model="row.country_id" :options="treeselect_countries" placeholder="Country" autocomplete="off"/>
                    </div>
                    <div class="col">
                        <input v-model="row.email" type="email" class="form-control" placeholder="Registered Email">
                    </div>

                    <div class="col">
                        <flat-pickr
                            id="email-count-date-filter"
                            :config="config.flatpickr"
                            class="form-control"
                            placeholder="Agreement Expiry"
                            v-model="row.agreement_expiry"
                        ></flat-pickr>
                    </div>   

                    <!-- <div class="col">
                        <input v-model="row.agent_mobile" type="text" class="form-control" placeholder="Agent Mobile">
                    </div> -->
                    <div class="col">
                        <input v-model="row.contact_person" type="text" class="form-control" placeholder="Key Contact Person">
                    </div>
                    <div class="col">
                        <input v-model="row.contact_email" type="text" class="form-control" placeholder="Key Contact Email">
                    </div>
                    <div class="col">
                        <input v-model="row.contact_number" type="text" class="form-control" placeholder="Key Contact Number">
                    </div>
                    <div class="col">
                        <treeselect v-model="row.territory_id" :options="territoryList" placeholder="Territory" id="territory_id" autocomplete="off"/>
                    </div>
                    <div class="col-1 text-right">
                        <button class="btn btn-outline-danger waves-effect" type="button" data-repeater-create="" v-on:click="remove_agent_form_row(index)">
                            x
                        </button>
                    </div>
                    
                </div>
                <button class="btn btn-outline-primary waves-effect mt-1" type="button" data-repeater-create="" v-on:click="add_agent_form_row()">
                    <i data-feather='plus'></i>
                    <span>Add New</span>
                </button>
                <button type="button" class="btn btn-success pull-right mt-1" v-if="saveData" @click.prevent="insertFormData()">Save</button>
                <button type="button" class="btn btn-success  pull-right mt-1" v-if="!saveData" @click.prevent="updateFormData()">Update</button>

            </div>
        </div>
    </main-layout>
</section>
</template>

<script>
import MainLayout from './MainLayout.vue';
import Treeselect from '@riophae/vue-treeselect';
import feather from 'feather-icons';
import swal from 'bootstrap-sweetalert';

export default {
    name: 'AddNewAgent',
    components:{
        MainLayout,
        Treeselect
    },
    data:()=>{
        return {
            list:{
                countries: []
            },
            saveData: true,
            form:{
                agents: [{
                    country_id: null,
                    supervisor_id: null,
                    territory_id: null,
                    name:'',
                    agent_code: '',
                    email:'',
                    type:'',
                    agreement_expiry:'',
                    agent_mobile_country_id:'',
                    agent_mobile:'',
                    contact_person:'',
                    contact_email:'',
                    contact_number_country_id:'',
                    contact_number:'',
                }]
            },
            row: [{
                country_id: null,
                supervisor_id: null,
                territory_id: null,
                name:'',
                email:'',
                type:'',
                agent_mobile_country_id:'',
                agent_mobile:'',
                contact_person:'',
                contact_number_country_id:'',
                contact_number:''
            }],
            config:{
                flatpickr:{
                    dateFormat: 'd M Y'
                }
            },
            territoryList: [],
        }
    },
    created () {
        this.setup_urls();
        this.init_data();
        this.getTerritoryList()
    },
    mounted () {
    },
    methods:{
        init_data: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/auth_user_info');

            try {

                var res = await jq.get(url);
                ref.list.countries=res.data.countries;

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        agent_form_row: ()=>{
            return {
                name:'',
                supervisor_id: null,
                country_id: null,
                territory_id: null,
                email:'',
                type:'',
                agent_mobile_country_id:'',
                agent_mobile:'',
                contact_person:'',
                contact_number_country_id:'',
                contact_number:''
            }
        },
        add_agent_form_row: function(){
            var ref=this;
            ref.form.agents.push(ref.agent_form_row());
            feather.replace();
        },
        remove_agent_form_row: function (index) {
            if (index >= 1) {
                swal({
                    title: "Are you sure?",
                    text: "Press Yes to confirm or Cancel",
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonClass: "btn-light",
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                }, (isConfirm)=>{

                    if(!isConfirm) return;
                    this.form.agents.splice(index, 1);
                    

                });
            }
        },
        insertFormData: async function () {
            var ref=this;
            var jq=ref.jq();
            
            try {
                var formData = {
                    agents: ref.form.agents
                }
                ref.wait_me('.new-agent-form');
                var res = await jq.post(ref.url('api/v1/university/ajax/add_agency'), formData);
                ref.alert(res.msg);
                // ref.form.agents = this.row
                this.$router.push('/agency-list')
                this.agent_form_row()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.new-agent-form', 'hide');
            }

        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                var res = await jq.post(ref.url('api/v1/university/ajax/update_agency'), ref.form.agents);
                ref.alert(res.msg);
                ref.form.agents = []
                this.saveData = true

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        editData: function (item) {
            this.saveData = false

            this.form.regional_supervisor = item
        },
        getTerritoryList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_active_territory_list_data'));
            this.territoryList = res.data.list.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        }
    },
    computed:{
        store_countries: function(){

            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.list.countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        }
    }
}
</script>
