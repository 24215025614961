<template>
<section class="agency-list">
    <main-layout>
        
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                        <h5 class="content-header-title float-left mt-50">Manage Course Level</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item">Settings</li>
                                <li class="breadcrumb-item active">Course Level List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card wait_me_list_data">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Course Level List</h3>
                    </div>
                    <div class="col-6 text-right">
                        <button v-show="form.uni_course_level.agent_ids.length" class="btn btn-info waves-effect waves-float waves-light" v-on:click="invitation_confirmation()">
                            <i data-feather="send"></i>
                            University Course level Add
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th width="75px">Serial No.</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + 1"></td>
                                <td>
                                    <span class="text-primary d-block" v-html="item.name"></span>
                                </td>
                                <td>
                                    <b-form-checkbox
                                        v-model="item.mapped_with_uni"
                                        v-on:change="uni_course_level_insert_or_delete(item)"
                                        :id="'switch-'+item.id"
                                        class="custom-control-success"
                                        name="check-button"
                                        switch
                                    />
                                    <!-- <b-form-checkbox
                                        :checked="item.uni_course != null ? true : false"
                                        v-on:change="uni_course_level_insert_or_delete(item)"
                                        :id="'switch-'+item.id"
                                        class="custom-control-success"
                                        name="check-button"
                                        switch
                                    /> -->
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="3" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no course level data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <b-modal class="pt-5" ref="invitation_confirmation">
            <template #modal-title>
                <span class="text-danger">Invitation email confirmation</span>
            </template>
            Are you sure do you want to Send Invitation for All?
            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.invitation_confirmation.hide()">No</button>
                <button type="button" class="btn btn-success waves-effect waves-float waves-light" v-on:click="uni_course_level_email()">Yes</button>
            </template>
        </b-modal>

    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import { BFormCheckbox } from 'bootstrap-vue'
//import SidebarForm from '../SidebarForm.vue';

import { BModal } from 'bootstrap-vue';

export default{
    name: 'CourseLevel',
    metaInfo: {
        titleTemplate: '%s | Course Level List'
    },
    components:{
        MainLayout,
        BModal,
        BFormCheckbox
    },
    data:()=>{
        return {
            agencyList: [],
            listData: null,
            flag:{
                show_agent_form:false
            },
            form:{
                uni_course_level:{
                    checked_all: false,
                    agent_ids:[]
                }
            }
        };
    },
    methods:{
        uni_course_level_insert_or_delete: async function(item){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.post(ref.url('api/v1/university/ajax/uni_course_level_insert_or_delete'), item);
                ref.alert(res.msg)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
                item.mapped_with_uni = !item.mapped_with_uni
            }
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_list_data');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_course_level_with_uni_course_list'));
                this.listData = res.data.list;

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_list_data', 'hide');
            }
        },
        uni_course_level_email: async function(){

            this.$refs.invitation_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/uni_course_level_emails');

            try{

                ref.wait_me('.wait_me_list_data');
                var res=await jq.post(url, ref.form.uni_course_level);

                ref.form.uni_course_level.checked_all=false;
                ref.form.uni_course_level.agent_ids=[];
                ref.alert(res.msg);

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.wait_me_list_data', 'hide');
            }

        }
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        }
    },
    created: function(){
        this.setup_urls();
    },
    mounted: function(){
        feather.replace();
        this.getListData()
    }
}
</script>
