<template>
  <section class="root">
    <sidebar-form
      :show_sidebar="flag.show_assign_task_modal"
      v-on:toggle-sidebar="show_modal()"
    >
      <div class="">
        <div class="mb-1 modal_form_heading">
          <h5>
            Task For
            <span class="badge badge-pill badge-light-secondary" v-if="agentStudentListInfo" v-html="agentStudentListInfo.list_code"></span>
          </h5>
        </div>

        <div class="tab_section mt-3">
          <b-tabs>
            <b-tab>
                <template #title>
                    <grid-icon size="1.5x" class="custom-class"></grid-icon> New Task
                </template>
                <div class="row wait_me_insert_form">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="title"><b>Title</b> <span class="text-danger">*</span></label>
                                    <input type="text" v-model="form.task.title" class="form-control" id="title" placeholder="Enter titles">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="user_id"><b>Responsible</b> <span class="text-danger">*</span></label>
                                    <treeselect v-model="form.task_users.user_id" :options="userList" placeholder="Select user" id="user_id" autocomplete="off"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="user_id"><b>Tag</b> <span class="text-danger"></span></label>
                                    <treeselect multiple v-model="form.task_users.tagged_user_id" :options="userList" placeholder="Select user" id="user_id" autocomplete="off"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="edit_end_date"><b>Due Date</b> <span class="text-danger">*</span></label>
                                        <flat-pickr
                                            id="edit_end_date"
                                            v-model="form.task.due_date"
                                            :config="configs.flatpickr"
                                            class="form-control"
                                            placeholder="Select date"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="status_priority_id"><b>Priority</b> <span class="text-danger">*</span></label>
                                    <treeselect v-model="form.task.status_priority_id" :options="priorityStatusesTreeselect" placeholder="Select priority" id="status_priority_id" autocomplete="off"/>
                                </div>
                            </div>
                            <!-- <div class="col-12">
                                <div class="form-group">
                                    <label for="remarks">Remarks <span class="text-danger"></span></label>
                                    <input type="text" v-model="form.task_remarks.remarks" class="form-control" id="remarks" placeholder="Enter remarks">
                                </div>
                            </div> -->
                            <div class="col-12">
                                <label for="description"><b>Description</b> <span class="text-danger"></span></label>
                                <vue-editor id="description editor1" :editor-toolbar="bodyContentCustomToolbar" v-model="form.task.description" placeholder="Enter description"></vue-editor>
                            </div>
                            <div class="col-12 mt-5">
                                <button class="btn btn-primary mr-1 waves-effect waves-float waves-light float-right" @click="insertFormData()">Save</button>
                                <button class="btn btn-outline-secondary waves-effect float-right mr-2"  @click="clearFormData()">Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab active>
                <template #title>
                    <align-justify-icon size="1.5x" class="custom-class"></align-justify-icon> Task Lists
                </template>
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive user-role-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Responsible</th>
                                <th>Title</th>
                                <th>Tagged</th>
                                <th class="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + 1"></td>
                                <td>
                                    <span class="badge badge-pill badge-light-primary" v-if="item.task_responsible" v-html="item.task_responsible.user.name"></span>
                                    <!-- <div class="avatar-wrapper align-center" v-if="item.task_responsible" v-tooltip="item.task_responsible.user.name">
                                        <div class="avatar bg-light-primary">
                                            <small class="avatar-content" v-html="avatarText(item.task_responsible.user.name)" ></small>
                                        </div>
                                    </div> -->
                                </td>
                                <td>
                                    <span v-html="item.title"></span>
                                </td>
                                <td>
                                    <div v-for="(tagged, index2) in item.task_tagged" :key="index2">
                                        <span class="badge badge-pill badge-light-primary" v-if="tagged.user" v-html="tagged.user.name"></span>
                                        <!-- <div class="avatar-wrapper align-center mt-25" v-if="tagged.user">
                                            <div class="avatar bg-light-primary">
                                                <small class="avatar-content dispute_avater" v-html="avatarText(tagged.user.name)" v-tooltip="tagged.user.name"></small>
                                            </div>
                                        </div> -->
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span v-if="item.status == 0" class="badge badge-light-warning">Pending</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="4" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
        </div>

        <!-- <div class="details_view_section" v-if="editItemFixed">
                <div class="row wait_me_insert_form">
                    <div class="col-12">
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="title">Title <span class="text-danger">*</span></label>
                                    <input type="text" disabled v-model="editItem.title" class="form-control" id="title" placeholder="Enter titles">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="user_id">Responsible <span class="text-danger">*</span></label>
                                    <treeselect  disabled v-model="editItem.user_id" :options="userList" placeholder="Select user" id="user_id" autocomplete="off"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="user_id">Tag <span class="text-danger">*</span></label>
                                    <treeselect  disabled multiple v-model="editItem.tagged_user_id" :options="userList" placeholder="Select user" id="user_id" autocomplete="off"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="edit_end_date">Due Date <span class="text-danger">*</span></label>
                                        <flat-pickr
                                            disabled
                                            id="edit_end_date"
                                            v-model="editItem.due_date"
                                            :config="configs.flatpickr"
                                            class="form-control"
                                            placeholder="Select date"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="status_priority_id">Priority <span class="text-danger">*</span></label>
                                    <treeselect disabled v-model="editItem.status_priority_id" :options="priorityStatusesTreeselect" placeholder="Select priority" id="status_priority_id" autocomplete="off"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="description">Description <span class="text-danger"></span></label>
                                <p v-html="editItem.description" style="background: #F9F9F9; border-radius: 5px; border: 1px solid lightgray; padding: 10px;"></p>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="status">Status <span class="text-danger">*</span></label>
                                    <treeselect :disabled="editItemFixed.status == 2 ? true : false" v-model="editItem.status" :options="taskStatusList" placeholder="Select status" id="status" autocomplete="off"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="remarks">Remarks <span class="text-danger">*</span></label>
                                <textarea :disabled="editItemFixed.status == 2 ? true : false" id="remarks" class="form-control" v-model="editItem.remarks" placeholder="Enter remarks"></textarea>
                            </div>
                            <div class="col-12 mt-5" v-if="editItemFixed.status != 2">
                                <button class="btn btn-primary mr-1 waves-effect waves-float waves-light float-right" @click="updateFormData()">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
        </div> -->

      </div>

    </sidebar-form>
  </section>
</template>

<script>
import feather from 'feather-icons';
import SidebarForm from "../../SidebarForm";
import Treeselect from '@riophae/vue-treeselect';
import { BTabs, BTab} from 'bootstrap-vue';
import { VueEditor } from "vue2-editor";
import { GridIcon, AlignJustifyIcon  } from 'vue-feather-icons'

export default {
  name: "AssignTask",
  props: ['list_id'],
  components: {
    SidebarForm,
    Treeselect,
    BTabs, BTab,
    VueEditor,
    GridIcon, AlignJustifyIcon
    //HomeIcon, UserIcon,
  },
  data: () => {
    return {
        agentStudentListInfo: {},
        listData: [],
        bodyContentCustomToolbar: [
            ["bold", "italic", "underline", "link"],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
        configs: {
            flatpickr: {
                dateFormat: 'd M Y',
                minDate: new Date()
            },        
            flatpickr2: {
                dateFormat: 'd M Y',
                mode: "range"
            }        
        },
        form: {
            task:{
                title:'',
                description:'',
                due_date:'',
                status:'',
                finish_time: '',
                status_priority_id: 83,
                task_type: '',
            },
            task_remarks:{
                remarks: '',
            },
            task_users:{
                user_id: null,
                tagged_user_id: null,
            },
        },
        flag: {
            show_assign_task_modal: false,
        },
        priorityStatuses: [],
        priorityStatusesTreeselect: [],
        userList: [],
        editItem: {},
        editItemFixed: {}
    };
  },
  created: function () {
    var ref = this;
    ref.setup_urls();
    this.getPriorityStatuses()
    this.getUsersList()
  },
  mounted: function(){
    feather.replace();
  },
  methods: {
    show_modal: function(){
      this.flag.show_assign_task_modal = !this.flag.show_assign_task_modal;
    },
    clearFormData: function () {
        this.form.task.title = ''
        this.form.task.description = ''
        this.form.task.due_date = ''
        this.form.task.status = ''
        this.form.task.finish_time = ''
        this.form.task.status_priority_id = 83
        this.form.task.task_type =  ''

        this.form.task_remarks.remarks = ''

        this.form.task_users.user_id = null
        this.form.task_users.tagged_user_id = null
    },
    getPriorityStatuses: async function(){

        var ref=this;
        var jq=ref.jq();
        try {
            const params = { group_code: 'task_priorty' }
            var res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);
            this.priorityStatuses = res.data.statuses.map(item => {
                return Object.assign({}, item, JSON.parse(item.data))
            })
            
            this.priorityStatusesTreeselect = this.priorityStatuses.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })

        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
    },
    getUsersList: async function(){

        var ref=this;
        var jq=ref.jq();
        try {
            var res = await jq.get(ref.url('api/v1/university/ajax/get_users_by_institute'))
            this.userList = res.data.list.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })

        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
    },
    getListData: async function (agent_student_list) {
        this.agentStudentListInfo = agent_student_list
        this.show_modal()
        var ref=this;
        var jq=ref.jq();
        try {
            ref.wait_me('.user-role-table');
            const params = Object.assign({}, { list_id: this.agentStudentListInfo.list_id })
            var res = await jq.get(ref.url('api/v1/university/ajax/get_task_list_data'), params);
            this.listData = res.data.list
        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.user-role-table', 'hide');
        }
    },
    insertFormData: async function () {

        var ref=this;
        var jq=ref.jq();
        try {
            ref.wait_me('.wait_me_insert_form');
            const params = Object.assign({}, this.form.task, this.form.task_remarks, this.form.task_users, { list_id: this.agentStudentListInfo.list_id })
            var res = await jq.post(ref.url('api/v1/uclan/ajax/assign_task_data_store'), params);
            this.getListData(this.agentStudentListInfo)
            ref.alert(res.msg);
            this.clearFormData()

        } catch (err) {
            ref.alert(ref.err_msg(err), 'error');
        }
        finally {
            ref.wait_me('.wait_me_insert_form', 'hide');
        }
    },
  },
  computed: {
  }
};
</script>

<style scoped>
.comment-block{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-top: 8px;
  border-radius: 25px;
}

.attachment-list{
  padding: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  /* margin-top: 8px; */
  border-radius: 25px;
}

.modal_form_heading {
  background: #F8F8F8;
  padding: 10px 10px 5px 10px;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  transition: 1s ease;
}
/* #editor1 {
  height: 100px !important;
} */
.quillWrapper{
    height: 6rem;
}

.dispute_avater {
    width: 25px !important;
    height: 25px !important;
    font-size: 10px !important;
    font-weight: 700 !important;
}
</style>
