<template>
<section class="user-role">
    <main-layout>

        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h2 class="content-header-title float-left mb-0">Settings</h2> -->
                        <h5 class="content-header-title float-left mt-50">Settings</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item">Settings</li>
                                <li class="breadcrumb-item active">Course Tuition Fees</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card bg-light-gray mb-1" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <treeselect v-model="search.faculty_id" :options="facultyList" id="faculty_id" placeholder="Select faculty" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <treeselect v-model="search.course_id" :options="courseList" id="course_id" placeholder="Select course" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.tuition_fees" class="form-control" id="tuition_fees" placeholder="Tuition fees">
                    </div>
                    <div class="col text-right">
                        <button class="btn btn-primary mr-50" @click="searchData()"><i data-feather='search'></i> Search</button>
                        <button class="btn btn-outline-danger" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <h3>Course Tuition Fees List</h3>
                        <small @click="getListData()" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + totalRowCount"></small>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_right_sidebar_form()">
                            <i data-feather="plus-square"></i>
                            Add New Course Tuition Fees
                        </button>
                        <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light ml-1"><i data-feather='filter'></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive user-role-table">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Faculty</th>
                                <th>Course Title</th>
                                <th class="text-center">Academic Year</th>
                                <th class="text-right">Tuition Fees</th>
                                <th class="text-center" width="205">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="listData && listData.length > 0">
                            <tr v-for="(item, index) in listData" :key="index">
                                <td v-html="index + pagination.slOffset"></td>
                                <td>
                                    <span v-html="item.faculty_name"></span>
                                </td>
                                <td>
                                    <span v-html="item.course_name"></span>
                                </td>
                                <td class="text-center">
                                    <span v-html="dYear(item.session_start_date)"></span> / 
                                    <span v-html="dYear(item.session_end_date)"></span>
                                </td>
                                <td class="text-right">
                                    <span v-html="'£ ' + float(item.tuition_fees)"></span>
                                </td>
                                <td class="text-center">

                                    <button @click="editData(item)" class="btn btn-outline-info btn-sm"  style="margin-right: 5px;">
                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                    </button>                                                

                                    <button @click="deleteData(item, index)" class="btn btn-outline-danger btn-sm"  style="margin-right: 5px;">
                                        <trash-2-icon size="1.5x" class="custom-class" style="color:red"></trash-2-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="7" class="text-center">
                                    <span v-if="listData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>

                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                </div>
            </div>
        </div>
        <sidebar-form
            :show_sidebar="flag.show_right_sidebar_form"
            v-on:toggle-sidebar="toggle_right_sidebar_form()"
            title="Add New User Role"
        >
            
            <div class="card-body add-edit-form">
                <h5 class="card-title text-primary" v-if="!form.course_tuition_fees.id">Add Course Tuition Fees</h5>
                <h5 class="card-title text-primary" v-if="form.course_tuition_fees.id">Edit Course Tuition Fees</h5>
                <div class="add_user_form">
                    <div class="form-group">
                        <label for="faculty_id"><b>Faculty</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.course_tuition_fees.faculty_id" :options="facultyList" placeholder="Select faculty" id="faculty_id" autocomplete="off"/>
                    </div>
                    <div class="form-group">
                        <label for="course_id"><b>Course</b> <span class="text-danger">*</span></label>
                        <treeselect v-model="form.course_tuition_fees.course_id" :options="courseList" placeholder="Select course" id="course_id" autocomplete="off"/>
                    </div>
                    <div class="form-group">
                        <label for="tuition_fees"><b>Tution Fees</b> <span class="text-danger">*</span></label>
                        <input type="text" v-model="form.course_tuition_fees.tuition_fees" class="form-control" id="tuition_fees" placeholder="Enter tuition fees">
                    </div>
                    <div class="form-group">
                        <label for="session_start_date"><b>Session Start Date</b> <span class="text-danger">*</span></label>
                        <flat-pickr
                            id="session_start_date"
                            v-model="form.course_tuition_fees.session_start_date"
                            :config="configs.flatpickr_start"
                            class="form-control"
                            @input="set_intake_end_date"
                            placeholder="Select start date"/>
                    </div>
                    <div class="form-group">
                        <label for="session_end_date"><b>Session End Date</b> <span class="text-danger">*</span></label>
                        <flat-pickr
                            id="session_end_date"
                            v-model="form.course_tuition_fees.session_end_date"
                            :config="configs.flatpickr_end"
                            class="form-control"
                            placeholder="Select end date"/>
                    </div>
                    
                    <button type="submit" class="btn btn-success pull-right" v-if="!form.course_tuition_fees.id" @click.prevent="insertFormData()">Save</button>

                    <button type="submit" class="btn btn-success pull-right" v-if="form.course_tuition_fees.id" @click.prevent="updateFormData()">Update</button>

                    <button type="button" @click="formClose()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button>
                    
                </div>
            </div>
        </sidebar-form>
    </main-layout>
</section>
</template>

<script>

import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../../SidebarForm.vue';
import { EditIcon, Trash2Icon, XIcon } from 'vue-feather-icons'
import moment from 'moment';
import { BPagination } from 'bootstrap-vue';

// import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'

export default{
    name: 'CourseTuitionFees',
    metaInfo: {
        titleTemplate: '%s | Course'
    },
    components:{
        MainLayout,
        Treeselect,
        SidebarForm,
        EditIcon,
        Trash2Icon,
        XIcon,
        BPagination
    },
    data:()=>{
        return {
            saveData: true,
            totalRowCount: 0,
            flag:{
                show_right_sidebar_form:false,
                show_filter:false
            },
            form: {
                course_tuition_fees: {
                    id: '',
                    faculty_id: null,
                    course_id: null,
                    session_start_date: '',
                    session_end_date: '',
                    tuition_fees: '',
                }
            },
            configs: {
                flatpickr_start: {
                    dateFormat: 'd M Y',
                },
                flatpickr_end: {
                    dateFormat: 'd M Y',
                    minDate: ''
                }        
            },
            search: {
                faculty_id: null,
                course_id: null,
                tuition_fees: '',
            },
            listData: null,
            facultyList:[],
            courseAllDataList:[],
            courseList:[],
        };
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
    },
    watch: {
        'form.course_tuition_fees.faculty_id': function(faculty_id) {
            this.findCourseListByFacultyId(faculty_id)
        },
        'search.faculty_id': function(faculty_id) {
            this.findCourseListByFacultyId(faculty_id)
        }
    },
    methods:{
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        searchData: function() {
            this.getListData()
        },
        ClearSearchData () {
            this.search = {
                faculty_id: null,
                course_id: null,
                tuition_fees: '',
            }
            this.getListData()
        },
        getListTotalLength: function (listData) {
            if (listData && listData.length) {
                return listData.length
            } else {
                return '0'
            }
        },
        toggle_right_sidebar_form: function(){
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
            this.form.course_tuition_fees = {}
        },
        update_reset: function () {
            this.form.course_tuition_fees = {};
            this.flag.show_right_sidebar_form = !this.flag.show_right_sidebar_form;
        },
        formClose: function() {
            this.toggle_right_sidebar_form()
        },
        getListData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.user-role-table');
                const params = Object.assign({ page: this.pagination.currentPage, per_page: this.pagination.perPage }, this.search)
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_course_tuition_fees_list'), params);
                this.listData = res.data.list.data
                this.totalRowCount = res.data.total_row_count
                this.paginationData(res.data.list)
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.user-role-table', 'hide');
            }
        },
        insertFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.add-edit-form');
                var res = await jq.post(ref.url('api/v1/uclan/ajax/store_course_tuition_fee_data'), ref.form.course_tuition_fees);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add-edit-form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.add-edit-form');
                var res = await jq.post(ref.url('api/v1/uclan/ajax/update_course_tuition_fee_data'), ref.form.course_tuition_fees);
                this.getListData()
                ref.alert(res.msg);
                this.update_reset()

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.add-edit-form', 'hide');
            }
        },
        editData: function (item) {
            this.toggle_right_sidebar_form()
            this.saveData = false

            const editItem = {
                id: item.id,
                faculty_id: item.faculty_id,
                course_id: item.course_id,
                session_start_date: moment(item.session_start_date).format("DD MMM YYYY"),
                session_end_date: moment(item.session_end_date).format("DD MMM YYYY"),
                tuition_fees: item.tuition_fees,
            }

            this.form.course_tuition_fees = JSON.parse(JSON.stringify(editItem))
        },
        deleteData: async function (item, index) {

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete!',
                html: "Are you sure to delete?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirm(item, index)
                }
            })
        },
        deleteDataConfirm: async function(item, index){

            var ref=this;
            var jq=ref.jq();

            var form_data={
                id: item.id
            }

            try{

                var res = await jq.post(
                    ref.url('api/v1/uclan/ajax/delete_course_tuition_fee_data'), form_data
                );

                this.listData.splice(index, 1);
                ref.alert(res.msg);

            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }

        },
        getCourseList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.user-role-table');
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_dropdown_course_list'));
                this.courseAllDataList = res.data.list
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.user-role-table', 'hide');
            }
        },
        getFacultyList: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_uni_active_faculty_list'));
                this.facultyList = res.data.list.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                        faculty_id: item.faculty_id,
                    }
                });
                console.log('this.facultyList', this.facultyList)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        findCourseListByFacultyId: function(facultyId) {
            const courseObjList = this.courseAllDataList.filter(item => item.faculty_id == facultyId)
            if (courseObjList) {
                this.courseList = courseObjList.map(item => {
                    return {
                        id: item.id,
                        label: item.name,
                    }
                })
            }
        },
        set_intake_end_date: function (ev) {
            this.configs.flatpickr_end.minDate = ev
        }
    },
    mounted: function(){
        feather.replace();
        this.getCourseList();
        this.getFacultyList();
        this.getListData();
    }
}
</script>
