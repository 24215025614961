<template>
<section class="component-data-list ">
    <main-layout>
        <!-- BEGIN: Content-->
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-2">
                        <div class="row breadcrumbs-top">
                            <div class="col-12">
                                <h2 class="content-header-title float-left mb-0">Settings</h2>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                        <li class="breadcrumb-item active">Email Setting</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                    <!-- Input Sizing start -->
                    <section id="input-sizing">
                        <div class="row match-height">
                            <!-- <div class="col-md-4 col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            Email Setting Item 
                                        </h4>
                                    </div>
                                    <div class="card-body wait_me_list_data">
                                        <div class="row">
                                            <div class="col-12">
                                                <div v-for="(item, index) in listData" :key="index" class="alert alert-primary alert-dismissible fade show" role="alert" style="width: 100%;">
                                                    <div class="alert-body">
                                                        <span v-html="item.name"></span>
                                                        <small class="badge badge-dark ml-1" v-html="getStatusName(item)"></small>
                                                    </div>
                                                    <button type="button" @click="editData(item)" class="close" style="margin-right: 25px;">
                                                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-sm-12 col-md-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Update Email Setting</h4>
                                    </div>
                                    <div class="card-body wait_me_insert_form">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <vue-upload-avatar class="company-logo-uploader" :init_img="form.email_settings.image_url" v-on:confirmedUpload="upload_invitation_email_image"></vue-upload-avatar>
                                            </div>
                                            <div class="col-8">
                                                <p class="mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="body_content"><h4>Email Body <span class="text-danger">*</span></h4></label>
                                                    <vue-editor :editor-toolbar="bodyContentCustomToolbar" v-model="form.email_settings.body_content" id="body_content" placeholder="Enter email body content"></vue-editor>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12">
                                                <div class="form-group">
                                                    <label for="footer_content"><h4>Email Signature <span class="text-danger">*</span></h4></label>
                                                    <vue-editor :editor-toolbar="footerContentCustomToolbar" v-model="form.email_settings.footer_content" id="footer_content" placeholder="Enter email footer content"></vue-editor>
                                                </div>
                                            </div> -->
                                            <div class="col-12">
                                                <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Email Preview</h4>
                                    </div>
                                    <div class="card-body wait_me_insert_form">
                                        <!-- <h4 class="mt-3 mb-2" style="color: #571B7E"><b>Hey</b></h4>
                                        <h4 class="mb-2"><a href="mailto:demo@gmail.com" style="color: blue" target="_blank">demo@gmail.com</a></h4>
                                        <h4 class="mb-3" style="color: #571B7E">👋 <b>Please sign up to our system now!</b></h4> -->

                                        <p>
                                            <img :src="form.email_settings.image_url" alt="Invitation Email" style="width: 100%">
                                        </p>

                                        <div class="mt-3 email_template" v-html="form.email_settings.body_content"></div>

                                        <!-- <div>
                                            <a href="#/settings/email-settings" class="btn" style="color: #571B7E"><b>Sign Up Now &rarr;</b></a>
                                        </div> -->

                                        <div class="mt-5" v-html="form.email_settings.footer_content"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- Input Sizing end -->
                </div>
        <!-- END: Content-->


        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
    </main-layout>
</section>
</template>

<script>
import VueUploadAvatar from '../../VueUploadAvatar.vue'
import MainLayout from '../MainLayout.vue';
import feather from 'feather-icons';
import { VueEditor } from "vue2-editor";
// import swal from 'bootstrap-sweetalert';
// import { EditIcon } from 'vue-feather-icons'

export default{
    name: 'EmailSettings',
    components:{
        MainLayout,
        VueUploadAvatar,
        VueEditor
        // EditIcon,
    },
    data:()=>{
        return {
            saveData: true,
            showModal: false,
            icon: 'edit',
            flag:{
                show_modal_form:false
            },
            form: {
                email_settings: {
                    id: '',
                    photo: '',
                    body_content: '',
                    footer_content: '',
                    image_url: null
                }
            },
            bodyContentCustomToolbar: [
                ["bold", "italic", "underline", "link"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            footerContentCustomToolbar: [
                ["bold", "italic", "underline", "link"],
            ],
            editItem: {},
            listData: [],
        };
    },
    computed: {
    },
    created () {
        this.setup_urls();
    },
    methods:{
        upload_invitation_email_image: function(form_data){

          var ref=this;
          ref.ajax_setup('uni_api_token');
          var jq=ref.jq();
          var url=ref.url('api/v1/university/ajax/upload_invitation_email_image');
          form_data.append('invitation_email_id', ref.form.email_settings.id);

         ref.wait_me('.company-logo-uploader');

          jq.ajax({
            url: url,
            data: form_data,
            processData: false,
            contentType: false,
            type: 'POST',
            success: res=>{
              ref.form.email_settings.image_url=res.data.image_url;
              ref.alert(res.msg);
                // console.log('ref.form.email_settings.image_url', ref.form.email_settings.image_url);
            }
          }).fail(
            err=>ref.alert(ref.err_msg(err), 'error')
          ).always(()=>ref.wait_me('.company-logo-uploader', 'hide'));
          
        },
        getEmailSettingsData: async function () {
            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.wait_me_insert_form');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_email_settings_data'));
                ref.form.email_settings = res.data.email_settings
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_insert_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_email_settings'), ref.form.email_settings);
                this.getEmailSettingsData()
                ref.alert(res.msg);

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
    },
    mounted: function(){
        feather.replace();
        this.getEmailSettingsData();
    }
}
</script>

<style scoped>
    /* .regional-form-section {
        min-height: 100% !important;
    } */
    .email_template p br{
        display:none !important;
    }

</style>