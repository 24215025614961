<template>
    <div v-if="auth_user">
        <div v-if="permission_check('confirmed_list')">

            <div class="card bg-light-gray mb-1" v-show="flag.show_filter">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h5>Search Filter</h5>
                        </div>
                    </div>
                    <div class="form-row mt-1">
                        <div class="col-md-2">
                            <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Student ID">
                        </div>
                        <div class="col-md-2">
                            <input type="text" v-model="search.first_name" class="form-control" id="first_name" placeholder="First Name">
                        </div>
                        <div class="col-md-2">
                            <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last Name">
                        </div>
                        <div class="col-md-2">
                            <treeselect v-model="search.course_level_id" :options="courseLevelList" id="course_level_id" placeholder="Select Course Level" autocomplete="off"/>
                        </div>
                        <div class="col-md-2">
                            <treeselect v-model="search.country_id" :options="countryList" id="country_id" placeholder="Select Country" autocomplete="off"/>
                        </div>
                        <div class="col-md-2">
                            <treeselect v-model="search.verification_status" :options="verificationStatusList" id="verify" placeholder="Verification Status" autocomplete="off"/>
                        </div>
                        <!-- <div class="col-md-2">
                            <treeselect v-model="search.funding_source" :options="fundingSourceList" id="funding_source" placeholder="Funding Source" autocomplete="off"/>
                        </div> -->
                    </div>
                    <div class="form-row mt-1">
                        <div class="col-md-3"></div>
                        <div class="col-md-3"></div>
                        <div class="col-md-3"></div>
                        <div class="col-md-3">
                            <div class="form-row">
                                <div class="col-md-6">
                                    <button class="btn btn-primary btn-block" @click="searchData()">Search</button>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-outline-danger btn-block" @click="ClearSearchData()">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="col-md-12 d-flex content-header pt-2 pb-1">   
                    <div class="col-md-6 d-flex">
                        <div class="col-md-7">
                            <div class="d-flex p-50 border-radius-10 box-shadow-inset" v-if="agentListInfo.agent_company">
                                <div class="avatar-wrapper" v-if="agentListInfo.agent_company.country">
                                    <div class="avatar  mr-1" v-tooltip="agentListInfo.agent_company.country.name">
                                        <img :src="agentListInfo.agent_company.country.flag_url" alt="Avatar" height="32" width="32">
                                    </div>
                                </div>
                                <router-link to="/settings/agent-account-managers" class="d-flex" v-else>
                                    <div class="avatar-wrapper align-center">
                                        <div class="avatar bg-light-warning mr-1">
                                            <small class="avatar-content">
                                                <user-plus-icon size="1.5x" class="custom-class"></user-plus-icon>
                                            </small>
                                        </div>
                                    </div>
                                </router-link>
                                <div>
                                    <h5 v-html="agentListInfo.agent_company.name" class="d-flex"></h5>
                                    <p>
                                        <span @click="gotoAgentProfilePage(agentListInfo)" v-tooltip="'Banner Code'" class="badge badge-pill badge-light-primary cursor-pointer" v-html="agentListInfo.agent_company.agent_code"></span>
                                        <span v-tooltip="'Primary Market For Commission'" class="badge badge-pill badge-light-info ml-50" v-if="agentListInfo.agent_company.territory" v-html="agentListInfo.agent_company.territory.name"></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- <span style="border-left: 1px solid rgba(0,0,0,.2); height: 25px;"></span>
                        <div class="col-md-2">
                            <div class="d-flex">
                                <div class="avatar-wrapper align-center"  v-if="agentListInfo.io_supervisor">
                                    <div class="avatar bg-light-primary mr-1">
                                        <small class="avatar-content" v-html="avatarText(agentListInfo.io_supervisor.name)" v-tooltip="agentListInfo.io_supervisor.name"></small>
                                    </div>
                                </div>
                                <div @click="addIoSupervisor(agentListInfo)" v-if="!agentListInfo.io_supervisor" class="avatar-wrapper align-center">
                                    <div class="avatar bg-light-warning mr-1">
                                        <small class="avatar-content">
                                            <user-plus-icon size="1.5x" class="custom-class"></user-plus-icon>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            
                        </div> -->
                        <!-- <span style="border-left: 1px solid rgba(0,0,0,.2); height: 70px;"></span> -->
                        <div class="col-md-5 p-0">
                            <div class="p-50 border-radius-10 box-shadow-inset">
                                List Ref: <span v-html="agentListInfo.list_code" class="text-uclan"></span><br/>
                                Submitted: <small v-html="dDate(agentListInfo.submitted_at)"></small><br/>
                                <span v-if="cn(agentListInfo, 'agent_company.assigned_account_manager')" v-tooltip="'Account Manager'">AM: <span class="font-weight-bold" v-html="cn(agentListInfo, 'agent_company.assigned_account_manager.name')"></span></span>
                            </div>
                        </div>
                        <!-- <span style="border-left: 1px solid rgba(0,0,0,.2); height: 70px;"></span> -->
                        <!-- <div class="col-md-6" style="margin-top: -24px;">
                            <span class="ml-1" v-if="agentListInfo.io_supervisor"> 
                                <treeselect v-model="agentListInfo.io_status" @select="io_status_updated(agentListInfo)" :options="student_io_statuses_treeselect" placeholder="Select Status" id="io_status" autocomplete="off"/>
                            </span>
                        </div>   -->
                        <!-- <div class="col-md-4">
                            <div>
                                <treeselect v-model="agentListInfo.io_supervisor_id" :options="userTreeselect" placeholder="Select supervisor" id="io_supervisor" autocomplete="off"/>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button @click="addIoSupervisor(agentListInfo)" type="button" class="btn btn-outline-success waves-effect">Assign List</button>
                        </div> -->

                        <!-- <h6 class="ml-1 mt-25">
                            <div class="d-inline" v-for="(item, index) in asVisitorListInfo" :key="index">
                                <div class="avatar bg-light-primary mr-25" :class="index > 0 ? 'overlap_avatar' : ''" v-if="item.visitor">
                                    <small class="avatar-content" v-html="avatarText(item.visitor.name)" v-tooltip="item.visitor.name"></small>
                                </div>
                            </div>
                        </h6> -->

                    </div>
                    <div class="col-md-6 text-right">
                        <div class="row">
                            <div class="col">
                                <span  v-if="disabled == true" class="badge badge-pill badge-light-success font-size-15 p-75 mr-1">
                                    Completed
                                    <i class="fa fa-check mr-1 text-success"></i>
                                </span>
                                <!-- <button type="button"  class="btn btn-outline-success waves-effect" style="margin-left: 3%;">Save </button>  -->
                                <!-- <a href="#" class="btn btn-success float-right waves-effect" style="margin-right: -21px;"> Send confirmed list to Agent</a> -->
                                <button v-if="agentStudentListData && agentStudentListData.length && disabled != true" :class="disabled == true ? 'btn btn-success waves-effect waves-light mr-1' : 'btn btn-info waves-effect waves-light mr-1'" v-on:click="submit_to_agent(list_id)">
                                    <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                                    <!-- <span> Send List to Agent</span> -->
                                    <span> Mark as Complete</span>
                                </button>
                                <a download :href="info.download_url" class="btn btn-primary waves-effect mr-1"><i data-feather='download'></i> Download</a>
                                <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light"><i data-feather='filter'></i></button>
                            </div>
                        </div>
                        <div class="row mt-1 justify-content-center" v-if="agentListInfo.fo_status">
                            <div class="col-md-6 d-flex align-items-center">
                                <span class="text-nowrap font-size-14 mr-25">PO Number</span>
                                <input type="text" v-model="agentListInfo.po_number" class="form-control" id="po_number" placeholder="Enter PO number">
                                <i @click="updateAgentListPONumber()" class="fa fa-check font-size-26 ml-1 text-success hand" v-tooltip="'Click here to save PO number'"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="form-row mt-1 mb-2  pl-2 pr-2"  v-show="flag.show_filter">
                <div class="col-md-2">
                    <input type="text" v-model="search.uni_ref_no" class="form-control" id="uni_ref_no" placeholder="Uni Ref No.">
                </div>
                <div class="col-md-2">
                    <input type="text" v-model="search.first_name" class="form-control" id="first_name" placeholder="First Name">
                </div>
                <div class="col-md-2">
                    <input type="text" v-model="search.last_name" class="form-control" id="last_name" placeholder="Last Name">
                </div>
                <div class="col-md-2">
                    <treeselect v-model="search.course_level_id" :options="courseLevelList" id="course_level_id" placeholder="Select Course Level" autocomplete="off"/>
                </div>
                <div class="col-md-2">
                    <treeselect v-model="search.country_id" :options="countryList" id="country_id" placeholder="Select Country" autocomplete="off"/>
                </div>
                <div class="col-md-2">
                    <treeselect v-model="search.funding_source" :options="fundingSourceList" id="funding_source" placeholder="Funding Source" autocomplete="off"/>
                </div>
            </div>
            <div class="form-row mt-1 mb-2  pl-2 pr-2"  v-show="flag.show_filter">
                <div class="col-md-3"></div>
                <div class="col-md-3"></div>
                <div class="col-md-3"></div>
                <div class="col-md-3">
                    <div class="form-row">
                        <div class="col">
                            <button class="btn btn-outline-danger btn-block" @click="ClearSearchData()"><i data-feather='x'></i> Clear</button>
                        </div>                            
                        <div class="col">
                            <button class="btn btn-primary btn-block" @click="searchData()">Search</button>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="card">
                <div class="form-row mt-1">
                    <div class="col-md-6 pl-2">
                        <!-- <small @click="searchData()" class="cursor-pointer badge badge-pill badge-light-primary mr-1" v-html="'Total - ' + totalAgentStudent"></small> -->
                        <b v-if="agentStudentListData && agentStudentListData.length && totalCommissionAmount == 0" v-html="'Total Commission: 0'"></b>
                        <b v-if="agentStudentListData && agentStudentListData.length && totalCommissionAmount > 0" v-html="'Total Commission: £ ' + integer(totalCommissionAmount)"></b>
                    </div>
                    <div class="col-md-6 text-right">
                        <b-dropdown id="dropdown-dropleft" dropleft size="sm" variant="link" class="text-dark" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <!-- <span><i class="fa fa-ellipsis-v text-dark" aria-hidden="true"></i></span> -->
                                <span><i class="fa fa-plus-circle font-size-20" aria-hidden="true"></i></span>
                            </template>
                            <b-dropdown-item href="javascript:void(0)" v-if="permission_check('communications')" @click="open_list_requests(agentListInfo)">
                                <div class="position-relative d-inline-block mr-1">
                                    <i class="fa fa-comment-o" v-tooltip="'Communicate with the agent about the list'"></i>
                                    <span class="badge badge-pill badge-light-success badge-up text-success" v-html="agentListInfo.total_communication_count"></span>
                                </div> Communication
                            </b-dropdown-item>
                            <b-dropdown-item href="javascript:void(0)" v-if="permission_check('task_add')" @click="open_assign_task_modal(agentListInfo)">
                                <div class="position-relative d-inline-block mr-1">
                                    <i class="fa fa-check-square-o custom-class" v-tooltip="'Assign a task'"></i>
                                    <span class="badge badge-pill badge-light-success badge-up text-success" v-html="agentListInfo.total_task_count"></span>
                                </div> Assign a task
                            </b-dropdown-item>
                            <b-dropdown-item href="javascript:void(0)" @click="open_agent_student_list_activity_log(agentListInfo)">
                                <i class="fa fa-clock-o cursor-pointer mr-1" v-tooltip="'Activity Log for the List'"></i> Activity Log
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="card-datatable table-responsive p-0">
                    <table class="user-list-table table"> 
                        <thead class="thead-light">
                            <tr>
                                <th width="4%" class="px-1">#</th>
                                
                                <th class="px-1"  width="30%">
                                    Student Details
                                    <i v-if="studentNameAsc == 'student_unsort'" @click="getStatusWiseListData('student_unsort')" class="fa fa-sort cursor-pointer"></i>
                                    <i v-if="studentNameAsc == 'student_name'" @click="getStatusWiseListData('student_name')" class="fa fa-sort-asc cursor-pointer"></i>
                                    <i v-if="studentNameAsc == 'student_name_desc'" @click="getStatusWiseListData('student_name_desc')" class="fa fa-sort-desc cursor-pointer"></i>
                                </th>
                                
                                <th class="px-1" width="30%">
                                    COURSE Details
                                    <!-- <i v-if="courseNameAsc == 'course_unsort'" @click="getStatusWiseListData('course_unsort')" class="fa fa-sort cursor-pointer"></i>
                                    <i v-if="courseNameAsc == 'course_name'" @click="getStatusWiseListData('course_name')" class="fa fa-sort-asc cursor-pointer"></i>
                                    <i v-if="courseNameAsc == 'course_name_desc'" @click="getStatusWiseListData('course_name_desc')" class="fa fa-sort-desc cursor-pointer"></i> -->
                                </th>
                                <!-- <th width="10%" class="text-right px-1" v-tooltip="'Fees/Commissionable Amount'">Fees/Comm AMT</th> -->
                                <th width="10%" class="text-right px-1">Fees</th>
                                <th width="10%" class="text-right px-1">Commission</th>
                                <!-- <th class="px-1">Status</th> -->
                                <!-- <th><div class="custom-control custom-checkbox"> <input class="custom-control-input" type="checkbox" value="" id="checkboxSelectAll"><label class="custom-control-label" for="checkboxSelectAll"></label></div></th> -->
                                <th class="px-1 text-right"  width="10%">Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="agentStudentListData && agentStudentListData.length > 0">
                            <tr v-for="(item, index) in agentStudentListData" :key="index">
                                <!-- <td>
                                    <i v-if="item.verified_at" class="fa fa-check ml-1 text-success" v-tooltip="'Auto Verified'"></i>
                                </td> -->

                                <td class="px-1">
                                    <span class="d-inline" v-html="index + pagination.slOffset"></span>
                                    <i v-if="item.verified_at" class="fa fa-check text-success ml-25 d-inline" v-tooltip="'Auto Verified'"></i>
                                </td>
                                <td class="px-1">
                                    <div @click="editData(item)" class="d-flex justify-content-left align-items-center cursor-pointer">
                                        <!-- <div class="avatar-wrapper">
                                            <div class="avatar mr-1">
                                                <b-avatar variant="primary" :text="avatarText(item.student_name)"></b-avatar>
                                            </div>
                                        </div> -->
                                        <div class="avatar-wrapper">
                                            <div class="avatar  mr-1" v-tooltip="cn(item, 'country.name', '')">
                                                <img v-if="item.country" :src="item.flag_url" alt="Avatar" height="32" width="32">
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <div class="text-dark">
                                            <span class="font-weight-bold" style="font-size: 12px" v-html="item.student_name">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-check"
                                            >
                                                <polyline points="20 6 9 17 4 12"></polyline></svg></span>
                                            </div>
                                            <small class="emp_post text-muted" >
                                                <template v-if="item.student_dob">
                                                    <span v-html="dDate(item.student_dob)"></span> ★
                                                </template>
                                            <span v-html="item.funding_source"></span>
                                            </small>
                                            <small class="text-truncate align-middle cursor-pointer">
                                                <span style="font-size: 100%" class="badge badge-pill badge-light-primary">
                                                    {{ item.uni_ref_no }}
                                                    <i v-show="item.duplicate_exists && item.dispute_status > 0 && item.dispute_status < 11" class="fa fa-exclamation-triangle ml-25 text-warning" aria-hidden="true" v-tooltip="'Dispute Case Exists'"></i>
                                                </span>                                       
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-1">
                                    <!-- <span class="text-truncate align-middle cursor-pointer" style="font-size: 13px">
                                        <span v-tooltip="item.subject" v-html="item.subject"></span>
                                    </span> -->
                                    <span v-if="item.programme_name" class="align-middle cursor-pointer d-block" style="font-size: 13px">
                                        <span v-tooltip="item.programme_name" v-html="limit_str(item.programme_name, 30)"></span>
                                    </span>
                                    <small class="emp_post text-muted" v-if="item.course_level" v-html="item.course_level.name"> ★
                                        <span class="badge badge-pill badge-light-primary mr-1">July 2022</span>
                                    </small>
                                </td>
                                <td class="px-1"> 
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.tuition_fee)"></small><br>
                                    <!-- <small class="emp_post text-muted text-truncate align-middle float-right" v-html="'£ ' + float2(item.scholarship)"></small><br>
                                    <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.commissionable_tuition_fee)"></small> -->
                                </td>
                                <td class="px-1">
                                    <div v-if="item.commission == 0">
                                            <small class="text-truncate align-middle float-right" v-html="'-'"></small><br>
                                            <small class="emp_post text-muted text-truncate align-middle float-right" v-html="'-'"></small>
                                        </div>
                                        <div v-else>
                                            <small class="text-truncate align-middle float-right" v-html="'£ ' + float2(item.commission)"></small><br>
                                            <small class="emp_post text-muted text-truncate align-middle float-right" v-html="float2(item.commission_percentage)+'%'"></small>
                                        </div>
                                </td>


                                <!-- <td class="px-1">
                                    <div class="d-flex justify-content-left align-items-center"><span data-toggle="tooltip" data-html="true" class="badge badge-light-success" title="" data-original-title="<span>Confirmed<br> <span class='font-weight-bold'>Mizanur Rahman</span> <br> <span class='font-weight-bold text-danger'>36 minutes ago</span></span>">Confirmed</span><span data-toggle="tooltip" data-html="true" class="badge  badge-light-warning ml-1" title="" data-original-title="<span>Not Arrived<br> <span class='font-weight-bold'>Mizanur Rahman</span> <br> <span class='font-weight-bold text-danger'>36 minutes ago</span></span>">Not Arrived</span><span data-toggle="tooltip" data-html="true" class="badge badge-light-success ml-1" title="" data-original-title="<span>Full Paid<br> <span class='font-weight-bold'>Mizanur Rahman</span> <br> <span class='font-weight-bold text-danger'>36 minutes ago</span></span>">Full Paid</span></div>
                                </td> -->
                                <!-- <td>
                                    <div class="custom-control custom-checkbox"> <input class="custom-control-input" type="checkbox" value="" id="checkboxSelectAll"><label class="custom-control-label" for="checkboxSelectAll"></label></div>
                                </td> -->
                                <td class="px-1 text-right">
                                    <b-dropdown size="sm" variant="link" class="text-dark" toggle-class="text-decoration-none" no-caret>
                                        <template #button-content>
                                            <span><i class="fa fa-ellipsis-v text-dark" aria-hidden="true"></i></span>
                                        </template>
                                        <b-dropdown-item href="javascript:void(0)" v-if="permission_check('communications')" v-on:click="open_list_req_with_student(item)">
                                            <i class="fa fa-comment-o hand mr-50" v-tooltip="'Communicate with the agent about the student'" ></i> Communications
                                        </b-dropdown-item>
                                        <b-dropdown-item href="javascript:void(0)" v-if="permission_check('student_edit')" @click="editData(item)">
                                            <i class="fa fa-pencil hand mr-50"></i> Edit
                                        </b-dropdown-item>
                                        <b-dropdown-item href="javascript:void(0)" v-on:click="open_agent_student_activity_log(item)">
                                            <i class="fa fa-clock-o mr-50" v-tooltip="'Activity List for the Student'"></i> Activity Log
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="7" class="text-center">
                                    <span v-if="agentStudentListData === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no student data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>

                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                    <!-- <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination> -->
                </div>
            </div>

            

            <div class="row student_edit_sidebar_form" v-if="this.editItem">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <sidebar-form 
                        :show_sidebar="flag.show_edit_modal_form"
                        v-on:toggle-sidebar="toggle_modal_form()"
                        title="Edit Student"
                        >
                        <div class="mb-1">
                            <h5 class="">
                                <span class="align-middle">Edit Student</span>
                            </h5>
                        </div>
                        <div class="flex-grow-1 wait_me_edit_form">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_intake_name"><b>First/Given Name</b> <span class="text-danger">*</span></label>
                                        <input :disabled="disabled" type="text" v-model="editItem.first_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_intake_name"><b>Last/Family Name</b> <span class="text-danger">*</span></label>
                                        <input :disabled="disabled" type="text" v-model="editItem.last_name" class="form-control" id="edit_intake_name" placeholder="Enter intake name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_end_date"><b>Date of Birth</b> <span class="text-danger">*</span></label>
                                            <flat-pickr
                                                :disabled="disabled"
                                                id="edit_end_date"
                                                v-model="editItem.dob"
                                                :config="configs.flatpickr"
                                                class="form-control"
                                                placeholder="Select end date"/>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="course_level_id"><b>Course Level</b> <span class="text-danger">*</span></label>
                                        <treeselect :disabled="disabled" v-model="editItem.course_level_id" :options="courseLevelList" placeholder="Select Course level" id="course_level_id" autocomplete="off"/>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="course_level_id"><b>Programme</b> <span class="text-danger"></span></label>
                                        <treeselect :disabled="disabled" v-model="editItem.programme_id" :options="programmeList" placeholder="Select programme" id="programme_id" autocomplete="off"/>
                                    </div>
                                </div>
                                <!-- <div class="col-12">
                                    <div class="form-group">
                                        <label for="subject"><b>Course Title</b> <span class="text-danger">*</span></label>
                                        <input :disabled="disabled" type="text" v-model="editItem.subject" class="form-control" id="subject" placeholder="Enter course title">
                                    </div>
                                </div> -->
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="tuition_fee"><b>Tuition Fee</b> <span class="text-danger">*</span></label>
                                        <input :disabled="disabled" type="text" v-model="editItem.tuition_fee" class="form-control" id="tuition_fee" placeholder="Enter tuition fee">
                                    </div>
                                </div>
                                <!-- <div class="col-12">
                                    <div class="form-group">
                                        <label for="scholarship"><b>Scholarship</b> <span class="text-danger">*</span></label>
                                        <input :disabled="disabled" type="text" v-model="editItem.scholarship" class="form-control" id="scholarship" placeholder="Enter scholarship">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="commissionable_tuition_fee"><b>Commissionable Tuition Fee</b> <span class="text-danger">*</span></label>
                                        <input type="text" v-model="commissionable_tuition_fee_calculate" disabled class="form-control" id="commissionable_tuition_fee" placeholder="Enter commissionable tuition fee">
                                    </div>
                                </div> -->
                                <div class="col-12" v-if="editItem.io_status && editItem.io_status_code == 'student_io_status_completed'">
                                    <div class="form-group">
                                        <label for="commission_percentage"><b>Commission Percentage (%)</b> <span class="text-danger">*</span></label>
                                        <input type="text" v-model="editItem.commission_percentage" class="form-control" id="commission_percentage" placeholder="Enter commission percentage">
                                        <!-- <input type="text" v-model="editItem.uni_comm_percentage" class="form-control" id="commission_percentage" placeholder="Enter commission percentage"> -->
                                    </div>
                                </div>
                                <div class="col-12" v-if="editItem.io_status && editItem.io_status_code == 'student_io_status_completed'">
                                    <div class="form-group">
                                        <label for="commission"><b>Commission</b> <span class="text-danger">*</span></label>
                                        <input type="text" v-model="commission_calculate" disabled class="form-control" id="commission" placeholder="Enter commission">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                                </div>
                            </div>
                        </div>
                    </sidebar-form>
                </div>
            </div>
            <student-list-request ref="student_list_request"/>
                                        
            <activity-log :log_data="agentStudentActivitylog" ref="agent_student_activity_log_modal"></activity-log>

            <assign-task ref="assign_task_modal"/>
            
        </div>
        <div v-else>
            <div class="card">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h4 class="text-danger p-5">You are not authorized on this page</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import feather from 'feather-icons';
// import { BPagination, BAvatar, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue'
import SidebarForm from '../../SidebarForm';
import moment from 'moment';
import Swal from 'sweetalert2'
import flatPickr from 'vue-flatpickr-component';
import Treeselect from '@riophae/vue-treeselect';
import StudentListRequest from '../Partials/StudentListRequest.vue';
// import { ListIcon} from 'vue-feather-icons';
import ActivityLog from '../Partials/ActivityLog.vue';
import AssignTask from '../Partials/AssignTask.vue';
import Hashids from 'hashids';

export default{
    name: 'InternationalOfficeStudentList',
    props: ['userTreeselect', 'asVisitorListInfo', 'statusGroupList', 'courseLevelList', 'programmeList'],
    components:{
        BPagination,
        SidebarForm,
        flatPickr,
        Treeselect,
        StudentListRequest,
        // ListIcon,
        ActivityLog,
        AssignTask,
        BDropdown,
        BDropdownItem,
    },
    data:()=>{
        return {
            studentNameAsc: 'student_unsort',
            courseNameAsc: 'course_unsort',
            list:{
                user_types:[]
            },
            intake_id: null,
            disabled: false,
            activeItem: 'zero',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: 2,
                slOffset: 1
            },
            agentStudentListActivitylog: [],
            agentStudentActivitylog: [],
            agentListInfo: [],
            agentStudentListData: null,
            totalCommissionAmount: 0,
            agentStudentListItem: {},
            flag: {
                show_agent_form:false,
                show_list_comment_form: false,
                show_edit_modal_form: false,
                show_modal_agent_student_list_activity_log: false,
                show_modal_agent_student_activity_log: false,
                show_filter: false,
            },
            sendStudentButtonShow: false,
            form: {
                student_approval_status: {
                    checked_all: false,
                    agent_student_ids: []
                },
                list_comment:{
                    visible_type_id:'',
                    message:'',
                    list_id:'',
                    student_id:''
                }
            },
            selected: [],
            editItem: {},
            approvalStudentIds: [],
            info:{
                intake:{
                    name: ''
                }
            },
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    maxDate: new Date()
                },        
                flatpickr2: {
                    dateFormat: 'd M Y',
                    mode: "range"
                }        
            },
            countryList: [],
            search: {
                course_level_id: null,
                country_id: null,
                uni_ref_no: '',
                first_name: '',
                last_name: '',
                start_date: '',
                submission_status_id: null,
                funding_source: null,
                intake_id: null,
                list_id: null,
                verification_status: null,
            },
        };
    },
    methods:{
        gotoAgentProfilePage: function (item) {
            if(this.permission_check('agent_profile')) {
                // this.$router.push({ name: 'AgentProfile', params: { id: this.hash_id(item.agent_company_id) } })
                let routeData = this.$router.resolve({name: 'AgentProfile', params: { id: this.hash_id(item.agent_company_id) }});
                window.open(routeData.href, '_blank');
            }
        },
        ClearSearchData () {
            this.search.course_level_id = null
            this.search.country_id = null
            this.search.uni_ref_no = ''
            this.search.first_name = ''
            this.search.last_name = ''
            this.search.start_date = ''
            this.search.submission_status_id = null
            this.search.funding_source = null
            this.search.intake_id = null
            this.search.verification_status = null
            this.searchData()
        },
        open_assign_task_modal: function(agent_student_list){
            if(!this.permission_check('task_add')) {
                return;
            }
            this.$refs.assign_task_modal.getListData(agent_student_list);
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        submit_to_agent: function(list_id){
            if(!this.permission_check('cl_completed_button')) {
                return;
            }

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Send List to Agent?',
                html: "Do you want to submit this list to Agent?<br/><br/><p style='text-align:left'>Remember, once you submit the list to the Agent, Confirm List will not be able to do further changes on this list.</p>",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submit_to_agent_confirm(list_id)
                }
            })
        },
        submit_to_agent_confirm: async function(list_id){

            var ref=this;
            var jq=this.jq();

            try{
                const params = { list_id: list_id }
                var res = await jq.post(ref.url('api/v1/uclan/ajax/agent_student_submit_to_agent'), params);

                this.getConfirmedListData();
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        open_agent_student_list_activity_log: async function () {
            var ref=this;
            var jq=ref.jq();
            this.agentStudentActivitylog = []
            this.$refs.agent_student_activity_log_modal.show_modal()

            try {
                const params = { list_id: this.list_id }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_student_list_activity_log'), params);
                if (res.data.data.length) {
                    this.agentStudentActivitylog = res.data.data
                } else {
                    this.agentStudentActivitylog = null
                }

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        open_agent_student_activity_log: async function (item) {
            var ref=this;
            var jq=ref.jq();
            this.agentStudentActivitylog = []
            this.$refs.agent_student_activity_log_modal.show_modal()

            try {
                const params = Object.assign({}, item)
                var res = await jq.get(ref.url('api/v1/university/ajax/get_student_activity_log_by_model_id'), params);
                 if (res.data.data.length) {
                    this.agentStudentActivitylog = res.data.data
                } else {
                    this.agentStudentActivitylog = null
                }

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        editData: async function (item) {
            if(!this.permission_check('student_edit')) {
                return;
            }
            // console.log(item);
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dob = moment(this.editItem.dob).format("DD MMM YYYY");
            this.flag.show_edit_modal_form = !this.flag.show_edit_modal_form;

            if(this.editItem.commission_percentage < 1){
                await this.fetch_student_uni_commission(this.editItem);
            }
        },
        toggle_modal_form: function(){
            this.flag.show_edit_modal_form = !this.flag.show_edit_modal_form;
        },
        async searchData () {
            this.getConfirmedListData()
            this.gen_download_url()
        },
        setActive: function (menuItem) {
            this.activeItem = menuItem // no need for Vue.set()
        },
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
        check_all_student_approval_status: function(check_all=false) {

            var ref=this;
            var send_form = ref.form.student_approval_status;

            // ref.sendStudentButtonShow = !ref.sendStudentButtonShow
            // console.log('check_all', check_all)

            if (check_all) {
                if (send_form.checked_all) {
                   ref.agentStudentListData.forEach(row => {
                        if (row.verified_at != null) {
                            send_form.agent_student_ids.push(row.id);
                        }
                   });
                } else send_form.agent_student_ids = [];

                send_form.agent_student_ids.length == this.agentStudentListData

            } else {
                var data = ref.agentStudentListData.filter(item => { item.verified_at != null })

                if (send_form.agent_student_ids.length == data.length) {
                    send_form.checked_all = true;
                } else send_form.checked_all = false;
            }
            
            if (send_form.agent_student_ids.length) {
                ref.sendStudentButtonShow = true
            } else {
                ref.sendStudentButtonShow = false
                send_form.checked_all = false
            }
            // console.log('send_form.agent_student_ids', send_form.agent_student_ids)
        },
        submit_to_finance_office: function(){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Student Submit',
                html: "Do you want to submit to Finance Office?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submit_to_finance_office_confirm()
                }
            })
        },
        submit_to_finance_office_confirm: async function(){

            var ref=this;
            var jq=this.jq();

            try{
                // console.log('ref.form.student_approval_status', ref.form.student_approval_status)

                var res = await jq.post(ref.url('api/v1/university/ajax/agent_student_submit_to_finance_office'), ref.form.student_approval_status);

                ref.form.student_approval_status.checked_all = false;
                ref.form.student_approval_status.agent_student_ids = [];
                this.getConfirmedListData();
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        agent_status: function(row){

            var ref=this;
            return ref.dropdownList.agent_statuses.find(each=>{
                return each.id==row.status;
            });

        },
        agent_status_update_confirmation: function(agent_id, new_status){
            this.form.agent_status_update.agent_id=agent_id;
            this.form.agent_status_update.new_status=new_status;
            this.$refs.agent_status_confirmation.show();
        },
        update_agent_status: async function(){

            this.$refs.agent_status_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/update_agent_status');

            try{

                ref.wait_me('.agency-list-card');
                var res=await jq.post(url, ref.form.agent_status_update);

                ref.form.agent_status_update.agent_id='';
                ref.form.agent_status_update.new_status='';
                ref.alert(res.msg);
                ref.getListData();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        check_all_invitaion: function(check_all=false){
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Do you want to accept this list from International Education Counseling Center?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$swal.fire(
                        'Approved!',
                        'You have been accepted.',
                        'success'
                        )

                        var ref=this;
                        var invitation_from=ref.form.student_approval_status;

                        if(check_all){

                            if(invitation_from.checked_all){
                            ref.listData.forEach(row=>{
                                invitation_from.agent_ids.push(row.id);
                            });
                            }else  invitation_from.agent_ids=[];

                        }else{
                            
                            if(invitation_from.agent_ids.length==ref.listData.length){
                                invitation_from.checked_all=true;
                            }else invitation_from.checked_all=false;
                        }
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === this.$swal.DismissReason.cancel
                    ) {
                        this.$swal.fire(
                        'Cancelled',
                        'Your imaginary file is safe :)',
                        'error'
                        )
                    }
                })
        },
        uni_course_level_insert_or_delete: async function(item){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.post(ref.url('api/v1/university/ajax/uni_course_level_insert_or_delete'));
                ref.alert(res.msg)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
                item.mapped_with_uni = !item.mapped_with_uni
            }
        },
        gotoBulkVerficationUploadPage: function () {
            this.$router.push({ name: 'BulkVerficationUpload', params: { id: this.$route.params.intake_id } })
        },
        goto_agent_wise_bulk_students: function () {
            this.$router.push({ name: 'AgencyWiseBulkStudent', params: { hash: this.hash_id(this.list_id) } })
        },
        getConfirmedListData: async function(){

            var ref=this;
            var jq=ref.jq();

            var url=ref.url('api/v1/university/ajax/get_uni_intake_info');
            
            this.list_id = this.hash_id(this.$route.params.id, false)[0]
            this.intake_id = this.hash_id(this.$route.params.intake_id, false)[0]

            try{
                var intake_res= await jq.get(url, {intake_id: this.intake_id});
                ref.info.intake=intake_res.data.intake;
            }catch(err){
                // console.log(err);
            }

            try {
                const params = Object.assign({}, { list_id: this.list_id })
                var response = await jq.get(ref.url('api/v1/university/ajax/get_agent_info_by_list_id'), params);
                this.agentListInfo = response.data.data
                this.agentListInfo.list_id = response.data.data.id
                if (this.agentListInfo.cl_status) {
                    this.disabled = true
                }
                // console.log('agentListInfo', this.agentListInfo)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }

            try {
                // console.log('list_id', list_id)
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage, list_id: this.list_id })
                ref.wait_me('.wait_me_list_data');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_confirmed_student_list'), params);
                if (res.data) {
                    this.agentStudentListData = res.data.map(item => {
                        item.approval_status = item.approval_status ? Object.assign({}, item.approval_status, JSON.parse(item.approval_status.data)) : ''
                        return Object.assign({}, item)
                    });
                } else {
                    this.agentStudentListData = []
                }
                // console.log('this.agentStudentListData', this.agentStudentListData)
                this.paginationData(res)
                // console.log('listData', this.listData)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_list_data', 'hide');
            }
            
            try {
                const params = Object.assign({}, this.search, { list_id: this.list_id })
                var commission_response = await jq.get(ref.url('api/v1/university/ajax/get_confirmed_student_total_comission_amount'), params);
                this.totalCommissionAmount = commission_response.data.total_commission

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getStatusWiseListData: async function(status){

            var ref=this;
            var jq=ref.jq();

            this.search.status = status

            if (status == 'student_unsort') {
                this.studentNameAsc = 'student_name'
            }

            if (status == 'student_name') {
                this.studentNameAsc = 'student_name_desc'
            }
            
            if (status == 'student_name_desc') {
                this.studentNameAsc = 'student_unsort'
            }

            if (status == 'course_unsort') {
                this.courseNameAsc = 'course_name'
            }

            if (status == 'course_name') {
                this.courseNameAsc = 'course_name_desc'
            }

            if (status == 'course_name_desc') {
                this.courseNameAsc = 'course_unsort'
            }

            try {
                const params = Object.assign({}, this.search, 
                { 
                    page: this.pagination.currentPage, 
                    per_page: this.pagination.perPage, 
                    list_id: this.list_id,
                    status: status,
                })
                ref.wait_me('.wait_me_list_data');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_confirmed_student_list'), params);
                if (res.data) {
                    this.agentStudentListData = res.data.map(item => {
                        item.approval_status = item.approval_status ? Object.assign({}, item.approval_status, JSON.parse(item.approval_status.data)) : ''
                        return Object.assign({}, item)
                    });

                    // this.completeButtonShow = this.agentStudentListData.every(item => item.fo_status_code == 'student_fo_status_completed')
                }
                if (res.total) {
                    this.totalAgentStudent = res.total
                }
                this.paginationData(res);
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_list_data', 'hide');
            }
            
            try {
                const params = Object.assign({}, this.search, { list_id: this.list_id })
                var commission_response = await jq.get(ref.url('api/v1/university/ajax/get_confirmed_student_total_comission_amount'), params);
                this.totalCommissionAmount = commission_response.data.total_commission

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        toggle_list_comment_form: async function(){

            var ref=this;
            var form=ref.form.list_comment;
            ref.flag.show_list_comment_form=!ref.flag.show_list_comment_form;

            if(!ref.flag.show_list_comment_form){
                form.visible_type_id='';
                form.message='';
                form.list_id='';
                form.student_id='';
            }

        },
        open_list_comment_for_agent: function(){

            var ref=this;
            var form=ref.form.list_comment;
            form.list_id=ref.agentStudentListItem.id;
            this.toggle_list_comment_form();

        },
        save_list_comment: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/save_student_list_comment');

            try{
                ref.wait_me('.sidebar-form');
                var res=await jq.post(url, ref.form.list_comment);
                ref.toggle_list_comment_form();
                ref.alert(res.msg);
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                ref.wait_me('.sidebar-form', 'hide');
            }

        },
        getCourseLevelList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_university_course_level_list'));
                this.courseLevelList = res.data.list.map(item => {
                    return {
                        id: item.course_level.id,
                        label: item.course_level.name
                    }
                });
                // console.log('this.courseLevelList', res.data.list)

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                const calculated_data = {
                    commissionable_tuition_fee: this.commissionable_tuition_fee_calculate,
                    commission: this.commission_calculate,
                }
                const params = Object.assign({}, ref.editItem, calculated_data )
                var res = await jq.post(ref.url('api/v1/university/ajax/update_agent_student_info'), params);
                this.getConfirmedListData()
                this.toggle_modal_form()
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        open_list_requests: function(agent_student_list){
            if(!this.permission_check('communications')) {
                return;
            }
            this.$refs.student_list_request.open_list_request_form(agent_student_list);
        },
        open_list_req_with_student: function(agent_student){
            if(!this.permission_check('communications')) {
                return;
            }
            this.$refs.student_list_request.open_list_req_with_student(agent_student);
        },
        toggle_modal_agent_student_list_activity_log: function(){
            this.flag.show_modal_agent_student_list_activity_log = !this.flag.show_modal_agent_student_list_activity_log;
        },
        toggle_modal_agent_student_activity_log: function(){
            this.flag.show_modal_agent_student_activity_log = !this.flag.show_modal_agent_student_activity_log;
        },
        gen_download_url: function () {
            // if (this.agentStudentListData && this.agentStudentListData.length) {
                var ref=this;
                var jq=this.jq();
                var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
                var institute_id = ref.store_auth_user.institute_id;
                ref.info.download_url = ref.url('download/confirmed_student_list/'+hash_ids.encode(this.list_id, institute_id));
                this.search.auth_id = ref.store_auth_user.id
                var search_query = jq.param(this.search)
                ref.info.download_url += '?' + search_query
            // }
        },
        fetch_student_uni_commission: async function(edit_data){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/fetch_student_uclan_commission');

            var qstr={
                student_id: edit_data.id,
                intake_id: edit_data.intake_id,
                agent_company_id: edit_data.agent_company.id,
                //comm_percentage: edit_data.commission_percentage
            };

            try{

                var res=await jq.get(url, qstr);
                edit_data.commission_percentage=res.data.comm_percentage;
                
            }catch(err){
                console.log(err);
            }

        },
        updateAgentListPONumber: function(){
            if(!this.permission_check('fo_submit_and_back_button')) {
                return;
            }

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Save PO Number',
                html: "Do you want to save PO number?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.updateAgentListPONumber_confirm()
                }
            })
        },
        updateAgentListPONumber_confirm: async function(){

            var ref=this;
            var jq=this.jq();

            try{
                const params = { list_id: this.list_id, po_number: this.agentListInfo.po_number }
                var res = await jq.post(ref.url('api/v1/uclan/ajax/update_agent_student_list_po_number'), params);

                this.getConfirmedListData();
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
    },
    computed: {
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        },
        store_countries: function(){
            return this.$store.state.site.countries
        },
        treeselect_countries: function(){
            return this.store_countries.map(each=>{
                return {
                    id: each.id,
                    label: each.name
                }
            });
        },
        selectAll: {
            get: function () {
                return this.agentStudentListData ? this.selected.length == this.agentStudentListData.length : false;
            },
            set: function (value) {
                var selected = [];

                if (value) {
                    this.agentStudentListData.forEach(function (item) {
                        selected.push(item.id);
                    });
                }

                this.selected = selected;
            }
        },
        commissionable_tuition_fee_calculate: function () {
            return parseFloat(this.editItem.tuition_fee) - parseFloat(this.editItem.scholarship)
        },
        commission_calculate: function () {
            return this.commissionable_tuition_fee_calculate * (parseFloat(this.editItem.commission_percentage) / 100)
        }
    },
    created: function(){
        this.setup_urls();
    },
    mounted: async function(){
        feather.replace();
        this.getConfirmedListData()
        // this.getCourseLevelList()
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        });
        this.gen_download_url()
        // this.$refs.student_remarks_component.getStudentRemarksListData()
    }
}
</script>
<style>
.bs-stepper .bs-stepper-content {
    padding: 0rem 0rem !important;
}
.card {
    margin-bottom: 0rem!important;
}
</style>