<template>
<sidebar-form 
  :show_sidebar="flag.comm_form"
  v-on:toggle-sidebar="toggle_comm_form()"
  title="Add Commission Type"
>

  <div class="mb-1">
    <h5 class="">
      <span class="align-middle">Add/Edit Commission configuration</span>
    </h5>
  </div>

  <section v-if="flag.show_copy_section" id="configuration-copy-section" class=" mb-1">

    <div class="form-row">
      <div class="col">
        <button class="btn btn-sm btn-outline-primary btn-block" v-on:click="create_from_scratch()">
          Create New Configuration
        </button>
      </div>
      <div class="col">
        <button class="btn btn-sm btn-outline-warning btn-block" v-on:click="form.copy_ucomm.copy_from_prev=!form.copy_ucomm.copy_from_prev">
          Copy From Previous Configuration
        </button>
      </div>
    </div>

    <section v-if="form.copy_ucomm.copy_from_prev">

      <div class="form-row mt-1">
        <div class="col">
          <label class="form-row">Intake Duration</label>
          <flat-pickr
            id="edit_end_date"
            v-model="form.copy_ucomm.intake_from"
            :config="configs.flatpickr"
            class="form-control form-control-sm"
            placeholder="From"
          />

        </div>
        <div class="col">
          <!-- <label class="form-row">Intake Duration</label> -->
          <flat-pickr
            id="edit_end_date"
            v-model="form.copy_ucomm.intake_to"
            :config="configs.flatpickr"
            class="form-control form-control-sm mt-2"
            placeholder="To"
          />
        </div>
      </div>

      <div class="form-row mt-1">
        <div class="col-md-9">
          <label for="">Select Previous Configuration</label>
          <treeselect :options="treeselect_prev_comm_configs" placeholder="Select Previous Configuration" autocomplete="off" v-model="form.copy_ucomm.comm_config_id"/>
        </div>
        <div class="col">
          <button class="btn btn-primary btn-block mt-2" v-on:click="copy_prev_uni_config()">
            <i class="fa fa-clone" aria-hidden="true"></i>
            Copy
          </button>
        </div>
      </div>

    </section>

  </section>

  <!-- <p>
    <strong>Select Commission Type</strong>				
  </p> -->

  <!-- <div class="form-row">
    <div class="col">
      <label class="form-label" for="">Select Intake</label>
      <treeselect v-model="form.comm.intake_id" :options="treeselect_intakes" placeholder="Select intake" autocomplete="off"/>
    </div>
    <div class="col">
      <label class="form-label" for="">Select Commission Type</label>
      <treeselect v-model="form.comm.comm_type_id" :options="treeselect_comm_types" placeholder="Select commission type" autocomplete="off"/>
    </div>
  </div> -->

  <section v-else id="comm-config-form-section">
    <div class="form-row mb-1">
      <div class="col-md-12">
        <label class="form-row">Configuration Title</label>
        <input type="text" class="form-control form-control-sm" placeholder="Enter configuration title" v-model="form.ucomm.title"/>
      </div>
    </div>

    <div class="form-row mb-3">
      <div class="col">
        <label class="form-row">Intake Duration</label>
        <flat-pickr
          id="edit_end_date"
          v-model="form.ucomm.intake_from"
          :config="configs.flatpickr"
          class="form-control form-control-sm"
          placeholder="From"
        />

      </div>
      <div class="col">
        <!-- <label class="form-row">Intake Duration</label> -->
        <flat-pickr
          id="edit_end_date"
          v-model="form.ucomm.intake_to"
          :config="configs.flatpickr"
          class="form-control form-control-sm mt-2"
          placeholder="To"
        />
      </div>
    </div>

    <!-- <div class="form-row mb-25">
      <div class="col">
        <strong>Select Territories:</strong>
      </div>
    </div> -->
    <div class="form-row mb-3 bg-light-orange" v-for="(row, index) in form.ucomm.territories" :key="index">
      <div class="col">
        <table class="mb-0 table_edit_commission">
          <tr class="">
            <td width="38%" style="padding-left: 15px">
                <label for="territory_ids">Territory</label>
                <treeselect id="territory_ids" :options="treeselect_territories" placeholder="Select terriories" autocomplete="off" multiple v-model="row.territory_ids"/>
            </td>
            <td>
                <tr v-for="(pd_row, pd_index) in row.percentage_distributions" :key="pd_index">
                  <td>
                    <div class="form-row" v-if="pd_index == 0">
                      <div class="col text-center">
                        <label for="stu_range_from">Student Range</label>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <input type="text" id="stu_range_from" class="form-control form-control-sm text-right" placeholder="From" v-model="pd_row.stu_range_from"/>
                      </div>
                      <div class="col">
                        <input type="text" class="form-control form-control-sm text-right" placeholder="To" v-model="pd_row.stu_range_to"/>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-row">
                      <div class="col text-center">
                          <label for="percentage"  v-if="pd_index == 0">Commission</label>
                          <div class="input-group">
                            <input type="text" id="percentage" class="form-control form-control-sm text-right" placeholder="Percentage" v-model="pd_row.percentage"/>
                            <div class="input-group-append ">
                              <span class="input-group-text form-control form-control-sm">%</span>
                            </div>
                          </div>
                      </div>
                    </div>
                  </td>
                  <td width="50" class="text-center">
                    <span v-if="!pd_index" class="badge badge-primary hand mt-1" v-on:click="add_ucomm_pd(row)">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </span>
                    <span v-if="pd_index" class="badge badge-light-danger hand" v-on:click="remove_ucomm_pd(row.percentage_distributions, pd_index)">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                  </td>
                </tr>
            </td>
            <td width="80px" class="text-center">
                <span
                  v-if="form.ucomm.territories.length == index + 1"
                  class="badge badge-primary hand mr-50"
                  v-on:click="add_ucomm_details(form.ucomm.territories)"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>

                <span class="badge badge-light-danger hand" v-on:click="remove_tt(form.ucomm.territories, index)">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </td>
          </tr>
        </table>
        <!-- <table class="table table-bordered table-sm mb-0">
          <thead>
            <tr class="">
              <td width="50%" class="align-middle"></td>
              <td colspan="2" class="align-middle"></td>
              <td class="text-center align-middle" width="50">
                <span
                  class="badge badge-primary hand d-block"
                  v-on:click="add_ucomm_details(form.ucomm.territories)"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
              </td>
            </tr>
          </thead>
          <tbody v-show="!form.ucomm.territories.length">
            <tr>
              <td colspan="4" class="bg-light-warning text-center">
                <strong>Please add territories.</strong>              
              </td>
            </tr>
          </tbody>
          <tbody v-for="(row, index) in form.ucomm.territories" :key="index">
            <tr v-if="index" style="height: 35px; border: 0px;">
              <td colspan="4"></td>
            </tr>
            <tr>
              <td colspan="3">
                <label for="territory_ids">Territory</label>
                <treeselect id="territory_ids" :options="treeselect_territories" placeholder="Select terriories" autocomplete="off" multiple v-model="row.territory_ids"/>
              </td>
              <td>
                <span class="badge badge-light-danger d-block hand" v-on:click="remove_tt(form.ucomm.territories, index)">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </td>
            </tr>
            <tr v-for="(pd_row, pd_index) in row.percentage_distributions" :key="pd_index">
              <td>
                <div class="form-row">
                  <div class="col text-center">
                    <label for="stu_range_from">Student Range</label>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <input type="text" id="stu_range_from" class="form-control form-control-sm text-right" placeholder="From" v-model="pd_row.stu_range_from"/>
                  </div>
                  <div class="col">
                    <input type="text" class="form-control form-control-sm text-right" placeholder="To" v-model="pd_row.stu_range_to"/>
                  </div>
                </div>
              </td>
              <td>
                <div class="form-row">
                  <div class="col text-center">
                      <label for="percentage">Commission %</label>
                      <div class="input-group">
                        <input type="text" id="percentage" class="form-control form-control-sm text-right" placeholder="Percentage" v-model="pd_row.percentage"/>
                        <div class="input-group-append ">
                          <span class="input-group-text form-control form-control-sm">%</span>
                        </div>
                      </div>
                  </div>
                </div>
              </td>
              <td width="50">
                <span v-if="!pd_index" class="badge badge-primary hand d-block" v-on:click="add_ucomm_pd(row)">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
                <span v-if="pd_index" class="badge badge-light-danger d-block hand" v-on:click="remove_ucomm_pd(row.percentage_distributions, pd_index)">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <div class="row mt-2">
      <div class="col text-right">
        <button class="btn btn-light btn-sm mr-25" v-on:click="reset_comm_form()">Reset</button>
        <button class="btn btn-primary btn-sm" v-on:click="save_comm()" :disabled="flag.disable_submit">Save</button>
      </div>
    </div>
  </section>

</sidebar-form>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import SidebarForm from '../../../SidebarFormHalfScreen.vue';

export default {
  name: 'AddEditCommission',
  props: ['args'],
  components:{
    SidebarForm,
    Treeselect
  },
  data:()=>{
    return {
      list:{},
      info:{},
      flag:{
        comm_form: false,
        disable_submit: false,
        show_copy_section: false
      },
      form: {
				comm: null,
        ucomm:{
          title:'',
          intake_from:'',
          intake_to:'',
          territories:[{
            territory_ids:null,
            percentage_distributions:[{
              stu_range_from:'',
              stu_range_to:'',
              percentage:''
            }]
          }]
        },
        copy_ucomm:{
          copy_from_prev: false,
          comm_config_id: null,
          intake_from:'',
          intake_to:'',
        }
			},
      configs: {
        flatpickr: {
          dateFormat: 'd M Y',
          //maxDate: new Date()
        }       
      }
    };
  },
  methods:{
		toggle_comm_form: function(edit_comm_id=null){
      
      //console.log('COMM ID:', edit_comm_id);

			var ref=this;

			ref.flag.comm_form=!ref.flag.comm_form;
      ref.flag.disable_submit=false;

			if(!ref.flag.comm_form){
				ref.reset_comm_form();
        ref.reset_copy_config_form();
			}else if(edit_comm_id){
        ref.get_comm_data_to_edit(edit_comm_id);
        ref.flag.show_copy_section=false;
      }else{
        ref.flag.show_copy_section=true;
      }

		},
    reset_comm_form: function(){

      this.form.ucomm={
          id:'',
          title:'',
          intake_from:'',
          intake_to:'',
          territories:[{
                  territory_ids:null,
                  percentage_distributions:[{
                    stu_range_from:'',
                    stu_range_to:'',
                    percentage:''
                  }]
                }]
      }

    },
    save_comm: async function(){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v1/uclan/ajax/save_commission_config');

      try {
        ref.flag.disable_submit=true;
        ref.wait_me('.sidebar-form');
        var res=await jq.post(url, ref.form.ucomm);
        ref.alert(res.msg);
        ref.toggle_comm_form();
        ref.$emit('comm_config_saved');
      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }finally{
        ref.wait_me('.sidebar-form', 'hide');
        ref.flag.disable_submit=false;
      }

    },
    get_comm_data_to_edit: async function(edit_comm_id){

      var ref=this;
      var jq=ref.jq();
      var url=ref.url('api/v1/university/ajax/get_uclan_comm_data_to_edit');
      var qstr={
        id: edit_comm_id
      }

      try {

        ref.wait_me('.sidebar-form');
        var res=await jq.get(url, qstr);
        ref.form.ucomm=res.data.ucomm;
        
      }catch(err){
        ref.alert(ref.err_msg(err), 'error');
      }finally{
        ref.wait_me('.sidebar-form', 'hide');
      }

    },
    add_ucomm_details: function(tt){

      //alert('working...');

      tt.push({
        territory_ids:null,
        percentage_distributions:[{
          stu_range_from:'',
          stu_range_to:'',
          percentage:''
        }]
      });

    },
    remove_tt: function(tt, index){
      if (tt.length < 2) {
        return;
      }
			this.remove_row(
				tt,
				index
			);
    },
    add_ucomm_pd: function(row){

      row.percentage_distributions.push({
        stu_range_from:'',
        stu_range_to:'',
        percentage:''
			});

    },
    remove_ucomm_pd: function(row_pd, index){
			this.remove_row(
				row_pd,
				index
			);
    },
    create_from_scratch: function(){
      this.flag.show_copy_section=false;
    },
    copy_prev_uni_config: async function(){

      var ref=this;

      if(!ref.form.copy_ucomm.intake_from){
        ref.alert('Please select intake duration form (start) date.', 'error');
        return;
      }else if(!ref.form.copy_ucomm.intake_to){
        ref.alert('Please select intake duration to (end) date.', 'error');
        return;
      }else if(!ref.form.copy_ucomm.comm_config_id){
        ref.alert('Please select previous commission configuration.', 'error');
        return;
      }

      await ref.get_comm_data_to_edit(
        ref.form.copy_ucomm.comm_config_id
      );

      this.flag.show_copy_section=false;
      ref.reset_copy_config_form();

      ref.form.ucomm.title='Copy of '+ref.form.ucomm.title;
      ref.form.ucomm.id='';
      ref.form.ucomm.intake_from=ref.form.copy_ucomm.intake_from;
      ref.form.ucomm.intake_to=ref.form.copy_ucomm.intake_to;


    },
    reset_copy_config_form: function(){

      this.form.copy_ucomm.copy_from_prev=false;
      this.form.copy_ucomm.comm_config_id=null;

    }
  },
  computed: {
    treeselect_territories: function(){
      if (this.args.territories.length) {
        return this.args.territories.map(row=>{
          return {
            id: row.id,
            label: row.name
          }
        });
      } else {
        return []
      }
    },
    treeselect_prev_comm_configs: function(){
      if (this.args.uni_comm_configs.length) {
        return this.args.uni_comm_configs.map(row=>{
          return {
            id: row.id,
            label: row.parsed_comm_configs.title
          }
        });
      } else {
        return []
      }
    }
	},
  created(){
    this.setup_urls();
    this.reset_comm_form();
  }
}
</script>

<style>
.level-wise-details-row{
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}
.input-group-text {
  padding: 3px !important;
}
.table_edit_commission {
  border: 1px solid #f6e7ae;
}
.table_edit_commission tr {}
.table_edit_commission tr td{
  padding: 5px;
}
</style>
