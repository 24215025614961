<template>
    <div>
        <!-- Remarks start here -->
        <div class="card wait_student_remarks mt-2" v-if="permission_check('list_remarks_view')">
            <!-- <div class="card-header">
                <h4 class="card-title">Blockquotes styling <small class="text-muted">Default</small></h4>
            </div> -->
            <div class="card-body">
                <b-tabs content-class="mt-3">
                    <b-tab title="Remarks" active>
                        <div class="row">
                            <div class="col-6" v-if="permission_check('list_remarks_add')">
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <!-- <vue-editor :editor-toolbar="bodyContentCustomToolbar" v-model="student_remarks.remarks" placeholder="Enter remarks"></vue-editor> -->
                                        <textarea style="height: 258px" v-model="student_remarks.remarks" placeholder="Enter remarks" class="form-control"></textarea>
                                    </div>
                                </div>
                                <div class="col-sm-12 p-0">
                                    <button class="btn btn-primary mr-1 waves-effect waves-float waves-light float-right" @click="saveRemarks()">Save</button>
                                    <button class="btn btn-outline-secondary waves-effect float-right mr-2"  @click="clearRemarks()">Clear</button>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group row">
                                    <div class="col-sm-12 student_remarks_list">
                                    <div class="media align-items-center" v-for="(item, index) in studentRemarksListData" :key="index">

                                            <div class="avatar bg-light-danger  mr-50" v-if="item.added_by">
                                                <div class="avatar-content" v-html="avatarText(item.added_by.name)" v-tooltip="item.added_by.name"></div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="mb-0">
                                                    <span v-if="item.university" v-html="item.university.name" class="mr-1"></span> 
                                                    <small>{{ dNow(item.created_at) }}</small>
                                                </h6>
                                                <p class="mb-0" v-html="item.remarks"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <!-- <b-tab title="COMMS">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="selectDefault">Select a Channel</label>
                                            <select class="form-control mb-1">
                                                <option selected="">Choose One</option>
                                                <option value="1">Email</option>
                                                <option value="2">Facebook</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="selectDefault">Type</label>
                                            <select class="form-control mb-1">
                                                <option selected="">Choose One</option>
                                                <option value="1">Incoming</option>
                                                <option value="2">Outgoing</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="selectDefault">Purpose</label>
                                            <select class="form-control mb-1">
                                                <option selected="">Choose One</option>
                                                <option value="1">Acknowledge</option>
                                                <option value="2">Additional Documents</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label for="basicInput">Remarks</label>
                                            <textarea  class="form-control" id="basicInput" placeholder="Enter Remarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 p-0">
                                    <button type="reset" class="btn btn-primary mr-1 waves-effect waves-float waves-light float-right">Save</button>
                                    <button type="reset" class="btn btn-outline-secondary waves-effect float-right mr-2">Reset</button>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                    <div class="media align-items-center">

                                            <div class="avatar bg-light-danger  mr-50">
                                                <div class="avatar-content">DU</div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="mb-0">Demo University <small>01 Nov 2021 20:00:pm</small></h6>
                                                <p class="mb-0">Hi</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab> -->
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>

import feather from 'feather-icons';
// import { VueEditor } from "vue2-editor";
import { BTabs, BTab} from 'bootstrap-vue';

export default{
    name: 'StudentRemarks',
    props: ['list_id'],
    components:{
        // VueEditor,
        BTabs, BTab,
    },
    data:()=>{
        return {
            bodyContentCustomToolbar: [
                ["bold", "italic", "underline", "link"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            studentRemarksListData: [],
            student_remarks: {
                agent_compnay_id: '',
                as_list_id: '',
                as_id: '',
                remarks: '',
                remarks_type: 'university',
            }
        };
    },
    watch: {
    },
    methods:{
        clearRemarks: function () {
            this.student_remarks.agent_compnay_id = ''
            this.student_remarks.as_list_id = ''
            this.student_remarks.as_id = ''
            this.student_remarks.remarks = ''
            this.student_remarks.remarks_type = 'university'
        },
        saveRemarks: async function(){

            var ref=this;
            var jq=this.jq();

            try{
                const params = Object.assign({}, this.student_remarks, { list_id: this.list_id }) 
                var res = await jq.post(ref.url('api/v1/university/ajax/store_student_remarks'), params);
                this.clearRemarks()
                this.getStudentRemarksListData();
                ref.alert(res.msg);

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }

        },
        getStudentRemarksListData: async function(){

            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { list_id: this.list_id })
                ref.wait_me('.wait_student_remarks');
                var res = await jq.get(ref.url('api/v1/university/ajax/get_student_remarks_list_id_wise_data'), params);
                this.studentRemarksListData = res.data.list

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_student_remarks', 'hide');
            }
        },
    },
    computed: {
    },
    created: async function(){
        this.setup_urls();
        console.log('list id from componant ', this.list_id)
    },
    mounted: function(){
        this.getStudentRemarksListData()
        feather.replace();
    }
}
</script>

<style>
.student_remarks_list {
    width: 100% !important;
    height: 258px !important;
    overflow-y: auto !important;
    /* overflow-x: hidden; */
}

.student_remarks_list::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.student_remarks_list::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}

.student_remarks_list::-webkit-scrollbar-thumb
{
    background-color: #047EAF;
    border-radius: 10px;
    background-image: -webkit-linear-gradient(0deg,rgba(255, 255, 255, 0.5) 25%, transparent 25%, transparent 50%,rgba(255, 255, 255, 0.5) 50%,rgba(255, 255, 255, 0.5) 75%, transparent 75%,transparent)
}
</style>