<template>
<section class="agency-list">
    <main-layout>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-6 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <!-- <h5 class="content-header-title float-left mb-0">Manage Agent</h5> -->
                        <h5 class="content-header-title float-left mt-50">Manage Agent</h5>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to="/">Dashboard</router-link></li>
                                <li class="breadcrumb-item active">Agent List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-6 text-right">
                <button type="button" @click="toggle_filter()" class="btn btn-secondary waves-effect waves-float waves-light mr-1"><i data-feather='filter'></i></button>
                <router-link v-if="permission_check('agent_add')" class="btn btn-primary waves-effect waves-float waves-light" to="/add-new-agent">
                    <i data-feather="plus-square"></i>
                    Add New Agent
                </router-link>
            </div>
        </div>

        <div class="card bg-light-gray" v-show="flag.show_filter">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h5>Search Filter</h5>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <input type="text" v-model="search.agent_code" class="form-control" id="agent_code" placeholder="Banner code" autocomplete="off">
                    </div>
                    <div class="col-md-3">
                        <input type="text" v-model="search.name" class="form-control" id="name" placeholder="Agency name" autocomplete="off">
                    </div>
                    <div class="col-md-3">
                        <!-- <treeselect v-model="search.region_id" :options="regionList" id="region_id" placeholder="Select Region" autocomplete="off"/> -->
                        <treeselect v-model="search.territory_id" :options="territoryList" placeholder="Select Territory" id="territory_id" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <treeselect v-model="search.country_id" :options="region_countries" id="country_id" placeholder="Select Country" autocomplete="off"/>
                    </div>
                </div>
                <div class="form-row mt-1">
                    <div class="col-md-3">
                        <input type="text" v-model="search.contact_email" class="form-control" id="contact_email" placeholder="Key contact email" autocomplete="off">
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.uni_status_id" :options="treeselectStatusList" id="uni_status_id" placeholder="Select status" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.account_manager_id" :options="treeselect_uni_users" id="supervisor_id" placeholder="Account Manager" autocomplete="off"/>
                    </div>
                    <div class="col-md-2">
                        <treeselect v-model="search.signed_up_id" :options="signedUpStatusList" id="signed_up_id" placeholder="Signed up" autocomplete="off"/>
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <div class="col-md-6">
                                <button class="btn btn-primary btn-block" @click="searchData()">Search</button>
                            </div>
                            <div class="col-md-6">
                                <button class="btn btn-outline-danger btn-block" @click="clearSearchData()">Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card agency-list-card mt-1">
            <div class="card-header d-block">
                <div class="row">
                    <div class="col-6 col-md-6">
                        <!-- <h3> Agent List</h3> -->
                        <small @click="getStatusWiseListData('')" v-tooltip="'Total Agents'" v-show="agencyListCount" class="cursor-pointer badge badge-pill badge-light-primary mr-50" v-html="'Total - ' + agencyListCount"></small>

                        <small @click="getStatusWiseListData('new')" v-tooltip="'Pending Registration'" v-show="agencyNewlySignedUpCount" class="cursor-pointer badge badge-pill badge-light-info mr-50" v-html="'Pending - ' + agencyNewlySignedUpCount"></small>

                        <small @click="getStatusWiseListData('active')" v-tooltip="'Active Agents'" v-show="agencySingUpListCount" class="cursor-pointer badge badge-pill badge-light-success mr-50" v-html="'Active - ' + agencySingUpListCount"></small>
                        <small @click="getStatusWiseListData('inactive')" v-tooltip="'Supended & Terminated Agents'" v-show="agencyInactiveListCount" class="cursor-pointer badge badge-pill badge-light-danger mr-50" v-html="'Inactive - ' + agencyInactiveListCount"></small>
                        <!-- <small class="badge badge-pill badge-light-secondary" v-html="'Newly Joined - ' + agencyNewlySignedUpCount"></small> -->
                    </div>
                    <div class="col-6 text-right">
                        <!-- <button type="button" class="btn btn-primary waves-effect waves-float waves-light" v-on:click="toggle_agent_form()"></button> -->
                        <!-- <router-link v-if="permission_check('agent_add')" class="btn btn-primary waves-effect waves-float waves-light" to="/add-new-agent">
                            <i data-feather="plus-square"></i>
                            Add New Agent
                        </router-link> -->
                        <!-- <button v-show="sendInvitationEmailButtonShow" class="btn btn-info waves-effect waves-float waves-light ml-1" v-on:click="invitation_confirmation()">
                            <i data-feather="send"></i>
                            Send Invitation Email
                        </button> -->
                        <button v-if="!send_email_action_checkbox" class="btn btn-success waves-effect waves-float waves-light ml-1" v-on:click="invitation_confirmation()">
                            <i data-feather="send"></i>
                            Send Invitation Email
                        </button>
                        
                        <!-- <b-dropdown class="ml-2" text="Download" variant="outline-primary">
                            <b-dropdown-item  download :href="download_url">
                                Excel
                            </b-dropdown-item>
                        </b-dropdown> -->
                        <a :href="download_url" download v-tooltip="'Download to excel'"><i class="fa fa-cloud-download text-uclan font-size-20" aria-hidden="true"></i></a>
                        <!-- <i class="fa fa-square-o cursor-pointer ml-50" style="font-size: 20px" @click="send_email_action_button_show()" v-if="send_email_action_checkbox && this.permission_check('agent_send_mail')" v-tooltip="'Send Invitation email to all pending agents'"></i> -->
                        <i class="fa fa-envelope-o custom-class text-uclan cursor-pointer ml-1 font-size-20" v-if="this.permission_check('agent_send_mail')" v-on:click="invitation_confirmation()" v-tooltip="'Send Invitation email to all pending agents'"></i>
                    </div>
                </div>
            </div>

            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th width="2%" class="px-1 align-middle"><span v-tooltip="'Account Manager'">AM</span></th>
                                <!-- <th class="px-1" width="10%">Banner code</th> -->
                                <th class="px-1" width="22%">Business Name</th>
                                <th class="px-1" width="20%">Key Contact</th>
                                <th class="px-1" width="10%"><span v-tooltip="'Commission Territory'">Com. Terri</span></th>
                                <th class="px-1" width="12%"><span v-tooltip="'Recruitment Territory'">Recruit. Terri</span></th>
                                <th width="12%" class="px-1 text-center"><span v-tooltip="'Agreement Expiry'">Expiry</span></th>
                                <!-- <th>Registered</th>
                                <th>Requested</th> -->
                                <th class="px-1" width="14%">Status</th>
                                <!-- <th class="px-1 text-right" width="13%">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="check-all-invitation" v-model="form.send_invitation.checked_all" v-on:change="check_all_invitaion(true)">
                                        <label class="custom-control-label" for="check-all-invitation"></label>
                                    </div>
                                </th> -->
                                <th class="px-1 text-right" width="8%">
                                    <span>Actions</span>
                                    <!-- <i class="fa fa-square-o cursor-pointer ml-50" style="font-size: 20px" @click="send_email_action_button_show()" v-if="send_email_action_checkbox && this.permission_check('agent_send_mail')" v-tooltip="'Send Invitation email to all pending agents'"></i>
                                    <i class="fa fa-check-square cursor-pointer text-success ml-50" style="font-size: 20px" @click="send_email_action_button_show()" v-if="!send_email_action_checkbox && this.permission_check('agent_send_mail')" v-tooltip="'Send Invitation email to all pending agents'"></i> -->
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="agencyList && agencyList.length > 0">
                            <tr v-for="(item, index) in agencyList" :key="index">
                                <td class="px-1">
                                    <div class="d-flex" v-if="cn(item, 'assigned_account_manager.name', null)">
                                        <div class="avatar-wrapper align-center">
                                            <div class="avatar bg-light-primary mr-1">
                                                <small class="avatar-content" v-html="avatarText(item.assigned_account_manager.name)" v-tooltip="item.assigned_account_manager.name"></small>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div @click="addAccountManager(item)" v-else class="avatar-wrapper align-center">
                                        <div class="avatar bg-light-warning mr-1">
                                            <small class="avatar-content">
                                                <user-plus-icon size="1.5x" class="custom-class"></user-plus-icon>
                                            </small>
                                        </div>
                                    </div> -->
                                    <div @click="open_assign_am_form_modal(item)" v-else class="avatar-wrapper align-center">
                                        <div class="avatar bg-light-warning mr-1">
                                            <small class="avatar-content">
                                                <user-plus-icon size="1.5x" class="custom-class"></user-plus-icon>
                                            </small>
                                        </div>
                                    </div>
                                </td>
                                <!-- <td class="px-1">
                                    <small @click="gotoAgentProfilePage(item)" v-tooltip="'Banner Code'" class="badge badge-pill badge-light-primary cursor-pointer" v-html="item.agent_code"></small>
                                </td> -->
                                <td class="px-1" @click="gotoAgentProfilePage(item)">
                                    <div class="d-flex justify-content-left align-items-center">
                                        <!-- <div class="avatar-wrapper">
                                            <div class="avatar  bg-light-warning  mr-1">
                                                <span class="avatar-content" v-html="limit_str(item.country.name, 4, '')"></span>
                                            </div>
                                        </div> -->
                                        
                                        <div class="avatar-wrapper">
                                            <div class="avatar  mr-1" v-tooltip="item.country ? 'Headquater in ' + item.country.name : ''">
                                                <img :src="item.flag_url" alt="Avatar" height="32" width="32">
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column">
                                            <small class="">
                                                <small class="font-weight-bolder" v-html="item.name"></small>
                                                <i class="fa fa-check ml-1 text-success" v-if="item.signed_up_at != null" v-tooltip="'Signed Up'"></i>
                                                <!-- <i class="fa fa-circle ml-1 text-success" v-if="item.signed_up_at != null" title="Sined Up"></i>
                                                <i class="fa fa-circle ml-1 text-danger" v-if="item.signed_up_at == null" title="Not Signed Up"></i> -->
                                            </small>
                                            <small class="emp_post text-muted">
                                                <!-- <span v-html="item.country.name"></span>   -->
                                                <!-- ★
                                                <span class="badge badge-pill badge-light-warning mr-1">A54320</span> -->
                                                <small class="d-block" v-html="item.email"></small>
                                                <!-- <i class="fa fa-circle ml-1 text-success" v-if="item.signed_up_at != null" title="Sined Up"></i> -->
                                                <!-- <i class="fa fa-check text-success" v-if="item.signed_up_at != null" title="Sined Up"></i> -->
                                            </small>
                                            <div>
                                                <small v-tooltip="'Banner Code'" class="badge badge-pill badge-light-primary cursor-pointer font-size-10" v-html="item.agent_code"></small>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <span class="text-primary d-block" v-html="item.name"></span>
                                    <span class="emp_post text-muted" v-html="item.country.name"></span> -->
                                </td>
                                <!-- <td>
                                    <span class="d-block" v-html="item.email"></span>
                                    <span v-if="item.agent_mobile" class="emp_post text-muted" v-html="'+' + item.country.calling_code + item.agent_mobile"></span>
                                </td> -->
                                <td class="px-1">
                                    <small v-if="item.contact_person" class="d-block" v-html="item.contact_person"></small>
                                    <small v-if="item.contact_number" class="emp_post text-muted d-block" v-html="'+' + item.country.calling_code + item.contact_number"></small>
                                    <small v-if="item.contact_email" class="emp_post text-muted" v-html="item.contact_email"></small>
                                </td>
                                <td class="px-1">
                                    <!-- <small class="d-block" v-if="item.region" v-html="item.region.title"></small> -->
                                    <small class="d-block" v-if="item.territory" v-html="item.territory.name"></small>
                                </td>
                                <td class="px-1">
                                    <div class="b-avatar-group" v-if="item.recruitment_markets && item.recruitment_markets.length">
                                        <div class="b-avatar-group-inner" style="padding-left: calc(5.1px); padding-right: calc(5.1px);">
                                            <span v-for="recMarket in item.recruitment_markets.slice(0, 2)" :key="recMarket.id" v-tooltip="recMarket.country.name" class="b-avatar pull-up badge-secondary rounded-circle" style="margin-left: calc(-5.1px); margin-right: calc(-5.1px); width: 30px; height: 30px; margin-right: 10px">
                                                <span class="b-avatar-img"><img :src="recMarket.country.flag_url" alt="avatar"></span>
                                            </span>
                                            <h6 v-if="item.recruitment_markets.length > 2" class="align-self-center cursor-pointer zoom" @click="showRecruitmentTerritoryListModal(item)"> +{{ (item.recruitment_markets.length - 2) }} </h6>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center px-1">
                                    <div v-if="item.agreement_expiry">
                                        <span>
                                            <small @click="editAgreementData(item)" v-if="checkCurrentDateLessThenDate(item.agreement_expiry)" v-html="dDate(item.agreement_expiry)"></small>
                                            <small @click="editAgreementData(item)" v-else v-html="'Expired'" class="badge badge-light-danger"></small>
                                        </span><br>
                                        <small @click="editAgreementData(item)" class="badge badge-light-primary" v-if="item.agreement_renewable == 1">Renewable</small>
                                    </div>
                                    <i v-else @click="editAgreementData(item)" class="fa fa-calendar"></i>                                 
                                </td>
                                <!-- <td class="text-center">
                                    <small v-if="item.signed_up_at" v-html="dDate(item.signed_up_at)"></small>
                                    <small v-else>-</small>
                                </td>
                                <td class="text-center">
                                    <div v-if="item.last_invitation">
                                        <small v-html="dDate(item.last_invitation.created_at)"></small>
                                    </div>
                                </td> -->
                                <td class="px-0">
                                    <!-- <div class="btn-group">
                                        <button class="btn btn-sm btn-outline-success dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Approved </button>
                                        <div class="dropdown-menu" style="">
                                            <a class="dropdown-item" href="javascript:void(0);">Inactive</a>
                                            <a class="dropdown-item" href="javascript:void(0);">Pending</a>
                                            <a class="dropdown-item" href="javascript:void(0);">Approved</a>
                                        </div>
                                    </div> -->
                                    <!-- <b-dropdown size="sm" :text="agent_status(item).label" :variant="agent_status(item).btn_color">
                                        <b-dropdown-item href="javascript:void(0)" v-for="dropdown in list.agent_statuses" :key="dropdown.id" v-on:click="agent_status_update_confirmation(item.id, dropdown.id)" :disabled="item.status==dropdown.id">
                                            <span :class="dropdown.text_color" v-html="dropdown.label"></span>                                            
                                        </b-dropdown-item>
                                    </b-dropdown> -->
                                    <b-dropdown class="agent_status_button" size="sm" :text="status_name(item)" :variant="'outline-' + status_color(item)">
                                        <!-- <b-dropdown-item href="javascript:void(0)" v-for="dropdown in statusList" :key="dropdown.id" v-on:click="agent_uni_status_update(item, dropdown)" :disabled="statusDropdownDisable(dropdown, item)">
                                            <span :class="'text-' + dropdown.color" v-html="dropdown.name"></span>                                            
                                        </b-dropdown-item> -->
                                        <b-dropdown-item href="javascript:void(0)" v-for="dropdown in getStatusListByAgentCode(item)" :key="dropdown.id" v-on:click="agent_uni_status_update(item, dropdown)" :disabled="getAgentDisableStatus(item)">
                                            <span :class="'text-' + dropdown.color" v-html="dropdown.name"></span>                                            
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <!-- <i :class="'fa fa-info-circle ml-25 text-' + status_color(item)" @click="open_agent_remarks_modal(item)" v-tooltip="item.uni_agent_status_reason"></i> -->
                                    <i :class="'fa fa-comment-o ml-25 text-' + status_color(item)" v-if="item.count_agent_remarks" @click="open_agent_remarks_modal(item)" v-tooltip="'View Remarks'"></i>
                                    <i :class="'fa fa-plus-circle ml-25 text-' + status_color(item)" v-else @click="open_agent_remarks_modal(item)" v-tooltip="'Add Remarks'"></i>
                                </td>
                                <td class="px-1 text-right">
                                    <div class="position-relative d-inline-block" v-if="show_invitation_email(item)">
                                        <i class="fa fa-envelope-o custom-class text-secondary" v-on:click="send_single_mail(item)" v-tooltip="'Send Mail'"></i>
                                        <span class="badge badge-pill badge-light-success badge-up text-success" v-html="item.count_invitation"></span>
                                    </div>
                                    <b-dropdown size="sm" variant="link" class="text-dark" toggle-class="text-decoration-none" no-caret>
                                        <template #button-content>
                                            <span><i class="fa fa-ellipsis-v text-dark" aria-hidden="true"></i></span>
                                        </template>
                                        <b-dropdown-item href="javascript:void(0)" v-if="permission_check('aggen_edit')" @click="editData(item)">
                                            <i v-tooltip="'Edit Agent Details'" class="fa fa-pencil mr-50"></i> Edit
                                        </b-dropdown-item>
                                        <b-dropdown-item href="javascript:void(0)" v-if="permission_check('agent_delete')" @click="deleteData(item)">
                                            <i v-tooltip="'Delete Agent'" class="fa fa-trash-o text-danger mr-50"></i> Delete
                                        </b-dropdown-item>
                                        <b-dropdown-item href="javascript:void(0)" v-if="permission_check('agent_profile')" @click="gotoAgentProfilePage(item)">
                                            <i class="fa fa-eye text-primary mr-50" v-tooltip="'Agent Profile'"></i> View
                                        </b-dropdown-item>
                                        <b-dropdown-item href="javascript:void(0)" v-on:click="open_agent_activity_log(item)">
                                            <i class="fa fa-clock-o cursor-pointer mr-50" v-tooltip="'Activity Log'"></i> Activity Log
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    
                                    <!-- <i v-if="permission_check('aggen_edit')" v-tooltip="'Edit Agent Details'" @click="editData(item)" class="fa fa-pencil ml-1"></i>
                                    <i v-if="permission_check('agent_delete')" v-tooltip="'Delete Agent'" @click="deleteData(item)" class="fa fa-trash-o text-danger ml-1"></i>
                                    <i v-if="permission_check('agent_profile')" @click="gotoAgentProfilePage(item)" class="fa fa-eye text-primary ml-1" v-tooltip="'Agent Profile'"></i>
                                    <i class="fa fa-clock-o cursor-pointer ml-1" v-on:click="open_agent_activity_log(item)" v-tooltip="'Activity Log'"></i> -->
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th colspan="7" class="text-center">
                                    <span v-if="agencyList === null" >Processing...</span>
                                    <span v-else >
                                        <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
                                        Found no agent data.
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    
                    <b-pagination
                        class="mt-1 mr-1"
                        align="right"
                        v-model="pagination.currentPage"
                        pills
                        :per-page="pagination.perPage"
                        :total-rows="pagination.totalRows"
                        @input="searchData"
                        >
                    </b-pagination>
                </div>
            </div>
        </div>



            <!-- Agent Company Info Edit -->
            <div class="row" v-if="this.editItem">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <sidebar-form 
                        :show_sidebar="flag.show_modal_form"
                        v-on:toggle-sidebar="toggle_modal_form()"
                        title="Edit Student"
                        >
                        <div class="mb-1">
                            <h4 class="">
                                <span class="align-middle">Edit {{ editItem.name }}</span>
                            </h4>
                        </div>
                        <div class="flex-grow-1 wait_me_edit_form">
                            <div class="row bg-white box-shadow p-1 mb-2">
                                <h5 class="text-center">Business Info</h5>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_name"><b>Business Name</b> <span class="text-danger">*</span></label>
                                        <input type="text" v-model="editItem.name" class="form-control" id="edit_name" placeholder="Enter Business Name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_email"><b>Business Email</b> <span class="text-danger">*</span></label>
                                        <input type="email" disabled v-model="editItem.email" class="form-control" id="edit_email" placeholder="Enter Email">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="country_id"><b>Headquater</b> <span class="text-danger">*</span></label>
                                        <treeselect v-model="editItem.country_id" :options="countryList" placeholder="Select Country" id="country_id" autocomplete="off"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row bg-white box-shadow p-1 mb-2">
                                <h5 class="text-center">Key Contact Person</h5>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_contact_person"><b>Name</b> <span class="text-danger">*</span></label>
                                        <input type="text" v-model="editItem.contact_person" class="form-control" id="edit_contact_person" placeholder="Enter Key Contact Person">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_contact_email"><b>Email</b> <span class="text-danger">*</span></label>
                                        <input type="text" v-model="editItem.contact_email" class="form-control" id="edit_contact_email" placeholder="Enter Key Contact Email">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_contact_number"><b>Phone</b> <span class="text-danger"></span></label>
                                        <input type="text" v-model="editItem.contact_number" class="form-control" id="edit_contact_number" placeholder="Enter Key Contact Email">
                                    </div>
                                </div>
                                <!-- <div class="col-12">
                                    <div class="form-group">
                                        <label for="edit_end_date">Date of Birth</label>
                                            <flat-pickr
                                                id="edit_end_date"
                                                v-model="editItem.dob"
                                                :config="configs.flatpickr"
                                                class="form-control"
                                                placeholder="Select end date"/>
                                    </div>
                                </div> -->
                                <!-- <div class="col-12">
                                    <div class="form-group">
                                        <label for="region_id">Region</label>
                                        <treeselect v-model="editItem.region_id" :options="regionList" placeholder="Select region" id="region_id" autocomplete="off"/>
                                    </div>
                                </div> -->
                            </div>
                            <div class="row bg-white box-shadow p-1">
                                <h4 class="text-center">Other Info</h4>
                                <div class="col-12 form-group">
                                    <label for="accoun_tmanager_id"><b>Account Manager</b></label>
                                    <treeselect v-model="editItem.account_manager_id" :options="treeselect_uni_users" placeholder="Select account manager" autocomplete="off" id="accoun_tmanager_id"/>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="territory_id"><b>Primary Terrritory For Commission</b></label>
                                        <treeselect v-model="editItem.territory_id" :options="territoryList" placeholder="Select territory" id="territory_id" autocomplete="off"/>
                                    </div>
                                </div>
                                <div class="col-12 form-group">
                                    <b-form-group label="Recruitment Territory" class="font-weight-bold">
                                        <b-form-radio-group
                                            v-model="editItem.recruitment_market_type"
                                            :options="recruitmentMarketTypeList"
                                            class="demo-inline-spacing"
                                            >
                                        </b-form-radio-group>
                                    </b-form-group>
                                </div>

                                <div class="col-12" v-if="editItem.recruitment_market_type == 2">
                                    <div class="form-group">
                                        <label for="territory_ids"><b>Select Region</b></label>
                                        <treeselect multiple v-model="editItem.recruitment_market_territory_ids" :options="territoryList" placeholder="Select Region" id="territory_ids" autocomplete="off"/>
                                    </div>
                                </div>

                                <div class="col-12" v-if="editItem.recruitment_market_type == 1">
                                    <div class="form-group">
                                        <label for="country_ids"><b>Select Country</b></label>
                                        <treeselect multiple v-model="editItem.recruitment_market_country_ids" :options="countryList" placeholder="Select Country" id="country_ids" autocomplete="off"/>
                                    </div>
                                </div>

                                <div class="col-12 form-group" v-if="editItem.recruitment_market_type">
                                    <b-form-checkbox
                                        id="domicile"
                                        class="font-weight-bold"
                                        v-model="editItem.domicile"
                                        name="domicile"
                                        >
                                        UK Domicile
                                        </b-form-checkbox>
                                </div>

                                <div class="col-12 mt-1">
                                    <button type="button" class="btn btn-success mr-1" @click.prevent="updateFormData()">Update</button>
                                </div>
                            </div>
                        </div>
                    </sidebar-form>
                </div>
            </div>
        
            <!-- Agent Agreement Info Edit -->
            <div class="row" v-if="this.editItem">
                <!-- <div class="col-md-6"></div> -->
                <div class="col-md-6">
                    <sidebar-form 
                        :show_sidebar="flag.show_agreement_modal_form"
                        v-on:toggle-sidebar="toggle_modal_agreement()"
                        title="Edit Student"
                        >
                        <div class="mb-1">
                            <h5 class="">
                                <span class="align-middle">Edit Agent Agreement</span>
                            </h5>
                        </div>
                        <div class="flex-grow-1 wait_me_edit_form" >
                            <div class="row bg-white box-shadow p-1 mb-2">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="agreement_expiry">Agreement Expire Date</label>
                                        <flat-pickr
                                            id="agreement_expiry"
                                            v-model="editItem.agreement_expiry"
                                            :config="configs.flatpickr"
                                            class="form-control"
                                            placeholder="Select date"/>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="agreement_renewable">Agreement Renewable</label>
                                            <b-form-radio-group
                                            id="agreement_renewable"
                                            v-model="editItem.agreement_renewable"
                                            :options="agreementRenewableOption"
                                        ></b-form-radio-group>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="button" class="btn btn-success mr-1" @click.prevent="updateAgentAgreementData()">Update</button>
                                    <!-- <button type="button" @click="update_reset()" class="btn btn-outline-secondary"><x-icon size="1.5x" class="custom-class"></x-icon> Close</button> -->
                                </div>
                            </div>
                            <div class="row mt-2 bg-white box-shadow p-1" v-if="agentAgreementHisotryList && agentAgreementHisotryList.length">
                                <div class="col-md-12">
                                    <h5><span class="align-middle">Agreement History</span></h5>
                                    <ul class="timeline agent_agreement_history mt-2">
                                        <li class="timeline-item" v-for="(logItem, logIndex) in agentAgreementHisotryList" :key="logIndex">
                                            <span class="timeline-point timeline-point-indicator"></span>
                                            <div class="timeline-event">
                                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                                    <h6>Agreement Expiry</h6>
                                                    <span class="timeline-event-time" v-html="dNow(logItem.created_at)"></span>
                                                </div>
                                                <p  v-html="'Date : ' + dDate(logItem.expiry_date)"></p>
                                                <p>Created by <b v-if="logItem.user" v-html="logItem.user.name"></b></p>
                                                <p>University <b v-if="logItem.university" v-html="logItem.university.name"></b></p>
                                                <p>Agent <b v-if="logItem.agent" v-html="logItem.agent.name"></b></p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </sidebar-form>
                </div>
            </div>


        <!-- <b-modal ref="recruitment_territory_list_modal" class="text-center">
            <template #modal-title>
                <span class="text-uclan">Recruitment Territory Countries</span>
            </template>
            <div class="b-avatar-group" v-if="recruitmentMarketsModalList && recruitmentMarketsModalList.length">
                <div class="b-avatar-group-inner" style="padding-left: calc(5.1px); padding-right: calc(5.1px);">
                    <span v-for="recMarket in recruitmentMarketsModalList" :key="recMarket.id" v-tooltip="recMarket.country.name" class="b-avatar pull-up badge-secondary rounded-circle" style="margin-left: calc(-5.1px); margin-right: calc(-5.1px); width: 30px; height: 30px; margin: 10px">
                        <span class="b-avatar-img"><img :src="recMarket.country.flag_url" alt="avatar"></span>
                    </span>
                </div>
            </div>

            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.recruitment_territory_list_modal.hide()">Close</button>
            </template>
        </b-modal> -->
                                    
        <b-modal ref="recruitment_territory_list_modal" :size="recruitmentMarketsModalTableSize" class="text-center">
            <template #modal-title>
                <span class="text-uclan">Recruitment Territory Countries</span>
            </template>

            <table class="table table-bordered table-sm">
                <tr>
                    <th :colspan="recruitmentMarketsModalTableThColSpan" class="text-center">Countries</th>
                </tr>
                    <tr v-for="(chunk, index) in recruitmentMarketsModalList" :key="index">
                        <td v-for="(chunkRow, chunkIndex) in chunk" :key="chunkIndex" class="align-items-center">
                            <span class="b-avatar pull-up badge-secondary rounded-circle mr-1" style="width: 30px; height: 30px;">
                                <span class="b-avatar-img"><img :src="chunkRow.country.flag_url" alt="avatar"></span>
                            </span>
                            <span v-html="chunkRow.country.name"></span>
                        </td>
                    </tr>
            </table>

            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.recruitment_territory_list_modal.hide()">Close</button>
            </template>
        </b-modal>

        <!-- <b-modal ref="invitation_confirmation">
            <template #modal-title>
                <span class="text-danger">Invitation email confirmation</span>
            </template>
            Are you sure do you want to Send Invitation for All?
            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.invitation_confirmation.hide()">No</button>
                <button type="button" class="btn btn-success waves-effect waves-float waves-light" v-on:click="send_invitation_email()">Yes</button>
            </template>
        </b-modal>

        <b-modal ref="agent_status_confirmation">
            <template #modal-title>
                <span class="text-danger">Change Agent Status</span>
            </template>
            To confirm status change please press Yes or No to cancel.
            <template #modal-footer>
                <button type="button" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="$refs.agent_status_confirmation.hide()">No</button>
                <button type="button" class="btn btn-success waves-effect waves-float waves-light" v-on:click="update_agent_status()">Yes</button>
            </template>
        </b-modal> -->


        <activity-log :log_data="agentActivitylog" ref="agent_activity_log_modal"></activity-log>

        <b-modal ref="assign_account_manager" hide-footer>
            <template #modal-title>
                <span class="text-primary">Assign Account Manager</span>
            </template>

            <div class="assign_account_manager">
                <div class="row">
                    <div class="col">
                        To assign account manager on <span v-html="form.assign_am.agent_name"></span> Please select an option from dropdown.
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col">
                        <treeselect v-model="form.assign_am.uni_user_id" :options="treeselect_uni_users" placeholder="Select account manager" autocomplete="off"/>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col text-right">
                        <button type="button" class="btn btn-light btn-sm waves-effect waves-float waves-light mr-50" v-on:click="$refs.assign_account_manager.hide()">Cancel</button>
                        <button type="button" class="btn btn-success btn-sm waves-effect waves-float waves-light" v-on:click="submit_assign_am_form()">Save</button>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal ref="agent_remarks_modal" size="lg" hide-footer>
            <template #modal-title>
                <span class="text-primary">Agent Company Remarks</span>
            </template>

            <div class="row">
                <div class="col-12">
                    <agent-remarks v-if="agentId" :agent_company_id="agentId" ref="agent_remarks_component"/>
                </div>
            </div>
        </b-modal>

    </main-layout>
</section>
</template>

<script>

import MainLayout from './MainLayout.vue';
import feather from 'feather-icons';
//import SidebarForm from '../SidebarForm.vue';
// import { BModal, BDropdown, BDropdownItem, BPagination, BAvatar } from 'bootstrap-vue';
import { BModal, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormRadioGroup } from 'bootstrap-vue';
// import { BModal, BPagination } from 'bootstrap-vue';
import { BFormCheckbox } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect';
import swal from 'bootstrap-sweetalert';
import Swal from 'sweetalert2'
import SidebarForm from '../SidebarFormLighterBlue';
import ActivityLog from './Partials/ActivityLog.vue';
import AgentRemarks from "./Partials/AgentRemarks.vue";

import flatPickr from 'vue-flatpickr-component';
import { UserPlusIcon } from 'vue-feather-icons'
import moment from 'moment';
import Hashids from 'hashids';

export default{
    name: 'AgencyList',
    metaInfo: {
        titleTemplate: '%s | Manage Agent'
    },
    components:{
        MainLayout,
        ActivityLog,
        AgentRemarks,
        BModal,
        BDropdown,
        BDropdownItem,
        BPagination,
        BFormGroup,
        BFormRadioGroup,
        BFormCheckbox,
        // MailIcon,
        Treeselect,
        SidebarForm,
        UserPlusIcon,
        flatPickr,
        // ListIcon
        // EyeIcon
        // ClockIcon
    },
    data:()=>{
        return {
            send_email_action_checkbox: true,
            sendInvitationEmailButtonShow: false,
            editItem: {},
            configs: {
                flatpickr: {
                    dateFormat: 'd M Y',
                    // maxDate: new Date()
                }        
            },
            agencyList: null,
            agentId: '',
            agentActivitylog: [],
            usersList: [],
            statusList: [],
            treeselectStatusList: [],
            accountManagerList: [],
            regionList: [],
            territoryList: [],
            agentAgreementHisotryList: [],
            regionCountryList: [],
            countryList: [],
            regionData: [],
            agencyListCount: 0,
            agencySingUpListCount: 0,
            agencyInactiveListCount: 0,
            agencyNewlySignedUpCount: 0,
            flag:{
                show_agent_form:false,
                show_modal_form:false,
                show_agreement_modal_form:false,
                show_modal_activity_log:false,
                show_filter: false,
            },
            form:{
                send_invitation:{
                    checked_all: false,
                    agent_ids:[]
                },
                agent_status_update:{
                    agent_id:'',
                    new_status: ''
                },
                assign_am:{
                    agent_id: null,
                    agent_name: null,
                    uni_user_id: null
                }
            },
            search: {
                region_id: null,
                country_id: null,
                territory_id: null,
                name: '',
                agent_code: '',
                contact_person: '',
                contact_email: '',
                supervisor_id: null,
                account_manager_id: null,
                uni_status_id: null,
                signed_up_id: null,
                status: '',
            },
            signedUpStatusList:[
                {
                    id: 1,
                    label: 'Signed Up',
                },
                {
                    id: 2,
                    label: 'Not Signed Up',
                }
            ],
            download_url:'#',
            recruitmentMarketsModalList: [],
            recruitmentMarketsModalTableSize: 'md',
            recruitmentMarketsModalTableThColSpan: '',
        };
    },
    watch: {
    },
    methods:{
        showRecruitmentTerritoryListModal: function (item) {
            if (item.recruitment_markets.length > 10 && item.recruitment_markets.length <= 28) {
                this.recruitmentMarketsModalList = this.chunk(item.recruitment_markets, 2)
                this.recruitmentMarketsModalTableSize = 'md'
                this.recruitmentMarketsModalTableThColSpan = 2
            } else if (item.recruitment_markets.length > 28) {
                this.recruitmentMarketsModalList = this.chunk(item.recruitment_markets, 3)
                this.recruitmentMarketsModalTableSize = 'lg'
                this.recruitmentMarketsModalTableThColSpan = 3
            } else {
                this.recruitmentMarketsModalList = this.chunk(item.recruitment_markets, 1)
                this.recruitmentMarketsModalTableSize = 'sm'
                this.recruitmentMarketsModalTableThColSpan = ''
            }
            this.$refs.recruitment_territory_list_modal.show()
        },
        toggle_filter : function(){
            this.flag.show_filter=!this.flag.show_filter;
        },
        send_email_action_button_show: function () {
            this.send_email_action_checkbox = !this.send_email_action_checkbox
        },
        getAgentDisableStatus: function (item) {
            if ((item.uni_status.color == 'danger' || item.uni_status.color == 'warning') && !this.permission_check('agent_status_terminate')) {
                return true
            } else {
                return false
            }
        },
        getStatusListByAgentCode: function (item) {
            if (item.signed_up_at != null) {
                return this.statusList.filter(status => status.code != 'uni_agent_status_pending')
            } else {
                return this.statusList
            }
        },
        toggle_modal_form: function(){
            this.flag.show_modal_form = !this.flag.show_modal_form;
        },
        toggle_modal_activity_log: function(){
            this.flag.show_modal_activity_log = !this.flag.show_modal_activity_log;
        },
        toggle_modal_agreement: function(){
            this.flag.show_agreement_modal_form = !this.flag.show_agreement_modal_form;
        },
        showActiviyLog: function () {
            this.flag.show_modal_activity_log = !this.flag.show_modal_activity_log;
        },
        gotoAgentProfilePage: function (item) {
            if (this.permission_check('agent_profile')) {
                // this.$router.push({ name: 'AgentProfile', params: { id: this.hash_id(item.id) } })
                let routeData = this.$router.resolve({name: 'AgentProfile', params: { id: this.hash_id(item.id) }});
                window.open(routeData.href, '_blank');
            }
        },
        editData: function (item) {
            if (this.permission_check('aggen_edit')) {
                this.editItem = JSON.parse(JSON.stringify(item))
                this.flag.show_modal_form = !this.flag.show_modal_form;
            }
            // console.log('edit data', item)
        },
        editAgreementData: function (item) {
            if (!this.permission_check('agent_renewable_update')) {
                return;
            }
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.agreement_expiry = moment(this.editItem.agreement_expiry).format("DD MMM YYYY");
            this.flag.show_agreement_modal_form = !this.flag.show_agreement_modal_form;
            this.getAgentAgreementHistoryData(item)
        },
        updateAgentAgreementData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_agent_agreement'), ref.editItem);
                this.getListData()
                // this.toggle_modal_form()
                this.flag.show_modal_form = false
                this.flag.show_agreement_modal_form = false
                ref.alert(res.msg);
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        updateFormData: async function () {

            var ref=this;
            var jq=ref.jq();
            try{
                ref.wait_me('.wait_me_edit_form');
                var res = await jq.post(ref.url('api/v1/university/ajax/update_agent_info'), ref.editItem);
                this.getListData()
                // this.toggle_modal_form()
                this.flag.show_modal_form = false
                this.flag.show_agreement_modal_form = false
                ref.alert(res.msg);
                // Swal.fire({
                //     position: 'top-center',
                //     icon: 'success',
                //     title: res.msg,
                //     showConfirmButton: false,
                //     timer: 1500
                // })
            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.wait_me_edit_form', 'hide');
            }
        },
        status_name: function(row){

            var ref=this;
            const status =  ref.statusList.find(item=>{
                if (item.id == row.uni_status.id) {
                    return item
                }
            });
            // console.log('row', row)

            if (!status) {
                return 'Loading ...'
            } else {
                return status.name
            }

        },
        status_color: function(row){

            var ref=this;
            const status =  ref.statusList.find(item=>{
                if (item.id == row.uni_status.id) {
                    return item
                }
            });

            if (!status) {
                return 'dark'
            } else {
                return status.color
            }

        },
        check_all_invitaion: function(check_all=false){
            // console.log('check_all', check_all)
            var ref=this;
            var invitation_from=ref.form.send_invitation;

            if(check_all){
                ref.sendInvitationEmailButtonShow = !ref.sendInvitationEmailButtonShow
                if(invitation_from.checked_all){
                   ref.agencyList.forEach(row=>{
                       invitation_from.agent_ids.push(row.id);
                   });
                }else invitation_from.agent_ids=[];

            }else{
                if(invitation_from.agent_ids.length==ref.agencyList.length){
                    invitation_from.checked_all=true;
                }else invitation_from.checked_all=false;
            }
        },
        clearSearchData: function () {
            this.search = {
                region_id: null,
                country_id: null,
                territory_id: null,
                name: '',
                agent_code: '',
                contact_person: '',
                contact_email: '',
                supervisor_id: null,
                account_manager_id: null,
                uni_status_id: null,
                signed_up_id: null,
                status: '',
            }
            this.getListData()
            this.getListCount()
        },
        async searchData () {
            this.getListData()
            this.getListCount()
        },
        toggle_agent_form: function(){
            this.flag.show_agent_form=!this.flag.show_agent_form;
        },
        getListData: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage }, this.search)
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_agency_list'), params);
                //console.log('this.agencyList  ========', res.data)
                this.agencyList = res.data.map(item => {
                    item.domicile = item.domicile == 1 ? true : false

                    if (!item.recruitment_markets.length) {
                        item.recruitment_market_country_ids = null
                        item.recruitment_market_territory_ids = null
                    } 
                    
                    if (item.recruitment_markets.length) {
                        if (item.recruitment_market_type == 1) {
                            item.recruitment_market_territory_ids = null
                        }

                        // if (item.recruitment_market_type == 2) {
                        //     item.recruitment_market_country_ids = null
                        // }

                        item.recruitment_markets.sort(function(a, b) {
                            var nameA = a.country.name.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.country.name.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        });
                    }



                    item.uni_status = Object.assign({}, item.uni_status, JSON.parse(item.uni_status.data))
                    return Object.assign({}, item)
                });
                // console.log('this.agencyList', this.agencyList)
                this.paginationData(res)
                this.gen_download_url();

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        getStatusWiseListData: async function(status){
            this.search.status = status

            var ref=this;
            var jq=ref.jq();
            try {
                ref.wait_me('.agency-list-card');
                const params = Object.assign({}, { page: this.pagination.currentPage, per_page: this.pagination.perPage }, this.search)
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agency_list'), params);
                //console.log('this.agencyList  ========', res.data)
                this.agencyList = res.data.map(item => {
                    item.uni_status = Object.assign({}, item.uni_status, JSON.parse(item.uni_status.data))
                    return Object.assign({}, item)
                });
                // console.log('this.agencyList', this.agencyList)
                this.paginationData(res)
                this.gen_download_url();

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
            finally {
                ref.wait_me('.agency-list-card', 'hide');
            }
        },
        getListCount: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                const params = Object.assign({}, this.search)
                var res = await jq.get(ref.url('api/v1/uclan/ajax/get_agency_list_count'), params);
                this.agencyListCount = res.data.list_count
                this.agencySingUpListCount = res.data.signed_up_count
                this.agencyInactiveListCount = res.data.inactive_count
                this.agencyNewlySignedUpCount = res.data.newly_signed_up_count

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        // getListSignUpCount: async function(){

        //     var ref=this;
        //     var jq=ref.jq();
        //     try {
        //         var res = await jq.get(ref.url('api/v1/university/ajax/get_agency_signup_list_count'));
        //         this.agencySingUpListCount = res.data.list_count

        //     } catch (err) {
        //         ref.alert(ref.err_msg(err), 'error');
        //     }
        // },
        // getInactiveListCountCount: async function(){

        //     var ref=this;
        //     var jq=ref.jq();
        //     try {
        //         var res = await jq.get(ref.url('api/v1/university/ajax/get_agency_inactive_list_count'));
        //         this.agencyInactiveListCount = res.data.list_count

        //     } catch (err) {
        //         ref.alert(ref.err_msg(err), 'error');
        //     }
        // },
        // getNewlySignedUpCountCount: async function(){

        //     var ref=this;
        //     var jq=ref.jq();
        //     try {
        //         var res = await jq.get(ref.url('api/v1/university/ajax/get_agency_newly_signed_up_count'));
        //         this.agencyNewlySignedUpCount = res.data.list_count

        //     } catch (err) {
        //         ref.alert(ref.err_msg(err), 'error');
        //     }
        // },
        getRegionList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_region_dropdown_list'));
            this.regionList = res.data.regionList.map(item => {
                return {
                    id: item.id,
                    label: item.title
                }
            })
            this.regionData = res.data.regionList
        },
        getTerritoryList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_active_territory_list_data'));
            this.territoryList = res.data.list.map(item => {
                return {
                    id: item.id,
                    label: item.name
                }
            })
        },
        getAgentAgreementHistoryData: async function (item) {
            var ref=this;
            var jq=ref.jq();
            const params = { id: item.id }
            var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_agreement_hisotry_list'), params);
            this.agentAgreementHisotryList = res.data.list
        },
        invitation_confirmation: function(){
            if(!this.permission_check('agent_send_mail')) {
                return;
            }
            // this.$refs.invitation_confirmation.show();
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Invitation email confirmation',
                html: "Do you want to send invitation email to all newly added agents?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.send_invitation_email()
                }
            })
        },
        send_invitation_email: async function(){

            // this.$refs.invitation_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/uclan/ajax/send_invitation_emails');

            try{

                ref.wait_me('.agency-list-card');
                var res=await jq.post(url, ref.form.send_invitation);

                ref.form.send_invitation.checked_all=false;
                ref.form.send_invitation.agent_ids=[];
                this.getListData();
                // ref.alert(res.msg);
                Swal.fire({
                    position: 'top-center',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        agent_status: function(row){

            var ref=this;
            return ref.list.agent_statuses.find(each=>{
                return each.id==row.status;
            });

        },
        agent_status_update_confirmation: function(agent_id, new_status){
            this.form.agent_status_update.agent_id=agent_id;
            this.form.agent_status_update.new_status=new_status;
            this.$refs.agent_status_confirmation.show();
        },
        statusDropdownDisable: function(dropdown, item){
            if (dropdown.name == 'Uploaded' || dropdown.name == 'Verified' || item.bulk_verification_status_name == 'Verify') {
                return true
            } else {
                return false
            }
        },
        deleteData: function(item){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Delete Agent!',
                html: `Are you sure to delete the agent <b>${item.name}</b>?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteDataConfirmed(item)
                }
            })
        },
        deleteDataConfirmed: async function(item){
            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { id: item.id })
                var res = await jq.post(ref.url('api/v1/university/ajax/delete_agent_company'), params);
                this.getListData();
                this.getListCount();
                swal("Agent Delete", res.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        agent_uni_status_update: function(item, dropdown){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Status Update!',
                html: "Are you sure to change status?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    if (dropdown.code == "uni_agent_status_inactive" || dropdown.code == "uni_agent_status_suspended") {
                        this.agent_uni_status_update_with_reason_confirm(item, dropdown)
                    } else {
                        this.agent_uni_status_update_without_reason_confirm(item, dropdown)
                    }
                }
            })
        },
        agent_uni_status_update_with_reason_confirm: async function(item, dropdown){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })
            const reason = dropdown.code == "uni_agent_status_suspended" ? 'Suspension' : 'Termination'
            swalWithBootstrapButtons.fire({
                title: 'Write the reason for ' + reason,
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Submit',
                showLoaderOnConfirm: true,
                // reverseButtons: true,
                preConfirm: async (reason_value) => {
                    var ref=this;
                    var jq=ref.jq();

                    try {
                        const params = Object.assign({}, { id: item.id, uni_status_id: dropdown.id, code: dropdown.code, uni_agent_status_reason: reason_value })
                        var res = await jq.post(ref.url('api/v1/university/ajax/update_agent_uni_status'), params);
                        this.getListData();
                        this.getListCount();
                        swal("Status Update", res.msg, "success")

                    } catch (err) {
                        ref.alert(ref.err_msg(err), 'error');
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
             })
        },
        agent_uni_status_update_without_reason_confirm: async function(item, dropdown){
            var ref=this;
            var jq=ref.jq();

            try {
                const params = Object.assign({}, { id: item.id, uni_status_id: dropdown.id, code: dropdown.code })
                var res = await jq.post(ref.url('api/v1/university/ajax/update_agent_uni_status'), params);
                this.getListData();
                this.getListCount();
                swal("Status Update", res.msg, "success")

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        update_agent_status: async function(){

            this.$refs.agent_status_confirmation.hide();

            var ref=this;
            var jq=this.jq();
            var url=ref.url('api/v1/university/ajax/update_agent_status');

            try{

                ref.wait_me('.agency-list-card');
                var res=await jq.post(url, ref.form.agent_status_update);

                ref.form.agent_status_update.agent_id='';
                ref.form.agent_status_update.new_status='';
                ref.alert(res.msg);
                ref.getListData();

            }catch(err){
                ref.alert(ref.err_msg(err), 'error')
            }finally{
                ref.wait_me('.agency-list-card', 'hide');
            }

        },
        send_single_mail: function(item){

            var ref=this;
            ref.form.send_invitation.checked_all=false;
            ref.form.send_invitation.agent_ids.push(item.id);
            // ref.invitation_confirmation();
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-2'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Send Agent Invitation Email',
                html: "Do you want to send invitation email to <b>"+ item.name + "</b>?",
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                // reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.send_invitation_email()
                }
            })

        },
        getCountryList: function (regionId) {
            if (regionId) {
                const regionCountryList = this.regionCountryList.filter(item => item.region_id == regionId)
                const countryMappingData = regionCountryList.map(item => {
                    const countryObj = this.$store.state.site.countries.find(country => country.id == item.country_id)
                    const countryData = {
                        id: countryObj.id,
                        label: countryObj.name,
                    }
                    return Object.assign({}, countryData)
                })
                return countryMappingData
            }
        },
        getStatusList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                const params = { group_code: 'uni_agent_statuses' }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_statuses_by_groups'), params);
                this.statusList = res.data.statuses.map(item => {
                    return Object.assign({}, item, JSON.parse(item.data))
                })

                this.treeselectStatusList = res.data.statuses.map(item => {
                    return {
                        id: item.id,
                        label: item.name
                    }
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        getAccountManagerList: async function(){

            var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_account_managers'));
                this.accountManagerList = res.data.managers.map(item => {
                    return {
                        id: item.id,
                        label: item.family_name + item.given_name
                    }
                })

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        gen_download_url: function () {
            var ref=this;
            var jq=this.jq();
            var hash_ids  = new Hashids('sams_pay_export_hash_salt', 30);
            var institute_id = ref.store_auth_user.institute_id;
            this.download_url = ref.url('download/agency_list/'+hash_ids.encode(institute_id));
            this.search.auth_id = ref.store_auth_user.id
            var search_query = jq.param(this.search)
            this.download_url += '?' + search_query

        },
        getUsersList: async function () {
            var ref=this;
            var jq=ref.jq();
            var res = await jq.get(ref.url('api/v1/university/ajax/get_uni_users_list'));
            this.usersList = res.data.usersList
        },
        open_agent_activity_log: async function (item) {
            var ref=this;
            var jq=ref.jq();
            this.agentActivitylog = []
            this.$refs.agent_activity_log_modal.show_modal()

            try {
                const params = { id: item.id }
                var res = await jq.get(ref.url('api/v1/university/ajax/get_agent_activity_log'), params);
                if (res.data.data.length) {
                    this.agentActivitylog = res.data.data
                } else {
                    this.agentActivitylog = null
                }

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }
        },
        open_assign_am_form_modal: function(row){

            if (!this.permission_check('assign_account_manager')) {
                return;
            }

            var form=this.form.assign_am;
            form.agent_id=row.id;
            form.agent_name=row.name;
            this.$refs.assign_account_manager.show();

        },
        open_agent_remarks_modal: function(item){
            this.agentId = item.id;
            this.$refs.agent_remarks_modal.show();

        },
        reset_assign_am_form: function(){

            var form=this.form.assign_am;
            form.agent_id=null;
            form.agent_name=null;
            form.uni_user_id=null;

        },
        submit_assign_am_form: async function(){

            var ref=this;
            var jq=ref.jq();
            var url=ref.url('api/v1/university/ajax/assign_agent_account_manager');

            var form_data=ref.form.assign_am;

            try{
                ref.wait_me('.assign_account_manager');
                var res=await jq.post(url, form_data);
                this.getListData();
                ref.alert(res.msg);
                ref.$refs.assign_account_manager.hide();
            }catch(err){
                ref.alert(ref.err_msg(err), 'error');
            }finally{
                 ref.wait_me('.assign_account_manager', 'hide');
            }
        },
        show_invitation_email: function(row){

            if(row.uni_status.code=='uni_agent_status_pending' && this.permission_check('agent_send_mail') && row.email){
                return true;
            }else return false;
             
        }
    },
    computed: {
        store_countries: function(){
            
            return this.$store.state.site.countries

        },
        // countryList: function(){
        //     return this.store_countries.map(each=>{
        //         return {
        //             id: each.id,
        //             label: each.name
        //         }
        //     });
        // },
        region_countries: function () {
            var regionId = this.search.region_id
            if (regionId) {
                var region = this.regionData.find(item => item.id == regionId)
                return region.region_countries.map(item => {
                    return {
                        id: item.country.id,
                        label: item.country.name,
                    }
                })
            } else {
                return this.countryList
            }
        },
        store_auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        },
        treeselect_uni_users: function(){

            return this.usersList.map((row)=>{
                return {
                    id: row.id,
                    label: row.name
                }
            });

        },
    },
    created: async function(){
        this.setup_urls();
        this.gen_download_url();
        const countries = await this.countries()
        this.countryList = countries.map(item => {
            return {
                id: item.id,
                label: item.name
            }
        })
        this.getStatusList();
        this.getAccountManagerList();
    },
    mounted: function(){
        this.getListCount();
        // this.getListSignUpCount();
        // this.getInactiveListCountCount();
        // this.getNewlySignedUpCountCount();
        this.getListData();
        this.getRegionList()
        this.getTerritoryList()
        // this.getAgentAgreementHistoryData()
        this.getUsersList()
        feather.replace();

        this.$root.$on('bv::modal::hide', () => {
            this.reset_assign_am_form();
        });

    }
}
</script>

<style>
.agent_status_button {
    position: relative !important;
    display: inline-flex !important;
    vertical-align: middle !important;
    min-width: 111px !important;
}
/* .btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    min-width: 111px;
} */
</style>
